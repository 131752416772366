import { FormField, Text } from '@simplisafe/ss-react-components/atoms'
import React from 'react'

type GetAQuoteTitleProps = {
  readonly formTitle?: string
}

const Title: React.FC<GetAQuoteTitleProps> = ({ formTitle }: GetAQuoteTitleProps) => {
  return (
    <div data-component={'Title'}>
      <FormField hideLabel={true} name="getAQuoteFormDescription">
        <Text fontWeight="light">
          <h2>{formTitle}</h2>
        </Text>
      </FormField>
    </div>
  )
}

export default Title
