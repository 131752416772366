import {
  FormField,
  FormTextInput,
  Text
} from '@simplisafe/ss-react-components/atoms'
import React from 'react'

type NameProps = {
  readonly nameFieldLabel: string
  readonly nameFieldPlaceholder: string
}

const Name: React.FC<NameProps> = (props: NameProps) => {
  return (
    <div data-component={'Name'}>
      <FormField hideLabel={true} label={props.nameFieldLabel}
        name='name'>
        <Text fontWeight='medium'>{props.nameFieldLabel}</Text>
        <FormTextInput fullWidth={true} name='name'
          placeholder={props.nameFieldPlaceholder} />
      </FormField>
    </div>
  )
}

export default Name
