import prop from '@simplisafe/ewok/ramda/prop'
import moment from 'moment-business-days'
import defaultTo from 'ramda/src/defaultTo'

const dateFormat = 'YYYY-MM-DD'

/**
 * Return 4 digit number of the current year
 */
export const getCurrentYear = () => {
  const date = moment()
  return date.year()
}

/**
 * Add a leading number if a number is less than 10
 */
export const padNumber = (n: number) => {
  const paddedNumber = (n < 10) ? `0${n}` : n
  return paddedNumber.toString()
}

/**
 * Return a date for the first Monday of the requested month in the current year
 */
export const getFirstMondayOfMonth = (monthNumber: string) => {
  const currentYear = getCurrentYear()
  const firstDayOfMonth = moment(`${currentYear}-${monthNumber}-01`).isoWeekday(8)
  const firstMondayDay = firstDayOfMonth.date() > 7 ? moment(firstDayOfMonth).isoWeekday(-6)
    .date() : firstDayOfMonth.date()
  const firstMondayDate = moment(`${currentYear}-${monthNumber}-${padNumber(firstMondayDay)}`).format(dateFormat)
  return firstMondayDate.toString()
}

/**
 * Return a date for the last specific Day of the week in a month in the current year
 */
export const getLastDayOfMonth = (month: number, dayOfWeek: string) => {
  const currentYear = getCurrentYear()
  const getDate = moment(`${currentYear}-${padNumber(month)}-01`).endOf('month')
    .day(dayOfWeek)
  const currentDate = moment().toString()
  // TODO: fix type
  // @ts-ignore
  const holidayDate: string = defaultTo(currentDate)(prop('_d', getDate))
  return moment(holidayDate).format('YYYY-MM-DD')
}

/**
 * Return a date for Thanksgiving Day in current year
 */
export const getThanksgivingDate = () => {
  const currentYear = getCurrentYear()
  const firstDayOfNovember = moment(`${currentYear}-11-01`)
  const dayOfWeekFirst = firstDayOfNovember.day()
  const thanksgivingDay = (22 + (11 - dayOfWeekFirst) % 7)
  const thanksgivingDate = moment(`${currentYear}-11-${thanksgivingDay}`).format(dateFormat)
  return thanksgivingDate.toString()
}

/**
 * Return a list of holidays
 * This includes a list of holidays for US only
 */
export const businessHolidaysList = () => {
  const currentYear = getCurrentYear()
  // get a list of holidays
  // TODO: a list of holidays was copied from Drupal, we may need to add additional holidays
  const memorialDay = getLastDayOfMonth(5, 'Monday')
  const julyFourthHoliday = `${currentYear}-07-04`
  const thanksgivingDay = getThanksgivingDate()
  const christmasEve = `${currentYear}-12-24`
  const christmasHoliday = `${currentYear}-12-25`
  const newYearsHoliday = `${currentYear + 1}-01-01`
  const laborDay = getFirstMondayOfMonth('09')
  // return array of US Holidays Dates
  return [
    memorialDay,
    julyFourthHoliday,
    laborDay,
    thanksgivingDay,
    christmasEve,
    christmasHoliday,
    newYearsHoliday
  ]
}

/**
 * Returns a date for the next business day
 * This is built for US only
 */
export const returnNextBusinessDay = () => {
  const currentDate = moment()
  const formattedDate = currentDate.format(dateFormat)

  moment.updateLocale(
    'us', {
      holidayFormat: dateFormat,
      holidays: businessHolidaysList()
    },
  )

  const nextBusinessDay = moment(formattedDate, dateFormat).nextBusinessDay()
  const nextDay = moment().add(1, 'day')
  // TODO: fix type
  // @ts-ignore
  const dayProp: string = defaultTo(nextDay)(prop('_d', nextBusinessDay))
  return moment(dayProp).format('MMMM D')
}
