import { window } from 'browser-monads-ts'
import { useEffect, useState } from 'react'

import { Status } from './useScript'

const useLiveChatClientExists = (status: Status) => {

  // Maximum number of retries to check for window.sprChat.
  const MAX_NUM_RETRIES = 10

  // Interval to poll for window.sprChat availability.
  const POLL_INTERVAL = 1000


  const [ pollForLiveChat, setPollForLiveChat ] = useState(false)
  const [ sprChatExists, setSprChatExists ] = useState<boolean>(!!window.sprChat)
  const [ sprChatInitialized, setSprChatInitialized ] = useState(false)
  const [ numRetries, setNumRetries ] = useState(0)

  // This effect does the polling.
  useEffect(() => {
    const timer = setTimeout(() => {
      setSprChatExists(!!window.sprChat)
      setNumRetries(numRetries => numRetries + 1)
    }, POLL_INTERVAL)

    return () => clearTimeout(timer)
  }, [ pollForLiveChat ])

  // This effect determines if we should continue polling or not.
  useEffect(() => {
    status === 'ready' && !sprChatExists && numRetries < MAX_NUM_RETRIES && setPollForLiveChat(true)
  }, [ numRetries, sprChatExists, status ])

  // This effect determines if we should initialize chatTracking after polling has completed.
  useEffect(() => {
    const initializeChatClientExists = () => {
      setSprChatInitialized(true)
    }

    sprChatExists && !sprChatInitialized && initializeChatClientExists()
  }, [ sprChatExists, sprChatInitialized ])

  return sprChatInitialized
}

export default useLiveChatClientExists
