import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import {
  HowItWorksDetail, HowItWorksDetailWrapper, HowItWorksHouse, HowItWorksHouseData, HowItWorksNav
} from '@simplisafe/ss-react-components/organisms'
import { ViewName } from '@simplisafe/ss-react-components/organisms/HowItWorksHouse/data'
import { graphql } from 'gatsby'
import defaultTo from 'ramda/src/defaultTo'
import isNil from 'ramda/src/isNil'
import React, { FC, useState } from 'react'

import { ContentfulHowItWorksDevice, ContentfulHowItWorksInteractiveHouse } from '../../../graphql'
import getDescriptionJson from '../../util/getDescriptionJson'
import FixedImg from '../FixedImg'
import FluidImg from '../FluidImg'
import RichText from '../RichText'

type HowItWorksInteractiveHouseProps = {
  readonly id: string
  readonly data: Partial<ContentfulHowItWorksInteractiveHouse>
}

/**
 * Contentful Nice Name => DeviceName
 *
 * The Contentful Nice Name comes from data.devices[x].device.
 * That value is a human-readable device they choose on the backend
 * to associate an icon, title, image, and more details with.
 *
 * This object maps those human-readable value to the DeviceName types
 * defined in ss-react-components/organisms/HowItWorksHouse/data, where
 * the DeviceName is used as CSS classes to highlight on each house view.
 */
type Keys = '105dB Siren' | 'Base Station' | 'Entry Sensor' | 'Glassbreak Sensor' | 'Keyfob'  | 'Keypad'  | 'Motion Sensor' | 'Panic Button' | 'SimpliCam'  | 'Smoke Detector' | 'Temperature Sensor' | 'Water Sensor' | 'Outdoor Camera';
type Values = 'siren' | 'baseStation' | 'entrySensor' | 'glassbreakSensor' | 'keyfob' | 'keypad' | 'motionSensor' | 'panicButton' | 'simplicam' | 'smokeDetector' | 'temperatureSensor' | 'waterSensor' | 'outdoorCamera'

type activeDeviceValues = undefined | Values

const deviceSlugsByName: Record<Keys, Values> = {
  '105dB Siren': 'siren',
  'Base Station': 'baseStation',
  'Entry Sensor': 'entrySensor',
  'Glassbreak Sensor': 'glassbreakSensor',
  'Keyfob': 'keyfob',
  'Keypad': 'keypad',
  'Motion Sensor': 'motionSensor',
  'Outdoor Camera': 'outdoorCamera',
  'Panic Button': 'panicButton',
  'SimpliCam': 'simplicam',
  'Smoke Detector': 'smokeDetector',
  'Temperature Sensor': 'temperatureSensor',
  'Water Sensor': 'waterSensor'
}

/**
 * Re-usable style for Gatsby Image.
 */
const defaultHouseImageProps = {
  imgStyle: { objectFit: 'contain' },
  style: {
    height: '100%',
    width: '100%'
  }
}

const createMenuItems = (devices: readonly ContentfulHowItWorksDevice[]) => {
  return devices
    .map(device => {
      const dev = prop('device', device)
      const id: string = isNil(dev) ? '' : deviceSlugsByName[dev]
      return {
        icon: <FixedImg
          alt={path([ 'icon', 'description' ], device)}
          fixed={path([ 'icon', 'fixed' ], device)}
          loading='eager'
          {...defaultHouseImageProps}
        />,
        id,
        key: prop('id', device),
        label: defaultTo('')(prop('title', device)),
      }
    })
}

/**
 * Creates an object where keys are device slugs, and values
 * are the associated rendered JSX output.
 */
const createDetails = (devices: readonly ContentfulHowItWorksDevice[]) => {
  return devices.reduce((accum, device) => {
    const dev = prop('device', device)
    const key: string = isNil(dev) ? '' : deviceSlugsByName[dev]
    return {
      ...accum,
      ...{
        [key]: <HowItWorksDetail
          content={<RichText json={getDescriptionJson(device)} />}
          image={
            <FluidImg
              alt={path([ 'image', 'description' ], device)}
              fluid={path([ 'image', 'fluid' ], device)}
              style={{ height: '100%' }}
            />
          }
          title={defaultTo('')(prop('title', device))}
        />,
      }
    }
  }, {})
}

const HowItWorksInteractiveHouse: FC<HowItWorksInteractiveHouseProps> =
  ({ data }: HowItWorksInteractiveHouseProps) => {
    const [ activeDevice, setActiveDevice ] = useState<activeDeviceValues>(undefined)
    const [ activeView, setActiveView ] = useState<ViewName>('insideFront')
    const [ isDetailOpen, setIsDetailOpen ] = useState<boolean>(false)

    const handleNavChange = menuItemId => {
      setActiveView(HowItWorksHouseData.getDeviceView(menuItemId))
      setActiveDevice(menuItemId)
      setIsDetailOpen(true)
    }

    const handleButtonChange = buttonId => {
      setActiveView(buttonId)
    }

    const dataDevices = prop('devices', data)
    const nonNullDevices = isNil(dataDevices) ? [] : dataDevices.filter((deva): deva is ContentfulHowItWorksDevice => !isNil(deva))
    const navItems = createMenuItems(nonNullDevices)
    const details = createDetails(nonNullDevices)

    const fixedImage = (imgPath) => path([ imgPath, 'fixed' ], data)

    return (
      <>
        <HowItWorksHouse
          activeDevice={activeDevice}
          activeView={activeView}
          houseFrontImage={
            <FixedImg alt={path([ 'houseFrontImage', 'description' ], data)}
              fixed={fixedImage('houseFrontImage')}
              {...defaultHouseImageProps}
            />
          }
          houseFrontLabel={defaultTo('')(prop('houseFrontLabel', data))}
          insideBackImage={
            <FixedImg alt={path([ 'insideBackImage', 'description' ], data)}
              fixed={fixedImage('insideBackImage')}
              {...defaultHouseImageProps}
            />
          }
          insideBackLabel={defaultTo('')(prop('insideBackLabel', data))}
          insideFrontImage={
            <FixedImg alt={path([ 'insideFrontImage', 'description' ], data)}
              fixed={fixedImage('insideFrontImage')}
              {...defaultHouseImageProps}
            />
          }
          insideFrontLabel={defaultTo('')(prop('insideFrontLabel', data))}
          onButtonChange={handleButtonChange}
          title={defaultTo('')(prop('title', data))}
        />
        <HowItWorksNav
          activeMenuItem={activeDevice}
          menuItems={navItems}
          onMenuItemChange={handleNavChange}
        />
        <HowItWorksDetailWrapper
          isOpen={isDetailOpen}
          onClose={() => {
            setActiveDevice(undefined)
            setIsDetailOpen(false)
          }}
        >
          {/* TODO: fix type */}
          {/* @ts-ignore */}
          {activeDevice ? prop(activeDevice, details) : ''}
        </HowItWorksDetailWrapper>
      </>
    )
  }

export default HowItWorksInteractiveHouse

export const howItWorksInteractiveHouseQuery = graphql`#graphql
  fragment howItWorksInteractiveHouse on ContentfulHowItWorksInteractiveHouse {
    devices {
      device
      description {
        json
      }
      icon {
        id
        title
        description
        fixed(width: 72) {
          ...GatsbyContentfulFixed_withWebp_noBase64
        }
      }
      image {
        id
        title
        description
        fluid(maxWidth: 1200) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      title
      id
    }
    houseFrontImage {
      id
      title
      description
      fixed(width: 1140) {
        ...GatsbyContentfulFixed_withWebp_noBase64
      }
    }
    houseFrontLabel
    id
    insideBackImage {
      id
      title
      description
      fixed(width: 1140) {
        ...GatsbyContentfulFixed_withWebp_noBase64
      }
    }
    insideBackLabel
    insideFrontImage {
      id
      title
      description
      fixed(width: 1140) {
        ...GatsbyContentfulFixed_withWebp_noBase64
      }
    }
    insideFrontLabel
    internal {
      type
    }
    title
  }
`
