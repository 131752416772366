import {
  FormField,
  FormTextInput,
  Text,
} from '@simplisafe/ss-react-components/atoms'
import React from 'react'

type CompanyProps = {
  readonly companyFieldLabel: string
  readonly companyFieldPlaceholder: string
};

const Company: React.FC<CompanyProps> = ({ companyFieldLabel, companyFieldPlaceholder }: CompanyProps) => {
  return (
    <div data-component={'Company'} style={{ marginBottom: '32px' }}>
      <FormField
        hideLabel={true}
        label={companyFieldLabel}
        name="company"
      >
        <Text fontWeight="medium">{companyFieldLabel}</Text>
        <FormTextInput
          fullWidth={true}
          name="company"
          placeholder={companyFieldPlaceholder}
        />
      </FormField>
    </div>
  )
}

export default Company
