import * as Promo from '@simplisafe/ss-react-components/promotions'
import propOr from 'ramda/src/propOr'
import React, { FC } from 'react'

import { ContentfulBannerTopPage } from '../../../graphql'
import { SimpliSafeCSSProperties } from '../../types/SimpliSafeCSSProperties'

export type HeaderBannerProps = {
  readonly data: ContentfulBannerTopPage
}

const TopPageBanner: FC<HeaderBannerProps> = ({ data }: HeaderBannerProps) => {

  const backgroundColor: string = propOr<string, string>('var(--promo-banner-bg)', 'backgroundColor', data)
  const primaryTextColor: string = propOr<string, string>('var(--white)', 'primaryTextColor', data)
  const secondaryTextColor: string = propOr<string, string>('var(--white)', 'secondaryTextColor', data)

  const primaryText: string = propOr<string, string>('', 'primaryText', data)
  const secondaryText: string = propOr<string, string>('', 'secondaryText', data)

  const styles: SimpliSafeCSSProperties = {
    '--promo-banner-bg': backgroundColor,
    '--promo-primary-text': primaryTextColor,
    '--promo-secondary-text': secondaryTextColor,
  }

  return !primaryText ? null : (
    <div style={styles}>
      <Promo.PromoBanner role="banner">
        <Promo.PartnerOfferText isSingleLine primaryText={primaryText}
          secondaryText={secondaryText} />
      </Promo.PromoBanner>
    </div>
  )
}

export default TopPageBanner
