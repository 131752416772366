import { RichText, SSButton, } from '@simplisafe/ss-react-components/atoms'
import { IconWithText } from '@simplisafe/ss-react-components/molecules'
import Img, { FluidObject } from 'gatsby-image'
import cond from 'ramda/src/cond'
import equals from 'ramda/src/equals'
import T from 'ramda/src/T'
import React, { FC, ReactNode } from 'react'

import { AlarmSensorSystemFragment } from '../../../graphql'
import { toButton } from '../../util/helper'

export type LegacyBannerProps = Partial<AlarmSensorSystemFragment>

const interactiveMonitoringKey = 'Interactive Monitoring'
const legacySystemKey = 'Legacy System'

const discountTextColors = {
  [interactiveMonitoringKey]: 'neutralWhite',
  [legacySystemKey]: 'brandPrimary'
}

const textColors = {
  [interactiveMonitoringKey]: 'neutralWhite',
  [legacySystemKey]: 'neutralBlack'
}

const textClasses = {
  [interactiveMonitoringKey]: 'fontSize36 neutralWhiteTextColor fontWeightLight',
  [legacySystemKey]: 'prose'
}

const textDiscountClasses = {
  [interactiveMonitoringKey]: 'fontSize44',
  [legacySystemKey]: '',
}

const subtextClasses = {
  [interactiveMonitoringKey]: 'fontWeightLight',
  [legacySystemKey]: ''
}

// TODO: We shouldn't be defining custom helper functions in components, explore using stringProp instead
const getOrEmptyString = (obj: { readonly [key: string]: string }, key: string): string => obj[key] || ''

type DiscountTextProps = {
  readonly children?: ReactNode
  readonly className?: string
  readonly style: string
}

const DiscountText = ({
  children, className, style
}: DiscountTextProps) => (
  <span
    style={{
      display:'inline-block',
      margin: '0 4px'
    }}
  >
    <RichText className={className}
      inlineComponent={children}
      textColor={getOrEmptyString(discountTextColors, style)} />
  </span>
)

const renderPlaceholderText = (
  text: string,
  className: string,
  discountClassName: string,
  style: string,
  legacyDiscount: string | null | undefined,
  legacyPromoCode: string | null | undefined
): readonly ReactNode[] => text.split(/(?:{{|}})/g)
  .filter(token => token.length > 0)
  .map((token, idx) => (
    cond([
      [ equals('legacy_discount'), () => (
        <DiscountText className={discountClassName}
          key={`discount-${idx}`}
          style={style}>{legacyDiscount}</DiscountText>
      ) ],
      [ equals('legacy_promo_code'), () => (
        <DiscountText className={discountClassName}
          key={`promo-code-${idx}`}
          style={style}>{legacyPromoCode}</DiscountText>
      ) ],
      [ T, (token: string) => (
        <RichText className={`inlineElement ${className}`}
          inlineComponent={token}
          key={`text-${idx}`}
          textColor={getOrEmptyString(textColors, style)} />
      ) ]
    ])(token)
  ))

const LegacyBanner: FC<LegacyBannerProps> = ({
  legacyDiscount, legacyPromoCode, legacyText, legacySubtext, legacyIcon, legacyButton, legacyStyle
}: LegacyBannerProps) => {
  const style = legacyStyle || legacySystemKey

  const icon = legacyIcon && legacyIcon.fluid && <Img alt="Legacy Banner Icon"
    fluid={legacyIcon.fluid as FluidObject} />

  const text = <div style={{
    display: 'flex',
    flexDirection: 'column',
  }}>
    { legacyText && <div style={{
      textAlign: 'center',
      width: '100%'
    }}>
      {renderPlaceholderText(legacyText, getOrEmptyString(textClasses, style), getOrEmptyString(textDiscountClasses, style), style, legacyDiscount, legacyPromoCode)}
    </div> }
    { legacySubtext && <div style={{
      textAlign: 'center',
      width: '100%'
    }}>
      {renderPlaceholderText(legacySubtext, getOrEmptyString(subtextClasses, style), '', style, legacyDiscount, legacyPromoCode)}
    </div> }
  </div>

  const button = legacyButton ? <div style={{ marginLeft: '2rem' }}>
    <SSButton {...toButton(legacyButton)}
      type="link" />
  </div> : null

  return (legacyText ?
    <div style={{
      alignItems: 'center',
      backgroundColor: style === interactiveMonitoringKey ? '#415364' : undefined,
      border: 'solid 1px #F6F6F5',
      display: 'flex',
      flexWrap:'wrap',
      justifyContent: 'center',
      padding: style === interactiveMonitoringKey ? '2rem' : '0'
    }}>
      {
        icon ? <IconWithText icon={icon}
          iconSize="medium"
          text={text} /> : text
      }
      {button}
    </div> : null)
}

export default LegacyBanner
