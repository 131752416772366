import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safePath, safeProp } from '@simplisafe/monda'
import {
  leadGenSubscribe, LeadGenSubscriptionParams, leadGenUnsub
} from '@simplisafe/ss-ecomm-data/leads/subscription'
import {
  RichText, SSButton, SSInput
} from '@simplisafe/ss-react-components/atoms'
import { ButtonColor } from '@simplisafe/ss-react-components/atoms/SSButton'
import { UnsubscribeForm } from '@simplisafe/ss-react-components/molecules'
import { graphql } from 'gatsby'
import { Maybe, None } from 'monet'
import propOr from 'ramda/src/propOr'
import React, {
  FC,
  FormEvent,
  useEffect,
  useRef,
  useState
} from 'react'

import { UnsubscribePageContentsFragment } from '../../../graphql'
import { getLeadData } from '../../tracking/cookies'
import getJson from '../../util/getJson'
import { strToCamelCase } from '../../util/helper'

export type UnsubscribePageContentsProps = {
    readonly data: UnsubscribePageContentsFragment
}

const UnsubscribePageContents: FC<UnsubscribePageContentsProps> = ({ data }: UnsubscribePageContentsProps) => {
  const [ emailAddress, setEmailAddress ] = useState(propOr<string, string>('', 'email', getLeadData()))
  const [ wasSuccessfullyUnsubscribed, setWasSuccessfullyUnsubscribed ] = useState(false)
  const [ wasSuccessfullySubscribed, setWasSuccessfullySubscribed ] = useState(false)
  const [ isSubscribeCheckboxChecked, setIsSubscribeCheckboxChecked ] = useState(false)

  const [ _error, setError ] = useState(None<string>())

  const emailInputRef = useRef<HTMLInputElement>()

  const resetDisplayState = () => {
    setWasSuccessfullyUnsubscribed(false)
    setWasSuccessfullySubscribed(false)
    setError(None())
  }

  useEffect(() => {
    emailInputRef.current.focus()
  }, [])

  const handleEmailChange = (e) => setEmailAddress(path([ 'target', 'value' ], e))

  const handleSubscribeCheckboxChange = (e) => setIsSubscribeCheckboxChecked(path([ 'target', 'checked' ], e))

  const handleUnsubSubmitFailure = (err) => setError(Maybe.fromUndefined(err))
  const handleUnsubSubmitSuccess = () => {
    setWasSuccessfullyUnsubscribed(true)
    setEmailAddress('')
  }

  const handleUnsubscribeSubmit = (e: FormEvent) => {
    e.preventDefault()
    resetDisplayState()

    const leadGenSubscriptionParams: LeadGenSubscriptionParams = { email: emailAddress.trim() }
    leadGenUnsub(leadGenSubscriptionParams)(handleUnsubSubmitFailure)(handleUnsubSubmitSuccess)
  }

  const handleSubscribeSubmitFailure = (err) => setError(Maybe.fromUndefined(err))
  const handleSubscribeSubmitSuccess = () => setWasSuccessfullySubscribed(true)

  const handleSubscribeSubmit = () => {
    resetDisplayState()

    const leadGenSubscriptionParams: LeadGenSubscriptionParams = { email: emailAddress.trim() }

    isSubscribeCheckboxChecked && leadGenSubscribe(leadGenSubscriptionParams)(handleSubscribeSubmitFailure)(handleSubscribeSubmitSuccess)
  }

  const emailValidationErrorMessage = safeProp('genericErrorMessage', data).getOrElse('')

  const emailAddressInput =
    <SSInput
      error={_error.cata(() => false, () => true)}
      errorMsg={
        _error.cata(
          () => '',
          () => emailValidationErrorMessage
        )
      }
      id={'emailAddress'}
      label={safeProp('emailFieldLabel', data).getOrElse('')}
      onChange={handleEmailChange}
      placeholder={safeProp('emailFieldPlaceholder', data).getOrElse('')}
      ref={emailInputRef}
      type={'text'}
      value={emailAddress}
    />

  const subscribeCheckbox =
    <SSInput
      id={'subscribeCheckbox'}
      label={safeProp('subscribeCheckboxLabel', data).getOrElse('')}
      onChange={handleSubscribeCheckboxChange}
      type={'checkbox'}
    />

  const unsubscribeButton =
    <SSButton
      color={
        strToCamelCase(
          safePath([ 'unsubscribeButton', 'type' ], data)
            .getOrElse('')
        ) as ButtonColor
      }
      type={'submit'}
    >
      {path([ 'unsubscribeButton', 'text' ], data)}
    </SSButton>

  const subscribeButton =
    <SSButton
      color={
        strToCamelCase(
          safePath([ 'subscribeButton', 'type' ], data)
            .getOrElse('')
        ) as ButtonColor
      }
      onClick={handleSubscribeSubmit}
    >
      {path([ 'subscribeButton', 'text' ], data)}
    </SSButton>

  const successContent = getJson(prop('successMessage', data))
  const successBanner = (wasSuccessfullyUnsubscribed || wasSuccessfullySubscribed) && successContent
    ? <RichText json={successContent} />
    : null

  const needHelpPromptText = getJson(prop('needHelpPrompt', data))
  const needHelpPrompt = needHelpPromptText
    ? <RichText json={needHelpPromptText} />
    : null

  return (
    <form onSubmit={handleUnsubscribeSubmit}>
      <UnsubscribeForm
        emailAddressInput={emailAddressInput}
        emailAddressPrompt={safeProp('emailPrompt', data).getOrElse('')}
        needHelpLabel={safeProp('needHelpLabel', data).getOrElse('')}
        needHelpPrompt={needHelpPrompt}
        pageTitle={safeProp('pageTitle', data).getOrElse('')}
        subscribeButton={subscribeButton}
        subscribeCheckbox={subscribeCheckbox}
        subscriptionsHeader={safeProp('subscribeHeader', data).getOrElse('')}
        subscriptionsPrompt={safePath([ 'subscribePrompt', 'subscribePrompt' ], data).getOrElse('')}
        successBanner={successBanner}
        unsubscribeButton={unsubscribeButton}
        unsubscribeFinePrint={safePath([ 'unsubscribeFinePrint', 'unsubscribeFinePrint' ], data).getOrElse('')}
        unsubscribeHeader={safeProp('unsubscribeHeader', data).getOrElse('')}
        unsubscribePrompt={safePath([ 'unsubscribePrompt', 'unsubscribePrompt' ], data).getOrElse('')}
      />
    </form>
  )
}

export default UnsubscribePageContents

export const query = graphql`#graphql
  fragment unsubscribePageContents on ContentfulUnsubscribePageContents {
    id
    internal {
      type
    }
    emailFieldLabel
    emailFieldPlaceholder
    emailPrompt
    genericErrorMessage
    needHelpLabel
    needHelpPrompt {
      json
    }
    pageTitle
    subscribeButton {
      text
      type
    }
    subscribeCheckboxLabel
    subscribeHeader
    subscribePrompt {
      subscribePrompt
    }
    successMessage {
      json
    }
    unsubscribeButton {
      text
      type
    }
    unsubscribeFinePrint {
      unsubscribeFinePrint
    }
    unsubscribeHeader
    unsubscribePrompt {
      unsubscribePrompt
    }
  }
`
