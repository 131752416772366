import path from '@simplisafe/ewok/ramda/path'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img, { FluidObject } from 'gatsby-image'
import React, {
  CSSProperties, FC, ReactNode
} from 'react'

import { ImageWithArtDirectionFragment } from '../../../graphql'

export type ImageWithArtDirectionProps = {
  /** Renders a background image if children are passed */
  readonly children?: ReactNode
  /** If rendering a background image for a Row, it needs to accept a class name for styling */
  readonly className?: string
  readonly style?: CSSProperties
  readonly data: Partial<ImageWithArtDirectionFragment>
}

// eslint-disable-next-line functional/prefer-readonly-type
const getFluidImages = (data: Partial<ImageWithArtDirectionFragment>): FluidObject[] | undefined => {
  const mobileImage: FluidObject | undefined = path([ 'mobileImage', 'fluid' ], data)
  const desktopImage: FluidObject | undefined = path([ 'desktopImage', 'fluid' ], data)
  const tabletImage: FluidObject | undefined = path([ 'tabletImage', 'fluid' ], data)

  // Require all images since they are required in content model
  return mobileImage && tabletImage && desktopImage &&
    [
      mobileImage,
      {
        ...desktopImage,
        media: '(min-width: 1200px)'
      },
      {
        ...tabletImage,
        media: '(min-width: 768px)' // TODO: Use CSS Variables when available
      }
    ]
}

const ImageWithArtDirection: FC<ImageWithArtDirectionProps> = ({
  children, className, data, style
}: ImageWithArtDirectionProps) => {
  const title = path([ 'desktopImage', 'title' ], data)
  const description = path([ 'desktopImage', 'description' ], data)
  const fluidImages = getFluidImages(data)

  return children ? (
    <BackgroundImage
      alt={description}
      className={className}
      fluid={fluidImages}
      style={style}
      title={title}
    >
      {children}
    </BackgroundImage>
  ) : (
    fluidImages ?
      <Img
        alt={description}
        fluid={fluidImages}
        style={style}
        title={title}
      />
      : null
  )
}

export default ImageWithArtDirection

export const query = graphql`#graphql
  fragment imageWithArtDirection on ContentfulImageWithArtDirection {
    id
    internal {
      type
    }
    mobileImage {
      fluid(maxWidth: 768) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    tabletImage {
      fluid(maxWidth: 1200) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    desktopImage {
      title
      description
      fluid(maxWidth: 1440) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
  }
`
