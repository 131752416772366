import { get, set } from 'local-storage'
import { StringParam, useQueryParam } from 'use-query-params'

import { params } from '../util/queryParams'

const useUtmContent = (enable = true) => {
  /** The promo code in the URL */
  const [ utmContentValue, setUtmContentValue ] = useQueryParam(params.content, StringParam)

  // if enable is true and utmValue is defined write it to locale storage
  enable && utmContentValue && set(params.content, utmContentValue)

  /** clear local storage if disable is true */
  // (!enable || !utmContentValue) && remove(params.content)

  /** clear utm_content from url if disable is true */
  !enable && setUtmContentValue(undefined)

  /** To persist the utm_content across sessions and pages we write it to local storage */
  const localStorageUtmValue: string | undefined = get(params.content)

  return localStorageUtmValue || utmContentValue
}

export default useUtmContent

// Values
export const outdoorCamCrm = 'outdoorcamCRM'
