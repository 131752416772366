import  { createContext } from 'react'

export type PopupContext = {
  readonly handleHidePopup: (hide: boolean) => void
  readonly hidePopupWizard: boolean
}

const defaultContext = {
  handleHidePopup: () => undefined,
  hidePopupWizard: true
}

export const HidePopupWizard = createContext<PopupContext>(defaultContext)
