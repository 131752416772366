import { TrackingData } from '@simplisafe/ecomm-ts-types'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { CheckMarkBlue, Info } from '@simplisafe/ss-react-components/icons'
import { ComparisonTableRow } from '@simplisafe/ss-react-components/molecules'
import { graphql } from 'gatsby'
import isNil from 'ramda/src/isNil'
import React, { FC } from 'react'
import { useTracking } from 'react-tracking'

import { ContentfulModal, ContentfulPlanComparisonTableRow } from '../../../graphql'
import ModalComponent from '../ModalComponent'

export const trackComparePlanTooltipEvent = (trackEvent, label) => {
  trackEvent({
    action: 'compare-plan-tooltip',
    event: 'tooltip',
    label: label,
    site: 'fcp'
  })
}

type ComparisonTableRowComponentProps = {
  readonly data: Partial<ContentfulPlanComparisonTableRow>
}

const CHECKMARKTEXT = '{true}'
const UNCHECKMARKTEXT = '{false}'

export const renderTarget =
   (rowLabel: string, infoButtonText: string, comparisonTableRowModal: ContentfulModal, trackEvent: (data: Partial<TrackingData>) => void) =>
     <><p>
       {rowLabel}
     </p><ModalComponent
       clickTarget={
         <Info
           className='icon'
           forceButtonMode={true}
           titleA11y={infoButtonText}
         />
       }
       data={comparisonTableRowModal}
       onClick={() => { trackComparePlanTooltipEvent(trackEvent, rowLabel) }} />
     </>

const ComparisonTableRowComponent: FC<ComparisonTableRowComponentProps> = ({ data }: ComparisonTableRowComponentProps) => {

  const comparisonTableRowModal = prop('rowModal', data)

  const isProPremiumFeature = prop('isProPremiumFeature', data) || false

  const isStandardFeature = prop('isStandardFeature', data) || false

  const rowLabel = prop('rowLabel', data) || ''

  // TODO the types here are all wrong
  // @ts-ignore
  const infoButtonText: string = path([ 'rowModal', 'clickTarget', 'icon', 'description' ], data)

  const monitoringFeature = prop('planType', data) as readonly string[]

  const { trackEvent } = useTracking()

  return (
    <ComparisonTableRow
      clickTarget={
        !isNil(comparisonTableRowModal)
          ? renderTarget(rowLabel, infoButtonText, comparisonTableRowModal, trackEvent)
          : <p className='rowLabel'>
            {rowLabel}
          </p>
      }
      isProPremiumFeature={isProPremiumFeature}
      isStandardFeature={isStandardFeature}
      monitoringFeature={monitoringFeature && monitoringFeature.map((feature) => {
        return ( feature === CHECKMARKTEXT ?
          <CheckMarkBlue /> : feature === UNCHECKMARKTEXT ?
            ' ' : feature
        )
      })}
      rowLabel={rowLabel}
    />
  )
}

export default ComparisonTableRowComponent

export const ComparisonTableRowQuery = graphql`#graphql
  fragment comparisonTableRowFragment on ContentfulPlanComparisonTableRow {
    id
    internal {
      type
    }
    planType
    isProPremiumFeature
    isStandardFeature
    rowModal {
      ... on ContentfulModal {
        ...modalFragment
      }
      clickTarget {
        ... on ContentfulButton {
          icon {
            description
          }
        }
      }
    }
    rowLabel
  }
`
