import type { Block, Inline } from '@contentful/rich-text-types'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safeFind, safeProp } from '@simplisafe/monda'
import { selectMonthsOfServiceDisplay } from '@simplisafe/ss-ecomm-data/redux/select'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import React from 'react'
import { useSelector } from 'react-redux'

import { isPartnerUpgradePromoOffer } from '../../util/partnerCookie'

export function getContentType<T>(obj: T): string {
  // TODO obj needs a type
  // @ts-ignore
  const sys = path([ 'data', 'target', 'sys', 'contentType', 'sys' ], obj)
  // TODO obj needs a type
  // @ts-ignore
  return prop( 'id', sys) || ''
}

export function getFieldType<T>(obj: T): string {
  // TODO obj needs a type
  // @ts-ignore
  return path([ 'data', 'target', 'fields', 'type' ], obj) || ''
}

// Can be used when we need to display a number of free months only (example: "free month", "2 free months", "3 free months")
const MonthOfFreeService = () => {
  const monthsOfFreeService = useSelector(selectMonthsOfServiceDisplay(true, isPartnerUpgradePromoOffer()))
  return (
    <>{monthsOfFreeService}</>
  )
}

// Can be used in a Service plan description text (example: "2 months are", "month is")
const MonthOfServiceText = () => {
  const monthsOfService = useSelector(selectMonthsOfServiceDisplay(false, isPartnerUpgradePromoOffer()))
  return (
    <>{monthsOfService}</>
  )
}

// Map which content needs to render based on placeholder type
const placeholderTypeRenderMaps: readonly {
  readonly placeholder: string
  readonly renderComponent: JSX.Element
  }[] = [
    {
      placeholder: 'Months of Free Service Description',
      renderComponent: <MonthOfServiceText/>
    },
    {
      placeholder: 'Months of Free Service',
      renderComponent: <MonthOfFreeService/>
    }
  ]

const renderPlaceholderContent = (node: Block | Inline) => {
  const fieldType = getFieldType(node)
  return safeFind((entry) => entry.placeholder === fieldType, placeholderTypeRenderMaps)
    .chain(safeProp('renderComponent'))
    .map(renderComponent => renderComponent)
    .orNull()
}

/**
 * Renders embedded entry in ContentfulRichText
 */
export const renderEmbeddedEntry = (node: Block | Inline) => {
  const contentType = getContentType(node)
  return ifElse(
    equals('placeholder'),
    () => renderPlaceholderContent(node),
    () => null
  )(contentType)
}
