import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import { AccordionItem } from '@simplisafe/ss-react-components/molecules'
import { AccordionItemProps } from '@simplisafe/ss-react-components/molecules/AccordionItem'
import { graphql } from 'gatsby'
import { Maybe } from 'monet'
import React, { FC } from 'react'

import { ContentfulAccordion } from '../../../graphql'
import getDescriptionJson from '../../util/getDescriptionJson'
import RichText from '../RichText'

type AccordionItemComponentProps = {
  readonly data: Partial<ContentfulAccordion>
}

const accordionThemeMapper: {[key: string]: AccordionItemProps['theme']} = {
  Default: 'default',
  ['Title Border']: 'border',
}
export const toAccordionThemeValue =
  (value?: string | null): AccordionItemProps['theme'] | undefined =>
    Maybe.fromNull(value)
      .chain(val => safeProp(val, accordionThemeMapper))
      .orUndefined()

const AccordionItemComponent: FC<AccordionItemComponentProps> = (
  { data }: AccordionItemComponentProps) => {
  return (
    <AccordionItem id={prop('id', data)}
      theme={toAccordionThemeValue(prop('theme', data))}
      title={path([ 'accordionTitle', 'accordionTitle' ], data)}>
      <RichText json={getDescriptionJson(data)} />
    </AccordionItem>
  )
}

export default AccordionItemComponent

export const contentfulAccordionItem = graphql`#graphql
  fragment contentfulAccordionItemFragment on ContentfulAccordion {
    internal {
      type
    }
    accordionTitle {
      accordionTitle
    }
    description {
      json
    }
    id
    theme
  }
`
