import { Document } from '@contentful/rich-text-types'
import prop from '@simplisafe/ewok/ramda/prop'
import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import { safePath, safeProp } from '@simplisafe/monda'
import { PartnerTemplate } from '@simplisafe/ss-react-components/organisms'
import { graphql } from 'gatsby'
import { Maybe } from 'monet'
import path from 'ramda/src/path'
import React, { FC } from 'react'

import {
  ContentfulRichTextWithOptions,
  IncludedItemFragment,
  IncludedItemsFragment,
  PartnerTemplateFragment,
} from '../../../graphql'
import { ContentfulComponent } from '../../componentMappings'
import documentFallback from '../../util/documentFallback'
import doesDocumentHaveContent from '../../util/doesDocumentHaveContent'
import getJson from '../../util/getJson'
import { getTokenizedJson } from '../../util/monitoring-price-placeholders'
import { renderComponentFromData } from '../../util/render'
import RichText from '../RichText'

export type PartnerTemplateComponentProps = {
  readonly data: PartnerTemplateFragment
};

type Items = ContentfulComponent & {
  readonly includedItems: readonly unknown []
}

const PartnerTemplateComponent: FC<PartnerTemplateComponentProps> = ({ data }: PartnerTemplateComponentProps) => {
  const contentComponents = prop('contentComponents', data)
  const detailComponents = prop('detailComponents', data)
  const modalPrice = prop('modalPrice', data) || 0


  const setModalPrice = (item: IncludedItemFragment | null) => {
    const itemModal  = item && path<readonly ContentfulRichTextWithOptions[]>([ 'infoModal', 'contentsToGroup' ], item) || []
    const contents = itemModal && itemModal.map((content: ContentfulRichTextWithOptions) => {
      const filteredJson = safePath([ 'richText', 'json' ], content)
        .map((json: Document) => getTokenizedJson(json, Number(modalPrice)))
        .getOrElse(documentFallback())

      return {
        ...content,
        richText: { json: filteredJson }
      }
    })

    return {
      ...item,
      infoModal: {
        ...prop('infoModal', item),
        contentsToGroup: contents
      }
    }
  }

  const detailsComponent = detailComponents &&
    detailComponents.map((contentfulComponent): Items => ({
      ...contentfulComponent,
      includedItems: Maybe.fromNull(contentfulComponent)
        .filter(contentfulComponent => path([ 'internal', 'type' ], contentfulComponent) === 'ContentfulIncludedItems')
        .chain((data: IncludedItemsFragment) => safeProp('includedItems', data))
        .map((data) => data.map(setModalPrice))
        .getOrElse([])
    }))

  const disclaimerData = getJson(prop('disclaimer', data))

  return (
    <PartnerTemplate
      content={contentComponents && contentComponents.map(renderComponentFromData).filter(isNotNil)}
      details={detailsComponent && detailsComponent.map(renderComponentFromData).filter(isNotNil)}
      disclaimer={doesDocumentHaveContent(disclaimerData) && <RichText json={disclaimerData} textSize="xs" />}
      key={prop('id', data) || ''}
    />
  )
}

export default PartnerTemplateComponent


export const PartnerTemplateQuery = graphql`
  #graphql
  fragment partnerTemplate on ContentfulPartnerTemplate {
    disclaimer {
      json
    }
    contentComponents {
      ... on ContentfulDivider {
        ...divider
      }
      ... on ContentfulPhoneCta {
        ...phoneCTA
      }

      ... on ContentfulPartnerForm {
        ...partnerForm
      }

      ... on ContentfulRichTextWithOptions {
        ...richTextWithOptions
      }
    }
    detailComponents {
      ... on ContentfulImageGallery {
        ...imageGallery
      }
      ... on ContentfulHeading {
        ...heading
      }
      ... on ContentfulIncludedItems {
        ...includedItems
      }
    }
    id
    internal {
      type
    }
    modalPrice
  }
`



