import { useDecision } from '@optimizely/react-sdk'
import { requestLiveChatAvailability } from '@simplisafe/ss-ecomm-data/thirdparty/livechat'
import { useEffect, useState } from 'react'

import { getChatAppId } from '../tracking/cookies'

const useLiveChatAvailability = (appId = '') => {
  const [ isLiveChatEnabled ] = useDecision('livechat')
  const [ hasAvailableAgents, setHasAvailableAgents ] = useState(false)
  const liveChatAppIdCookie = getChatAppId() || appId

  useEffect(() => {
    const handleFailure = () => setHasAvailableAgents(false)
    const handleSuccess = (response: boolean) => response === true ? setHasAvailableAgents(true) : handleFailure()

    isLiveChatEnabled.enabled && requestLiveChatAvailability(liveChatAppIdCookie)(handleFailure)(handleSuccess)
  }, [ isLiveChatEnabled.enabled, liveChatAppIdCookie ])

  return isLiveChatEnabled.enabled && hasAvailableAgents ? true : false
}

export default useLiveChatAvailability
