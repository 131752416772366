import { useLocation } from '@reach/router'
import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import { IOAddServicePlanToCart, IOAddToCart } from '@simplisafe/ss-ecomm-data/cart'
import { AddServicePlanBody } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import { liftSelectProduct, selectMonthsOfServiceDisplay } from '@simplisafe/ss-ecomm-data/redux/select'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { SSButton } from '@simplisafe/ss-react-components/atoms'
import { MinWidthType } from '@simplisafe/ss-react-components/atoms/SSButton'
import { graphql, navigate } from 'gatsby'
import { get, remove } from 'local-storage'
import join from 'ramda/src/join'
import propOr from 'ramda/src/propOr'
import split from 'ramda/src/split'
import React, {
  FC,
  useCallback,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import { pipe } from 'ts-functional-pipe'

import { ContentfulLinkAddToCart } from '../../../graphql'
import AddToCartError, { AddToCartErrorType } from '../../errorComponents/AddToCartError'
import { useOptimizelyTrackSiteEvents } from '../../tracking/optimizely'
import { trackAddToCartEvent } from '../../util/analytics/addToCart'
import { configureServicePlanTextPlaceholders, toFirstCharLower } from '../../util/helper'
import { isPartnerUpgradePromoOffer } from '../../util/partnerCookie'
import { verifyButtonColor } from '../../util/verifyButtonColor'

type LinkAddToCartProps = {
  readonly data: Partial<ContentfulLinkAddToCart>
}

const LinkAddToCart: FC<LinkAddToCartProps> = ({ data }: LinkAddToCartProps) => {
  // This is the variant SKU added to Contentful to handle the monitoring plans.
  // There will need to be an update to include a check for the master SKU when the monitoring plans get added to CommerceTools.
  const skuID = safeProp('productSku', data)
  const product = useSelector(liftSelectProduct(skuID))
  const url = prop('url', data)
  const buttonSize: MinWidthType = propOr('auto', 'buttonSize', data)
  const dispatch = useDispatch()
  const [ addToCartError, setAddToCartError ] = useState<AddToCartErrorType>(null)
  const [ showSpinner, setShowSpinner ] = useState(true)
  const { Track, trackEvent } = useTracking()
  const location = useLocation()
  const parentId: string = get('parentId')
  const isChangePlan = location.pathname === '/change-monitoring'
  const monthsOfFreeService: string = useSelector(selectMonthsOfServiceDisplay(true, isPartnerUpgradePromoOffer()))

  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()

  const key = prop('id', data)
  const linkText: string = configureServicePlanTextPlaceholders(safeProp('text', data).getOrElse(''), monthsOfFreeService)

  const handleLinkClick = useCallback(() => {
    const quantity = 1

    setAddToCartError(null)
    const urlRedirect = (url: string) => {
      navigate(url)
    }

    const handleSuccess = () => {
      // Removing the change plan parent id from the LS.
      remove('parentId')
      setShowSpinner(false)
      optimizelyTrackSiteEvents({ eventType: 'add_to_cart_clicked' })
      trackAddToCartEvent(product, trackEvent, quantity)
      url && urlRedirect(url)
    }
    const handleFailure = () => {
      setShowSpinner(false)
      setAddToCartError('recoverable')
      optimizelyTrackSiteEvents({ eventType: 'website_error' })
    }

    skuID
      .cata(
        () => {
          setShowSpinner(false)
          setAddToCartError('unrecoverable')
          logError(Error('Cannot add to cart: received null/empty sku'))
        },
        _sku => {
          const product = {
            quantity,
            sku: _sku,
          }
          const productWithParentId: AddServicePlanBody = {
            action:'addServicePlan',
            packageParentId:parentId,
            sku: _sku
          }

          isChangePlan
            ? dispatch(IOAddServicePlanToCart([ productWithParentId ], handleFailure, handleSuccess))
            : dispatch(IOAddToCart({ products: [ product ] }, handleFailure, handleSuccess))
        }
      )
  }, [ dispatch, isChangePlan, parentId, product, skuID, trackEvent, url, optimizelyTrackSiteEvents ])

  const buttonType = safeProp('buttonType', data)
    .map(pipe(toFirstCharLower, split(' '), join('')))
    .orUndefined()

  const buttonColor = buttonType ? verifyButtonColor(buttonType) : undefined
  /**
   *  On some mobile pages, the word wrap of the button interferes with an explicitly set height,
   *  currently this is happening on the /choose-monitoring2. The ProductPlan component should currently be the
   *  only place where this prop is being set and passed here.
   */
  const spinnerMobileAutoHeight: boolean =
    // TODO: fix type
  // @ts-ignore
    prop('spinnerMobileAutoHeight', data) || false

  return (
    <>
      <Track>
        <SSButton
          color={buttonColor}
          key={key}
          minWidth={buttonSize}
          onClick={handleLinkClick}
          showSpinner={showSpinner}
          spinnerMobileAutoHeight={spinnerMobileAutoHeight}
        >
          {linkText}
        </SSButton>
        {addToCartError && <div style={{
          margin: '8px auto auto',
          maxWidth: '200px'
        }}>
          <AddToCartError
            errorType={addToCartError}
            textAlign='center'
          />
        </div>}
      </Track>
    </>
  )
}

export default LinkAddToCart

export const query = graphql`#graphql
  fragment contentfulLinkAddToCart on ContentfulLinkAddToCart {
    buttonType
    id
    internal {
      type
    }
    productSku
    text
    url
  }
`
