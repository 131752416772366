import {
  FormField,
  FormTextInput,
  Text,
} from '@simplisafe/ss-react-components/atoms'
import React from 'react'

type EmailProps = {
  readonly emailFieldLabel: string
  readonly emailFieldPlaceholder: string
};

const Email: React.FC<EmailProps> = ({ emailFieldLabel, emailFieldPlaceholder }: EmailProps) => {
  return (
    <div data-component={'Email'} style={{ marginBottom: '32px' }}>
      <FormField hideLabel={true} label={emailFieldLabel}
        name="email">
        <Text fontWeight="medium">{emailFieldLabel}</Text>
        <FormTextInput
          fullWidth={true}
          name="email"
          placeholder={emailFieldPlaceholder}
        />
      </FormField>
    </div>
  )
}

export default Email
