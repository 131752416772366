import { OptimizelyContext } from '@optimizely/react-sdk'
import path from '@simplisafe/ewok/ramda/path'
import { cookiesOption } from '@simplisafe/ss-ecomm-data/simplisafe'
import {
  useContext, useEffect, useState
} from 'react'
import Cookies from 'universal-cookie'

import useLiveChatAvailability from '../../src/hooks/useLiveChatAvailability'
import { PageProps } from '../components/Page'
import { visitorIdAtAt } from '../tracking/atat'
import {
  COOKIE_CHAT_APP_ID, getChatAppId, getChatOpened
} from '../tracking/cookies'
import { useOptimizelyActivateExperiment, useOptimizelyTrackSiteEvents } from '../tracking/optimizely'

const cookies = new Cookies()

const enableLiveChat = (
  liveChat: boolean,
  bmsLiveChat: boolean,
  isVariation1: boolean,
  chatOpened: string
) => {
  return ((liveChat && !bmsLiveChat) || (bmsLiveChat && isVariation1)) || chatOpened === 'true'
}

const useEnableLiveChat = (data: PageProps['data']) => {
  const [ isInBmsLiveChatExperiment, setIsInBmsLiveChatExperiment ] = useState(false)
  const [ bmsLiveChatVariation, setBmsLiveChatVariation ] = useState('')
  const pagePath = path([ 'contentfulPage', 'pageUrl' ], data) || ''
  const liveChatAppId: string = path([ 'contentfulPage', 'pageLayout', 'liveChatAppId' ], data) || ''
  // Enable BMS LiveChat in webConfig
  const bmsLiveChat: boolean = path([ 'contentfulPage', 'pageLayout', 'bmsLiveChatTest' ], data) || false

  const isBmsPage = pagePath.includes('build-my-system')
  const isLiveChatEnabled: boolean = useLiveChatAvailability(liveChatAppId)
  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()
  const { optimizely } = useContext(OptimizelyContext)
  const optimizelyActivateExperiment = useOptimizelyActivateExperiment()
  const onActivateCallbackFn = () => {
    setIsInBmsLiveChatExperiment(true)
    setBmsLiveChatVariation(optimizely && optimizely.getVariation('ECP_3915', visitorIdAtAt()) || '')
  }
  isBmsPage && isLiveChatEnabled && optimizelyActivateExperiment({ experimentId: 'ECP_3915' }, onActivateCallbackFn)

  const isBmsLiveChatVariation1 = bmsLiveChatVariation === 'variation_1' ? true : false

  // If the user is in any variation within BMS Livechat experiment, push impacted_audience event.
  useEffect(() => {
    isInBmsLiveChatExperiment && optimizelyTrackSiteEvents({ eventType: 'impacted_ecp_3915', })
  }, [ isInBmsLiveChatExperiment, optimizelyTrackSiteEvents ] )

  // Enable regular LiveChat on page or the BMS Livechat
  const hasLiveChat: boolean = path([ 'contentfulPage', 'pageLayout', 'hasLiveChat' ], data) || false
  const chatOpened: string = getChatOpened()
  const _enableLiveChat = enableLiveChat(hasLiveChat, bmsLiveChat, isBmsLiveChatVariation1, chatOpened)

  // Get AppId Cookie for BMS LiveChat
  const liveChatAppIdCookie: string = getChatAppId()
  const chatId = liveChatAppIdCookie || liveChatAppId

  // Set Cookie for BMS LiveChat
  isInBmsLiveChatExperiment && chatOpened === 'true' && cookies.set(COOKIE_CHAT_APP_ID, liveChatAppId, cookiesOption)

  return {
    liveChatEnabled: _enableLiveChat,
    liveChatId: chatId
  }
}

export default useEnableLiveChat
