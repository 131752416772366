import { Document } from '@contentful/rich-text-types'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safePath } from '@simplisafe/monda'
import { selectMiniCart } from '@simplisafe/ss-ecomm-data/redux/select'
import {
  HeroSlideProps, IDisplayMode, PreloadLink
} from '@simplisafe/ss-react-components/molecules/HeroSlide'
import { Carousel } from '@simplisafe/ss-react-components/organisms'
import { graphql } from 'gatsby'
import { FluidObject } from 'gatsby-image'
import { List, None } from 'monet'
import propEq from 'ramda/src/propEq'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { ContentfulPdpFloorplanCarouselSlide, DynamicPackageFloorplanBannerFragment } from '../../../graphql'
import getJson from '../../util/getJson'
import FluidImg from '../FluidImg'

type DynamicPackageFloorplanBannerComponentProps = {
  readonly data: DynamicPackageFloorplanBannerFragment
}

const DynamicPackageFloorplanBannerComponent: FC<DynamicPackageFloorplanBannerComponentProps> =
  ({ data }: DynamicPackageFloorplanBannerComponentProps) => {

    const miniCart = useSelector(selectMiniCart)
    const miniCartLineItems = List.from(miniCart.orJust([]))

    const possibleSlides = prop('possibleSlides', data)
    const heroSlideProps: readonly HeroSlideProps[] | undefined = possibleSlides && possibleSlides.map((possibleSlide: ContentfulPdpFloorplanCarouselSlide) =>
      miniCartLineItems.find(propEq('masterSku', path([ 'systemComponent', 'sku' ], possibleSlide)))
        .map(() => {
          const fluid = safePath<'image', 'fluid', ContentfulPdpFloorplanCarouselSlide>([ 'image', 'fluid' ], possibleSlide)
          const description: Document = getJson(prop('blurb', possibleSlide))
          const title: string = prop('blurbTitle', data) || ''
          const displayMode: IDisplayMode = 'left'
          const textColor: 'dark' | 'light' = 'dark'
          const childrenPosition: 'center' | 'left' | 'right' = 'right'
          const backgroundColor = 'gray' as const
          return {
            backgroundColor: backgroundColor,
            children: fluid
              .map((fluid: FluidObject) => {
                const alt: string | undefined = path([ 'systemComponent', 'displayName' ], possibleSlide)
                const key: string | undefined = path([ 'image', 'id' ], possibleSlide)
                return ( <FluidImg
                  alt={alt}
                  fluid={fluid}
                  key={key}
                />
                )
              }),
            childrenPosition: childrenPosition,
            content: {
              description: description,
              title: title
            },
            displayMode: displayMode,
            preload: None<PreloadLink>(),
            textColor: textColor
          }
        })
    )
      .filter(x => x.isSome())
      .map(x => x.just())

    return miniCart.map(
      _miniCart => {
        return (
          heroSlideProps
            ? <Carousel
              carouselContainerData={{
                arrows: true,
                autoplay: false,
                dotsColor: 'blue',
                enableDots: false,
                //TODO should be configurable from content
                paginationPosition: 'below-text',
                playButton: false
              }}
              enableInlineArrow={true}
              key={data.id} // we're not mapping over an array, but eslint still sees that we're calling something called map and wants a key
              mediaType={'image'}
              slideData={heroSlideProps}
              // TODO needs a separate mobile version? Really?
              slideMobileData={heroSlideProps}
            />
            : null
        )
      }
    ).orNull()
  }

export const query = graphql`
 fragment dynamicPackageFloorplanBanner on ContentfulDynamicPackageFloorplanBanner {
   id
   internal {
     type
   }
   blurbTitle
   possibleSlides {
     blurb {
       json
     }
     image {
       fluid(maxWidth: 833) {
         ...GatsbyContentfulFluid_withWebp_noBase64
       }
       id
     }
     systemComponent {
       displayName
       sku
     }
   }
 }
`

export default DynamicPackageFloorplanBannerComponent
