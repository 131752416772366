/* eslint-disable functional/prefer-readonly-type */
import { Document } from '@contentful/rich-text-types'
import prop from '@simplisafe/ewok/ramda/prop'

import documentFallback from './documentFallback'

/**
 * Contentful types json as an `any` type from GraphQL, but we know that the type matches Document.
 * This function returns the json key of an object as a valid Document type.
 * If there is no valid Document at the path, this function will return an empty Document that will render nothing.
 */
function getJson<T extends { json?: Document }>(input: T): Document {
  return documentFallback(prop('json', input))
}

export default getJson
