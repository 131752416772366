import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { Modal } from '@simplisafe/ss-react-components/atoms'
import { ModalStyle } from '@simplisafe/ss-react-components/atoms/Modal'
import { graphql } from 'gatsby'
import { Maybe } from 'monet/dist/monet'
import React, {
  FC,
  ReactNode,
  useState
} from 'react'

import { ContentfulButtonContentfulSmallTextSectionUnion, ModalFragmentFragment } from '../../../graphql'
import { toModalSize } from '../../attributeMappings'
import { ContentfulComponent, getMappedComponent } from '../../componentMappings'

export type ModalComponentProps = {
  /* Click Target and Model Content data from Contentful, Click target field is optional in Contentful */
  // Once other components using ModalComponent are changed to not use Partial<> types anymore,
  // this should be changed to just ModalFragmentFragment
  readonly data?: Omit<ModalFragmentFragment, 'id' | 'internal' | 'clickTarget'> & {
    readonly internal?: ModalFragmentFragment['internal']
    readonly id?: ModalFragmentFragment['id']
    readonly clickTarget?:  ModalFragmentFragment['clickTarget'] | ContentfulButtonContentfulSmallTextSectionUnion
  }
  /* Click target component can be passed in here, instead of setting it in Contentful */
  readonly clickTarget?: ReactNode
  /* Modal content component can be passed in here, instead of setting it in Contentful */
  readonly modalContent?: ReactNode
  /** custom style for modal */
  readonly style?: ModalStyle
  /* if true, applies the 'minimal' theme to put the close button outside the modal */
  readonly closeButtonOutside?: boolean
  /* External click to be called when the button is clicked */
  readonly onClick?: () => void

  /* Size of modal */
  readonly size?: 'small' | 'medium' | 'large'
}

const externalClick = (onClick?: () => void) => { onClick && onClick() }

const isRichTextWithButtons = <T extends ContentfulComponent>(data: T) => !!data && path([ 'internal', 'type' ], data) === 'ContentfulRichTextWithButtons'

type ClickTargetComponentProps = {
  readonly data: ModalFragmentFragment['clickTarget'] | ContentfulButtonContentfulSmallTextSectionUnion
}
type ContentComponentProps = {
  readonly data: ModalFragmentFragment['modalContent']
  readonly onUrlFragmentClick?: () => void
}

const ModalComponent: FC<ModalComponentProps> = ({
  data: dataProp,
  clickTarget,
  closeButtonOutside,
  modalContent: modalContentProp,
  style,
  onClick,
  size,
}: ModalComponentProps) => {
  const data = dataProp || {}
  const clickTargetContent = prop('clickTarget', data)
  const modalContent = prop('modalContent', data)
  const [ isOpen, setIsOpen ] = useState(false)
  const ClickTargetComponent = clickTargetContent && getMappedComponent<ClickTargetComponentProps>(clickTargetContent)

  const ContentComponent = modalContent && getMappedComponent<ContentComponentProps>(modalContent)
  const clickTargetIsSet = !!(ClickTargetComponent || clickTarget)
  const modalContentIsSet = !!(ContentComponent || modalContentProp)
  const modalSize = size ? toModalSize(size) : toModalSize(prop('modalSize', data))
  const closeModal = () => setIsOpen(false)

  return (clickTargetIsSet && modalContentIsSet) ? (
    //TODO apply click event to ClickTargetComponent
    <>
      <span
        className="clickTarget"
        data-component="click-target"
        onClick={(e) => {
          e.preventDefault()
          externalClick(onClick)
          setIsOpen(true)
        }}>
        {clickTarget ? clickTarget : <ClickTargetComponent data={clickTargetContent} />}
      </span>
      <Modal
        appElementId='___gatsby'
        isOpen={isOpen}
        onRequestClose={closeModal}
        size={modalSize}
        style={style}
        theme={closeButtonOutside ? 'minimal' : undefined}
      >
        {modalContentProp ? modalContentProp : (
          <ContentComponent
            data={modalContent}
            // Prop is specific to RichTextWithButtonsComponent
            onUrlFragmentClick={Maybe.fromNull(modalContent)
              .filter(isRichTextWithButtons)
              .map(() => closeModal)
              .orUndefined()}
          />
        )}
      </Modal>
    </>
  ) : null
}

export default ModalComponent

export const query = graphql`#graphql
  fragment modalFragment on ContentfulModal {
    clickTarget {
      ... on ContentfulButton {
        ...contentfulButtonFragment
      }
      ... on ContentfulSmallTextSection {
        ...smallTextSectionFragment
      }
    }
    id
    internal {
      type
    }
    modalContent {
      ... on ContentfulGroupSection {
        ...nonCyclicalGroupSectionFragment
      }
      ... on ContentfulModalSensorAdditionToSystem {
        ...addExtraSensors
      }
      ... on ContentfulRichTextWithButtons {
        ...contentfulRichTextWithButtonsFragment
      }
      ... on ContentfulRichTextWithOptions {
        ...richTextWithOptions
      }
      ... on ContentfulPartnerCaptureForm {
        ...contentfulPartnerCaptureForm
      }
      ... on ContentfulSmallTextSection {
        ...smallTextSectionFragment
      }
      ... on ContentfulTwoColumn {
        ...contentfulTwoColumnFragment
      }
      ... on ContentfulBanner {
        ...contentfulBanner
      }
      ... on ContentfulPdpPackageSensorEdit {
        ...contentfulPackageSensorEditFragment
      }
    }
    modalSize
  }

  # this fragment has been made to support a scenario where adding modalFragment to
  # contentfulTwoColumnFragment resulted in a recursive graphql error since 
  # modalFragment has twoColumnBanner as potential content in contentful
  fragment secondaryModalFragment on ContentfulModal {
    clickTarget {
      ... on ContentfulButton {
        ...contentfulButtonFragment
      }
    }
    id
    internal {
      type
    }
    modalContent {
      ... on ContentfulFindYourPerfectSystem {
        ...quoteWizard
      }
    }
    modalSize
  }
`
