import { Address } from '@commercetools/platform-sdk'
import { TrackingData } from '@simplisafe/ecomm-ts-types'
import __ from '@simplisafe/ewok/__'
import path from '@simplisafe/ewok/ramda/path'
import isNilOrEmpty from '@simplisafe/ewok/ramda-adjunct/isNilOrEmpty'
import { safeProp } from '@simplisafe/monda'
import { selectAffirmCheckoutData } from '@simplisafe/ss-ecomm-data/affirm'
import { ImmutableCart } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import { Locale, localeInfo } from '@simplisafe/ss-ecomm-data/commercetools/locale'
import { selectLocale } from '@simplisafe/ss-ecomm-data/redux/select'
import { RemoteData } from '@simplisafe/ss-ecomm-data/RemoteData'
import {
  fetchPaymentMethod,
  PaymentMethodResponse
} from '@simplisafe/ss-ecomm-data/simplisafe'
import {
  buildPaymentFormAddressData, paymentFormForkedFetch, PaymentFormRequestParams, PaymentFormResponse
} from '@simplisafe/ss-ecomm-data/simplisafe/paymentForm'
import {
  fetchPaymentExperience,
  fetchSafeTechCollectorInfo,
  PaymentExperience,
  PaymentExperienceResponse,
  SafeTechCollectorInfoResponse
} from '@simplisafe/ss-ecomm-data/simplisafe/paymentsClient'
import { cookiesOption } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { ZuoraPaymentResponse } from '@simplisafe/ss-ecomm-data/thirdparty/zuora'
import { window } from 'browser-monads-ts'
import { Maybe } from 'monet'
import always from 'ramda/src/always'
import concat from 'ramda/src/concat'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import isNil from 'ramda/src/isNil'
import when from 'ramda/src/when'
import {
  useCallback, useEffect, useLayoutEffect,
  useRef, useState
} from 'react'
import { useSelector } from 'react-redux'
import { debounce } from 'throttle-debounce'
import Cookies from 'universal-cookie'
import { v4 as uuidv4 } from 'uuid'

import { visitorIdAtAt } from '../../tracking/atat'
import { COOKIE_PREACTIVATION } from '../../tracking/cookies'
import { OptimizelyEvent, useOptimizelyTrackSiteEvents } from '../../tracking/optimizely'
import { getCookieDomain } from '../../util/common'
import { get as getSessionStorage, set as setSessionStorage } from '../../util/session-storage'
import createOrder, { OrderData } from './createOrder'
import submitAffirmOrder from './submitAffirmOrder'
import submitChaseOrder from './submitChaseOrder'
import submitZuoraOrder from './submitZuoraOrder'

const cssPath: (_windowOrigin: string) => string = ifElse(isNil, always(''), concat(__, '/ecomm-payment-plugin/payment-form.css'))

const redirectPath: (_windowOrigin: string) => string = ifElse(isNil, always(''), concat(__, '/ecomm-payment-plugin/payment-callback.html'))

const usCssPath: (_windowOrigin: string) => string = ifElse(isNil, always(''), concat(__, '/ecomm-payment-plugin/payment-form-us.css'))

/* To trigger the gtm event when the error is occured */
export const handlePaymentErrorEvent =
    (trackEvent: <T>(_data: T) => void) =>
      <E>(e: E) => {
        trackEvent({
          errorMessage: e,
          event: 'buttonClick'
        })
      }

export const handlePreactivationEvents =
    (trackEvent: <T>(_data: T) => void) =>
      (optimizelyTrackSiteEvents: (_data: OptimizelyEvent) => void) => {
        optimizelyTrackSiteEvents({ eventType: 'auto_activation' })
        trackEvent({ event: 'userInAutoActivation' })
      }


export const preactivationCookieSettings = {
  ...cookiesOption,
  ...{
    domain: getCookieDomain(),
    // Override default behavior so value is not encoded. However, the service
    // consuming this value should decode it.
    encode: (str: string) => str,
  }
}

export const handlePreactivationCookie =
    (cookies: Cookies) =>
      (value: string) => {
        // The cookie has characters that have been escaped when saving from API to
        // hidden input value. We need to decode any escaped characters so the
        // cookie is back in its raw value.
        cookies.set(COOKIE_PREACTIVATION, value, preactivationCookieSettings)
      }

export const fetchChasePaymentForm =
  (cart: ImmutableCart, locale: Locale, domain: string, secret?: string) =>
    (onError: () => void) =>
      (onSuccess: (_res: Maybe<PaymentFormResponse>) => void) => {
        const billingData = buildPaymentFormAddressData(cart.billingAddress.map<Partial<Address>>(addr => addr).orJust({}))

        const payload: PaymentFormRequestParams['payload'] = locale === 'en-GB'
          ? {
            cssPath: cssPath(path([ 'location', 'origin' ], window)),
            data: billingData,
            orderId: cart.id,
            redirectPath: redirectPath(path([ 'location', 'origin' ], window)),
            secret: secret
          } : {
            cssPath: usCssPath(path([ 'location', 'origin' ], window)),
            data: billingData,
            orderId: cart.id,
            redirectPath: redirectPath(path([ 'location', 'origin' ], window)),
            secret: undefined
          }

        paymentFormForkedFetch({
          endpoint: domain,
          method: 'POST',
          payload
        })(e => {
          logError(e)
          onError()
        })(onSuccess)
      }

export const fetchZuoraPaymentForm =
  () =>
    (onError: () => void) =>
      (onSuccess: (_res: Maybe<PaymentMethodResponse>) => void) =>
        fetchPaymentMethod()((e: Error) => {
          logError(e)
          onError()
        })(onSuccess)


export type PaymentState = 'loading' | 'ready' | 'processing' | 'complete' | 'error'

const usePayment = (
  cart: RemoteData<ImmutableCart>,
  cookies: Cookies,
  trackEvent: (__data: TrackingData) => void,
  // allows passing in a different value for affirmClient in unit tests
  affirmClient = window.affirm
) => {
  const locale = useSelector(selectLocale)
  const domain = path([ locale, 'domain' ], localeInfo)
  const checkoutUserId = getSessionStorage('checkoutUserId')
  const sessionId = visitorIdAtAt()

  const [ paymentState, setPaymentState ] = useState<PaymentState>('loading')
  const [ isPaymentFormRequested, setIsPaymentFormRequested ] = useState(false)

  const [ secret, setsecret ] = useState('')
  const [ iframeSrc, setIframeSrc ] = useState('')
  const [ token, settoken ] = useState('')
  const [ dataCollectorSrc, setDataCollectorSrc ] = useState('')
  // Error codes returned by Chase on payment failure
  const [ chaseErrorCodes, setChaseErrorCodes ] = useState([])

  const [ paymentMethodRequired, setPaymentMethodRequired ] = useState(true)
  const [ zuoraPaymentMethod, setZuoraPaymentMethod ] = useState<PaymentMethodResponse>()
  const [ creditPaymentExperience, setCreditPaymentExperience ] = useState<PaymentExperience>()
  const [ safeTechSdkUrl, setSafeTechSdkUrl ] = useState<string>()
  const [ windowWidth, setWindowWidth ] = useState(window.outerWidth)

  const setChaseErrors = useCallback<(_chaseErrors?: string) => void>((chaseErrors = '') => {
    const errorCodes = chaseErrors.split('|').filter(e => !isNilOrEmpty(e))
    setChaseErrorCodes(errorCodes)
  }, [ setChaseErrorCodes ])

  const paymentFormSecret = useRef(uuidv4())
  const affirmCheckoutData = useSelector(selectAffirmCheckoutData)

  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()

  // Enable logging domain with payment-callback errors https://simplisafe.atlassian.net/browse/ECP-2261
  useEffect(() => setSessionStorage('payment-domain', domain))

  const email = cart.chain(safeProp('shippingAddress'))
    .chain((address: Maybe<Address>) => address.chain(safeProp('email')))
    .orUndefined()

  useEffect(() => {
    cookies.set('email', email, cookiesOption)
  }, [ cookies, email ])

  useEffect(() => {
    Maybe.fromFalsy(creditPaymentExperience === undefined)
      .forEach(() => {
        fetchPaymentExperience({ userId: checkoutUserId })(() => {
          setCreditPaymentExperience('chase')
        })((response: Maybe<PaymentExperienceResponse>) => {
          response.forEach(response => {
            setCreditPaymentExperience(response.experience)
          })
        })
      })
  }, [ checkoutUserId, creditPaymentExperience ])

  useEffect(() => {
    Maybe.fromFalsy(creditPaymentExperience === 'zuora')
      .forEach(() => {
        Maybe.fromFalsy(safeTechSdkUrl === undefined)
          .forEach(() => {
            fetchSafeTechCollectorInfo({
              domain,
              sessionId
            })(() => {
              setSafeTechSdkUrl('')
            })((response: Maybe<SafeTechCollectorInfoResponse>) => {
              response.forEach(response => {
                setSafeTechSdkUrl(response.sdkUrl)
              })
            })
          })
      })
  }, [ creditPaymentExperience, domain, safeTechSdkUrl, sessionId ])

  useEffect(() => {
    /**
     * 1) Fetch the payment form if we have a valid cart.
     * 2) Don't re-request the payment form if it's already been fetched.
     */
    const fetchChaseIframe = (cart: ImmutableCart) => {
      Maybe.fromFalsy(!isPaymentFormRequested)
        .forEach(() => {
          fetchChasePaymentForm(cart, locale, domain, paymentFormSecret.current)(() => {
            setPaymentState('error')
          })(response => {
            response.forEach(res => {
              safeProp('iframeSrc', res).forEach(setIframeSrc)
              safeProp('secret', res).forEach(setsecret)
              safeProp('token', res).forEach(settoken)
              safeProp('dataCollectorSrc', res).forEach(setDataCollectorSrc)
            })
            setPaymentState('ready')
          })

          setIsPaymentFormRequested(true)
        })
    }

    const fetchZuoraPaymentMethod = () => {
      Maybe.fromFalsy(paymentMethodRequired)
        .forEach(() => {
          setPaymentState('loading')
          setPaymentMethodRequired(false)

          fetchZuoraPaymentForm()(() => {
            setPaymentState('error')
          })(response => {
            response.forEach((response: PaymentMethodResponse) => {
              setZuoraPaymentMethod(response)
            })
          })
        })
    }

    Maybe.fromUndefined(creditPaymentExperience)
      .forEach(experience => cart.forEach(cart => experience === 'zuora' ? fetchZuoraPaymentMethod() : fetchChaseIframe(cart)))
  }, [ cart, creditPaymentExperience, isPaymentFormRequested, paymentMethodRequired, paymentState, domain, cookies, locale ])


  const handleResizeDebounced = debounce(500, () => {
    when(equals(false),
      () => {
        const zuoraIFrame: Maybe<HTMLElement> = Maybe.fromNull(document.getElementById('z_hppm_iframe'))
        zuoraIFrame.forEach((iframeDOMElement: HTMLElement) => {
          // Remove the iframe from the DOM before re-rendering it to prevent issue
          // where loading begins before the old iframe is removed
          iframeDOMElement.remove()
          // Trigger a re-render of the payment form
          setPaymentMethodRequired(true)
          // Track the window width through a React state to avoid to only re-render
          // when the width has changed, preventing issues on mobile
          setWindowWidth(window.outerWidth)
        })
      }
    )(windowWidth === window.outerWidth)

  })

  const resizeRef = useRef(handleResizeDebounced)

  useEffect(() => {
    resizeRef.current = handleResizeDebounced
    const resizeHandler = () => resizeRef.current()
    window.addEventListener('resize', resizeHandler)
    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [ windowWidth, handleResizeDebounced ])

  const handleError = useCallback((error: Error) => {
    setPaymentState('loading')
    handlePaymentErrorEvent(trackEvent)(error)
  }, [ trackEvent ])

  /* Trigger GTM/Optimizely events and set the preactivation cookie */
  const handlePreactivationReady = useCallback((webappToken: string) => {
    handlePreactivationEvents(trackEvent)(optimizelyTrackSiteEvents)
    handlePreactivationCookie(cookies)(webappToken)
  }, [ cookies, trackEvent, optimizelyTrackSiteEvents ])

  /* Partially applied `createOrder` call. The returned function takes order data (e.g. chase token, transaction secret) and submits the order. */
  const handleCreateOrder = useCallback((orderData: OrderData) =>
    createOrder({
      cartId: cart.chain<string>(safeProp('id')).orJust(''),
      // Toggles payment complete message while post payment flows initialize
      onPaymentComplete: () => setPaymentState('complete'),
      onPaymentError: handleError,
      onPreactivationReady: handlePreactivationReady
    })(orderData),
  [ cart, handleError, handlePreactivationReady ])

  const handleSubmitChaseOrder = useCallback(() => {
    // clear any existing errors before processing the order
    setChaseErrorCodes([])

    submitChaseOrder({
      chaseToken: token,
      createOrder: handleCreateOrder,
      onPaymentError: handleError,
      // Controls the loader when payment has been submitted
      onPaymentProcessing: () => setPaymentState('processing'),
      transactionSecret: secret
    })
  }, [ handleError, handleCreateOrder, secret, token ])

  const handleSubmitZuoraOrder = useCallback((zuoraResponse: ZuoraPaymentResponse) => {
    submitZuoraOrder({
      createOrder: handleCreateOrder,
      onFormLoadError: () => setPaymentState('error'),
      onPaymentError: handleError,
      // Controls the loader when payment has been submitted
      onPaymentProcessing: () => setPaymentState('processing'),
      trackEvent,
      zuoraResponse
    })
  }, [ handleError, handleCreateOrder, trackEvent ])

  const handleZuoraFormRender = useCallback(() => {
    paymentState !== 'error' ? setPaymentState('ready') : undefined
  }, [ paymentState ])

  const handleSubmitAffirmOrder = useCallback(() => {
    submitAffirmOrder({
      affirmCheckoutData,
      affirmClient,
      createOrder: handleCreateOrder,
      onPaymentCanceled: () => setPaymentState('ready'),
      onPaymentError: handleError,
      // Controls the loader when payment has been submitted
      onPaymentProcessing: () => setPaymentState('processing'),
    })
  }, [ affirmCheckoutData, affirmClient, handleCreateOrder, handleError ])

  useLayoutEffect(() => {
    /**
     * For use in payment-callback.ts for Chase credit card orders.
     */
    window['submit-chase-order'] = handleSubmitChaseOrder
  }, [ handleSubmitChaseOrder ])

  useLayoutEffect(() => {
    /**
     * For use in payment-callback.ts for Chase credit card orders.
     */
    window['handle-chase-errors'] = setChaseErrors
  }, [ setChaseErrors ])

  return {
    chaseErrorCodes,
    creditPaymentExperience,
    dataCollectorSrc,
    domain,
    handleSubmitAffirmOrder,
    handleSubmitZuoraOrder,
    handleZuoraFormRender,
    iframeSrc,
    paymentState,
    safeTechSdkUrl,
    token,
    zuoraPaymentMethod
  }
}

export default usePayment
