import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import {
  Expander,
  RichText
} from '@simplisafe/ss-react-components/atoms'
import { graphql } from 'gatsby'
import React from 'react'

import type { ImageWithOverlayFragment } from '../../../graphql'
import getJson from '../../util/getJson'
import FluidImg from '../FluidImg'
import ImageWithFocalPoint from '../ImageWithFocalPoint'

type ImageWithOverlayProps = {
    readonly data: ImageWithOverlayFragment
}

const ImageWithOverlay = ({ data }: ImageWithOverlayProps) => {
  const badgeComponent =
    <FluidImg
      alt={path([ 'overlay', 'badge', 'image', 'title' ], data)}
      fluid={path([ 'overlay', 'badge', 'image', 'fluid' ], data)}
      style={{ filter: 'drop-shadow( 0 0 2px rgba(0, 0, 0, 1))' }}
    />

  const richTextComponent = <RichText json={getJson(path([ 'overlay', 'textContent', ], data))}
    textColor={'neutralWhite'}/>

  const imageData = prop('image', data)

  return (
    <div style={{
      height: '100%',
      position: 'relative'
    }}>
      <div style={{
        left: 20,
        position: 'absolute',
        right: 20,
        top: 20
      }}>
        <Expander
          left={badgeComponent}
          right={richTextComponent}
        />
      </div>
      {
        imageData && (
          <ImageWithFocalPoint data={imageData}/>
        )
      }
    </div>
  )
}

export default ImageWithOverlay
export const ImageWithOverlayQuery = graphql`#graphql
  fragment imageWithOverlay on ContentfulImageWithOverlay {
    id
    internal {
      type
    }
    image {
      ... on ContentfulImageWithFocalPoint {
        ...imageWithFocalPoint
      }
    }
    overlay {
      id
      badge {
        image {
          fluid {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
      }
      textContent {
        json
      }
    }
    overlayPosition
  }
`
