import { INLINES, Node } from '@contentful/rich-text-types'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import isNotEmpty from '@simplisafe/ewok/ramda-adjunct/isNotEmpty'
import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import { safeProp } from '@simplisafe/monda'
import type { ButtonColor } from '@simplisafe/ss-react-components/atoms/SSButton'
import {
  Wizard,
  WizardEmailSubmit,
  WizardFinal,
  WizardMultipleChoice,
  WizardPhoneSubmit
} from '@simplisafe/ss-react-components/molecules'
import type { WizardProps } from '@simplisafe/ss-react-components/molecules/Wizard/Wizard'
import { graphql } from 'gatsby'
import always from 'ramda/src/always'
import ifElse from 'ramda/src/ifElse'
import isEmpty from 'ramda/src/isEmpty'
import map from 'ramda/src/map'
import pathOr from 'ramda/src/pathOr'
import propOr from 'ramda/src/propOr'
import toLower from 'ramda/src/toLower'
import React, { FC, ReactNode } from 'react'

import {
  ContentfulFindYourPerfectSystem, ContentfulForms, ContentfulQuizAnswer,
  ContentfulQuizQuestions, ContentfulSystemComponent
} from '../../../graphql'
import getJson from '../../util/getJson'
import RichText from '../RichText'

export type QuoteWizardProps = {
  readonly handleEmailSubmit: (responses: Record<string, string | unknown>) => void
  readonly handlePhoneSubmit?: <T>(val: T) => void
  readonly data: Partial<ContentfulFindYourPerfectSystem>
  readonly includePhone?: boolean
  readonly type: 'embedded' | 'floating' | 'popup'
  readonly finalTabDescription?: ReactNode
  readonly finalTabContents: readonly ReactNode[]
  readonly firstTab?: ReactNode
  readonly showTitle?: boolean
  readonly onClick?: () => void
  readonly defaultEmail?: string
}

export const toWizardType = (value: string | null | undefined): WizardProps['type'] => {
  return value === 'Docked'
    ? 'popup'
    : value === 'Floating'
      ? 'floating'
      : 'embedded'
}

export const toRichText =
    (key: keyof Partial<ContentfulFindYourPerfectSystem>, data: Partial<ContentfulFindYourPerfectSystem>, email?: string) =>
      safeProp(key, data)
        .map(getJson)
        .map(json =>
          <RichText json={json}
            key={`RichText${key}`}
            options={{
              renderNode: {
                [INLINES.HYPERLINK]: (node: Node, children: ReactNode) =>
                  <a className={'link colorPrimary'}
                    href={pathOr<string, string>('', [ 'data', 'uri' ], node)}
                    rel="noreferrer"
                    target={'_blank'}>{children}</a>,
                [INLINES.EMBEDDED_ENTRY]: (node: Node, children: ReactNode) =>
                  email && isNotEmpty(email) ?
                    <span id="qwEmail">{email}</span>
                    : null,
              }
            }} />)
        .orUndefined()

const QuoteWizardComponent: FC<QuoteWizardProps> = ({
  handleEmailSubmit,
  handlePhoneSubmit,
  data,
  includePhone,
  finalTabContents,
  finalTabDescription,
  firstTab = null,
  showTitle = true,
  type = 'embedded',
  defaultEmail = ''
}: QuoteWizardProps) => {
  const wizardType: WizardProps['type'] = toWizardType(prop('wizardType', data))
  const showPhone = !!includePhone && !!handlePhoneSubmit && !!data.additionalInfoFields

  const quizQuestions = propOr<readonly ContentfulQuizQuestions[], readonly ContentfulQuizQuestions[]>([], 'quizQuestions', data).filter(isNotNil)
  const emailMessage =  toRichText('message', data)
  const emailInputPlaceholder = pathOr<string, string>('', [ 'emailDetails', 'formInput', '0', 'placeholderText' ], data)
  const emailTermsMessage = toRichText('termsAndConditions', data)
  const emailSubmitButtonLabel = pathOr<string, string>('', [ 'emailDetails', 'button', 'text' ], data)
  // This type assertion is because toUpper changes ButtonColor to string
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const emailSubmitButtonType = toLower(pathOr<string, string>('', [ 'emailDetails', 'button', 'type' ], data)) as ButtonColor

  const phoneFormPlaceholders = showPhone ? pathOr<readonly ContentfulForms[], readonly ContentfulForms[]>([], [ 'additionalInfoFields', 'formInput' ], data) : []

  const phoneSubtitle = showPhone ? <p><strong>{pathOr('', [ 'additionalInfoFields', 'subTitle' ], data)}</strong></p> : null
  const phoneMessage = showPhone ? <p>{pathOr('', [ 'additionalInfoFields', 'description', 'description' ], data)}</p> : null
  const nameInputPlaceholder = (showPhone && phoneFormPlaceholders[0]?.placeholderText) || ''
  const phoneInputPlaceholder = (showPhone && phoneFormPlaceholders[1]?.placeholderText) || ''
  const phoneSubmitButtonLabel = showPhone ? pathOr<string, string>('', [ 'additionalInfoFields', 'button', 'text' ], data) : ''
  const emailFormLabel = toLower(pathOr<string, string>('', [ 'emailDetails', 'formInput', '0', 'title' ], data))
  const nameInputLabel = pathOr<string, string>('', [ 'additionalInfoFields', 'formInput', '0', 'title' ], data)
  const phoneInputLabel = pathOr<string, string>('', [ 'additionalInfoFields', 'formInput', '1', 'title' ], data)

  return (
    <Wizard
      backButtonText={propOr<string, string>('', 'backButtonText', data)}
      nextButtonText={propOr<string, string>('', 'nextButtonText', data)}
      title={showTitle ? propOr<string, string>('', 'title', data) : ''}
      type={wizardType}
    >
      {firstTab}
      {
        quizQuestions.map((quizQuestion: ContentfulQuizQuestions) => {
          const answersPerRow = prop('answersPerRow', quizQuestion)
          const questionAnswers = propOr<readonly ContentfulQuizAnswer[], readonly ContentfulQuizAnswer[]>([], 'answers', quizQuestion).filter(isNotNil)
          const answers = questionAnswers.map(qAns => {
            const label = prop('text', qAns)
            const sComponents = propOr<readonly ContentfulSystemComponent[], readonly ContentfulSystemComponent[]>([], 'systemComponents', qAns).filter(isNotNil)
            const value = ifElse(isEmpty, () => qAns.answer_id, map((sComp: ContentfulSystemComponent) => sComp.sku))(sComponents)
            return {
              label,
              value
            }
          })

          return (
            <WizardMultipleChoice
              answers={answers}
              answersPerRow={answersPerRow}
              id={`${path([ 'systemComponent', 'sku' ], quizQuestion) || prop('question_id', quizQuestion)}`}
              isMultiSelect={quizQuestion.answerType === 'Multiple Choice'}
              key={prop('id', quizQuestion)}
              question={<RichText json={getJson(prop('question', quizQuestion))} />}
            />
          )
        })
      }
      <WizardEmailSubmit
        buttonProps={{ color: emailSubmitButtonType }}
        data-testid="WizardEmailSubmit"
        defaultEmail={defaultEmail}
        // should remain hardcoded value "email" so long as
        // leadGenClient.buildCreateBody() is expecting it
        id="email"
        label={emailFormLabel}
        legalText={emailTermsMessage}
        message={emailMessage}
        onSubmit={handleEmailSubmit}
        placeholder={emailInputPlaceholder}
        submitButtonLabel={emailSubmitButtonLabel}
        type={wizardType}
      />
      {
        showPhone &&
          <WizardPhoneSubmit
            message={phoneMessage}
            nameLabel={nameInputLabel}
            onSubmit={handlePhoneSubmit ?? always(undefined)}
            phoneLabel={phoneInputLabel}
            placeholderName={nameInputPlaceholder}
            placeholderPhone={phoneInputPlaceholder}
            submitButtonLabel={phoneSubmitButtonLabel}
            subtitle={phoneSubtitle}
          />
      }
      <WizardFinal>
        {finalTabDescription}
        {finalTabContents}
      </WizardFinal>
    </Wizard>
  )
}

export default QuoteWizardComponent

export const QuoteWizardQuery = graphql`#graphql
  fragment quoteWizard on ContentfulFindYourPerfectSystem {
    internal {
      type
    }
    message {
      json
    }
    wizardType
    title
    quizQuestions {
      id
      question {
        id
        json
      }
      question_id
      answers {
        id
        answer_id
        text
        systemComponents {
          sku
        }
      }
      answersPerRow
      answerType
      systemComponent {
        sku
      }
    }
    backButtonText
    nextButtonText
    termsAndConditions {
      json
    }
    finalTabDescription{
      json
    }
    finalTabContents {
      ... on ContentfulPlainText {
        id
        internal {
          type
        }
        text {
          text
        }
      }
      ... on ContentfulRichText {
        id
        internal {
          type
        }
        richText {
          json
        }
      }
      ... on ContentfulSmallTextSection {
        ...smallTextSectionFragment
      }
    }
    firstTab {
      ...firstQuoteWizardTab
    }
    emailDetails {
      button {
        text
        type
      }
      formInput {
        ... on ContentfulForms {
          placeholderText
          propName
          title
        }
      }
    }
    additionalInfoFields {
      button {
        text
      }
      formInput {
        ... on ContentfulForms {
          placeholderText
          title
        }
      }
      description {
        description
      }
      subTitle
    }
  }

  fragment firstQuoteWizardTab on ContentfulQuoteWizardFirstScreen {
    newUserHelpText
    newUserSkipQuizButton {
      ...contentfulButtonFragment
    }
    newUserStartQuizButton {
      ...contentfulButtonFragment
    }
    returningUserHelpText
    returningUserRestartQuizButton {
      ...contentfulButtonFragment
    }
    returningUserSeeResultsButton {
      ...contentfulButtonFragment
    }
  }
`
