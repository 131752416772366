import {
  FormSection,
  FormTextInput,
  SSButton,
  Text
} from '@simplisafe/ss-react-components/atoms'
import { CheckMarkBlue } from '@simplisafe/ss-react-components/icons'
import React, { CSSProperties } from 'react'


type CouponProps = {
  readonly couponsSectionTitle: string
  readonly couponFieldLabel: string
  readonly couponButtonLabel: string
  readonly couponSubmissionFail: boolean
  readonly couponSubmissionMessage: string
  readonly couponSubmissionSuccess: boolean
  readonly isMobile: boolean
  readonly locale: string
  readonly submitNewCouponOnClick: () => void
  readonly submitNewCouponOnEnter: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

const Coupons: React.FC<CouponProps> = (props: CouponProps) => {
  const desktopStyles: CSSProperties = { display: 'block' }
  const mobileStyles: CSSProperties = { display: 'none' }
  const styles = (props.isMobile === false) ? desktopStyles : mobileStyles
  return (

    <div data-component={'Coupons'} style={styles}>
      <FormSection name={props.couponsSectionTitle}>
        <div style={{ marginLeft: '170px' }}>
          <div style={{
            alignItems: 'center',
            display: 'flex',
            gap: '20px'
          }}>
            <Text><label htmlFor={'couponCode'}>{props.couponFieldLabel}</label></Text>
            <FormTextInput hasError={props.couponSubmissionFail} id='couponCode'
              name='couponCode' onKeyDown={props.submitNewCouponOnEnter}/>
            {props.couponSubmissionSuccess &&
              <>
                <Text fontWeight='medium' textColor='brandPrimary'
                  textSize='sm'>{props.couponSubmissionMessage} <CheckMarkBlue />
                </Text>
              </>}
            {props.couponSubmissionFail &&
              <Text fontWeight='medium' textColor='errorRed'
                textSize='sm'>{props.couponSubmissionMessage}</Text>}
            <SSButton color='link' onClick={props.submitNewCouponOnClick}>
              {props.couponButtonLabel}</SSButton>
          </div>
          {props.locale === 'en-GB' &&
          <div id='mmWrapper' style={{ marginTop: '10px' }}></div>}
        </div>
      </FormSection>
    </div>
  )
}

export default Coupons
