import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safePath, safeProp } from '@simplisafe/monda'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { TestimonialBanner } from '@simplisafe/ss-react-components/organisms'
import { CompanyReview, TestimonialBannerProps } from '@simplisafe/ss-react-components/organisms/TestimonialBanner'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { FluidObject } from 'gatsby-image'
import {
  Just, Maybe, None
} from 'monet'
import always from 'ramda/src/always'
import applySpec from 'ramda/src/applySpec'
import identity from 'ramda/src/identity'
import ifElse from 'ramda/src/ifElse'
import isNil from 'ramda/src/isNil'
import map from 'ramda/src/map'
import not from 'ramda/src/not'
import pathOr from 'ramda/src/pathOr'
// TODO replace with ts-functional-pipe
// eslint-disable-next-line no-restricted-imports
import pipe from 'ramda/src/pipe'
import toLower from 'ramda/src/toLower'
import React, { FC } from 'react'

import { ContentfulCompanyReviewBanner } from '../../../graphql'
import FluidImg from '../FluidImg'

type CompanyReviewBannerComponentProps = {
  readonly id: string
  readonly data: Partial<ContentfulCompanyReviewBanner>
}

const toCompanyList = (x) => (
  prop('companyIcon', x) ?
    <FluidImg
      alt={path([ 'companyIcon', 'title' ], x)}
      fluid={path([ 'companyIcon', 'fluid' ], x)}
      imgStyle={{ objectFit: 'contain', }}
      style={{
        maxHeight: '90px',
        width: '100%',
      }}
    /> : null
)

const toCompanyReviewItem = applySpec<CompanyReview>({
  companyIcon: toCompanyList,
  companyName: prop('companyName'),
  companyReview: prop('companyReview'),
  iconSize: prop('iconSize')
})

const toButton = applySpec<TestimonialBannerProps['button']>({
  color:pipe(pathOr('', [ 'button', 'type' ]), toLower),
  text:path([ 'button', 'text' ]),
  url:path([ 'button', 'url' ])
})

const toCompanyReviewBanner = applySpec<TestimonialBannerProps>({
  button: ifElse(pipe(identity(prop('button')), isNil, not), toButton, always(null)),
  companyReviewList: pipe(prop('companyReviewComponent'), map(toCompanyReviewItem)),
  sidePaddingSize: data => safeProp('sidePadding', data)
    .map(toLower)
    .orUndefined()
})

const CompanyReviewBannerComponent: FC<CompanyReviewBannerComponentProps> =
  ({ data }: CompanyReviewBannerComponentProps) => {
    const isMobile = !useMediaQuery('TabletAndUp')

    const mobileMedia: Maybe<FluidObject> = safePath([ 'mediaMobile', 'fluid' ], data)
      .chain(fluid => isMobile ? Just(fluid) : None())

    const BackgroundComponent = safeProp('media', data)
      .chain(media =>
        safeProp('fluid', media)
          .map(fluid => (props: React.PropsWithChildren<{readonly [x: string]: unknown}>) =>
            <BackgroundImage
              {...props}
              alt={safeProp('title', media).getOrElse('CompanyReview')}
              fluid={mobileMedia.orJust(fluid)}
            />)
      )
      .orUndefined()

    return (
      <TestimonialBanner
        {...toCompanyReviewBanner(data)}
        BackgroundComponent={BackgroundComponent}
      />
    )
  }

export const query = graphql`
  fragment mediaAsset on ContentfulAsset {
    file {
    url
    }
  }
  fragment CompanyReviewBannerInformation on ContentfulCompanyReviewBanner{
      id
      internal {
        type
      }
      title
      companyReviewComponent {
          companyIcon {
            fluid(maxWidth: 511) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
          }
          companyName
          companyReview
          iconSize
      }
      button {
        url
        text
        type
      }
      media {
        fluid(maxWidth: 1440) {
              ...GatsbyContentfulFluid_withWebp_noBase64
          }
        title
      }
      mediaMobile {
        fluid(maxWidth: 511) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
        title
      }
      sidePadding
  }
`

export default CompanyReviewBannerComponent
