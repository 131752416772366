import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { CarouselBotAssistant, CarouselTextImage } from '@simplisafe/ss-react-components/organisms'
import { CarouselTextImageProps, Description } from '@simplisafe/ss-react-components/organisms/CarouselTextImage'
import { graphql } from 'gatsby'
import { Maybe } from 'monet'
import always from 'ramda/src/always'
import applySpec from 'ramda/src/applySpec'
import defaultTo from 'ramda/src/defaultTo'
import map from 'ramda/src/map'
import multiply from 'ramda/src/multiply'
import pathOr from 'ramda/src/pathOr'
// TODO replace with ts-functional-pipe
// eslint-disable-next-line no-restricted-imports
import pipe from 'ramda/src/pipe'
import React, { FC, ReactElement } from 'react'

import { ContentfulAsset, ContentfulCarouselTextAndImage } from '../../../graphql'
import { toButton } from '../../util/helper'
import FluidImg from '../FluidImg'

type CarouselTextImageComponentProps = {
  readonly data: Partial<ContentfulCarouselTextAndImage>
}

const descList = (data: ContentfulCarouselTextAndImage) => {
  const toDescList = applySpec<Description>({
    desc: path([ 'text', 'json' ]),
    image: always(<FluidImg
    // TODO fix these types
      // @ts-ignore icon does not exist on data
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      alt={path([ 'icon', 'title' ], data)}
      // @ts-ignore icon does not exist on data
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      fluid={path([ 'icon', 'fluid' ], data)}
    />)
  })

  return toDescList(data)
}
const toCarouselTextImage = applySpec<CarouselTextImageProps>({
  authoritiesAlertedContent: path([ 'authoritiesAlertedContent', 'description', 'json' ]),
  descList: pipe(prop('imageWithText'), map(descList), defaultTo([])),
  planComparisonButton: pipe(prop('planComparisonButton'), toButton),
  playSpeed: pipe(prop('switchDuration'), multiply(1000)),
  title: prop('title'),
})

const toListImage = (x: Maybe<ContentfulAsset>): ReactElement => (
  <FluidImg
    // TODO: fix type
    // @ts-ignore
    alt={prop('title', x)}
    // TODO: fix type
    // @ts-ignore
    fluid={prop('fluid', x)}
    imgStyle={{
      left: '0px',
      maxHeight: '100px',
      maxWidth: '150px',
      objectFit: 'contain',
      right: '0px'
    }}
    // TODO: fix type
    // @ts-ignore
    key={prop('id', x)}
    style={{
      margin: '0 5px',
      overflow: 'visible',
      width: '100%'
    }}
  />
)

const CarouselTextImageComponent: FC<CarouselTextImageComponentProps> =
  ({ data }: CarouselTextImageComponentProps) => {
    const imageSlideObjects: readonly Maybe<ContentfulAsset>[] = pathOr([], [ 'alexaContentBlock', 'imageCarousel' ])(data)
    return (
      <CarouselTextImage
        alexaArmComponent={<CarouselBotAssistant
          descriptionText={pathOr<string, string>('', [ 'alexaContentBlock', 'description', 'json' ], data)}
          slides={map(toListImage)(imageSlideObjects)}
          title={pathOr<string, string>('', [ 'alexaContentBlock', 'title' ], data)}
        />}
        {...toCarouselTextImage(data)}
      />
    )
  }

export const query = graphql`
  fragment carouselTextImageInformation on ContentfulCarouselTextAndImage{
      id
      internal {
        type
      }
      authoritiesAlertedContent {
        backgroundColor
        description {
          json
        }
      }
      switchDuration
      planComparisonButton {
        text
        type
        url
      }
      title
      imageWithText {
        icon {
        fluid(maxWidth: 300) {
            ...GatsbyContentfulFluid_withWebp_noBase64
        }
          title
        }
        text {
          json
        }
      }
      alexaContentBlock {
        id
        title
        internal {
          type
        }
        description {
          json
        }
        imageCarousel {
          id
          title
          fluid(maxWidth: 300) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
`

export default CarouselTextImageComponent
