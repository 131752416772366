import { safeProp } from '@simplisafe/monda'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { CarouselContainer } from '@simplisafe/ss-react-components/molecules'
import { graphql } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import { Maybe } from 'monet'
import React, { FC } from 'react'

import { ImageGalleryFragment } from '../../../graphql'

export type ImageGalleryComponentProps = {
  readonly data: ImageGalleryFragment
}

const toGatsbyImage = (imgData, isMobile: boolean) => {
  // todo this needs to be done in react components, not here
  // todo also move it out of DynamicPackageHeroBannerComponent
  const getHeight = isMobile ? {
    height: '340px',
    minWidth: '380px'
  } : {
    height: '600px',
    width: '100%'
  }

  const altText = Maybe.fromNull(imgData)
    .chain(safeProp('description'))
    .getOrElse('')

  const id = Maybe.fromNull(imgData)
    .chain(safeProp('id'))
    .getOrElse('')

  return Maybe.fromNull(imgData)
    .chain(safeProp('fluid'))
    .map(
      fluid => <Img
        alt={altText}
        // Note: Need to create or identify the right type for `fluid` that maps
        // correctly to FluidObject.
        fluid={fluid as FluidObject}
        key={id}
        style={{ ...getHeight }}
      />
    )
}

// Copied from ItemContainerComponent's `toProductImage` fn + ItemContainer.
const ImageGalleryComponent: FC<ImageGalleryComponentProps> = ({ data }: ImageGalleryComponentProps) => {
  const isMobile = !useMediaQuery('TabletAndUp')
  const images = safeProp( 'images', data )
    .map((imagesArray) => {
      return imagesArray.map(x => toGatsbyImage(x, isMobile)) })
    .orNull()
  return Maybe.fromNull(images)
    .cata(
      () => null,
      images => <CarouselContainer
        arrows={true}
        autoplay={false}
        paginationPosition={'bottom-right-to-center'}
        playButton={false}
        slides={images}
      />
    )
}

export default ImageGalleryComponent

export const ImageGalleryQuery = graphql`#graphql
  fragment imageGallery on ContentfulImageGallery {
    id
    images {
      description
      fluid(maxWidth: 833) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
      id
    }
    internal {
      type
    }
  }
`
