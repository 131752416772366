import prop from '@simplisafe/ewok/ramda/prop'
import { Column, HoverBorder } from '@simplisafe/ss-react-components/atoms'
import { Spans } from '@simplisafe/ss-react-components/atoms/Column'
import React, { MouseEventHandler } from 'react'

import { nullToUndefined } from '../../util/helper'
import SimpleBannerComponent from '../SimpleBannerComponent'
import { SimpleBannerComponentProps } from '../SimpleBannerComponent/types'

export type SimpleBannerWrapperProps = {
  readonly data: SimpleBannerComponentProps['data']
  readonly spans: Spans
  readonly isSelected: boolean
  readonly onClick?: MouseEventHandler<HTMLElement>
}

const SimpleBannerWrapper = ({
  data, spans, isSelected, onClick
}: SimpleBannerWrapperProps) => {
  const styles = {
    button: {
      appearance: 'none',
      backgroundColor: 'transparent',
      border: 0,
      padding: 0
    }
  } as const

  return (
    <Column key={data.id}
      spans={spans as Spans}>
      <button aria-controls="alarm-sensors-list"
        aria-expanded={isSelected}
        aria-label={nullToUndefined(prop('title', data))}
        onClick={onClick}
        style={ styles.button }
      >
        <HoverBorder isSelected={isSelected}>
          {[ <SimpleBannerComponent data={data}
            id={data.id}
            key={data.id} /> ]}
        </HoverBorder>
      </button>
    </Column>
  )
}

export default SimpleBannerWrapper
