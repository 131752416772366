import { RichTextWithOptionsFragment } from '../../../graphql'

const richTextWithOptionsFallback =
    (richTextWithOptions?: RichTextWithOptionsFragment): RichTextWithOptionsFragment =>
      richTextWithOptions
        ? richTextWithOptions
        : {
          id: '',
          internal: { type: '' }
        }

export default richTextWithOptionsFallback
