/* eslint-disable functional/no-class */
/* eslint-disable functional/no-this-expression */
import { BLOCKS, Document } from '@contentful/rich-text-types'
import prop from '@simplisafe/ewok/ramda/prop'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { BannerError } from '@simplisafe/ss-react-components/molecules'
import React, { ErrorInfo, ReactElement } from 'react'

import RichText from '../../components/RichText'

type Props = {
    readonly children: ReactElement | readonly ReactElement[]
}

type State = {
    readonly hasError: boolean
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(__error: string) {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logError(error, { componentStack: prop('componentStack', errorInfo) })
  }

  render() {
    // TODO Define this error message via RichText in Contentful.
    const errorMessage: Document = {
      'content': [
        {
          'content': [
            {
              'data': {},
              'marks': [],
              'nodeType': 'text',
              'value': 'Something went wrong.'
            }
          ],
          'data': {},
          'nodeType': BLOCKS.HEADING_2
        },
        {
          'content': [
            {
              'data': {},
              'marks': [],
              'nodeType': 'text',
              'value': 'Try reloading the page.'
            }
          ],
          'data': {},
          'nodeType': BLOCKS.PARAGRAPH
        },
      ],
      'data': {},
      'nodeType': BLOCKS.DOCUMENT
    }

    return this.state.hasError
      ? <BannerError backgroundColor="neutralLightGray">
        <RichText json={errorMessage} />
      </BannerError>
      : this.props.children
  }
}

export default ErrorBoundary
