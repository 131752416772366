/* eslint-disable max-lines */
import { useLocation } from '@reach/router'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safePath, safeProp } from '@simplisafe/monda'
import { LOCALE as locale, Locale } from '@simplisafe/ss-ecomm-data/commercetools/locale'
import {
  leadGenCapture, LeadGenCaptureParams, LeadGenCaptureResponse
} from '@simplisafe/ss-ecomm-data/leads/capture'
import {
  selectActivePromoCode, selectActivePromoDiscountTextWithOverrides, selectActivePromoEndTime, selectTopBannerVisible
} from '@simplisafe/ss-ecomm-data/redux/select'
import { cookiesOption } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import { getDeviceType } from '@simplisafe/ss-ecomm-data/utils/windowScreenSize'
import { SSButton, Timer } from '@simplisafe/ss-react-components/atoms'
import type { SSButtonProps } from '@simplisafe/ss-react-components/atoms/SSButton'
import { PromotionalBanner } from '@simplisafe/ss-react-components/organisms'
import type { PromotionalBannerSection } from '@simplisafe/ss-react-components/organisms/PromotionalBanner'
import { format, getHours } from 'date-fns'
import { Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import {
  Just, Maybe, None
} from 'monet'
import always from 'ramda/src/always'
import applySpec from 'ramda/src/applySpec'
import cond from 'ramda/src/cond'
import defaultTo from 'ramda/src/defaultTo'
import equals from 'ramda/src/equals'
import gt from 'ramda/src/gt'
import ifElse from 'ramda/src/ifElse'
import isNil from 'ramda/src/isNil'
import pathOr from 'ramda/src/pathOr'
// TODO replace with ts-functional-pipe
// eslint-disable-next-line no-restricted-imports
import pipe from 'ramda/src/pipe'
import propOr from 'ramda/src/propOr'
import replace from 'ramda/src/replace'
import startsWith from 'ramda/src/startsWith'
import T from 'ramda/src/T'
import toUpper from 'ramda/src/toUpper'
import unless from 'ramda/src/unless'
import React, {
  FC,
  useCallback,
  useEffect,
  useState
} from 'react'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

import { ContentfulButton, PromotionalBannerFragmentFragment } from '../../../graphql'
import useCookieChange from '../../hooks/useCookieChange'
import { handleBrazeTrackingEvent } from '../../tracking/braze'
import {
  COOKIE_LEAD_DATA,
  cookies,
  getLeadData
} from '../../tracking/cookies'
import { useOptimizelyTrackSiteEvents } from '../../tracking/optimizely'
import { trackSubmitLeadEvent } from '../../util/analytics'
import { getLocale } from '../../util/date-fns'
import {
  getTime, leadingSlashIt, toButton
} from '../../util/helper'

type ContentfulPromotionalBannerProps = {
  readonly data?: PromotionalBannerFragmentFragment
}

const KEY_PROMO_CLOSE = 'isPromoClosed'

const leadSourceI18N: Record<Locale, string> = {
  'en-GB': 'uk_promo_banner',
  'en-US': 'us_promo_banner'
}

/** To render the timer component in the banner. */
export const getTimerBanner = (
  leftSection: Partial<PromotionalBannerFragmentFragment>,
  onTimerExpire: () => void,
  expiryTime: number,
  textColor: string
) => {
  const timerFormat = propOr<readonly string[], readonly string[]>([], 'countdownTimerFormat', leftSection)

  return expiryTime > 0 ? ({
    ...leftSection,
    expiryTimer: getTimer(timerFormat, onTimerExpire, expiryTime, textColor)
  }) : null
}

/** To render the timer component in the banner. */
const getTimer = (
  timerFormat: readonly string[],
  onTimerExpire: () => void,
  expiryTime: number,
  textColor: string
) => {
  return expiryTime > 0 ? (<Timer textColor={textColor}
    timerSettings={{
      expiryTimestamp: expiryTime,
      format: toUpper(timerFormat.join(':')),
      onExpire: onTimerExpire
    }} />) : null
}

const getLeftSection = (
  data: PromotionalBannerFragmentFragment,
  onTimerExpire: () => void,
  expiryTime: number
) => {
  const leftSection = prop('leftSection', data)
  const bannerType = pathOr<string, string>('', [ 'internal', 'type' ], leftSection || {})
  const textColor = prop('secondaryTextColor', data)

  const ribbonType = prop('ribbonType', data)
  const hasTimer = ribbonType === 'Countdown Timer'
  const timerProps = hasTimer && textColor ? {
    expiryTimer: getTimer(
      [ 'hr', 'min', 'sec' ],
      onTimerExpire,
      expiryTime,
      textColor
    )
  } : undefined
  const totalProps = timerProps && leftSection ? {
    ...leftSection,
    ...timerProps
  } : leftSection

  return leftSection && textColor && equals('ContentfulPromotionalBannerItemTimer')(bannerType)
    ? getTimerBanner(leftSection, onTimerExpire, expiryTime, textColor)
    : totalProps
}

const getRightSection = (data) => {
  return safeProp('rightSection', data)
    .map(bannerSectionProps => {
      const bannerType = safePath([ 'internal', 'type' ], bannerSectionProps).orElse(Just(''))
      return ifElse(
        equals('ContentfulPromotionalBannerItemButton'),
        () => {
          return {
            ...bannerSectionProps,
            internal: { type: 'reactnode' },
            promotionContent: getCustomButton(prop('button', bannerSectionProps)),
          }
        },
        () => ({
          // Todo: Add Contentful field for textColor. This is so PromotionalForm
          // and its following success message are legible based on the configured
          // background of the banner. Hardcoded to white for July's promo for now.
          ...bannerSectionProps,
          ...{ textColor: 'none' },
        })
      )(bannerType.getOrElse(''))
    })
    .cata(
      () => undefined,
      props => props
    )
}

/** To get the props of mobile redeem button. */
const getButton = (data: PromotionalBannerFragmentFragment) => {
  const button = prop('promoButton', data)

  return applySpec<SSButtonProps>({
    buttonColor: pipe(path([ 'buttonColor' ]), defaultTo('')),
    children: pipe(path([ 'text' ]), defaultTo(null)),
    color: pipe(path([ 'color' ]), defaultTo('customPlain')),
    href: pipe(path([ 'url' ])),
    textColor: pipe(path([ 'textColor' ]), defaultTo(null))
  })(button)
}

/**
 * Generates a list of relative formatted end times that are
 * shown in the promo banner text (e.g. "ends tomorrow")
 *
 * - If < 1 day, use localized time (e.g. 3PM) or noon/midnight when appropriate
 * - If > 1 day and < 2 days, use "TOMORROW"
 * - If > 2 days and < 7 days, use appropriate weekday
 *
 * @param expiryDate end time of the active promo
 * @returns string
 */
const getPromoEndTextFromDaysRemaining = (expiryDate: Date, daysRemaining: number, locale: Locale) => {
  const dateLocale = getLocale(locale)

  // If less than a day remaining, get the hours or midnight/noon if appropriate
  const getFormatHours = cond([
    [ equals(0), always('MIDNIGHT') ],
    [ equals(12), always('NOON') ],
    [ T, () => format(expiryDate, 'haa', { locale: dateLocale }) ] // haa = 1PM, 10AM, etc
  ])

  return cond([
    [ equals(0), () => getFormatHours(getHours(expiryDate)) ],
    [ equals(1), always('TOMORROW') ],
    [ gt(7), () => format(expiryDate, 'EEEE', { locale: dateLocale }) ] // EEEE = Monday, Tuesday, etc
  ])(daysRemaining)
}

/**
 * Take promo end datetime str and comparison start date and
 * return textual expiration like 'SUNDAY', 'TOMORROW' or '5 PM'
 *
 * @param promoEndStr string
 * @param startDate Date
 * @returns string
 */
export const getPromoEndText = (promoEndStr: string, startDate: Date, locale: Locale) => {
  // js internal for determining invalid date string
  const invalidDateStr = 'Invalid Date'

  return Maybe.fromUndefined(promoEndStr)
    // startDate must be valid
    .filter(() => startDate.toString() !== invalidDateStr)
    .map(expiryDateStr => new Date(expiryDateStr))
    // expiryDate must be valid
    .filter(expiryDate => expiryDate.toString() !== invalidDateStr)
    // expiryDate must come after startDate
    .filter(expiryDate => expiryDate.getTime() >= startDate.getTime())
    .chain(expiryDate =>
      Just((expiryDate.getTime() - startDate.getTime()) / (1000 * 60 * 60))
        .map(hoursUntilExpiry => Math.floor(hoursUntilExpiry / 24))
        // only keep # days less than 7 (note: gt(7) evaluates true if 7 > arg)
        .filter(gt(7))
        .map(daysRemaining => getPromoEndTextFromDaysRemaining(expiryDate, daysRemaining, locale))
        .map(toUpper)
    )
}

export const getCustomButton = (data: Partial<ContentfulButton>) => {
  // Link URL.
  const url: string = safeProp('url', data)
    .map(leadingSlashIt)
    .getOrElse('')

  // Link Text.
  const text: string = safeProp('text', data).getOrElse('')

  // Only return a link url and link text exists; else, return an empty ReactNode.
  return url && text ? <Link
    style={{ textDecoration: 'none' }}
    to={url}>
    <SSButton {...toButton(data)}
      type='div'>
      {text}
    </SSButton>
  </Link> : null
}

/**
 * To construct the promotional banners props.
 *
 * @param data
 * @param onTimerExpire
 * @param expiryTime
 * @param defaultEmail
 * @returns props
 */
const toPromotionalBannerProps = (data: PromotionalBannerFragmentFragment, onTimerExpire, expiryTime: number, defaultEmail: string) => {
  return {
    backgroundComponentProps: { fluid: path([ 'backdropImage', 'fluid' ], data) },
    bannerBehavior: safeProp('bannerBehavior', data).getOrElse(''),
    bannerTheme: {
      backgroundColor: prop('bannerColor', data),
      primaryTextColor: prop('primaryTextColor', data),
      secondaryTextColor: prop('secondaryTextColor', data)
    },
    defaultEmail,
    leftSection: getLeftSection(data, onTimerExpire, expiryTime) as PromotionalBannerSection,
    middleSection: prop('middleSection', data),
    redeemButton: getButton(data),
    ribbonType: prop('ribbonType', data),
    rightSection: getRightSection(data)
  }
}

const PromotionalBannerComponent: FC<ContentfulPromotionalBannerProps> =
  ({ data }: ContentfulPromotionalBannerProps) => {
    const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()
    const leadDataCookie = getLeadData()

    const leadSource = propOr<string, string>('', 'leadSource', leadDataCookie)
    const isLeadCaptured = startsWith(leadSourceI18N[locale], leadSource)
    const isModalClose = cookies.get(KEY_PROMO_CLOSE) === 'true'
    const location = useLocation()
    const currentUrlName = prop('pathname', location)
    const [ expiryTime, setExpiryTime ] = useState(0)
    const [ showBanner, setShowBanner ] = useState(false)
    const [ showSuccessForm, setshowSuccessForm ] = useState(isLeadCaptured)
    const [ promoEndTime, setPromoEndTime ] = useState(None<string>())
    const [ placeholders, setPlaceholders ] = useState({})
    const [ isHideShopNowBtn, setIsHideShopNowBtn ] = useState(false)
    const promoCode = useSelector(selectActivePromoCode)
    const validUntil = useSelector(selectActivePromoEndTime)
    const discountText = useSelector(selectActivePromoDiscountTextWithOverrides)
    const isBannerVisible = useSelector(selectTopBannerVisible)

    useEffect(() => {
      setShowBanner(isBannerVisible)
    }, [ isBannerVisible ])

    const onTimerExpire = useCallback(() => setShowBanner(false), [])
    const [ defaultEmail, setDefaultEmail ] = useState(propOr<string, string>('', 'email', leadDataCookie))
    const bannerProps = toPromotionalBannerProps(data, onTimerExpire, expiryTime, defaultEmail)
    const { Track, trackEvent } = useTracking({ appSection: 'promoSubmit' })

    useCookieChange(COOKIE_LEAD_DATA, data => setDefaultEmail(propOr('', 'email', JSON.parse(data))))

    const onModalClose = useCallback((isClose: boolean) => {
      cookies.set(KEY_PROMO_CLOSE, isClose)
    }, [])

    const onEmailSubmit = useCallback((email: string) => {
      const handleFailure = () => {
        setshowSuccessForm(false)
        optimizelyTrackSiteEvents({ eventType: 'website_error' })
      }

      const handleSuccess = (value: Maybe<LeadGenCaptureResponse>) => {
        setshowSuccessForm(true)
        cookies.set(COOKIE_LEAD_DATA, value.orUndefined(), cookiesOption)
        ifElse(equals(true), always(setIsHideShopNowBtn(true)), always(''))((currentUrlName.indexOf('home-security-shop') >= 0))
        handleBrazeTrackingEvent(value.orUndefined())
        optimizelyTrackSiteEvents({ eventType: 'lead_captured_fs' })
        trackEvent({ event: 'submit' })
        trackSubmitLeadEvent(trackEvent)
      }

      const leadGenParams: LeadGenCaptureParams = {
        email,
        promoCode: promoCode.getOrElse(''),
        source: `${leadSourceI18N[locale]}_${getDeviceType().toLowerCase()}`
      }
      leadGenCapture(leadGenParams)(handleFailure)(handleSuccess)
    }, [ promoCode, trackEvent, currentUrlName, optimizelyTrackSiteEvents ])


    useEffect(() => {
      discountText
        .map(replace(/^0+(?!\.)|(?:\.|(\..*?))0+$/gm, '$1'))
        .cata(
          onTimerExpire,
          text => setPlaceholders({ '{DISCOUNT_OFFER}': text }),
        )

      validUntil.cata(
        onTimerExpire,
        date => {
          setExpiryTime(getTime(date))
          setPromoEndTime(getPromoEndText(date, new Date(), locale))
        }
      )

      setShowBanner(discountText.isSome() && validUntil.isSome())
      // @ts-ignore
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ discountText.val, validUntil.val ])


    return showBanner
      ? (<Track>
        <span data-component="PromotionalBannerComponent">
          <PromotionalBanner
            {...bannerProps}
            BackgroundComponent={unless(isNil, always(BackgroundImage))(prop('backdropImage', data)) || undefined}
            isHideShopNowBtn={isHideShopNowBtn}
            isModalClose={isModalClose}
            onEmailSubmit={onEmailSubmit}
            onModalClose={onModalClose}
            placeholders={placeholders}
            promoEndTime={promoEndTime.orUndefined()}
            showSuccessForm={showSuccessForm}
          />
        </span>
      </Track>
      )
      : null

  }

export default PromotionalBannerComponent
