import { safeProp } from '@simplisafe/monda'
import { Text } from '@simplisafe/ss-react-components/atoms'
import { RichTextProps } from '@simplisafe/ss-react-components/atoms/RichText'
import React, { FC } from 'react'

import { SiteWideMessagesContext } from '../../contexts/siteWideMessagesContext'

export type AddToCartErrorType = 'recoverable' | 'unrecoverable' | null

export type AddToCartErrorProps = {
  readonly errorType: AddToCartErrorType
  readonly textAlign?: RichTextProps['textAlignment']
}

const AddToCartError: FC<AddToCartErrorProps> = ({
  errorType,
  textAlign = 'left'
}: AddToCartErrorProps) => {
  return (
    errorType ? (
      <Text
        dataComponent='AddToCartError'
        textAlignment={textAlign}
        textColor='darkOrange'
        textSize='xs'
      >
        <SiteWideMessagesContext.Consumer>
          { msgs => {
            const errorMsg = errorType === 'recoverable'
              ? safeProp('addToCartError', msgs)
              : safeProp('addToCartErrorUnrecoverable', msgs)

            return errorMsg.orNull()
          } }
        </SiteWideMessagesContext.Consumer>
      </Text>
    ) : null
  )
}

export default AddToCartError
