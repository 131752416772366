import { Document } from '@contentful/rich-text-types'
import prop from '@simplisafe/ewok/ramda/prop'
import { safePath, safeProp } from '@simplisafe/monda'
import { IOUpdateCart } from '@simplisafe/ss-ecomm-data/cart/actions'
import { buildCustomFieldUpdateAction } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import { PartnerMembershipCaptureBody, partnersMembershipFormSubmit }
  from '@simplisafe/ss-ecomm-data/partners/submission'
import { selectLocale } from '@simplisafe/ss-ecomm-data/redux/select'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { FormField, SSButton } from '@simplisafe/ss-react-components/atoms'
import { SSButtonProps } from '@simplisafe/ss-react-components/atoms/SSButton'
import {
  Form,
  Formik,
} from 'formik'
import propOr from 'ramda/src/propOr'
import React, { ReactNode } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  boolean,
  number,
  object,
  string,
} from 'yup'

import {
  ContentfulAsset, ContentfulButton, ContentfulModal,
  ContentfulPartnerCaptureForm
} from '../../../graphql'
import { toButtonTypeValue, toButtonWidthValue } from '../../attributeMappings'
import { toButton } from '../../util/helper'
import { parseArray } from '../../util/parseContentfulValues'
import { getValueFromPartnerCookie } from '../../util/partnerCookie'
import ContentfulRichText from '../ContentfulRichText'
import FluidImg from '../FluidImg'
import ModalComponent from '../ModalComponent'
import RichTextWithOptionsComponent from '../RichTextWithOptionsComponent'
import Description from './form-sections/Description'
import SimpleText from './form-sections/SimpleText'
import SuccessMessage from './form-sections/SuccessMessage'

type PartnerCaptureFormProps = {
  readonly data: ContentfulPartnerCaptureForm
}

enum InputTypes {
  string = 'string',
  number = 'number',
  date = 'date',
  boolean = 'boolean'
}

type FormTypes = {
  readonly partnerName: string
  readonly firstName?: string
  readonly lastName?: string
  readonly memberNumber: string
}

// TODO: Refactor to use existing CTFL inputs instead of "Form Input" we created for this.
const PartnerCaptureForm = ({ data }: PartnerCaptureFormProps) => {
  const locale = useSelector(selectLocale)
  const dispatch = useDispatch()

  const partnerFields = parseArray(prop('partnerFields', data))
  const formTitle = propOr<string, string>('', 'formTitle', data)
  const termsAndConditions = safeProp('termsAndConditionsModal', data).orUndefined()
  const formDescription = <p>{propOr<string, string>('', 'formDescription', data)}</p>
  const submitButton = safeProp('submitButton', data).orUndefined()
  const image =  safeProp('partnerImage', data).orUndefined()

  const schema = partnerFields.reduce((prevSchema, currentField) => {
    const types = {
      'boolean': boolean(),
      'number': number(),
      'string': string()
    }

    const minValidationNumber = prop('minRequirement', currentField)
    const maxValidationNumber = prop('maxRequirement', currentField)

    // TODO: refactor this code to fix lint and TS errors
    /*eslint-disable */
    // @ts-ignore
    let fieldValidation = types[prop('inputType', currentField)]
      .label(currentField.label)

    prop('isEmail', currentField) && (fieldValidation.type === 'string') ? fieldValidation = fieldValidation.email() : undefined
    prop('required', currentField) ? fieldValidation = fieldValidation.required() : undefined
    minValidationNumber && (fieldValidation.type === 'number' || fieldValidation.type === 'string') ? fieldValidation = fieldValidation.min(minValidationNumber) : undefined
    maxValidationNumber && (fieldValidation.type === 'number' || fieldValidation.type === 'string') ? fieldValidation = fieldValidation.max(maxValidationNumber) : undefined

    return {
      ...prevSchema,
      // @ts-ignore
      [currentField.name]: fieldValidation
    }
  }, {})

  const initialFormValues = partnerFields.reduce((prevValue, currentField) => {
    return {
      ...prevValue,
      // @ts-ignore
      [currentField.name]: ''
    }
  }, { locale })
  /*eslint-enable */

  // TODO: add variables for reused values to avoid duplicate code
  // @ts-ignore
  const renderPartnerField = field => ({
    'number': <SimpleText
      key={propOr<string, string>('', 'id', field)}
      label={propOr<string, string>('', 'label', field)}
      name={propOr<string, string>('', 'name', field)}
      placeholder={propOr<string, string>('', 'placeholder', field)}
      type="number"
    />,
    'string': <SimpleText
      key={propOr<string, string>('', 'id', field)}
      label={propOr<string, string>('', 'label', field)}
      name={propOr<string, string>('', 'name', field)}
      placeholder={propOr<string, string>('', 'placeholder', field)}
      type={field.isEmail ? 'email' : 'text'}
    />
  })

  const renderButton = (props: ContentfulButton, buttonType: SSButtonProps['type'], isSubmitting: boolean, dirty: boolean, isValid: boolean, alwaysEnable: boolean) => {
    const buttonProps = {
      ...toButton(props),
      minWidth: toButtonWidthValue('medium'),
      type: toButtonTypeValue(buttonType),
    }
    return <div data-component={buttonType}>
      <FormField hideLabel={true} name={`${buttonType}`}>
        <SSButton
          {...buttonProps} />
      </FormField>
    </div>
  }

  const renderTermsAndConditions = (termsAndConditions: ContentfulModal) => {
    const termsAndConditionsLink =
    <a
      href="#"
      style={{
        'color': '#9D9CA0',
        'padding': '10px'
      }}
    >
      Terms and Conditions
    </a>

    // @ts-ignore
    const modalContentData = safeProp('modalContent', termsAndConditions).orUndefined()
    const modalContent: ReactNode = modalContentData ? <RichTextWithOptionsComponent data={modalContentData}/> : <></>

    return <ModalComponent clickTarget={termsAndConditionsLink} modalContent={modalContent} />
  }

  const renderPartnerImage = (image: ContentfulAsset) => {
    return <FluidImg
      alt={prop('title', image)}
      // @ts-ignore
      fluid={prop('fluid', image)}
      imgStyle={{ objectFit: 'contain', }}
      style={{
        'height': '64px',
        'width': '320px'
      }}
    />
  }

  const successMessageDescription =
    safePath([ 'successMessageDescription', 'json' ], data)
      .map((json: Document) => <ContentfulRichText key={prop('id', data)}
        rawRichText={json}/>)
      .getOrElse(<></>)

  const formatFormData = ( data: FormTypes ) => {
    const partnerName = getValueFromPartnerCookie('partnerName') || ''
    const partnerGroup = getValueFromPartnerCookie('partnerGroup')

    const reward = partnerGroup === 'airlines' ? 7000 : 0

    return {
      memberNumber: propOr('', 'memberNumber', data),
      membershipData: {
        firstName: propOr('', 'firstName', data),
        lastName: propOr('', 'lastName', data),
        memberNumber: propOr('', 'memberNumber', data),
        rewardValue: reward
      },
      partnerName: partnerName
    }
  }

  const updateCartMemberNumber = (partnerMemberNumber: string) => {
    const partnerMemberNumberAction = buildCustomFieldUpdateAction({
      name: 'partnerMemberNumber',
      value: partnerMemberNumber
    })
    const updateActions = [
      partnerMemberNumberAction
    ]
    dispatch(IOUpdateCart(updateActions, () => logError(Error('something went wrong trying to update partnerMemberNumber'))))
  }

  return (
    <Formik initialValues={initialFormValues}
      // @ts-ignore
      onSubmit={
        async (
          formData: PartnerMembershipCaptureBody,
          {
            setSubmitting, setStatus, setFieldError
          }
        ) => {
          const handleSuccess = () => {
            setStatus('success')
            setSubmitting(true)
            updateCartMemberNumber(formData.memberNumber)
          }

          const handleFailure = () => {
            const message = 'Uh oh! Looks like something is wrong! try again'
            /* eslint-disable @typescript-eslint/no-unsafe-argument */
            setFieldError('memberNumber', message)
          }
          setSubmitting(true), 200
          // @ts-ignore
          partnersMembershipFormSubmit(formatFormData(formData))(handleFailure)(handleSuccess)
        }
      }
      validationSchema={object().shape(schema)}>
      {({
        isSubmitting,
        status,
        dirty,
        isValid
      }) => (
        <div style={{ 'padding': '50px' }}>
          {image && renderPartnerImage(image)}
          <Form>
            {!status && (
              <>
                {(formTitle || formDescription) && <Description
                  formDescription={formDescription}
                  formTitle={formTitle}
                />}
                <div style={{ marginBottom: '10px' }}>
                  {partnerFields.map(field =>
                    // @ts-ignore
                    renderPartnerField(field)[field.inputType])}
                </div>
                {termsAndConditions && renderTermsAndConditions(termsAndConditions)}
                {submitButton && renderButton(submitButton, 'submit', isSubmitting, dirty, isValid, false)}
                {//TODO re-enable when we can add functionality to close the modal from this button
                /* {continueButton && renderButton(continueButton, 'link', isSubmitting, dirty, isValid, true)} */}
              </>
            )}
          </Form>
          {status && (
            <SuccessMessage
              successMessageDescription={successMessageDescription}
              successMessageTitle={propOr<string, string>('', 'successMessageTitle', data)}
            />
          )}
        </div>
      )}
    </Formik>
  )
}

export default PartnerCaptureForm
