import { Document } from '@contentful/rich-text-types'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import { selectPartnerBannerLoading } from '@simplisafe/ss-ecomm-data/promotions/select'
import {
  liftSelectProduct,
  selectActivePromoLoading,
  selectTopBannerVisible
} from '@simplisafe/ss-ecomm-data/redux/select'
import { Price } from '@simplisafe/ss-react-components/atoms'
import { FloatingPromoWidget } from '@simplisafe/ss-react-components/molecules'
import { graphql } from 'gatsby'
import React, {
  FC, useCallback,
  useState
} from 'react'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import Cookies from 'universal-cookie'

import { FloatingPromoWidgetFragment } from '../../../graphql'
import { formatDisplayPrice } from '../../commercetools/price'
import useDelayedRender from '../../hooks/useDelayedRender'
import useLiveChatAvailability from '../../hooks/useLiveChatAvailability'
import { getChatAppId } from '../../tracking/cookies'
import { trackAddToCartFloatingBannerEvent } from '../../util/analytics/addToCart'
import documentFallback from '../../util/documentFallback'
import getDescriptionJson from '../../util/getDescriptionJson'
import getJson from '../../util/getJson'
import { toButton } from '../../util/helper'
import ContentfulRichText from '../ContentfulRichText'
import FluidImg from '../FluidImg'

type FloatingPromoWidgetComponentProps = {
  readonly data: FloatingPromoWidgetFragment
}

const cookies = new Cookies()
export const COOKIE_FLOATING_PROMO_WIDGET_CLOSED = 'floating_promo_widget_closed'

const renderPrice = (price?: string) =>
  <Price
    key={`total-price-${price}`}
    regularPrice={price} />

const renderBackdropImage = (data: FloatingPromoWidgetFragment) => <FluidImg alt={path([ 'backdropImage', 'title' ], data)}
  fluid={path([ 'backdropImage', 'fluid' ], data)}
  style={{ width: '100%' }}/>

const FloatingPromoWidgetComponent: FC<FloatingPromoWidgetComponentProps> =
({ data }: FloatingPromoWidgetComponentProps) => {
  const isTopBannerVisible = useSelector(selectTopBannerVisible)
  const isActivePromoLoading = useSelector(selectActivePromoLoading)
  const isPartnerBannerLoading = useSelector(selectPartnerBannerLoading)
  const hasWidgetClosedCookie = cookies.get(COOKIE_FLOATING_PROMO_WIDGET_CLOSED) === 'true'
  const chatId = getChatAppId()
  const isLiveChatEnabled: boolean = useLiveChatAvailability(chatId)
  const [ widgetClosed, setWidgetClosed ] = useState(hasWidgetClosedCookie)
  const [ showSuccess, setshowSuccess ] = useState(false)
  const [ showSpinner, setShowSpinner ] = useState(true)
  const { Track, trackEvent } = useTracking()
  const delayedRender = useDelayedRender(1000)
  const description: Document  = getDescriptionJson(data)
  const thankYouNote: Document  = getJson(prop('thankYouNote', data))
  const title: string = safeProp('title', data).getOrElse('')
  const button = prop('button', data) || {}

  const setMinimizedCookie = () => cookies.set(COOKIE_FLOATING_PROMO_WIDGET_CLOSED, 'true')

  const onClose = () => {
    cookies.set(COOKIE_FLOATING_PROMO_WIDGET_CLOSED, 'true')
    setMinimizedCookie()
    setWidgetClosed(true)
  }

  const productId = safeProp('productId', data)
  const product = useSelector(liftSelectProduct(productId))

  // TODO move this to ecomm-data
  const productPrice: string = product.cata(
    () => '',
    p => formatDisplayPrice(prop('price', p), {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }).orJust('')
  )

  const onClick = useCallback(() => {
    const handleSuccess = () => {
      setShowSpinner(false)
      setshowSuccess(true)
      setMinimizedCookie()
      trackAddToCartFloatingBannerEvent(product, trackEvent, 1)
    }

    handleSuccess()
  }, [ product, trackEvent ])

  const showWidget = !widgetClosed && !isTopBannerVisible && !isActivePromoLoading && !isPartnerBannerLoading && !isLiveChatEnabled
  const content = <ContentfulRichText rawRichText={documentFallback(description)} />
  const thankYouNoteContent = <ContentfulRichText rawRichText={documentFallback(thankYouNote)} />

  return (
    showWidget ? delayedRender(() => (
      <Track>
        <FloatingPromoWidget
          backdropImage={renderBackdropImage(data)}
          buttonProps={{
            ...toButton(button),
            showSpinner
          }}
          content={content}
          dataComponent={title}
          onApplyCoupon={onClick}
          onClick={onClick}
          onClose={onClose}
          price={renderPrice(productPrice)}
          showSuccess={showSuccess}
          thankYouNoteContent={thankYouNoteContent}
          title={title}
        />
      </Track>
    )) : null)
}

export const floatingPromoWidgetQuery = graphql`#graphql
  fragment floatingPromoWidget on ContentfulFloatingPromoWidget {
    title
    backdropImage {
      file {
        url
      }
      fluid(maxWidth: 600, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
      title
    }
    productId
    id
    description {
      json
      id
    }
    button {
      text
      type
      url
    }
    thankYouNote {
      json
    }
  }
`

export default FloatingPromoWidgetComponent
