import { ButtonColor } from '@simplisafe/ss-react-components/atoms/SSButton'

const buttonColors = [ 'anchorNav',  'customPlain',  'link',  'none',  'primary',  'primaryHollow',  'primaryOutline',  'primaryPlain',  'secondary',  'secondaryOutline',  'tertiary',  'tertiaryOutline' ] as const
/**
 * A type guard that returns true if the input string is a valid ButtonColor
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isButtonColor = (color: any): color is typeof buttonColors[number] => {
  return buttonColors.includes(color)
}

/**
 * Verifies that the input string matches the ButtonColor union type.
 */
export const verifyButtonColor =
  (color: string | ButtonColor): ButtonColor =>
    isButtonColor(color) ? color : 'primary'
