import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safePath, safeProp } from '@simplisafe/monda'
import { IOAddToCart } from '@simplisafe/ss-ecomm-data/cart'
import { liftSelectProduct } from '@simplisafe/ss-ecomm-data/redux/select'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { AddExtraSensors } from '@simplisafe/ss-react-components/organisms'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Just } from 'monet'
import T from 'ramda/src/T'
import React, {
  FC,
  useCallback, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

import { AddExtraSensorsFragment } from '../../../graphql'
import { renderOutOfStockMessage  } from '../../commercetools/outOfStock'
import AddToCartError, { AddToCartErrorType } from '../../errorComponents/AddToCartError'
import useRequestPrice from '../../hooks/useRequestPrice'
import { priceDefault } from '../../providers/PriceProvider/formatter'
import { useOptimizelyTrackSiteEvents } from '../../tracking/optimizely'
import { trackAddToCartEvent } from '../../util/analytics/addToCart'
import getJson from '../../util/getJson'
import { toButton } from '../../util/helper'
import RichText from '../RichText'

export type AddExtraSensorsComponentProps = {
  readonly data: AddExtraSensorsFragment
}

const AddExtraSensorsComponent: FC<AddExtraSensorsComponentProps> = ({ data }: AddExtraSensorsComponentProps) => {
  const additionalTextIcon = Just(title => smallIconFixed => (
    // TODO: remove eslint-disable after upgrading Monda
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    <Img alt={title}
      // TODO: remove eslint-disable after upgrading Monda
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      fixed={smallIconFixed}/>
  ))
    .apTo(safePath([ 'note', 'icon', 'title' ], data))
    .apTo(safePath([ 'note', 'icon', 'smallIconFixed' ], data))
    .orUndefined()
  const sku = safeProp('productId', data)
  const product = useSelector(liftSelectProduct(sku))
  const isOnStock = product.cata(T, p => prop('isOnStock', p) ? true : false)
  const isSellable = product.cata(T, p => prop('isSellable', p) ? true : false)
  const outOfStockButtonText = prop('outOfStockButtonText', data)
  const outOfStockText = prop('outOfStockText', data)
  const dispatch = useDispatch()
  const [ addToCartError, setAddToCartError ] = useState<AddToCartErrorType>(null)
  const [ showSpinner, setShowSpinner ] = useState(true)
  const { Track, trackEvent } = useTracking()

  const { getFormattedPrice } = useRequestPrice(sku.orJust(''))
  const price = getFormattedPrice(priceDefault)

  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()

  const onAddToCart = useCallback((quantity: number, handleSuccess: () => void) => {
    setAddToCartError(null)

    const onFailure = () => {
      setShowSpinner(false)
      setAddToCartError('recoverable')
      optimizelyTrackSiteEvents({ eventType: 'website_error' })
    }

    const onSuccess = () => {
      handleSuccess()
      setShowSpinner(false)
      optimizelyTrackSiteEvents({ eventType: 'add_to_cart_clicked' })
      trackAddToCartEvent(product, trackEvent, quantity)
    }

    const message =
      sku.cata(() => 'received null/empty sku',
        _sku => `no product with sku ${_sku} found in redux`
      )

    product.cata(
      () => {
        setShowSpinner(false)
        setAddToCartError('unrecoverable')
        logError(Error(`Cannot add to cart: ${message}`))
      },
      _product => {
        dispatch(IOAddToCart({
          products: [ {
            quantity,
            sku: _product.masterSku
          } ]
        }, onFailure, onSuccess))
      }
    )
  }, [ dispatch, product, sku, trackEvent, optimizelyTrackSiteEvents ])

  return (
    safeProp('button', data).map(button => {
      const initialButtonProps = {
        ...toButton(button),
        showSpinner,
      }

      const outStockButtonProps = {
        ...initialButtonProps,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        children: outOfStockButtonText
          ? outOfStockButtonText
          : prop('children', initialButtonProps),
      }

      const buttonProps = !isSellable || !isOnStock ? outStockButtonProps : initialButtonProps

      const maxQuantity = !isSellable ? 0 : prop('maximumQuantity', data) || undefined


      const text = path([ 'note', 'text' ], data)

      // eslint-disable-next-line react/jsx-key
      return (<Track>
        <AddExtraSensors
          additionalText={<RichText json={getJson(text)} />}
          additionalTextIcon={additionalTextIcon}
          buttonProps={buttonProps}
          cartUpdateSuccessMessage={path([ 'cartUpdatedText', 'text', 'text' ], data)}
          defaultQuantity={safeProp('defaultQuantity', data).orUndefined()}
          // Passing null to errorMessage if addToCartError is null so we can show the cart updated message on success
          errorMessage={addToCartError && <AddToCartError errorType={addToCartError}
            textAlign='center' />}
          headerText={prop('headerText', data) || ''}
          isCartable={isSellable && isOnStock ? true : false}
          isNotCartableText={outOfStockText}
          maxQuantity={maxQuantity}
          onButtonClick={onAddToCart}
          outOfStockMessage={isSellable ? renderOutOfStockMessage({ product: product }) : null}
          price={price}
        />
      </Track>)
    })
      .orNull()
  )
}

export default AddExtraSensorsComponent

export const query = graphql`#graphql
  fragment addExtraSensors on ContentfulModalSensorAdditionToSystem {
    id
    internal {
      type
    }
    button {
      ... on ContentfulButton {
        ...contentfulButtonFragment
      }
    }
    cartUpdatedText {
      text {
        text
      }
    }
    defaultQuantity
    headerText
    maximumQuantity
    note {
      ... on ContentfulIconWithText {
        ...iconWithText
        icon {
          smallIconFixed: fixed(height: 50) {
            ...GatsbyContentfulFixed_withWebp_noBase64
          }
        }
      }
    }
    outOfStockButtonText
    outOfStockText
    productId
  }
`
