import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { SmallTextSection } from '@simplisafe/ss-react-components/molecules'
import type { SmallTextSectionProps } from '@simplisafe/ss-react-components/molecules/SmallTextSection'
import { graphql } from 'gatsby'
import always from 'ramda/src/always'
import applySpec from 'ramda/src/applySpec'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import isNil from 'ramda/src/isNil'
import join from 'ramda/src/join'
// TODO replace with ts-functional-pipe
// eslint-disable-next-line no-restricted-imports
import pipe from 'ramda/src/pipe'
import split from 'ramda/src/split'
import trim from 'ramda/src/trim'
import React, { FC } from 'react'

import { ContentfulSmallTextSection } from '../../../graphql'
import {
  nullToUndefined, strToCamelCase, toFirstCharLower
} from '../../util/helper'
import { PageProps } from '../Page'

type ContentfulSmallTextSectionProps = {
  readonly id: string
  readonly data: Partial<ContentfulSmallTextSection>
  readonly pageContext: PageProps['pageContext']
}

export const toUiTheme =
  (data: Partial<ContentfulSmallTextSection>) =>
    safeProp('uiTheme', data)
      .map(toFirstCharLower)
      .map(split(':'))
      .map(x => x.map(trim))
      .map(join(''))
      .orUndefined()

const toBackgroundColor =
  (data: Partial<ContentfulSmallTextSection>) =>
    safeProp('backgroundColor', data)
      .map(strToCamelCase)
      .getOrElse('none')

const toShadowBorder =
      (data: Partial<ContentfulSmallTextSection>) =>
        safeProp('shadowBorder', data)
          .map(strToCamelCase)
          .orUndefined()

const totextColor = pipe(path([ 'textColor', 'color' ]), nullToUndefined)
const toSmallTextSectionData = (data: Partial<ContentfulSmallTextSection>, showInMobile: boolean): SmallTextSectionProps => {
  return applySpec<SmallTextSectionProps>({
    backgroundColor: toBackgroundColor,
    borderLine: pipe(prop('borderLine'), nullToUndefined),
    description: ifElse(
      equals(true), always(path( [ 'descriptionMobile', 'json' ])), always(path( [ 'description', 'json' ]))
    )(showInMobile && !isNil(prop('descriptionMobile', data))),
    listStyle: pipe(prop('listStyle'), nullToUndefined),
    otherDeviceVisibility: pipe(prop('otherDeviceVisibility'), nullToUndefined),
    shadowBorder: toShadowBorder,
    sidePadding: pipe(prop('sidePadding'), nullToUndefined),
    textColor: totextColor,
    theme: toUiTheme,
    verticalPadding: pipe(prop('verticalPadding'), nullToUndefined)
  })(data)
}

const SmallTextSections: FC<ContentfulSmallTextSectionProps> =
  ({ data, pageContext }: ContentfulSmallTextSectionProps) => {
    const showInMobile = !useMediaQuery('TabletAndUp')
    const props = {
      ...toSmallTextSectionData(data, showInMobile),
      pageContext
    }
    return (
      <SmallTextSection {...props} />
    )
  }

export default SmallTextSections

export const query = graphql`#graphql
    fragment smallTextSectionFragment on ContentfulSmallTextSection {
        id
        internal {
          type
        }
        title
        description {
          json
        }
        descriptionMobile {
          json
        }
        uiTheme
        backgroundColor
        textColor {
          color
        }
        otherDeviceVisibility
        sidePadding,
        verticalPadding,
        listStyle
        height
        borderLine
        shadowBorder
    }
`
