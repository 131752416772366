import { TrackingData } from '@simplisafe/ecomm-ts-types'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safePath, safeProp } from '@simplisafe/monda'
import { LeadGenCreateParams, leadGenRecommendations } from '@simplisafe/ss-ecomm-data/leads/quoteWizard'
import { selectActivePromoCode, selectLocale } from '@simplisafe/ss-ecomm-data/redux/select'
import { cookiesOption } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import { Column, LoadingSpinner } from '@simplisafe/ss-react-components/atoms'
import { WizardInitial } from '@simplisafe/ss-react-components/molecules'
import { Link } from 'gatsby'
import { set } from 'local-storage'
import { None } from 'monet'
import propOr from 'ramda/src/propOr'
import React, {
  FC,
  useContext,
  useMemo,
  useState
} from 'react'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

import { locale } from '../../commercetools/utils'
import { HidePopupWizard } from '../../contexts/hidePopupWizardContext'
import useCookieChange from '../../hooks/useCookieChange'
import { visitorIdAtAt } from '../../tracking/atat'
import { handleBrazeTrackingEvent } from '../../tracking/braze'
import {
  COOKIE_LEAD_DATA,
  cookies,
  getLeadData
} from '../../tracking/cookies'
import { useOptimizelyTrackSiteEvents } from '../../tracking/optimizely'
import { trackEventCompletedQuoteWizard, trackSubmitLeadEvent } from '../../util/analytics'
import ButtonComponent from '../ButtonComponent'
import QuoteWizardComponent, { QuoteWizardProps, toRichText } from '../QuoteWizardComponent'
import RichText from '../RichText'
import { buildRecommendationResponseData } from './quoteWizardRecommendationTrackingData'

const KEY_QW_RECOMMENDATIONS_SUBMITTED = 'qw_recommendations_submitted'
const KEY_RETURN_TO_QW = 'ss_return_to_qw'
const POP_UP_STORAGE_KEY = 'popupWizardOpened'

export type QuoteWizardWrapperProps = {
  readonly handleEmailSubmit?: QuoteWizardProps['handleEmailSubmit']
  readonly handlePhoneSubmit?: QuoteWizardProps['handlePhoneSubmit']
  readonly data: QuoteWizardProps['data']
  readonly includePhone?: QuoteWizardProps['includePhone']
  readonly type?: QuoteWizardProps['type']
  readonly finalTabDescription?: QuoteWizardProps['finalTabDescription']
  readonly finalTabContents?: QuoteWizardProps['finalTabContents']
  readonly firstTab?: QuoteWizardProps['firstTab']
  readonly showTitle?: QuoteWizardProps['showTitle']
  readonly columnType?: 'none' | 'shadowbox'
}

const QuoteWizardWrapper: FC<QuoteWizardWrapperProps> =
  ({
    columnType = 'shadowbox',
    data,
    type = 'embedded',
    includePhone = false
    // eslint-disable-next-line sonarjs/cognitive-complexity
  }: QuoteWizardWrapperProps) => {
    const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()
    const leadDataCookie = getLeadData()

    const [ emailAddress, setEmailAddress ] = useState<string>(propOr<string, string>('', 'email', leadDataCookie))
    const [ attributeHash, setAttributeHash ] = useState(None())
    const [ isLoading, setIsLoading ] = useState(false)
    const { Track, trackEvent } = useTracking<TrackingData>({
      // @ts-ignore - fix type; 'action' does not exist on TrackingData
      action: 'quoteWizard',
      appSection: 'quoteWizard',
      wizardType: type
    })

    const promoCode = useSelector(selectActivePromoCode)

    const siteLocale = useSelector(selectLocale)
    const { handleHidePopup } = useContext(HidePopupWizard)

    const storedAttributeHash: string | undefined = cookies.get<string>(KEY_RETURN_TO_QW)

    const {
      newUserHelpText,
      newUserSkipQuizButton,
      newUserStartQuizButton,
      returningUserHelpText,
      returningUserRestartQuizButton,
      returningUserSeeResultsButton
    } = data.firstTab || {}

    // TODO: fix type
    // @ts-ignore
    const newUserSkipQuizButtonUrl = prop('url', newUserSkipQuizButton || {})

    const handleEmailSubmitFailure = () => {
      optimizelyTrackSiteEvents({ eventType: 'website_error' })
    }

    const handleEmailSubmitSuccess = (responses: Record<string, unknown>) => (value) => {
      setAttributeHash(value.map(prop('attributeHash')))
      cookies.set(KEY_QW_RECOMMENDATIONS_SUBMITTED, true, cookiesOption)
      const attributeHash = value.map(prop('attributeHash')).orUndefined()
      cookies.set(KEY_RETURN_TO_QW, attributeHash, cookiesOption)
      cookies.set(COOKIE_LEAD_DATA, value.orUndefined(), cookiesOption)
      handleBrazeTrackingEvent(value.orUndefined())

      // GTM recursively merges objects; reset braze wizard data by explicitly pushing undefined before pushing the real data
      trackEvent({
        brazePopupWizardResponseData: undefined,
        brazeWizardWithRecsResponseData: undefined,
        event: 'resetObjects'
      })
      trackEvent({
        brazeWizardWithRecsResponseData: buildRecommendationResponseData(siteLocale, attributeHash, responses),
        event: 'submit'
      })
      trackEventCompletedQuoteWizard(trackEvent)
      optimizelyTrackSiteEvents({ eventType: 'quote_wizard_complete_fs' })
      optimizelyTrackSiteEvents({ eventType: 'lead_captured_fs' })
      setIsLoading(false)
      trackSubmitLeadEvent(trackEvent)
    }

    const handleEmailSubmit = (responses: Record<string, string | unknown>) => {
      const leadGenParams: LeadGenCreateParams = {
        format: type,
        leadPromoOffer: promoCode.getOrElse('NO_CODE'),
        leadSourceVersion: {},  // TODO pull in from page context
        locale: locale,
        // @ts-ignore // TODO the types for the responses need to be fixed in ecomm-data. There should be no unknown types.
        responses,
        showPhoneField: includePhone,
        visitorId: visitorIdAtAt() || 'NO_VISITOR_ID'
      }

      const emailAddress = safeProp('email', responses).getOrElse('')
      setEmailAddress(
        typeof emailAddress === 'string'
          ? emailAddress : ''
      )

      setIsLoading(true)
      leadGenRecommendations(leadGenParams)(handleEmailSubmitFailure)(handleEmailSubmitSuccess(responses))
    }

    useCookieChange(COOKIE_LEAD_DATA, data => setEmailAddress(propOr('', 'email', JSON.parse(data))))
    const onClickButton = () => {
      handleHidePopup(false)
      set(POP_UP_STORAGE_KEY, true)
    }

    const isNewUser = !storedAttributeHash

    /**
      * `useMemo` allows us to avoid inconsistencies between the number of steps for a new user and a returning one.
      * Without the use of "useMemo," a bug occurs because when a new user submits their email, a cookie is set,
      * and it becomes a returning user, so an initial step is added, and an inconsistency occurs.
      */
    const showNewUsersFirstTab = useMemo(
      () => !!(isNewUser && newUserHelpText && newUserSkipQuizButton && newUserSkipQuizButtonUrl && newUserStartQuizButton)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      , []
    )
    const showReturningUsersFirstTab = useMemo(
      () => !!(!isNewUser && returningUserHelpText && returningUserRestartQuizButton && returningUserSeeResultsButton),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )

    const firstTab = showNewUsersFirstTab || showReturningUsersFirstTab ?
      <WizardInitial
        isNewUser={isNewUser}
        newUserHelpText={newUserHelpText}
        newUserSkipQuizButton={
          <Link
            to={newUserSkipQuizButtonUrl}
          >
            <ButtonComponent data={newUserSkipQuizButton} />
          </Link>
        }
        newUserStartQuizButton={<ButtonComponent data={newUserStartQuizButton}
          onClick={onClickButton} />}
        returningUserHelpText={returningUserHelpText}
        returningUserRestartQuizButton={<ButtonComponent data={returningUserRestartQuizButton} />}
        returningUserSeeResultsButton={
          <Link
            style={{ display: 'block' }}
            to={`/product/system/${storedAttributeHash}`}
          >
            <ButtonComponent data={returningUserSeeResultsButton} />
          </Link>
        }
      /> : null

    const loading = <div key="loading"
      style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        left: '50%',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)'
      }}>
      <LoadingSpinner />
      <span className="m1_t">Building your System</span>
    </div>

    const finalTabDescription = isLoading ? [ loading ] : toRichText('finalTabDescription', data, emailAddress)
    const finalTabContents = isLoading ? [ loading ]
      : safeProp('finalTabContents', data)
        .map(components => components.map(componentData => {
          const component = componentData

          const key = prop('id', component)

          return path([ 'internal', 'type' ], component) === 'ContentfulRichText' && attributeHash.isSome()
            ? (
              <Link
                key={key}
                to={`/product/system/${attributeHash.just()}`}
              >
                {safePath([ 'richText', 'json' ], component).map(json => <RichText json={json}
                  key={key} />)}
              </Link>
            )
            : <div key={key} />
        })
        )
        .getOrElse([])

    const renderQuoteWizard = () => (<QuoteWizardComponent
      data={data}
      defaultEmail={emailAddress}
      finalTabContents={finalTabContents}
      finalTabDescription={finalTabDescription}
      firstTab={firstTab}
      handleEmailSubmit={handleEmailSubmit}
      includePhone={includePhone}
      type={type}
    />)

    return (columnType === 'none' ? renderQuoteWizard() :
      <Track>
        <Column
          backgroundColor='neutralLightGray'
          padding='large'
          shadow='medium'>
          {renderQuoteWizard()}
        </Column>
      </Track>
    )
  }

export default QuoteWizardWrapper
