import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safePath, safeProp } from '@simplisafe/monda'
import { liftSelectProduct, selectPackagesDiscountDisplayText } from '@simplisafe/ss-ecomm-data/redux/select'
import { RichText } from '@simplisafe/ss-react-components/atoms'
import { ProductPlan } from '@simplisafe/ss-react-components/organisms'
import { graphql } from 'gatsby'
import { Just, Maybe } from 'monet'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { pipe } from 'ts-functional-pipe'

import {
  ContentfulLinkAddToCartFragment, ContentfulProductPlanFragment, ModalFragmentFragment
} from '../../../graphql'
import { renderPriceWithTemplate } from '../../commercetools/price'
import { getMappedComponent } from '../../componentMappings'
import getDescriptionJson from '../../util/getDescriptionJson'
import { parseArray } from '../../util/parseContentfulValues'
import ModalComponent from '../ModalComponent'


type ProductPlanComponentProps = {
  readonly data: ContentfulProductPlanFragment
}

const ProductPlanComponent: FC<ProductPlanComponentProps> = ({ data }: ProductPlanComponentProps) => {
  const productId = safeProp('productId', data)
  const product = useSelector(liftSelectProduct(productId))
  const productOfferValue = useSelector(selectPackagesDiscountDisplayText).orUndefined()

  const keyFeaturesModals = parseArray(prop('keyFeaturesModals', data))

  const prepareButtonData = (buttonData: ContentfulLinkAddToCartFragment | ModalFragmentFragment) => {
    return {
      ...buttonData,
      /**
       * TODO
       * Map.fromNull(buttonData).flatMap(safePath([ 'internal', 'type' ]))
       * can become:
       * safePath([ 'internal', 'type' ], buttonData)
       * once simplisafe/monda#13 (https://github.com/simplisafe/monda/issues/13) is complete
       */
      spinnerMobileAutoHeight: Maybe.fromNull(buttonData)
        .flatMap(safePath([ 'internal', 'type' ]))
        .map(type => type === 'ContentfulLinkAddToCart')
        .getOrElse(false)
    }
  }

  const buttonData = safeProp('button', data).map(prepareButtonData)
  const buttonComponent = buttonData.flatMap(pipe(getMappedComponent, Maybe.fromNull))

  //TODO Get the right data for productOffer - how to switch between Promo/Evergreen offer?
  return (
    <ProductPlan
      button={
        Just((buttonData: unknown) => (ButtonComponent: FC<{ readonly data: unknown }>) =>
          <ButtonComponent data={buttonData} />
        )
          .apTo(buttonData)
          .apTo(buttonComponent)
          .orUndefined()
      }
      keyFeatures={keyFeaturesModals.map(feature => {
        return {
          clickTarget: <ModalComponent
            data={feature}
            key={feature.id} />,
          id: feature.id
        }
      })}
      planDescription={<RichText json={getDescriptionJson(data)} />}
      price={renderPriceWithTemplate({
        product,
        showDiscountedPrice: false,
        template: prop('priceRate', data),
        templateVar: '{price}'
      })}
      // @ts-ignore the type for product is wrong? or this doesn't work.
      productOfferContent={<RichText json={path([ 'productOfferText', 'json' ], product)} />}
      productOfferValue={productOfferValue}
      relatedInformation={prop('relatedInformation', data) || ''}
      title={prop('title', data) || ''}
    />
  )
}

export default ProductPlanComponent

export const ProductPlanQuery = graphql`#graphql
  fragment contentfulProductPlan on ContentfulProductPlan {
    button {
      ... on ContentfulLinkAddToCart {
        ...contentfulLinkAddToCart
      }
      ... on ContentfulModal {
        ...modalFragment
      }
    }
    description {
      json
    }
    id
    internal {
      type
    }
    keyFeaturesModals {
      ... on ContentfulModal {
        ...modalFragment
      }
    }
    priceRate
    productOffer
    productOfferText {
      json
    }
    productId
    relatedInformation
    title
  }
`
