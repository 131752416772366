import { safeProp } from '@simplisafe/monda'
import { cookiesOption } from '@simplisafe/ss-ecomm-data/simplisafe'
import { Maybe } from 'monet'
import Cookies from 'universal-cookie'

const cookies = new Cookies()
export const PARTNER_DATA = 'partners_data'

export type PartnerCookie = {
  readonly customShopUrl?: string
  readonly partnerGroup: string
  readonly partnerName: string
  readonly partnerUrl: string
}

export const setPartnerCookie = (partnerCookieObj: PartnerCookie) =>
  cookies.set(PARTNER_DATA, partnerCookieObj, cookiesOption)

export const getValueFromPartnerCookie = (partnerCookieKey: keyof PartnerCookie): string | null =>
  Maybe.fromNull(cookies.get(PARTNER_DATA))
    .chain(safeProp(partnerCookieKey))
    .orNull()

// Returns true if a partner group qualifies for a better promo offer than a sitewide offer
export const isPartnerUpgradePromoOffer = (): boolean =>
  getValueFromPartnerCookie('partnerGroup') === 'standard-partner'
