import {
  leadGenCapture, LeadGenCaptureParams, LeadGenCaptureResponse
} from '@simplisafe/ss-ecomm-data/simplisafe'
import { cookiesOption } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import {
  Column, Row, Text
} from '@simplisafe/ss-react-components/atoms'
import type { Spans } from '@simplisafe/ss-react-components/atoms/Column'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import * as Promo from '@simplisafe/ss-react-components/promotions'
import { Link, navigate } from 'gatsby'
import { Maybe } from 'monet'
import always from 'ramda/src/always'
import cond from 'ramda/src/cond'
import equals from 'ramda/src/equals'
import T from 'ramda/src/T'
import React, { FC, useState } from 'react'
import { useTracking } from 'react-tracking'

import { handleBrazeTrackingEvent } from '../../tracking/braze'
import { COOKIE_LEAD_DATA, cookies } from '../../tracking/cookies'
import { useOptimizelyTrackSiteEvents } from '../../tracking/optimizely'
import { SimpliSafeCSSProperties } from '../../types/SimpliSafeCSSProperties'
import { trackSubmitLeadEvent } from '../../util/analytics'
import LeadCaptureForm, { LeadCaptureFormPropsOnSubmit } from '../LeadCaptureForm'

export type PartnerBannerProps = {
  readonly partnerName?: string
  readonly discountText?: string
  readonly lineItemText?: string
  readonly monitoringText?: string
	readonly primaryOfferText?: string
	readonly secondaryOfferText?: string
  readonly secondaryOfferTextMobile?: string
  readonly discountSecondaryText?: string
  readonly displayEmailInputField?: boolean
  readonly showCta?: boolean
  readonly linkPath?: string

  readonly backgroundColor?: string
  readonly primaryTextColor?: string
  readonly secondaryTextColor?: string

  readonly logoDescription?: string
  readonly logoUrl?: string
}

export const COOKIE_LEAD_CAPTURE_SUBMITTED = 'partnerBanner_isLeadCaptureSubmitted'

const PartnerBanner: FC<PartnerBannerProps> = ({
  partnerName, discountText, lineItemText, monitoringText, primaryOfferText, secondaryOfferText, secondaryOfferTextMobile, discountSecondaryText, displayEmailInputField = false, showCta = true, linkPath, primaryTextColor, secondaryTextColor, backgroundColor, logoDescription, logoUrl
}) => {
  const styles: SimpliSafeCSSProperties = {
    '--btn-accent': 'var(--promo-banner-bg)',
    '--btn-primary': 'var(--promo-primary-text)',
    '--link': 'var(--promo-primary-text)',
    '--promo-banner-bg': backgroundColor,
    '--promo-primary-text': primaryTextColor || 'var(--white)',
    '--promo-secondary-text': secondaryTextColor || 'var(--white)'
  }

  const [ isLeadCaptureSubmitted, setIsLeadCaptureSubmitted ] = useState(cookies.get(COOKIE_LEAD_CAPTURE_SUBMITTED) ? true : false)
  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()
  const { trackEvent } = useTracking({ appSection: 'promoSubmit' })

  const handleEmailSubmit: LeadCaptureFormPropsOnSubmit = (email, onFailure) => {

    const handleLeadCaptureFailure = () => {
      optimizelyTrackSiteEvents({ eventType: 'website_error' })
      onFailure('We\'ve encountered an error. Please try again later.')
    }

    const handleLeadCaptureSuccess = (value: Maybe<LeadGenCaptureResponse>) => {
      setIsLeadCaptureSubmitted(true)
      cookies.set(COOKIE_LEAD_CAPTURE_SUBMITTED, true)
      cookies.set(COOKIE_LEAD_DATA, value.orUndefined(), cookiesOption)
      handleBrazeTrackingEvent(value.orUndefined())
      optimizelyTrackSiteEvents({ eventType: 'lead_captured_fs' })
      trackEvent({ event: 'submit' })
      trackSubmitLeadEvent(trackEvent)
    }

    const leadGenParams: LeadGenCaptureParams = {
      email,
      source: partnerName
    }

    leadGenCapture(leadGenParams)(handleLeadCaptureFailure)(handleLeadCaptureSuccess)
  }

  const colCount = 1 + (displayEmailInputField ? 1 : 0) + (logoUrl ? 1 : 0)

  /* Provide larger click target for link unless email input is shown so we don't trigger navigation when interacting with email input.
  Inspired by https://css-tricks.com/block-links-the-search-for-a-perfect-solution/ */
  const isBannerClickable = linkPath && !displayEmailInputField

  const onBannerClick = (event: React.MouseEvent) => {
    linkPath && navigate(linkPath)
    event.preventDefault()
  }

  const isPhone = !useMediaQuery('TabletAndUp')

  const PartnerOfferText = () => <Promo.PartnerOfferText
    discountText={discountSecondaryText ? `${discountText} ${discountSecondaryText}` : discountText}
    isCompact={colCount > 2}
    isSingleLine={colCount === 1}
    lineItemText={lineItemText}
    monitoringText={monitoringText}
    primaryText={primaryOfferText}
    secondaryText={isPhone && secondaryOfferTextMobile ? secondaryOfferTextMobile : secondaryOfferText}
  />

  return (
    <div style={styles}>
      <Promo.PromoBanner onClick={isBannerClickable ? onBannerClick : undefined} role="banner"
        style={{ cursor: isBannerClickable ? 'pointer' : 'auto' }}>
        <Row inheritTextColor={true}>
          {logoUrl && <Column
            alignSelf='center'
            marginLeftRight='medium'
            spans={[ 12, 3, 4 ]}
          >
            <img alt={logoDescription} src={logoUrl}
              style={{
                display: 'block',
                margin: 'auto',
                maxWidth: '350px',
                width: '100%'
              }} />
          </Column>}
          <Column
            alignSelf='center'
            justifySelf='center'
            spans={cond<number, Spans>([
              [ equals(3), always([ 12, 4 ]) ],
              [ equals(2), always([ 12, 7, 8 ]) ],
              [ T, always([ 12 ]) ]
            ])(colCount)}
          >
            {linkPath ? <Link style={{
              color: 'unset',
              textDecoration: 'none'
            }}
            to={linkPath}>
              <PartnerOfferText/>
            </Link> :
              <PartnerOfferText/>
            }
          </Column>
          {displayEmailInputField &&
            <Column
              justifySelf='center'
              spans={[ 12, 5, 4 ]}
            >
              <Text
                inheritTextColor={true}
                textSize='xs'
                useTailwind={true}
              >
                <LeadCaptureForm
                  buttonVariant="outlined"
                  ctaButtonHref='/home-security-shop'
                  ctaButtonText='shop now'
                  isSubmitted={isLeadCaptureSubmitted}
                  label='Enter your email'
                  legal={<span>You may receive email offers from us in accordance with our <Link to="/privacy-policy"><strong>Privacy Policy</strong></Link>.</span>}
                  onSubmit={handleEmailSubmit}
                  placeholder='enter your email'
                  showCta={showCta}
                  submitButtonText='get offer'
                  successMessage='Thank you. Your code will automatically apply at checkout.'
                />
              </Text>
            </Column>
          }
        </Row>
      </Promo.PromoBanner>
    </div>
  )
}

export default PartnerBanner
