/**
 * To hold common exports and enum declarations.
 */
// TODO: get this from ecomm-data
export type languageType = 'en-US' | 'en-GB'

export const getCookieDomain = () => {
  return process.env.LOCALE === 'en-GB' ? '.simplisafe.co.uk' : '.simplisafe.com'
}

export const getWebAppUrl = () => {
  return process.env.WEBAPP_URL ? process.env.WEBAPP_URL : 'https://webapp.simplisafe.com'
}
