import { Document } from '@contentful/rich-text-types'
import localizeCent from '@simplisafe/ewok/currency-utils/localizeCent'
import prop from '@simplisafe/ewok/ramda/prop'
import { Product } from '@simplisafe/ss-ecomm-data/commercetools/products'
import {
  Column, RichText, Row,
  SSButton
} from '@simplisafe/ss-react-components/atoms'
import { Either } from 'monet'
import React from 'react'

import { ContentfulLinkAddToCart, RichTextWithOptionsFragment } from '../../../graphql'
import { locale } from '../../commercetools/utils'
import type { TrackEvent } from '../../util/analytics'
import documentFallback from '../../util/documentFallback'
import getJson from '../../util/getJson'
import { getTokenizedJson, replaceTokensWithPrice } from '../../util/monitoring-price-placeholders'
import ModalComponent from '../ModalComponent'
import RichTextWithOptionsComponent from '../RichTextWithOptionsComponent'
import richTextWithOptionsFallback from '../RichTextWithOptionsComponent/richTextWithOptionsFallback'
import { getAddPlanButton, modalClickTargetGTM } from '.'

export const renderModalContent = (
  topRichText: Document | undefined,
  leftRichTextWithOptions: RichTextWithOptionsFragment,
  rightRichTextWithOptions: RichTextWithOptionsFragment,
  bottomRichTextWithOptions: RichTextWithOptionsFragment,
  acceptButton?: Partial<ContentfulLinkAddToCart> | undefined,
) => {
  return (
    <div data-compoment="LimitedProMonitoringModal"
      style={{ padding: '50px' }}>
      <Column spans={[ 6, 6, 6 ]}>
        <Row>
          <Column spans={[ 12, 12, 12 ]}>
            <RichText json={topRichText}/>
          </Column>
        </Row>
        <Row>
          <Column alignSelf={'start'}
            spans={[ 6, 6, 6 ]}>
            <Column spans={[ 12, 12, 12 ]}>
              <RichTextWithOptionsComponent data={leftRichTextWithOptions} />
            </Column>
          </Column>
          <Column alignSelf={'start'}
            spans={[ 6, 6, 6 ]}>
            <Column spans={[ 12, 12, 12 ]}>
              <RichTextWithOptionsComponent data={rightRichTextWithOptions} />
            </Column>
            { acceptButton &&
              <Column spans={[ 12, 12, 12 ]}>
                <div style={{
                  margin: '0 auto',
                  minWidth: '156px',
                  textAlign: 'center',
                  whiteSpace: 'nowrap'
                }}>
                  { getAddPlanButton(acceptButton) }
                </div>
              </Column>
            }
          </Column>
        </Row>
        <Row>
          <Column spans={[ 12, 12, 12 ]}>
            <RichTextWithOptionsComponent data={bottomRichTextWithOptions} />
          </Column>
        </Row>
      </Column>
    </div>
  )
}

const renderLimitedProMonitoringModal = (
  limitedProMonitoringProduct: Either<Error, Product>,
  trackEvent: TrackEvent,
  modalClickTargetText?: string,
  limitedProMonitoringCTA?: Document,
  topRichText?: Document,
  leftRichTextWithOptions?: RichTextWithOptionsFragment,
  rightRichTextWithOptions?: RichTextWithOptionsFragment,
  bottomRichTextWithOptions?: RichTextWithOptionsFragment,
  acceptButton?: Partial<ContentfulLinkAddToCart> | undefined,
) => {
  const price = limitedProMonitoringProduct.cata(
    () => 0,
    value => prop('price', value)
  )

  const leftJson = leftRichTextWithOptions && getJson(prop('richText', leftRichTextWithOptions))
  const leftJsonTokenized = leftJson && getTokenizedJson(leftJson, price)

  return (
    <div style={{
      border: '1px solid #ebeae8',
      padding: '20px 40px'
    }}>
      <RichText inlineComponent={
        <ModalComponent
          clickTarget={
            <span style={{
              display: 'inline',
              fontSize: '18px',
              textDecoration: 'none'
            }}>
              <SSButton
                color="link"
                onClick={() => {
                  modalClickTargetGTM(trackEvent, `${localizeCent(locale, price)}/day`)
                }}>
                {replaceTokensWithPrice(modalClickTargetText || '', price)}
              </SSButton>
            </span>
          }
          modalContent={
            renderModalContent(
              documentFallback(topRichText),
              richTextWithOptionsFallback(leftRichTextWithOptions && {
                ...leftRichTextWithOptions,
                richText: {
                  ...leftRichTextWithOptions['richText'],
                  json: leftJsonTokenized
                }
              }),
              richTextWithOptionsFallback(rightRichTextWithOptions),
              richTextWithOptionsFallback(bottomRichTextWithOptions),
              acceptButton
            )
          }
          size={'medium'} />
      }
      isClickTargetInline={true}
      json={limitedProMonitoringCTA} />
    </div>
  )
}

export default renderLimitedProMonitoringModal
