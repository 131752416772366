import prop from '@simplisafe/ewok/ramda/prop'
import isNotEmpty from '@simplisafe/ewok/ramda-adjunct/isNotEmpty'
import transformObject from '@simplisafe/ewok/transformObject'
import { safeProp } from '@simplisafe/monda'
import { getLocalizedString, Product } from '@simplisafe/ss-ecomm-data/commercetools/products'
import { OutOfStockMessage, Text } from '@simplisafe/ss-react-components/atoms'
import moment from 'moment-business-days'
import { Either } from 'monet'
import defaultTo from 'ramda/src/defaultTo'
import isEmpty from 'ramda/src/isEmpty'
import React from 'react'
import { pipe } from 'ts-functional-pipe'

import { locale } from './utils'

// List of SKUs for Core Compoonents
export const systemCoreComponents = [ 'SSES3', 'SSKP3W', 'SSBS3W' ]

export type CoreComponentsData = {
  readonly name: string
  readonly restockDate: string
}

export const getDurationInWeeks = (duration: moment.Duration) => `${Math.round(duration.asWeeks())} - ${(Math.round(duration.asWeeks()) + 1)}`

export const getDurationInDays = (duration: moment.Duration) => Math.round(duration.asDays())

export const outOfStockMessage = (restock: string) => {
  const todayDate = moment()
  const restockDate = moment(restock, 'YYYY-MM-DD')
  const duration = moment.duration(restockDate.diff(todayDate))
  const durationInDays = getDurationInDays(duration)
  const durationInWeeks = getDurationInWeeks(duration)
  return durationInDays < 8 ? `${durationInDays} days` : `${durationInWeeks} weeks`
}

export const renderCartLineOutOfStockMessage = (products: readonly CoreComponentsData[]) => {
  return products.length === 1
    ?
    <span>{products[0].name} ships separately in {outOfStockMessage(products[0].restockDate)}</span>
    :
    products.length === 2
      ?
      <div>
        <span>{products[0].name} ships separately in {outOfStockMessage(products[0].restockDate)}</span>
        <br />
        <span>{products[1].name} ships separately in {outOfStockMessage(products[1].restockDate)}</span>
      </div>
      :
      <span>{products.map(product => product.name).join(', ')} ship separately in {outOfStockMessage(getLatestDate(products.map(product => product.restockDate)))}</span>
}

// If a product cannot be set as out of stock through Drupal, restock date is returned as an empty string
// Otherwise it returns '1970-01-01' by default if there's no restock date
export const showProductsOutOfStock = (restockDate: string) => {
  return !isEmpty(restockDate) ? new Date(restockDate).getTime() > new Date().getTime()
    : false
}

// Returns an array of Products that are out of stock
export const componentsNotInStock = (coreComponetsProducts: Either<Error, readonly Product[]>) => coreComponetsProducts.cata(
  () => [],
  (val: readonly Product[]) => val
    .filter((coreProduct: Product) => showProductsOutOfStock(safeProp('restockDate', coreProduct).getOrElse('')) === true)
    .map(toCoreComponentsList)
)

export const toCoreComponentsList = transformObject<Product, CoreComponentsData>({
  name: (x) => pipe((x) => defaultTo('')(prop('name', x)),
    getLocalizedString(locale),
    (prodName: string) => prodName)(x),
  restockDate: (x) => safeProp('restockDate', x).getOrElse('')
})

// Return a list of names of Core Components not in stock
export const getSensorListString = (coreComponents: readonly CoreComponentsData[], pluralize?: boolean) => {
  const numberOfSensors: number = coreComponents.length

  const coreComponetsNames =
    coreComponents
      .map(product => pluralize ? `${prop('name', product).toLowerCase()}s` : prop('name', product))

  return numberOfSensors > 1 ? coreComponetsNames.join(', ').replace(/,([^,]*)$/, ' and$1') : coreComponetsNames.toString()
}

// Return the latest date from an array of dates
export const getLatestDate = (datesArray) => {
  const dates = datesArray
    .filter(dates => dates !== '')
    .map(dates => new Date(dates))
  return new Date(Math.max(...dates)).toISOString()
    .slice(0, 10)
    .toString()
}

// Return latest restock date for core components
export const coreComponentsRestockDate = (coreComponents: readonly CoreComponentsData[]) => {
  const backInStockDates =
    coreComponents
      .map(product => safeProp('restockDate', product).getOrElse(''))
      .filter(restockDate => restockDate !== '')

  const restockDatesNumber: number = backInStockDates.length
  return restockDatesNumber > 1 ? getLatestDate(backInStockDates) : backInStockDates.toString()
}

// description for cart if one or more of the core components are out of stock
export const renderCoreComponentsCartMessage = (coreComponents: readonly CoreComponentsData[]) => {
  const restockDate = coreComponentsRestockDate(coreComponents)
  const restockDateFormatted = moment(restockDate).format('MMMM D')
  const sensorsList = getSensorListString(coreComponents)
  const sensorsListPlural = getSensorListString(coreComponents, true)

  // TODO: the copy should be coming from Contentful
  return coreComponents.length > 0 ? (
    <Text>
      <p>
        {`Our ${sensorsListPlural} are currently backordered and expected to be back in stock on ${restockDateFormatted}. Each system requires ${sensorsList} for setup. We will ship your system as soon as it is available.`}
      </p>
    </Text>
  ) : null
}

// Core componenst out of stock message to render on the page
export const renderCoreComponentsNotInStockMsg = (
  coreComponents: readonly CoreComponentsData[],
  showDetails = true
) => {
  const coreComponentsNamesList = getSensorListString(coreComponents)
  const backInStockDate = coreComponentsRestockDate(coreComponents)

  return (
    <OutOfStockMessage
      backInStockDate={backInStockDate}
      coreComponentNotInStock={true}
      coreComponentsDesc={showDetails ? `Each system requires ${coreComponentsNamesList} for setup. We will ship your system as soon as it is available.` : undefined}/>
  )
}

/**
 * Returns Out of Stock Message if a product is out of stock
 */

type OutOfStockMessageProps = {
  readonly product: Either<Error, Product>
  readonly includedInPackage?: boolean
  readonly lowStockTemplate?: boolean
  readonly coreComponentsNotInStock?: readonly CoreComponentsData[]
}

export const renderOutOfStockMessage = ({
  product, includedInPackage = false, lowStockTemplate = false, coreComponentsNotInStock = []
}: OutOfStockMessageProps): React.ReactNode | null => {
  return product.cata(
    () => null,
    (v: Product) => {
      const restockDate: string = safeProp('restockDate', v).getOrElse('')
      const showOutOfStockMessage: boolean = showProductsOutOfStock(restockDate)
      const areCoreComponentsNotInStock: boolean = coreComponentsNotInStock.length > 0

      const coreComponentsBackInStockDate = areCoreComponentsNotInStock ? coreComponentsRestockDate(coreComponentsNotInStock) : ''
      const productBackInStockLater: boolean = isNotEmpty(coreComponentsBackInStockDate) ? (new Date(restockDate).getTime() > new Date(coreComponentsBackInStockDate).getTime()) : true
      const showShipsSeparately: boolean = (includedInPackage && productBackInStockLater)
      /*
        if there is a core component not in stock and another non-core component is out of stock,
        back in stock date for non-core component needs to be the same as core component restock date,
        if non-core component is supposed to be back in stock earlier (the system will not be shipped without core components)
      */
      const restockDateRecalculate: boolean = (isNotEmpty(coreComponentsBackInStockDate) && includedInPackage && !productBackInStockLater)
      const restockDateDisplay: string = restockDateRecalculate ? getLatestDate([ coreComponentsBackInStockDate, restockDate ]) : restockDate

      return showOutOfStockMessage ? (
        <OutOfStockMessage
          backInStockDate={restockDateDisplay}
          includedInPackage={includedInPackage ? showShipsSeparately : false}
          lowStockMessage={lowStockTemplate ? lowStockTemplate : false}/>
      ) : null
    }
  )
}
