import { TrackingData } from '@simplisafe/ecomm-ts-types'
import { StickyPopoverLink } from '@simplisafe/ss-react-components/molecules'
import { graphql } from 'gatsby'
import React, {
  FC, useContext, useState
} from 'react'
import { useTracking } from 'react-tracking'

import { ContentfulFloatingBar } from '../../../graphql'
import { getMappedComponent } from '../../componentMappings'
import { HidePopupWizard } from '../../contexts/hidePopupWizardContext'
import { OptimizelyEvent, useOptimizelyTrackSiteEvents } from '../../tracking/optimizely'
import { QuoteWizardWrapperProps } from '../QuoteWizardWrapper'

const handleLinkClick =
  (trackEvent: (_data: Partial<TrackingData>) => void) =>
    (optimizelyTrackSiteEvents: (_data: OptimizelyEvent) => void) => {
      optimizelyTrackSiteEvents({ eventKey: 'get_a_quote_click_fs' })
      // TODO this event needs to be added to ecomm-ts-types
      // @ts-ignore
      trackEvent({ event: 'quote-wizard-floating-start' })
    }

type FloatingQuizDialogueProps = {
  readonly data: Partial<ContentfulFloatingBar>
}

const FloatingQuizDialogue: FC<FloatingQuizDialogueProps> =
  ({ data }: FloatingQuizDialogueProps) => {
    const [ isShow, setIsShow ] = useState(false)
    const { hidePopupWizard: showStickyPopup } = useContext(HidePopupWizard)
    const Component = data.component && getMappedComponent<QuoteWizardWrapperProps>(data.component)

    const { Track, trackEvent } = useTracking({
      appSection: 'quoteWizard',
      wizardType: 'floating'
    })

    const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()

    return data.title && data.component && (isShow || showStickyPopup) ? (
      <Track>
        <StickyPopoverLink
          linkText={data.title}
          onLinkClick={() => { handleLinkClick(trackEvent)(optimizelyTrackSiteEvents), setIsShow(true) }}
        >
          {
            Component ?
              <Component
                columnType='none'
                data={data.component}
                type='floating'
              /> :
              null
          }
        </StickyPopoverLink>
      </Track>
    ) : null
  }

export default FloatingQuizDialogue

export const fragment = graphql`#graphql
  fragment floatingQuizDialogue on ContentfulFloatingBar {
    title
    internal {
      type
    }
    component {
      ... on ContentfulFindYourPerfectSystem {
        ... quoteWizard
      }
    }
  }
`
