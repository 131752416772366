import { Maybe } from 'monet'
import { useEffect, useRef } from 'react'

/**
 * The useEffect hook runs on initial render, which is occasionally not what we want. For example, the redux store isn't
 * hydrated with products/packages from pageContext until after initial mount, and we may want to avoid logging an error
 * for a missing product before that happens.
 * @see {@link https://github.com/facebook/react/issues/15873}
 * Note: if the name of this hook ever changes, the corresponding entry in .eslintrc.js should be updated as well
 */
export const useEffectAfterMount = <T>(effectFn: () => void, dependencies: ReadonlyArray<T>) => {
  const didMountRef = useRef(false)
  useEffect(() => {
    Maybe.fromFalsy(didMountRef.current).cata(
      () => { didMountRef.current = true },
      effectFn
    )
  // We don't need to pass effectFn in the dependency array because the exhaustive-deps eslint rule is set up to work with useEffectAfterMount's dependencies
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)
}
