/* eslint-disable functional/prefer-readonly-type */
import { Document } from '@contentful/rich-text-types'
import path from '@simplisafe/ewok/ramda/path'

import documentFallback from './documentFallback'

/**
 * Contentful types json as an `any` type from GraphQL, but we know that the type matches Document.
 * This function returns description.json as a valid Document type.
 * If there is no valid Document at the path, this function will return an empty Document that will render nothing.
 */
function getDescriptionJson<T extends { description?: { json?: Document } }>(input: T): Document {
  return documentFallback(path([ 'description', 'json' ], input))
}

export default getDescriptionJson
