import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import type { ColorSelectorColor } from '@simplisafe/ss-react-components/atoms/ColorSelector'
import type {
  ColumnProps, Span, Spans
} from '@simplisafe/ss-react-components/atoms/Column'
import type { ModalSize } from '@simplisafe/ss-react-components/atoms/Modal'
import { RichTextProps } from '@simplisafe/ss-react-components/atoms/RichText'
// TODO Ideally we import SiteGridGap, SitePadding from
// ss-react-components/src/types, but was unable to get that in the bundle with
// rollup. Instead, importing the RowProps interface that users those under the hood.
import type { RowProps } from '@simplisafe/ss-react-components/atoms/Row'
import type { SSButtonProps } from '@simplisafe/ss-react-components/atoms/SSButton'
import type { HeroSlideProps } from '@simplisafe/ss-react-components/molecules/HeroSlide'
import type { IconWithTextProps } from '@simplisafe/ss-react-components/molecules/IconWithText'
import { Maybe } from 'monet'

import type { ActivePromoBannerType } from './components/ActivePromoBanner'

export type SitePadding = 'large' | 'medium' | 'none' | 'small'
export type TextSize = 'md' | 'sm' | 'xs'
export type SiteColor = 'accentBlueGray' | 'accentTan' | 'accentYellowGrey' | 'blackFriday1' | 'blackFriday2' | 'blackFriday3' | 'blackFriday4' | 'blackFriday5' | 'blackFriday6' | 'brandPrimary' | 'brandPrimaryLight' | 'brandSecondary' | 'cautionTextYellow' | 'darkOrange' | 'default' | 'errorRed' | 'neutralBlack' | 'neutralDarkGray' | 'neutralDarkRed' | 'neutralLightBrown' | 'neutralLightGray' | 'neutralLightRed' | 'neutralLightTan' | 'neutralLightYellow' | 'neutralMediumGray' | 'neutralWhite' | 'neutralYellow' | 'none' | 'trueBlack'

/** Map Contentful field values to ss-react-components attribute values. */
export const gapSizeValueMapper: {[key: string]: 'large' | 'medium' | 'none' | 'small'} = {
  Large: 'large',
  Medium: 'medium',
  None: 'none',
  Small: 'small',
}
export const toGapValue =
  (value?: string | null) =>
    Maybe.fromNull(value)
      .chain(val => safeProp(val, gapSizeValueMapper))
      .orUndefined()

export const marginValueMapper: {[key: string]: RowProps['padding']} = {
  large: 'large',
  medium: 'medium',
  none: 'none',
  small: 'small',
}

export const toMarginValue = (value?: string | null): ColumnProps['marginLeftRight'] => value ? prop(value, marginValueMapper) : undefined

export const paddingValueMapper: Record<RowProps['padding'], SitePadding> = {
  Large: 'large',
  Medium: 'medium',
  None: 'none',
  Small: 'small',
}

type paddingFunctionType = (value?: string | null) => SitePadding
export const toPaddingValue: paddingFunctionType = (value?: string | null): RowProps['padding'] => value ? prop(value, paddingValueMapper) : undefined

export const textPositionMapper: {[key: string]: HeroSlideProps['displayMode']} = {
  'Bottom': 'bottom',
  'Bottom-Center': 'bottom',
  'Bottom-Left': 'bottom-left',
  'Bottom-Right': 'bottom-right',
  'Center': 'center',
  'Center-Left': 'left',
  'Center-Right': 'right',
  'Left': 'left',
  'Right': 'right',
  'Top': 'top',
  'Top-Center': 'top',
  'Top-Left': 'top-left',
  'Top-Right': 'top-right',
}
export const toTextPositionValue = (value?: string | null): HeroSlideProps['displayMode'] | undefined => value ? prop(value, textPositionMapper) : undefined

export const buttonWidthMapper: {[key: string]: SSButtonProps['minWidth']} = {
  auto: 'auto',
  full: 'full',
  large: 'large',
  medium: 'medium',
  small: 'small'
}

export const toButtonWidthValue = (value?: string | null): SSButtonProps['minWidth'] | undefined => value ? prop(value, buttonWidthMapper) : undefined

export const buttonTypeMapper: {[key: string]: SSButtonProps['type']} = {
  button: 'button',
  link: 'link',
  reset: 'reset',
  submit: 'submit'
}
export const toButtonTypeValue = (value?: string | null): SSButtonProps['type'] | undefined => value ? prop(value, buttonTypeMapper) : undefined

export const listStyleMapper: {[key: string]: RichTextProps['listStyle']} = {
  checkmark: 'checkmark',
  disc: 'disc',
  square: 'square'
}

export const toListStyleValue = (value?: string | null): RichTextProps['listStyle'] => value ? prop(value, listStyleMapper) : undefined

const colorSelectorColorMapper: {[key: string]: ColorSelectorColor} = {
  black: 'obsidian',
  cloud: 'white',
  obsidian: 'obsidian',
  white: 'white'
}
export const toColorSelectorColor = (value: string | null | undefined) => (value && colorSelectorColorMapper[value]) || 'white'

const siteColorMapper: {[key: string]: RowProps['backgroundColor']} = {
  accentBlueGray: 'accentBlueGray',
  accentTan: 'accentTan',
  accentYellowGrey: 'accentYellowGrey',
  brandPrimary: 'brandPrimary',
  brandPrimaryLight: 'brandPrimaryLight',
  brandSecondary: 'brandSecondary',
  cautionTextYellow: 'cautionTextYellow',
  darkOrange: 'darkOrange',
  default: 'default',
  errorRed: 'errorRed',
  neutralBlack: 'neutralBlack',
  neutralDarkGray: 'neutralDarkGray',
  neutralDarkRed: 'neutralDarkRed',
  neutralLightBrown: 'neutralLightBrown',
  neutralLightGray: 'neutralLightGray',
  neutralLightRed: 'neutralLightRed',
  neutralLightTan: 'neutralLightTan',
  neutralLightYellow: 'neutralLightYellow',
  neutralMediumGray: 'neutralMediumGray',
  neutralWhite: 'neutralWhite',
  neutralYellow: 'neutralYellow',
  none: 'none',
  trueBlack: 'trueBlack',
}
export const toSiteColor = (value: string | null | undefined) => (value && siteColorMapper[value]) || undefined

const modalSizeMapper: {[key: string]: ModalSize} = {
  large: 'large',
  medium: 'medium',
  small: 'small'
}
export const toModalSize = (value: string | null | undefined) => (value && modalSizeMapper[value]) || undefined

const spanMapper: {[key: number]: Span} = {
  0: 0,
  1: 1,
  10: 10,
  11: 11,
  12: 12,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
}

export const toSpan = (value: number) => Number.isInteger(value) && spanMapper.hasOwnProperty(value) ? spanMapper[value] : spanMapper[12]

const parseColumnRatio = (ratio?: string | null): readonly Span[] =>
  Maybe.fromNull(ratio)
    .map(ratio => ratio.split(':').map(r => parseInt(r, 10)))
    // Check if the ratio contains 2 valid Span values
    .filter(ratio => ratio.length === 2 && ratio.every(r => r >= 0 && r <= 12))
    .map(ratio => [ toSpan(ratio[0]), toSpan(ratio[1]) ])
    .getOrElse([ toSpan(12), toSpan(12) ])

/**
 * Returns the left and right column spans from a list of ratios.
 * E.g. `parseRatio('12', '8:4', '7:5')` becomes `[[12, 8, 7], [12, 4, 5]]`
 */
export const toColumnSpans = (
  mobileRatio?: string | null,
  tabletRatio?: string | null,
  desktopRatio?: string | null
): readonly [ Spans, Spans ] => {
  const mobileValues = parseColumnRatio(mobileRatio)
  const tabletValues = parseColumnRatio(tabletRatio)
  const desktopValues = parseColumnRatio(desktopRatio)

  const xSpans: Spans = [ mobileValues[0], tabletValues[0], desktopValues[0] ]
  const ySpans: Spans = [ mobileValues[1], tabletValues[1], desktopValues[1] ]

  return [ xSpans, ySpans ]
}

export const iconPositionMapper: {[key: string]: IconWithTextProps['iconPosition']} = {
  'Bottom': 'bottom',
  'Left': 'left',
  'Right': 'right',
  'Top': 'top',
  'bottomcenter': 'bottomcenter',
  'topcenter': 'topcenter'
}

export const toIconPositionValue = <T extends string>(value?: T | null): IconWithTextProps['iconPosition'] | undefined => value ? prop(value, iconPositionMapper) : undefined

export const iconSizeMapper: {[key: string]: IconWithTextProps['iconSize']} = {
  'extralarge': 'extralarge',
  'extrasmall': 'extrasmall',
  'large': 'large',
  'medium': 'medium',
  'responsive': 'responsive',
  'small': 'small'
}

export const toIconSizeValue = <T extends string>(value?: T | null): IconWithTextProps['iconSize'] | undefined => value ? prop(value, iconSizeMapper) : undefined

export const iconVerticalAlignmentMapper: {[key: string]: IconWithTextProps['iconVerticalAlignment']} = {
  'bottom': 'bottom',
  'middle': 'middle',
  'top': 'top'
}

export const toIconVerticalAlignmentValue = <T extends string>(value?: T | null): IconWithTextProps['iconVerticalAlignment'] | undefined => value ? prop(value, iconVerticalAlignmentMapper) : undefined

export const textAlignmentMapper: {[key: string]: IconWithTextProps['textAlignment']} = {
  'Center': 'center',
  'Left': 'left',
  'Right': 'right'
}

export const toTextAlignmentValue = <T extends string>(value?: T | null): IconWithTextProps['textAlignment'] | undefined => value ? prop(value, textAlignmentMapper) : undefined

export const promoBannerStyleMapper: {[key: string]: ActivePromoBannerType} = {
  'Cart': 'cart',
  'None': 'none',
  'PDP/PLP': 'pdp-plp',
  'Standard': 'standard',
}

export const toPromoBannerStyleValue = (value: string | null | undefined): ActivePromoBannerType | undefined => value ? prop(value, promoBannerStyleMapper) : undefined
