import { FormField, Text } from '@simplisafe/ss-react-components/atoms'
import React, { ReactElement } from 'react'

type GetAQuoteFormSuccessMessageProps = {
  readonly successMessageDescription?: ReactElement
  readonly successMessageTitle?: string
}

const SuccessMessage: React.FC<GetAQuoteFormSuccessMessageProps> = ({
  successMessageDescription,
  successMessageTitle,
}: GetAQuoteFormSuccessMessageProps) => {
  return (
    <FormField hideLabel={true} name="getAQuoteSuccessMessage">
      <div
        style={{
          margin: 'auto',
          textAlign: 'center',
        }}
      >
        {successMessageTitle && (
          <Text fontWeight="light">
            <h3 style={{ textAlign: 'center' }}>{successMessageTitle}</h3>
          </Text>
        )}
        {successMessageDescription}
      </div>
    </FormField>
  )
}

export default SuccessMessage
