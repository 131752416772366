import { safeProp } from '@simplisafe/monda'
import { SSButton } from '@simplisafe/ss-react-components/atoms'
import { CardShop } from '@simplisafe/ss-react-components/molecules'
import { graphql, Link } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import { Maybe } from 'monet'
import React, { FC, ReactNode } from 'react'

import { CardShopFragment } from '../../../graphql'
import { leadingSlashIt } from '../../util/helper'

const toGatsbyImage = (imgData: CardShopFragment['img'] | null): ReactNode => {
  const altText = Maybe.fromNull(imgData)
    .chain(safeProp('title'))
    .getOrElse('')

  return Maybe.fromNull(imgData)
    .chain(safeProp('fluid'))
    .cata(
      () => null,
      fluid => <Img
        alt={altText}
        fluid={fluid as FluidObject}
        imgStyle={{ objectFit: 'contain', }}
        style={{
          height: '100%',
          width: '100%'
        }}
      />
    )
}

const toButtonLink = (
  pageLink: CardShopFragment['pageLink'],
  linkText: CardShopFragment['linkText']
): ReactNode => {
  // Link URL.
  const url: string = Maybe.fromNull(pageLink)
    .chain(safeProp('pageUrl'))
    .map(leadingSlashIt)
    .getOrElse('')

  // Link Text.
  const text: string = Maybe.fromNull(linkText).getOrElse('')

  // Only return a link url and link text exists; else, return an empty ReactNode.
  return url && text ? <Link
    to={url}>
    <SSButton
      color='primaryHollow'
      minWidth='small'
      type='div'>
      {text}
    </SSButton>
  </Link> : null
}

export type CardShopComponentProps = {
  readonly data: CardShopFragment
}

const CardShopComponent: FC<CardShopComponentProps> = ({ data }: CardShopComponentProps) => {

  const {
    bgColor: backgroundColor,
    flag,
    hasPadding,
    id,
    img: image,
    linkText,
    pageLink,
    textColor,
    title,
  } = data

  return (
    <CardShop
      backgroundColor={Maybe.fromNull(backgroundColor).chain(safeProp('color'))
        .getOrElse('neutralLightGray')}
      color={Maybe.fromNull(textColor).chain(safeProp('color'))
        .getOrElse('neutralBlack')}
      flag={flag ? flag : undefined}
      footer={toButtonLink(pageLink, linkText)}
      // Default hasPadding to true unless explicitly set to false. Undefined or null will default to true.
      hasPadding={hasPadding === false ? false : true}
      img={toGatsbyImage(image)}
      key={id}
      title={title ? title : ''}
    />
  )
}

export default CardShopComponent

export const CardShopQuery = graphql`#graphql
  fragment cardShop on ContentfulCardShop {
    bgColor: backgroundColor {
      color
    }
    flag
    hasPadding
    id
    img: image {
      id
      # Quality set to 95 because PNG as a webP looked pixelated at 50% quality (default).
      # 95% gives us filesize savings (65kB => 16kB) with almost perfect quality.
      fluid(maxWidth: 540, quality: 95) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
      title
    }
    internal {
      type
    }
    linkText
    pageLink {
      pageUrl
    }
    textColor {
      color
    }
    title
  }
`
