import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safePath } from '@simplisafe/monda'
import { selectPromoDiscountText, selectPromoWithMonitoringDiscountText } from '@simplisafe/ss-ecomm-data/promotions/select'
import { selectTopBannerVisible } from '@simplisafe/ss-ecomm-data/redux/select'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import {
  HeroSlideProps, LinkItem, PreloadLink
} from '@simplisafe/ss-react-components/molecules/HeroSlide'
import { Carousel } from '@simplisafe/ss-react-components/organisms'
import { CarouselProps } from '@simplisafe/ss-react-components/organisms/Carousel'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { Maybe, None } from 'monet'
import allPass from 'ramda/src/allPass'
import always from 'ramda/src/always'
import applySpec from 'ramda/src/applySpec'
import defaultTo from 'ramda/src/defaultTo'
import equals from 'ramda/src/equals'
import filter from 'ramda/src/filter'
import has from 'ramda/src/has'
import ifElse from 'ramda/src/ifElse'
import isEmpty from 'ramda/src/isEmpty'
import isNil from 'ramda/src/isNil'
import map from 'ramda/src/map'
import multiply from 'ramda/src/multiply'
import toLower from 'ramda/src/toLower'
import unless from 'ramda/src/unless'
import when from 'ramda/src/when'
import React, {
  FC, useCallback, useEffect,
  useState
} from 'react'
import { useSelector } from 'react-redux'
import { pipe } from 'ts-functional-pipe'

import {
  CarouselFragment, ContentfulAsset, ContentfulImageWithArtDirection
} from '../../../graphql'
import { toSiteColor, toTextPositionValue } from '../../attributeMappings'
import { GtmData, sendGtmCustomEvent } from '../../util/analytics'
import getDescriptionJson from '../../util/getDescriptionJson'
import { getBadgeDiscount } from '../BadgeText'
import ImageWithArtDirection from '../ImageWithArtDirection'

type CarouselComponentProps = {
  readonly data: CarouselFragment
}

const getLinkUrl = prop('linkUrl')

const getContent = (keyDescription: string) => {
  return applySpec({
    description: ifElse(
      has(keyDescription),
      path([ keyDescription, 'json' ]),
      getDescriptionJson
    ),
    title: prop('title')
  })
}

const getDisplayMode = (isMobile: boolean) =>
  ifElse(equals(true),
    always(pipe(prop('textPositionMobile'), toTextPositionValue)),
    always(pipe(prop('textPosition'), toTextPositionValue)))(isMobile)

const getBannerImage = (banner: CarouselFragment[ 'banner' ], isMobile: boolean): Maybe<ContentfulAsset> =>
  ifElse(equals(true),
    always(safePath([ 'mediaMobile', '0' ], banner)
      .orElse(safePath([ 'media', '0' ], banner))
      .filter(img => !!prop('fluid', img))),
    always(safePath([ 'media', '0' ], banner)
      .orElse(safePath([ 'backgroundImage', '0' ], banner))
      .filter(img => !!prop('fluid', img))))(isMobile)

const getImageWithArtDirection = (banner: CarouselFragment['banner']): Maybe<ContentfulImageWithArtDirection> =>
// TODO: fix this type
// imageWithArtDirection does not exist on type banner
// @ts-ignore
  Maybe.fromNull(banner && prop('imageWithArtDirection', banner))

const getHeroSlide = (keyDescription: string, isMobile: boolean) => {
  const toContent = getContent(keyDescription)
  const toTextColor = ifElse(
    equals('neutralWhite'),
    always('light'),
    always('dark'),
  )

  return applySpec<HeroSlideProps>({

    BackgroundComponent: (banner: CarouselFragment[ 'banner' ]) => {
      const newImage = getImageWithArtDirection(banner)
        .map(() => ImageWithArtDirection)
        .orUndefined()
      const oldImage = getBannerImage(banner, isMobile)
        .map(() => BackgroundImage)
        .orUndefined()
      return newImage ? newImage : oldImage
    },
    backgroundComponentProps: (banner: CarouselFragment[ 'banner' ]) => {
      const newImageProps = getImageWithArtDirection(banner)
        .map(data => ({ data: data, }))
        .orUndefined()
      const oldImageProps = getBannerImage(banner, isMobile)
        .map(img => ({
          alt: prop('description', img),
          fluid: prop('fluid', img),
          loading: 'eager',
          title: prop('title', img)
        }))
        .orUndefined()
      return newImageProps ? newImageProps : oldImageProps
    },
    content: pipe(unless(isNil, toContent), defaultTo({})),
    displayMode: getDisplayMode(isMobile),
    linkText: prop('linkText'),
    linkUrl: getLinkUrl,
    preload: () => None<PreloadLink>(),
    textColor: pipe(path([ 'textColor', 'color' ]), when(isNil, always('dark')), toTextColor),
  })
}

const toCarouselContainerData = applySpec({
  autoPlaySpeed: pipe(prop('switchDuration'), when(isNil, always(4)), multiply(1000)),
  displayBadgeIcon: prop('displayDiscountBadge'),
  paginationPosition: pipe(prop('paginationPosition'), toLower),
  playButton: prop('playButton'),
})

const toMediaType = (data: CarouselFragment) => {
  const toBanner = path([ 'banner', 0 ], data)
  const maybeBannerType = safePath([ 'internal', 'type' ], toBanner)
  return (maybeBannerType.getOrElse('') === 'ContentfulBannerVideo') ? 'video' : 'image'
}

const toFloatingBadge = (contentfulCarousel: CarouselFragment, placeholders: Record<string, string>) => {
  return !isEmpty(placeholders) ? applySpec({
    backgroundColor: () => toSiteColor('brandPrimary'),
    borderColor: () => toSiteColor('brandPrimary'),
    description: path([ 'promoBadge', 'text', 'json' ]),
    linkUrl: prop('discountBadgeUrl'),
    placeholders: always(placeholders),
    textColor: () => toSiteColor('neutralWhite'),
  })(contentfulCarousel) : null
}

const getCarouselData = (contentfulCarousel: CarouselFragment, isPromoTopBanner: boolean, placeholders: Record<string, string>, isMobile: boolean) => {
  const toDesktopHeroSlide = getHeroSlide('descriptionDesktop', isMobile)
  const toMobileHeroSlide = getHeroSlide('descriptionMobile', isMobile)
  const displayDiscountBadge = prop('displayDiscountBadge')
  const toCarousel = applySpec<CarouselProps>({
    carouselContainerData: toCarouselContainerData,
    floatingBadge: ifElse(
      allPass([ displayDiscountBadge, always(!isPromoTopBanner) ]), (x) => toFloatingBadge(x, placeholders), always(undefined)
    ),
    mediaType: toMediaType,
    slideData: pipe(prop('banner'), map(toDesktopHeroSlide)),
    slideMobileData: pipe(prop('banner'), filter(has('descriptionMobile')), map(toMobileHeroSlide))
  })
  return toCarousel(contentfulCarousel)
}

const setLinkGtmCustomEvent = (linkItem: LinkItem) => {
  const gtmData: GtmData = {
    event: 'buttonClick',
    eventAction: linkItem.id === '#' ? 'play' : linkItem.name,
    eventCategory: linkItem.id === '#' ? 'video' : 'cta',
    eventLabel: linkItem.id === '#' ? linkItem.url : linkItem.name
  }
  sendGtmCustomEvent(gtmData)
}

const CarouselComponent: FC<CarouselComponentProps> =
  ({ data }: CarouselComponentProps) => {
    const [ placeholders, setPlaceholders ] = useState({})
    const isMobile = !useMediaQuery('TabletAndUp')
    const isPromoTopBanner = useSelector(selectTopBannerVisible)
    const discountTextWithMonitoring: Maybe<string> = useSelector(selectPromoWithMonitoringDiscountText)
    const discountTextWithoutMonitoring: Maybe<string> = useSelector(selectPromoDiscountText)
    const discountText = discountTextWithMonitoring.orElse(discountTextWithoutMonitoring)

    const handleFailure = () => {
      setPlaceholders({})
    }

    const onClickLink = useCallback((linkItem: LinkItem) => {
      setLinkGtmCustomEvent(linkItem)
    }, [])

    useEffect(() => {
      discountText.cata(
        handleFailure,
        text => setPlaceholders(getBadgeDiscount(text)),
      )
    }, [ discountText ])
    return (
      <Carousel
        {...getCarouselData(data, isPromoTopBanner, placeholders, isMobile)}
        onClickLink={onClickLink}
      />
    )
  }

export const query = graphql`
  fragment Carousel on ContentfulCarousel{
    id
    internal{
        type
    }
    title
    displayDiscountBadge
    promoBadge {
      text {
        json
      }
      promoCode
    }
    discountBadgeUrl
    paginationPosition
    playButton
    switchDuration
    banner {
      ... on ContentfulBanner {
        internal{
          type
        }
        id
        textPosition
        title
        mediaPosition
        linkText
        linkUrl
        description {
          json
        }
        media {
          description
          fluid(maxWidth: 1440) {
            ...GatsbyContentfulFluid_withWebp
          }
          id
          title
        }
        mediaMobile {
          description
          fluid(maxWidth: 511) {
            ...GatsbyContentfulFluid_withWebp
          }
          id
          title
        }
        textColor {
          color
        }
      }
      # TODO the only "Banner - Video" in Contentful has a jpg for an asset, and the video is embedded in a RichText
      # field (that gets opened in a popup?) (for desktop only). Could we switch this over to something else and get
      # rid of this content type entirely?
      ... on ContentfulBannerVideo {
        internal{
          type
        }
        id
        textColor {
          color
        }
        textPosition
        title
        backgroundImage{
          description
          fluid(maxWidth: 1440) {
            ...GatsbyContentfulFluid_withWebp
          }
          id
          title
        }
        imageWithArtDirection {
          ... on ContentfulImageWithArtDirection {
            ...imageWithArtDirection
          }
        }
        descriptionMobile {
          json
        }
        descriptionDesktop {
          json
        }
      }
    }
  }
`

export default CarouselComponent
