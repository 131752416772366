import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import { PopupButton } from '@simplisafe/ss-react-components/molecules'
import { graphql } from 'gatsby'
import join from 'ramda/src/join'
// TODO replace with ts-functional-pipe
// eslint-disable-next-line no-restricted-imports
import pipe from 'ramda/src/pipe'
import split from 'ramda/src/split'
import React, { FC } from 'react'

import { ContentfulPopupButtonFragment } from '../../../graphql'
import { getMappedComponent } from '../../componentMappings'
import { toFirstCharLower  } from '../../util/helper'
import { stringProp } from '../../util/parseContentfulValues'
import { verifyButtonColor } from '../../util/verifyButtonColor'

type PopupButtonComponentProps = {
  readonly data: ContentfulPopupButtonFragment
}

const PopupButtonComponent: FC<PopupButtonComponentProps> =
  ({ data }: PopupButtonComponentProps) => {
    const buttonType = safeProp('buttonType', data)
      .map(pipe(toFirstCharLower, split(' '), join('')))
      .orUndefined()
    const buttonColor = buttonType ? verifyButtonColor(buttonType) : undefined

    const popupContent = prop('popupContent', data)
    // TODO: define explicit return type for getMappedComponent
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const PopupComponent = popupContent && getMappedComponent(popupContent) as FC<{ readonly data: unknown}>
    const buttonPositionList = [ 'center', 'inline', 'left', 'right' ] as const
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isButtonPosition = (str: any): str is typeof buttonPositionList[number] => buttonPositionList.includes(str)
    const buttonPosition = prop('buttonPosition', data)
    const buttonText = stringProp('buttonText', data)

    return (
      <PopupButton
        buttonContent={buttonText}
        buttonPosition={buttonPosition && isButtonPosition( buttonPosition) ? buttonPosition : undefined}
        buttonProps={{ color: buttonColor }}
        modalContent={PopupComponent && <PopupComponent data={popupContent} />}
        modalProps={{ appElementId: '___gatsby' }}
      />
    )
  }

export default PopupButtonComponent

export const query = graphql`#graphql
    fragment contentfulPopupButton on ContentfulPopupButton {
        id
        internal {
          type
        }
        buttonPosition
        buttonText
        buttonType
        popupContent {
          ... on ContentfulRichTextWithButtons {
            ...contentfulRichTextWithButtonsFragment
          }
          ... on ContentfulSmallTextSection {
            ...smallTextSectionFragment
          }
          ... on ContentfulModalSensorAdditionToSystem {
            ...addExtraSensors
          }
          ... on ContentfulGroupSection {
            ...nonCyclicalGroupSectionFragment
          }
        }
    }
`
