import prop from '@simplisafe/ewok/ramda/prop'
import { selectPromoDiscountText } from '@simplisafe/ss-ecomm-data/promotions/select'
import { selectTopBannerVisible } from '@simplisafe/ss-ecomm-data/redux/select'
import { FloatingBadge } from '@simplisafe/ss-react-components/molecules'
import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import type { ContentfulBadgeText } from '../../../graphql'
import { toSiteColor } from '../../attributeMappings'
import getJson from '../../util/getJson'

type BadgeTextProps = {
    readonly data: Partial<ContentfulBadgeText>
}

export const getBadgeDiscount = (discountText: string) => ({ 'Discount Percentage': discountText.replace('%', '') })

const BadgeText = ({ data }: BadgeTextProps) => {

  /**
   * GraphQL alias don't type correctly
   * https://simplisafe.atlassian.net/browse/ECP-4325
   */
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const badgeText = prop('badgeText_text', data)
  const floatingBadgeContent = getJson(badgeText)
  const [ showBadgeText, setShowBadgeText ] = useState(false)
  const [ placeholders, setPlaceholders ] = useState({})

  const isPromoTopBanner = useSelector(selectTopBannerVisible)
  const discountText = useSelector(selectPromoDiscountText)

  useEffect(() => {
    discountText.cata(
      () => setShowBadgeText(false),
      text => {
        setShowBadgeText(true)
        setPlaceholders(getBadgeDiscount(text))
      }
    )
  }, [ discountText ])

  return (
    showBadgeText && !isPromoTopBanner ? <div style={
      {
        bottom: 0,
        position: 'absolute',
        right: 0
      }
    }
    >
      <FloatingBadge
        backgroundColor={toSiteColor('brandPrimary')}
        borderColor={toSiteColor('brandPrimary')}
        description={floatingBadgeContent}
        placeholders={placeholders}
        textColor={toSiteColor('neutralWhite')} />
    </div> : null
  )
}

export default BadgeText
export const BadgeTextQuery = graphql`#graphql
  fragment badgeText on ContentfulBadgeText {
    id
    internal {
      type
    }
    title
    promoCode
    badgeText_text: text {
      json
    }
  }
`
