import { useFeature } from '@optimizely/react-sdk'
import React from 'react'

import { ProductFragmentFragment } from '../../../graphql'
import PackageDetailPage from '../../PackageDetailPage'
import type { AffirmClient } from '../../types/affirm'
import OldVersion from './OldVersion'

export type ItemContainerComponentProps = {
  readonly affirmClient?: AffirmClient
  readonly data: ProductFragmentFragment
}

// To see the new version set feature_flag to refactor_itemcontainercomponent in local storage.
const ItemContainerComponentWrapper = (props: ItemContainerComponentProps) => {
  const [ newVersion ] = useFeature('refactor_itemcontainercomponent')

  /**
   * Warning!
   * This component is being replaced by PackageDetailPage.
   * If you need to make changes, you should probably make those changes to PackageDetailPage.
   */

  return newVersion
    ? <PackageDetailPage />
    : <OldVersion {...props} />
}

export default ItemContainerComponentWrapper
