/* eslint-disable max-lines */
import { RouterProps, WindowLocation } from '@reach/router'
import path from '@simplisafe/ewok/ramda/path'
import isArray from '@simplisafe/ewok/ramda-adjunct/isArray'
import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import {
  safeFind, safePath, safeProp
} from '@simplisafe/monda'
import { chainProp } from '@simplisafe/monda/chain'
import { IOPartnerBanner } from '@simplisafe/ss-ecomm-data/promotions/actions'
import { selectPartnerBanner, selectPartnerBannerLoading } from '@simplisafe/ss-ecomm-data/promotions/select'
import {
  selectActivePromoDiscountText,
  selectLocale, selectMonthsOfServiceDisplay, selectPromotionBanner,
} from '@simplisafe/ss-ecomm-data/redux/select'
import { configureNewRelic } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import {
  PageBody, PageMasthead, PageSection, PageWrapper
} from '@simplisafe/ss-react-components/atoms'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { ToastCheckMarkAlert } from '@simplisafe/ss-react-components/molecules'
import { graphql, navigate } from 'gatsby'
import { Maybe, None } from 'monet'
import always from 'ramda/src/always'
import defaultTo from 'ramda/src/defaultTo'
import ifElse from 'ramda/src/ifElse'
import map from 'ramda/src/map'
import propEq from 'ramda/src/propEq'
import React, {
  FC, ReactNode, useContext, useEffect
} from 'react'
import type { Toast } from 'react-hot-toast'
import {
  toast, ToastBar, Toaster
} from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import { BooleanParam, useQueryParam } from 'use-query-params'

import {
  ContentfulBannerTopPage,
  ContentfulDefaultBanner,
  ContentfulFloatingBar,
  ContentfulFooterFragment,
  ContentfulHeaderPartner,
  ContentfulHeaderWithProgressBar,
  ContentfulPage,
  ContentfulTrustpilotReviewsBanner,
  ContentfulWebConfig,
  FloatingPromoWidgetFragment,
  PageAnchorNavigationFragment,
  SiteWideMessagesFragment,
} from '../../../graphql'
import { toPromoBannerStyleValue } from '../../attributeMappings'
import { getMappedComponent } from '../../componentMappings'
import ErrorBoundary from '../../errorComponents/ErrorBoundary'
import useApplyPromoCode from '../../hooks/useApplyPromoCode'
import useAwinTracking from '../../hooks/useAwinTracking'
import useEnableLiveChat from '../../hooks/useEnableLiveChat'
import useOptimizelyParams from '../../hooks/useOptimizelyParams'
import useUtmCode from '../../hooks/useUtmCode'
import useUtmContent from '../../hooks/useUtmContent'
import { SmallTextSectionRedirect } from '../../templates/DefaultPage'
import { PageTitleContext } from '../../tracking/pageTitleContext'
import { sendGtmCustomEvent, trackEventIsMobile } from '../../util/analytics'
import { languageType } from '../../util/common'
import { getValueFromPartnerCookie } from '../../util/partnerCookie'
import { devParams } from '../../util/queryParams'
import SEO from '../../util/seo'
import ActivePromoBanner from '../ActivePromoBanner'
import AuthenticationComponent from '../AuthenticationComponent'
import ContentfulVariationContainerComponent from '../ContentfulVariationContainerComponent'
import CountryRedirectModal from '../CountryRedirectModal'
import DefaultBannerComponent from '../DefaultBannerComponent'
import FloatingPromoWidgetComponent from '../FloatingPromoWidgetComponent'
import FooterComponent from '../FooterComponent'
import GlobalPromotionalComponent from '../GlobalPromotionalComponent'
import HeaderComponent from '../Header'
import { ContentfulHeaderFragment } from '../Header/query'
import HeaderPartnerComponent from '../HeaderPartnerComponent'
import HeaderProgressBarComponent from '../HeaderProgressBarComponent'
import LiveChat from '../LiveChat'
import ModalExitIntentComponent from '../ModalExitIntentComponent'
import PageAnchorNavigationComponent from '../PageAnchorNavigationComponent'
import PartnerBanner from '../PartnerBanner'
import PopupWizard from '../PopupWizard'
import SimpleFooterComponent from '../SimpleFooterComponent'
import TopPageBanner from '../TopPageBanner'
import TrustpilotReviewBanner from '../TrustpilotBanner'

export type PageProps = {
    readonly children?: ReactNode
    readonly pageContext: {
        readonly id: string
        readonly isNewUser: boolean
        readonly language: languageType
        readonly locationCrimesTable?: {
            readonly head: readonly string[]
            readonly body: ReadonlyArray<ReadonlyArray<string>>
        }
        readonly url?: string
    }
    readonly data: {
        readonly contentfulHeader?: ContentfulHeaderFragment
        // TODO should this use the autogenerated type for PageFragment based on our fragment below?
        readonly contentfulPage: Partial<ContentfulPage>
        readonly contentfulSmallTextSection?: SmallTextSectionRedirect
        readonly allContentfulSmallTextSection?: readonly { readonly node: SmallTextSectionRedirect }[]
        readonly contentfulSiteWideMessages?: SiteWideMessagesFragment
    }

    // intended for passing page-level data to child components
  readonly params?: Record<string, unknown>

  readonly location?: RouterProps['location']
}

export type PageComponent = {
  readonly id: string
  readonly internal: {
    readonly type: string
  }
}

export const isBrowser = typeof window !== 'undefined'
export const getWindow = () => window

type MappedComponentProps = {
  readonly data: PageComponent
  readonly location?: WindowLocation
  readonly pageContext?: PageProps['pageContext']
}
// TODO: This will need to map the names of page components to templates
export const mapPageComponentToTemplate =
    (pageComponent: PageComponent, pageContext?: PageProps['pageContext'], location?: PageProps['location']) =>
    {
      const Component = getMappedComponent<MappedComponentProps>(pageComponent)
      return Component
        ? <ErrorBoundary key={pageComponent.id}>
          <Component
            data={pageComponent}
            key={pageComponent.id}
            location={location}
            pageContext={pageContext}
          />
        </ErrorBoundary>
        : null
    }

export const wrapComponentWithSection = (x: ReactNode) => <PageSection>{x}</PageSection>

type Component<A> = A extends readonly (infer T)[] ? T : never | undefined
type PageLayoutComponent = Exclude<Component<ContentfulWebConfig['components']>, null | undefined>
export const getPageLayoutComponent = (components: readonly PageLayoutComponent[], contentType: string): Maybe<PageLayoutComponent> => ifElse(
  isArray,
  safeFind<PageLayoutComponent>(propEq('__typename', contentType)),
  always(None<PageLayoutComponent>())
)(components)

const Page: FC<PageProps> =
    ({
      data,
      pageContext,
      location
    }: PageProps) => {

      useEffect(() => {
        // This sets up New Relic with our configuration from ecomm-data
        configureNewRelic()
        // Scroll to top on route change
        isBrowser && getWindow().scrollTo(0, 0)
      }, [])

      const pathname = safePath([ 'pathname' ], location).orJust('')
      const partnerShopURL = getValueFromPartnerCookie('customShopUrl')

      const dispatch = useDispatch()
      const partnerName = getValueFromPartnerCookie('partnerName')
      const partnerGroup = getValueFromPartnerCookie('partnerGroup')
      useEffect(() => {
        partnerName && partnerGroup && dispatch(IOPartnerBanner(partnerName, partnerGroup))
      }, [ dispatch, partnerName, partnerGroup ])

      // Redirect from Security Systems Shop page to Partner Shop Page if a custom shop URL is set on the Partner Page
      useEffect(() => {
        (isNotNil(partnerShopURL) && pathname === '/home-security-shop-packages') && navigate(partnerShopURL)
      }, [ pathname, partnerShopURL ])

      const liveChatStatus = useEnableLiveChat(data)
      const liveChatId = liveChatStatus && liveChatStatus.liveChatId
      const liveChatEnabled = liveChatStatus && liveChatStatus.liveChatEnabled
      const locale = useSelector(selectLocale)

      const isTabletUp = useMediaQuery('TabletAndUp')
      const [ hidePopups ] = useQueryParam(devParams.hidePopUps, BooleanParam)
      // this is the value from contentful that we will no longer be using
      const globalPromoCode = defaultTo('')(path([ 'contentfulPage', 'pageLayout', 'promoCode' ], data))
      const promoBannerStyle = toPromoBannerStyleValue(path([ 'contentfulPage', 'pageLayout', 'promoBannerStyle' ], data)) || 'none'
      const pagePath = path([ 'contentfulPage', 'pageUrl' ], data) || ''
      const utmCode = useUtmCode(true, Maybe.fromFalsy(pagePath))
      useUtmContent()
      useAwinTracking(locale === 'en-GB')

      useApplyPromoCode(Maybe.fromNull(utmCode))
      useOptimizelyParams()

      // pageTitle is passed to our SEO component below, and also set via the setPageTitle context hook so it can be passed
      // to tracking endpoints like AT-AT (see InjectPageTracking).
      const pageTitle: string = path([ 'contentfulPage', 'seoDetails', 'metaTitle' ], data) || ''
      const setPageTitle = useContext(PageTitleContext)
      const { trackEvent } = useTracking()

      useEffect(() => {
        setPageTitle(pageTitle)
      }, [ pageTitle, setPageTitle ])

      useEffect(() => {
        trackEventIsMobile(trackEvent, isTabletUp)
      }, [ isTabletUp, trackEvent ])

      const pageComponents: Maybe<readonly PageComponent[]> =
        safePath([ 'contentfulPage', 'pageComponents' ], data)
          .chain(x => Maybe.fromNull(x))

      // TODO
      /**
       * All of this needs to be fixed. I finally understand the logic happening here.
       * Basically all of these components are tossed into 1 array of entities on the Web Config (page layout) type in contentful.
       * We need move this a system where we explicity call out a header, footer, and have above and below the page content that is rendered in the order from Contentful.
       */

      const components: readonly PageLayoutComponent[] = path([ 'contentfulPage', 'pageLayout', 'components' ], data) || []
      const belowComponents: readonly PageLayoutComponent[] = path([ 'contentfulPage', 'pageLayout', 'belowComponents' ], data) || []
      // TODO why is this different from the normal header?
      const headerProgressBar: Maybe<ContentfulHeaderWithProgressBar> = getPageLayoutComponent(components, 'ContentfulHeaderWithProgressBar')
      const headerPartner: Maybe<ContentfulHeaderPartner> = getPageLayoutComponent(components, 'ContentfulHeaderPartner')
      // TODO why is the simple footer a small text section? this is a footer?
      const simpleFooter = getPageLayoutComponent(components, 'ContentfulSmallTextSection')
      // TODO fix this type
      const contentfulHeader = getPageLayoutComponent(components, 'ContentfulHeader') as Maybe<ContentfulHeaderFragment>
      const contentfulDefaultBanner: Maybe<ContentfulDefaultBanner> = getPageLayoutComponent(components, 'ContentfulDefaultBanner')
      const contentfulHeaderVariationContainer = getPageLayoutComponent(components, 'ContentfulVariationContainer')

      const contentfulFloatingPromoWidget: Maybe<FloatingPromoWidgetFragment> = getPageLayoutComponent(components, 'ContentfulFloatingPromoWidget')

      const popupWizard: Maybe<ContentfulFloatingBar> = getPageLayoutComponent(components, 'ContentfulFloatingBar')

      //@ts-ignore
      const contentfulFooter: Maybe<ContentfulFooterFragment> = getPageLayoutComponent(components, 'ContentfulFooter')
      // TODO should this be something more specific than a small text section?
      const countryRedirectModal: Maybe<SmallTextSectionRedirect> = safeProp('contentfulSmallTextSection', data)

      const anchoredNav: PageAnchorNavigationFragment = safePath([ 'contentfulPage', 'anchoredNavigation' ], data).orNull()

      const belowPageLayoutCompoments = belowComponents.map(component => mapPageComponentToTemplate(component))

      // TODO: save this value on package and product pages as a Product SKU
      const productId: Maybe<string> = safePath([ 'contentfulPage', 'productId' ], data)
      const pageUrl: string = safePath([ 'contentfulPage', 'pageUrl' ], data).getOrElse('')

      const modalExitIntent = getPageLayoutComponent(components, 'ContentfulModalExitIntent')

      const authentication = getPageLayoutComponent(components, 'ContentfulAuthentication')

      const trustpilotBanner: Maybe<ContentfulTrustpilotReviewsBanner> = getPageLayoutComponent(components, 'ContentfulTrustpilotReviewsBanner')

      const pageTopBanner: ContentfulBannerTopPage = safePath([ 'contentfulPage', 'pageTopBanner' ], data).orUndefined()

      const pageSchema = safePath([ 'contentfulPage', 'seoDetails', 'pageSchema' ], data).orUndefined()
      const renderPageComponents = () => (
        pageContext &&
          <ErrorBoundary>
            <PageBody>
              {pageComponents
                .cata(
                  () => null,
                  map((comp: PageComponent) =>
                    <React.Fragment key={comp.id}>
                      { wrapComponentWithSection(mapPageComponentToTemplate(comp, pageContext, location)) }
                    </React.Fragment>
                  )
                )
              }
            </PageBody>
          </ErrorBoundary>
      )

      useEffect(() => {
        toast.remove('promoToast')
      }, [ pageUrl ])

      const isPartnerBannerLoading = useSelector(selectPartnerBannerLoading)
      const partnerBanner = useSelector(selectPartnerBanner)
      const monthsOfServiceDisplay = useSelector(selectMonthsOfServiceDisplay(true, true))
      const discountText = useSelector(selectActivePromoDiscountText).map(text => `${text} off`)
      const lineItemText = useSelector(selectPromotionBanner).chain(chainProp('freeGiftItemPrimaryText'))

      return (
        <PageWrapper key={pageContext.id}>
          {
            anchoredNav && <PageAnchorNavigationComponent data={anchoredNav} key='PageAnchorNavigationComponent'/>
          }
          {
            countryRedirectModal.map(data => (
              !hidePopups && <CountryRedirectModal data={data}
                data-testid="country-redirect-modal" key='CountryRedirectModal'/>
            )).orNull()
          }
          {
            <Toaster toastOptions={{ style: { padding: 0 } }}>
              {(t: Toast) => (
                <ToastBar toast={t}>
                  {({ message }) => (
                    <ToastCheckMarkAlert
                      hasCloseButton={true}
                      // @ts-ignore
                      message={message}
                      onCloseHandler={() => {
                        sendGtmCustomEvent({
                          event: 'toastAlert',
                          eventAction: 'closeToastAlert',
                          eventCategory: 'UTMAdditionAlert',
                          eventLabel: 'closedToastAlert'
                        })
                        toast.dismiss(t.id)
                        window.localStorage.utm_code && window.localStorage.removeItem('utm_code')
                      }}
                    />
                  )}
                </ToastBar>
              )}
            </Toaster>
          }
          {
            <GlobalPromotionalComponent data={{ globalPromoCode }}
              key='GlobalPromotionalComponent' pageContext={pageContext}/>
          }
          {
            pageTopBanner && <TopPageBanner data={pageTopBanner} />
          }
          {
            !pageTopBanner && !isPartnerBannerLoading && partnerBanner.map(data =>
              promoBannerStyle !== 'cart' && promoBannerStyle !== 'none' && <PartnerBanner
                backgroundColor={data.backgroundColor.orUndefined()}
                discountSecondaryText={data.discountSecondaryText.orUndefined()}
                discountText={discountText.orSome('15% off')}
                displayEmailInputField={data.displayEmailInputField.orUndefined()}
                lineItemText={lineItemText.orSome('free SimpliCam')}
                linkPath={data.linkPath.orUndefined()}
                logoDescription={data.logoDescription.orUndefined()}
                logoUrl={data.logoUrl.orUndefined()}
                monitoringText={`${monthsOfServiceDisplay} of monitoring`}
                partnerName={partnerName || undefined}
                primaryOfferText={data.primaryOfferText.orUndefined()}
                primaryTextColor={data.primaryTextColor.orUndefined()}
                secondaryOfferText={data.secondaryOfferText.orUndefined()}
                secondaryOfferTextMobile={data.secondaryOfferTextMobile.orUndefined()}
                secondaryTextColor={data.secondaryTextColor.orUndefined()}
                showCta={promoBannerStyle !== 'pdp-plp'}
              ></PartnerBanner>
            ).orSome(
              <ActivePromoBanner
                pageData={data}
                type={promoBannerStyle}
              />
            )
          }
          {
            contentfulHeaderVariationContainer.map(data => Maybe.fromNull(<ContentfulVariationContainerComponent data={data} key={data.id}
              // Assert type since TypeScript doesn't infer type from variant.internal.type check
              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
              renderVariant={(variant) => variant.internal.type === 'ContentfulHeader' ? <HeaderComponent data={variant as ContentfulHeaderFragment}/> : null}/>)).orNull()
          }
          {
            contentfulHeader.map(data => <HeaderComponent data={data}
              key={data.id}
            />).orNull()
          }
          {
            headerProgressBar.map(data => <HeaderProgressBarComponent data={data}
              key={data.id} />).orNull()
          }
          {
            headerPartner.map(data => <HeaderPartnerComponent data={data}
              key={data.id} />).orNull()
          }
          {
            modalExitIntent.map(data => (
              <ModalExitIntentComponent
                data={data}
                key={data.id}
                location={location}
                pageContext={pageContext} />
            )).orNull()
          }
          {/* Wrap with an extra div to avoid odd hard-refresh/initial render issue. */}
          <div key='TrustpilotReviewBanner-wrapper'>
            {trustpilotBanner.map(data => <TrustpilotReviewBanner data={data}
              key={data.id} />).orNull()}
          </div>
          {
            contentfulDefaultBanner.cata(
              () => null,
              data => <PageMasthead key={data.id}>
                <DefaultBannerComponent data={data} />
              </PageMasthead>
            )
          }
          {
            // Wrap with an extra div to avoid odd hard-refresh/initial render issue.
            contentfulFloatingPromoWidget.map(data => <div key="floating-promo-widget"><FloatingPromoWidgetComponent data={data}
              key={data.id}/></div>).orNull()
          }
          {
            authentication.cata(
              () => renderPageComponents(),
              data => (
                <AuthenticationComponent
                  data={data}
                  key='AuthenticationComponent'
                  location={location}
                  pageContext={pageContext}
                >
                  { renderPageComponents() }
                </AuthenticationComponent>
              )
            )
          }
          {
            popupWizard.map(data =>
              <ErrorBoundary key={data.id}>
                <PopupWizard data={data} />
              </ErrorBoundary>
            ).orNull()
          }

          {
            // I think I have this logic correct, but it was hard to tell from the original code
            // If simplefooter is Just, render it, otherwise if contentfulFooter is Just, render that, otherwise render null
            <div key='Footer-wrapper'>
              {simpleFooter
                .cata(() =>
                  contentfulFooter
                    .map((data) => (
                      <div key='FooterComponent-wrapper'>
                        <FooterComponent data={data}
                          id={data.id}
                          key={data.id}/>
                      </div>
                    )).orNull(),
                (simpleFooter) => (
                  <div key='SimpleFooter-wrapper'>
                    <SimpleFooterComponent data={simpleFooter}
                      id={simpleFooter.id}
                      key={simpleFooter.id} />
                  </div>
                ))}
            </div>
          }
          { belowPageLayoutCompoments }
          <SEO canonicalLink={path([ 'contentfulPage', 'seoDetails', 'canonicalLinks' ], data)}
            key='page-seo'
            lang={path([ 'contentfulPage', 'node_locale' ], data)}
            metaDescription={path([ 'contentfulPage', 'seoDetails', 'metaDescription', 'metaDescription' ], data)}
            metaKeyword={path([ 'contentfulPage', 'seoDetails', 'keywords', 'keywords' ], data)}
            metaTitle={pageTitle}
            pageSchema={pageSchema}
            pageUrl={pageUrl}
            productId={productId}
            seoDetails={safePath([ 'contentfulPage', 'seoDetails' ], data).getOrElse({})}
          />
          {liveChatEnabled && <LiveChat appId={liveChatId} />}
        </PageWrapper>
      )
    }

export const pageQuery = graphql`#graphql
    fragment pageFragment on ContentfulPage {
      id
      contentful_id
      node_locale
      pageUrl
      productId
      seoDetails {
        canonicalLinks
        isNoindex
        isNofollow
        metaTitle
        metaDescription {
          metaDescription
        }
        keywords {
          keywords
        }
        schema {
          description
          name
          image
          legalName
          sameAs
          contactPoint {
            _type
            contactType
            telephone
          }
          sku
          _context
          _type
          brand {
            _type
            name
          }
          offers {
            _type
            availability
            itemCondition
            price
            priceCurrency
            url
          }
          logo {
            _type
            url
          }
          url
        }
        pageSchema {
          brand {
            jsonData {
              _type
              name
            }
          }
          context
          type
          offers {
            jsonData {
              availability
              itemCondition
              priceCurrency
              _type
            }
          }
        }
        productImage {
          file {
            url
          }
        }
      }
      pageTopBanner {
        backgroundColor
        primaryText
        primaryTextColor
        secondaryText
        secondaryTextColor
      }
      pageComponents {
         ... on ContentfulBmsMiniCart{
           ...contentfulBmsMiniCart
         }
        ... on ContentfulPrefectSystemBanner {
          ...perfectSystemBanner
        }
        ... on ContentfulCompanyReviewBanner {
          ...CompanyReviewBannerInformation
        }
        ... on ContentfulAdditionalInfoBanner {
          ...OverlayBanner
        }
        ... on ContentfulTrustpilotReviewsBanner {
          ...trustpilotBannerFragment
        }
        ... on ContentfulComparisonBanner {
          ...contentfulComparisonBanner
        }
        ... on ContentfulBanner {
          ...contentfulBanner
        }
        ... on ContentfulSmallTextSection {
          contentful_id
          ...smallTextSectionFragment
        }
        ... on ContentfulCarousel {
          ...Carousel
        }
        ... on ContentfulProductCardAccessories {
          ...productCardAccessories
        }
        ... on ContentfulProductCard {
          ...productCardFragment
        }
        ... on ContentfulCategoryList {
          ...categoryListFragment
        }
        ... on ContentfulGroupSection {
          ...contentfulGroupSectionFragment
        }
        ... on ContentfulPressKitGrid {
          ...pressKitGridFragment
        }
        ... on ContentfulPlanComparisonTable {
          ...planComparisonTableRowsFragment
        }
        ... on ContentfulProduct {
          ...productFragment
        }
        ... on ContentfulFooterTopSection {
          ...footerTopSectionFragment
        }
        ... on ContentfulFloorPlan {
          ...floorPlan
        }
        ... on ContentfulCartDetails {
          ...cartDetailsInformation
        }
        ... on ContentfulCarouselTextAndImage {
          ...carouselTextImageInformation
        }
        ... on ContentfulCheckoutOrderDetails {
          ...checkoutOrderDetails
        }
        ... on ContentfulCarouselFeatures {
          ...carouselFeaturesInformation
        }
        ... on ContentfulLoginforgetPassword  {
          ...form
        }
        ... on ContentfulReviewGrid {
          ...reviewGrid
        }
        ... on ContentfulLegal {
          ...contentfulLegalFragment
        }
        ... on ContentfulCrimeLocationBannerWithAddressField {
          ...locationBanner
        }
        ... on ContentfulTwoColumn {
          ...contentfulTwoColumnFragment
        }
        ... on ContentfulPaymentForm {
          ...paymentForm
        }
        ... on ContentfulBmsSensorGroup {
          ...bmsSensorGroup
        }
        ... on ContentfulHowItWorksInteractiveHouse {
          ...howItWorksInteractiveHouse
        }
        ... on ContentfulContactUs {
          ...contactUsFragment
        }
        ... on ContentfulBlogArticle {
          ...contentfulBlogArticle
        }
        ... on ContentfulBlogArticleList {
          ...contentfulBlogArticleList
        }
        ... on ContentfulProductPageHeroSensor {
          ...sensorProductBannerFragment
        }
        ... on ContentfulDynamicPackageHeroBanner {
          ...dynamicPackageHeroBannerFragment
        }
        ... on ContentfulDynamicPackageFloorplanBanner {
          ...dynamicPackageFloorplanBanner
        }
        ... on ContentfulCarouselVerticalScrollAndBanner {
          ...carouselVerticalScrollFragment
        }
        ... on ContentfulReferAFriendPlaceholder {
          ...mentionMeFragment
        }
        ... on ContentfulPaymentConfirmationContainer {
          ...paymentConfirmationContainerFragment
        }
        ... on ContentfulVariationContainer {
          ...variationContainer
        }
        ... on ContentfulUnsubscribePageContents {
          ...unsubscribePageContents
        }
        ... on ContentfulPromotionalBanner {
          ...promotionalBannerFragment
        }
        ... on ContentfulPaymentFormErrorMessage {
          ...paymentFormErrorMessageFragment
        }
        ... on ContentfulProductPageHeroV2 {
          ...productPageHero
        }
        ... on ContentfulExpandableMonitoringPlan {
          ...expandableMonitoringPlanFragment
        }
        ... on ContentfulPartnerTemplate {
          ...partnerTemplate
        }
        ... on ContentfulPlaceholder {
          ...placeholderFragment
        }
        ... on ContentfulAlarmSensors {
          ...alarmSensors
        }
        ... on ContentfulModalExitIntent {
          ...modalExitIntent
        }
        ... on ContentfulExpandable {
          ...expandable
        }
        ... on ContentfulDeviceVariations{
          ...deviceVariationsFragment
        }
        ... on ContentfulCheckoutForm {
          ...checkoutForm
        }
      }
      pageLayout {
        id
        contentful_id
        hasLiveChat
        liveChatAppId
        bmsLiveChatTest
        promoBannerStyle
        promoCode
        components {
          ... on ContentfulSmallTextSection {
            id
            title
            description {
              json
            }
          }
          ... on ContentfulHeader {
            ...contentfulHeaderFragment
          }
          ... on ContentfulHeaderWithProgressBar {
            ...headerProgressBarFragment
          }
          ... on ContentfulHeaderPartner {
            ...headerPartner
          }
          ... on ContentfulDefaultBanner {
            ...defaultBannerFragment
          }
          ... on ContentfulFloatingPromoWidget {
            ...floatingPromoWidget
          }
          ... on ContentfulPromotionalBanner {
            ...promotionalBannerFragment
          }
          ... on ContentfulFloatingBar {
            ...popupWizardFragment
          }
          ... on ContentfulFooter {
            ...contentfulFooter
          }
          ... on ContentfulModalExitIntent {
            ...modalExitIntent
          }
          ... on ContentfulTrustpilotReviewsBanner {
            ...trustpilotBannerFragment
          }
          ... on ContentfulAuthentication {
            ...authentication
          }
          ... on ContentfulVariationContainer {
            ...variationContainer
          }
        }
        belowComponents {
          ... on ContentfulModalSaveYourSystem {
            ...saveMySystemModal
          }
        }
      }
      anchoredNavigation {
        ...pageAnchorNavigation
      }
    }
`

export default Page
