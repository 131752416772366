/* eslint-disable max-lines */
import {
  documentToReactComponents,
  RenderMark,
  RenderNode
} from '@contentful/rich-text-react-renderer'
import {
  BLOCKS,
  Document,
  INLINES
} from '@contentful/rich-text-types'
import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import { Divider } from '@simplisafe/ss-react-components/atoms'
import always from 'ramda/src/always'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import pathOr from 'ramda/src/pathOr'
import React, { FC, ReactNode } from 'react'

import { renderEmbeddedEntry } from './embeddedEntries'
// https://www.npmjs.com/package/@contentful/rich-text-react-renderer

export type Options = {
  readonly renderNode?: RenderNode
  readonly renderMark?: RenderMark
}

export type Children = { readonly children: ReactNode }

/**
 * This list of options should be mapped out to include any possible components that can be embedded in RichText.
 */
const options = (
  onLinkClick?: (_e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, _url: string) => void
): Options => ({
  renderMark: {},
  renderNode: {
    // Blocks
    [BLOCKS.HR]: () => <Divider />,
    // Inlines
    [INLINES.EMBEDDED_ENTRY]: (node) => renderEmbeddedEntry(node),
    [INLINES.HYPERLINK]: (node, children) => {
      const uri = pathOr<string, string>('', [ 'data', 'uri' ], node)

      return <a
        href={uri}
        onClick={(e) => { onLinkClick && onLinkClick(e, uri) }}
      >{children}</a>
    }
  }
})

export type RichTextProps = {
  readonly onLinkClick?: (_e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, _url: string) => void
  /** From Contentful */
  readonly rawRichText?: Document | null
  readonly optionsCustom?: Options
}

/**
 * A component to render Contentful Rich Text with embedded
 * and inline entires.
 *
 * Does not do any styling or layout, it just converts the
 * source into the matching React components and HTML.
 *
 * To style text the component that contains the rich text should target the html primitives used,
 * such as `p { color: blue }`.
 */
const ContentfulRichText: FC<RichTextProps> =
    ({
      onLinkClick, rawRichText, optionsCustom
    }: RichTextProps) => {
      const customComponent = ifElse(
        equals(true),
        always(optionsCustom),
        always(options(onLinkClick))
      )(isNotNil(optionsCustom))

      return (
        <>
          {rawRichText && documentToReactComponents(rawRichText, customComponent)}
        </>
      )
    }

export default ContentfulRichText
