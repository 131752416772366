import { Maybe } from 'monet'
import { useEffect, useState } from 'react'

export type Status = 'loading' | 'ready' | 'error'
export type ScriptElt = HTMLScriptElement | null

const dataAttr = 'data-status'

// Borrowed from 'usehooks-ts/src/useScript/useScript':
// https://github.com/juliencrn/usehooks-ts/blob/master/lib/src/useScript/useScript.ts
const useScript = (src: string): Status => {
  const [ status, setStatus ] = useState<Status>('loading')
  const [ script, setScript ] = useState<HTMLScriptElement>()

  useEffect(() => {
    // Fetch existing script element by src
    // It may have been added by another instance of this hook
    const existingScript: ScriptElt = document.querySelector(`script[src="${src}"]`)

    Maybe.fromNull(existingScript)
      .cata(
        // If no existing script exists, create one.
        () => {
          const newScript = document.createElement('script')
          newScript.setAttribute('src', src)
          newScript.setAttribute('async', '')
          newScript.setAttribute(dataAttr, 'loading')

          // Store status in attribute on script
          // This can be read by other instances of this hook
          const setAttributeFromEvent = (event: Event) => {
            newScript.setAttribute(
              dataAttr,
              event.type === 'load' ? 'ready' : 'error',
            )
          }

          newScript.addEventListener('load', setAttributeFromEvent)
          newScript.addEventListener('error', setAttributeFromEvent)

          // Add script to document body
          document.body.appendChild(newScript)

          // Set the current script for this hook.
          setScript(newScript)
        },
        // Grab existing script status from attribute and set to state.
        (_existingScript: HTMLScriptElement) => {
          setScript(_existingScript)
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          setStatus(_existingScript.getAttribute(dataAttr) as Status)
        }
      )


    // Script event handler to update status in state
    // Note: Even if the script already exists we still need to add
    // event handlers to update the state for *this* hook instance.
    const setStateFromEvent = (event: Event) => {
      setStatus(event.type === 'load' ? 'ready' : 'error')
    }

    // Add event listeners
    script && script.addEventListener('load', setStateFromEvent)
    script && script.addEventListener('error', setStateFromEvent)

    // Remove event listeners on cleanup
    return () => {
      script && script.removeEventListener('load', setStateFromEvent)
      script && script.removeEventListener('error', setStateFromEvent)
    }
  }, [ script, src ])

  return status
}

export default useScript
