import { useLocation } from '@reach/router'
import { TrackingData } from '@simplisafe/ecomm-ts-types'
import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import {
  commercetoolsGetCart, CTCartToRecord, LineItem
} from '@simplisafe/ss-ecomm-data/commercetools/cart'
import { CurrencyCode } from '@simplisafe/ss-ecomm-data/commercetools/locale'
import { selectLocale } from '@simplisafe/ss-ecomm-data/redux/select'
import { awinRequest } from '@simplisafe/ss-ecomm-data/thirdparty/awin'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { liveChatScriptUrl } from '@simplisafe/ss-ecomm-data/thirdparty/livechat'
import { SmallTextSection } from '@simplisafe/ss-react-components/molecules'
import { fork } from 'fluture'
import { graphql } from 'gatsby'
import { Just, Maybe } from 'monet'
import always from 'ramda/src/always'
import applySpec from 'ramda/src/applySpec'
import assocPath from 'ramda/src/assocPath'
import findIndex from 'ramda/src/findIndex'
import pathEq from 'ramda/src/pathEq'
import React, {
  FC, useEffect, useState
} from 'react'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import Cookies from 'universal-cookie'

import { ContentfulPaymentConfirmationContainer } from '../../../graphql'
import { currencyCode } from '../../commercetools/utils'
import useLiveChatClientExists from '../../hooks/useLiveChatClientExists'
import useScript from '../../hooks/useScript'
import { getChatAppId, getPurchasedCartId } from '../../tracking/cookies'
import liveChatOrderTracking, { OrderTracking } from '../../util/analytics/liveChatOrderTracking'
import getDescriptionJson from '../../util/getDescriptionJson'
import { getCartDiscountCode } from '../CartDetailsComponent/transformLineItem'

type PaymentConfirmationContainerComponentProps = {
  readonly id?: string
  readonly data: Partial<ContentfulPaymentConfirmationContainer>
}

export type TrackEvent = (data: Partial<TrackingData | Record<string, unknown>>) => void

/**
 * TODO: This piece of tracking code is specifically for ECP-3110, where the UK just wants to
 * double check perceived discrepencies in GA orders
 *
 * I expect that once any discrepencies are found or identified, this piece of code can
 * be removed as it adds no further value.
 */
const trackSystemPurchase = (trackEvent) => {
  trackEvent({
    // TODO add 'purchaseSystemConfirmation' to ecomm-ts-types/tracking/TrackingEvent to remove this ts-ignore, if this functionality ends up remaining
    // @ts-ignore
    event: 'purchaseSystemConfirmation'
  })
}

const toOrderNumberObject = (orderNumber: string) => {
  return applySpec({
    data: always({}),
    marks: always([]),
    nodeType: always('text'),
    value: always(orderNumber),
  })()
}

export const toOrderTrackingObject = (
  orderId: string,
  cartTotal: Maybe<number>,
  currency: CurrencyCode,
  lineItems: readonly LineItem[] ): OrderTracking => {
  return ({
    cartTotal: cartTotal,
    currency: currency,
    lineItems: lineItems,
    orderId: orderId
  })
}

const contentTypePath = [ 'data', 'target', 'sys', 'contentType', 'sys', 'id' ]

const QS_PARAM_ORDER_ID = 'orderId'
const AWIN_COOKIE = 'awin_awc'
const cookies = new Cookies()

const PaymentConfirmationContainerComponent: FC<PaymentConfirmationContainerComponentProps> = ({ data }: PaymentConfirmationContainerComponentProps) => {
  const [ orderTracking, setOrderTracking ] = useState<OrderTracking>({
    cartTotal: Just(0),
    currency: currencyCode,
    lineItems: [],
    orderId: ''
  })
  const { Track, trackEvent } = useTracking()
  const location = useLocation()
  const orderId = new URLSearchParams(location.search).get(QS_PARAM_ORDER_ID) || ''
  const locale = useSelector(selectLocale)

  useEffect(() => {
    !orderId && logError(Error('Missing orderId on payment confirmation page'))
  }, [ orderId ])

  // Normally, we'd want to get cartId from redux instead of directly from local storage. This component is an
  // exception because redux clears the cart out of state and removes cartId from local storage once the cart
  // is ordered, which it is by the time the user gets to this page. Here, we're grabbing the cart id out of
  // local storage before redux has a chance to clear it, and then making a separate cart request (but not
  // storing it) so that we can track the purchased line items.

  const cartId = getPurchasedCartId()

  useEffect(() => {
    Maybe.fromNull(cartId).forEach((_cartId) => {
      // TODO turn this into a specialized function in ecomm-data instead of doing fetching & parsing here
      commercetoolsGetCart(_cartId)
        .pipe(fork((e: Error) => {
          logError(e)
        })(ctCart => {
          ctCart.filter(ctCart => ctCart.cartState === 'Ordered')
            .forEach(ctCart => {
              const cart = CTCartToRecord(ctCart)

              const shippingAddress = safeProp('shippingAddress', ctCart)
              const email = shippingAddress.chain(safeProp('email'))
              const firstName = shippingAddress.chain(safeProp('firstName'))
              const lastName = shippingAddress.chain(safeProp('lastName'))

              const transactionTotal = safeProp('totalPrice', cart)

              const systemInOrder = cart.isThereAnySecurity

              systemInOrder && trackSystemPurchase(trackEvent)

              // Awin server to server
              const awinCookie: string = cookies.get<string>(AWIN_COOKIE)
              locale === 'en-GB' && awinCookie && awinRequest({
                amount: transactionTotal.getOrElse(0),
                clickSource: awinCookie,
                orderRef: orderId,
                voucher: getCartDiscountCode(cart)
              })(err => logError(err))(() => null)

              setOrderTracking(toOrderTrackingObject(orderId, transactionTotal, currencyCode, cart.lineItems))

              trackEvent({
                // TODO add 'paymentConfirmation' to ecomm-ts-types/tracking/TrackingEvent to remove this ts-ignore
                // @ts-ignore
                event: 'paymentConfirmation',
                transactionCoupon: getCartDiscountCode(cart),
                transactionId: orderId,
                transactionTotal: transactionTotal.getOrElse(0),
                userData: {
                  email: email.getOrElse(''),
                  firstName: firstName.getOrElse(''),
                  lastName: lastName.getOrElse(''),
                }
              })
            })
        }))
    })
  }, [ cartId, orderId, trackEvent, locale ])

  const liveChatAppIdCookie: string = getChatAppId()
  const status = useScript(liveChatScriptUrl)
  const clientExists = useLiveChatClientExists(status)

  useEffect(() => {
    const runSideEffect = () => {
      liveChatAppIdCookie && liveChatOrderTracking(orderTracking)
    }

    clientExists && runSideEffect()
  }, [ clientExists, liveChatAppIdCookie, orderTracking ])

  const json = getDescriptionJson(prop('successMessage', data))
  const description = prop('content', json)
  const descriptionwithOrdeNumber = description.map((data) => {
    const formInputIndexPlaceHolder = findIndex(pathEq(contentTypePath, 'placeholder'))(prop('content', data))
    return formInputIndexPlaceHolder !== -1 ? assocPath([ 'content', formInputIndexPlaceHolder ], toOrderNumberObject(orderId), data) : data
  })
  const dataWithOrderNumber = assocPath([ 'successMessage', 'description', 'json', 'content' ], descriptionwithOrdeNumber, data)
  const successMessage = getDescriptionJson(prop('successMessage', dataWithOrderNumber))

  return ( <Track>
    <SmallTextSection
      description={successMessage}
      theme={'textFullWidth'}/>
  </Track>
  )
}
export default PaymentConfirmationContainerComponent

export const query = graphql`#graphql
    fragment paymentConfirmationContainerFragment on ContentfulPaymentConfirmationContainer {
      id
      title
      successMessage {
        description {
          json
        }
      }
      internal {
        type
      }
    }
`
