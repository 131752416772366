import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import { selectPackage } from '@simplisafe/ss-ecomm-data/redux/select'
import { CardKit } from '@simplisafe/ss-react-components/molecules'
import { graphql } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import { Maybe } from 'monet'
import propOr from 'ramda/src/propOr'
import React, { ReactNode } from 'react'
import { useSelector } from 'react-redux'

import { CardKitFragment } from '../../../graphql'
import { renderPrice } from '../../commercetools/price'

export type CardKitComponentProps = {
  readonly data: CardKitFragment
}

const CardKitComponent: React.FC<CardKitComponentProps> = ({ data }: CardKitComponentProps) => {
  const { tagPosition, img } = data

  const url = propOr<string, string>('', 'url', data)
  const kitName = propOr<string, string>('', 'kitName', data)

  const productId: string = propOr<string, string>('', 'productId', data)
  const _package = useSelector(selectPackage(productId))

  const toGatsbyImage = (imgData: CardKitFragment['img']): ReactNode => {
    const altText = propOr<string, string>('', 'title', imgData)

    /*eslint-disable */
    return Maybe.fromNull(imgData)
      .chain(safeProp('fluid'))
      .cata(
        () => null,
        (fluid) => (
          <Img
            alt={altText}
            fluid={fluid as FluidObject}
            imgStyle={{ objectFit: 'cover' }}
            style={{
              width: '100%',
            }}
          />
        )
      )
    /*eslint-enable */
  }

  return (
    <CardKit
      image={toGatsbyImage(img)}
      price={_package && renderPrice(_package, false)}
      tagPosition={tagPosition === 'left' ? 'left' : 'right'}
      tagText={prop('tagText', data)}
      title={kitName}
      url={url}
    />
  )
}
export default CardKitComponent

export const CardKitQuery = graphql`
  #graphql
  fragment cardKit on ContentfulCardKit {
    entryTitle
    kitName
    tagText
    tagPosition
    productId
    url
    img {
      fluid(maxWidth: 540) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
      title
    }
    internal {
      type
    }
  }
`
