import { safeProp } from '@simplisafe/monda'
import { LineItem } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import { selectCart } from '@simplisafe/ss-ecomm-data/redux/select'
import { graphql } from 'gatsby'
import all from 'ramda/src/all'
import any from 'ramda/src/any'
import cond from 'ramda/src/cond'
import isEmpty from 'ramda/src/isEmpty'
import propOr from 'ramda/src/propOr'
import T from 'ramda/src/T'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { ConditionalContentFragment } from '../../../graphql'
import { getValueFromPartnerCookie } from '../../util/partnerCookie'
import { renderComponentFromData } from '../../util/render'

export type ConditionalContentProps = {
  readonly data: ConditionalContentFragment
}

const contentConditionTypeMapper: {[key: string]: 'all' | 'any'} = {
  all: 'all',
  any: 'any'
}

const ConditionalContent: FC<ConditionalContentProps> = ({ data }: ConditionalContentProps) => {
  // Cart data
  const cart = useSelector(selectCart)
  const cartSkus = cart
    .map(_cart => _cart.lineItems.reduce((acc: ReadonlySet<string>, lineItem: LineItem) => {
      const skus = new Set(acc)
        .add(lineItem.sku)
      safeProp('child', lineItem).forEach(children =>
        children.forEach(child => skus.add(child.sku)))
      return skus
    }, new Set()))
    .orJust(new Set())
  const skuIsInCart = (sku: string) => cartSkus.has(sku)

  // TODO: write unit tests for this condition
  const partnerCondition: string = propOr<string, string>('', 'partnerCondition', data)

  const partnerValue = getValueFromPartnerCookie('partnerName')

  // Cart content condition data
  const cartConditionSkus: ReadonlyArray<string> = safeProp('cartContentCondition', data)
    .map(systemComponents =>
      systemComponents
        .map(systemComponent => systemComponent && systemComponent.sku)
        .filter((sku): sku is string => !!sku)
    )
    .orJust([])
  const cartConditionType = safeProp('cartContentConditionType', data)
    .map(type => contentConditionTypeMapper[type])
    .orJust('all')
  const matchesCartCondition = cond([
    [ isEmpty, T ],
    [ () => cartConditionType === 'any', any(skuIsInCart) ],
    [ T, all(skuIsInCart) ]
  ])(cartConditionSkus)

  const matchesPartnerCondition = isEmpty(partnerCondition) || partnerCondition === partnerValue

  // Components to conditionally render
  const components = safeProp('conditionalContent', data)
    .map(contentsData => contentsData.map(renderComponentFromData))
    .orNull()

  return matchesCartCondition && matchesPartnerCondition ? <>{components}</> : null
}

export default ConditionalContent

export const query = graphql`#graphql
  fragment conditionalContent on ContentfulConditionalContent {
    id
    internal {
      type
    }
    cartContentCondition {
      sku
    }
    cartContentConditionType
    conditionalContent {
      ... on ContentfulRichTextWithOptions {
        ...richTextWithOptions
      }
      ... on ContentfulGroupSection {
        ...contentfulGroupSectionFragment
      }
    }
    partnerCondition
  }
`
