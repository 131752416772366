import { SSButton } from '@simplisafe/ss-react-components/atoms'
import React from 'react'

type SubmitProps = {
  readonly submitButtonLabel: string
  readonly submitButtonDisabled: boolean
  readonly showSpinner: boolean
};

const Submit: React.FC<SubmitProps> = (props: SubmitProps) => {
  return (
    <div data-component={'Submission'}>
      <SSButton
        disabled={props.submitButtonDisabled}
        isLoading={props.submitButtonDisabled}
        minWidth={'full'}
        showSpinner={props.showSpinner}
        type="submit"
      >
        {props.submitButtonLabel}
      </SSButton>
    </div>
  )
}

export default Submit
