import { Document } from '@contentful/rich-text-types'
import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'

import { PaymentFormFragment } from '../../../../graphql'
import ModalComponent from '../../ModalComponent'
import ChaseErrorMessages from '../ChaseErrorMessages'
import { useChaseIframeHeight } from '../useChaseIframeHeight'
import { PaymentState } from '../usePayment'
import CardVerificationNote from './CardVerificationNote'

export type CvvModal = PaymentFormFragment['cvvModal']

const modalStyle = { content: { padding: '40px' } }

const Container = styled.div`
  position: relative;
`

export type InlineChasePaymentFormProps = {
  readonly chaseErrorCodes?: readonly string[]
  readonly fullKaptchaSrc: string
  readonly iframeSrc: string
  readonly paymentErrorMessage: ReactNode
  readonly cvvModalContent?: CvvModal
  readonly cardVerificationNote?: Document
  readonly paymentState: PaymentState
}

const InlineChasePaymentForm: FC<InlineChasePaymentFormProps> = ({
  chaseErrorCodes,
  cvvModalContent,
  fullKaptchaSrc,
  iframeSrc,
  paymentErrorMessage,
  cardVerificationNote,
  paymentState
}: InlineChasePaymentFormProps) => {
  const iframeHeight = useChaseIframeHeight()

  return (
    <Container>
      <script
        src={fullKaptchaSrc}
        type="text/javascript"></script>
      <ChaseErrorMessages errorCodes={chaseErrorCodes} />
      <CardVerificationNote cardVerificationNote={cardVerificationNote} paymentState={paymentState} />
      <iframe className='payment-iframe'
        data-component="payment-iframe"
        frameBorder='0'
        height={iframeHeight}
        scrolling='auto'
        src={iframeSrc}
        width='100%'
      />
      <div hidden
        id={'error-message'}>
        {paymentErrorMessage}
      </div>
      {
        cvvModalContent && <ModalComponent
          clickTarget={
            <input id={'payment-cvv-popup'}
              name={'payment-cvv-popup'}
              type={'hidden'}
            />}
          data={cvvModalContent}
          style={modalStyle} />
      }
      {/* To trigger the gtm event when the submit button is clicked */}
      {/* This input doesn't do anything */}
      <input id={'payment-start'}
        name={'payment-start'}
        type={'hidden'}
      />
    </Container>
  )
}

export default InlineChasePaymentForm
