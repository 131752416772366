// @ts-strict

import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import transformObject from '@simplisafe/ewok/transformObject'
import { CountryGridProps } from '@simplisafe/ss-react-components/molecules/CountryGridItem'
import { CountryGridContainer } from '@simplisafe/ss-react-components/organisms'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import propOr from 'ramda/src/propOr'
import React, { FC, useMemo } from 'react'

import { ContentfulLocationGridFragment } from '../../../graphql'

type ContentfulCrimeLocationGridProps = {
  readonly id: string
  readonly data: ContentfulLocationGridFragment
}

/** The gridContent from the ContentfulLocationGridFragment with undefined and null excluded. */
type GridContentArray = Exclude<ContentfulLocationGridFragment['gridContent'], null | undefined>

/** A single item from the GridContentArray with undefined and null excluded. */
type GridContent<ArrayType extends readonly unknown[]> = Exclude<ArrayType extends readonly (infer ElementType)[] ? ElementType : never, null | undefined>

/** Transforms the raw GridContent into props for the CountryGrid. */
export const toCountryGridContainer = transformObject<GridContent<GridContentArray>, CountryGridProps>({
  BackgroundComponent: data => prop('linkIcon', data) ? BackgroundImage : undefined,
  backgroundComponentProps: data => ({ fluid: path( [ 'linkIcon', 'fluid' ], data) }),
  linkText: data => propOr<string, string>('', 'linkText', data),
  linkUrl: data => propOr<string, string>('', 'linkUrl', data)
})

/** Gets the gridContent from data with a fallback to an empty array. */
export const getGridContent =
  (data: ContentfulLocationGridFragment): GridContentArray =>
    prop('gridContent', data) || []

/** A typegaurd to allow us to filter out anything that is not CountryGridProps */
const isCountryGridProps = (item: CountryGridProps | undefined): item is CountryGridProps => !!item

export const toLocationGrid =
  (data: Exclude<ContentfulLocationGridFragment, null | undefined>): { readonly locationGridProps: readonly CountryGridProps[]} =>
    ({
      locationGridProps: getGridContent(data)
        /** If val is not null or undefined we convert it to props. */
        .map(val => val ? toCountryGridContainer(val) : undefined)
        /** Remove anything that is null or undefined so we just have an array of valid props. */
        .filter(isCountryGridProps)
    })

const CountryGridContainerComponent: FC<ContentfulCrimeLocationGridProps> =
  ({ data }: ContentfulCrimeLocationGridProps) => {
    const gridProps = useMemo(() => toLocationGrid(data), [ data ])
    return gridProps.locationGridProps.length > 0 ? (
      <CountryGridContainer {...gridProps}/>
    ) : null
  }

export default CountryGridContainerComponent

export const query = graphql`#graphql
    fragment contentfulLocationGrid on ContentfulCrimeLocationGrid {
        id 
        internal {
          type
        }
        gridContent {
          linkText
          linkUrl
          linkIcon {
            fluid(maxWidth: 220){
              ...GatsbyContentfulFluid_withWebp_noBase64
            } 
          }
        }
    }
`
