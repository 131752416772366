/* eslint-disable max-lines */
/* eslint-disable functional/no-conditional-statement */
// @ts-nocheck
/**
 *
 *
 *
 *
 *
 * TODO
 * WARNING
 * This file has many type issues that need to be fixed.
 * I will fix these in a separate PR.
 * Please do not make any changes to this file until this warning is removed.
 *
 *  - Josh
 *
 *
 *
 */
import { TrackingData } from '@simplisafe/ecomm-ts-types'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import { safeProp } from '@simplisafe/monda'
import { LOCALE_INFO } from '@simplisafe/ss-ecomm-data/commercetools/locale'
import { selectCartCount } from '@simplisafe/ss-ecomm-data/redux/select'
import {
  CartCount,
  Header as HeaderAtom,
  HeaderDropdown,
  HeaderDropdownItem,
  HeaderDropdownMultipleItems,
  HeaderNavigation,
  HeaderNavigationDrawer,
  SSButton
} from '@simplisafe/ss-react-components/atoms'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { Profile } from '@simplisafe/ss-react-components/icons'
import { IconDropDown } from '@simplisafe/ss-react-components/molecules'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import {
  Just, Maybe, None
} from 'monet'
import and from 'ramda/src/and'
import applySpec from 'ramda/src/applySpec'
import defaultTo from 'ramda/src/defaultTo'
import equals from 'ramda/src/equals'
import map from 'ramda/src/map'
// TODO replace with ts-functional-pipe
// eslint-disable-next-line no-restricted-imports
import pipe from 'ramda/src/pipe'
import propOr from 'ramda/src/propOr'
import toLower from 'ramda/src/toLower'
import React, {
  FC, ReactElement
  ,
  useEffect,
  useState
} from 'react'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

import { setGtmCustomEvent, trackShopNowEvent } from '../../util/analytics'
import { getValueFromPartnerCookie } from '../../util/partnerCookie'
import FloatingQuizDialogue from '../FloatingQuizDialogue'
import RichTextWithOptionsComponent from '../RichTextWithOptionsComponent'
import {
  ContentfulAccountNavFragment,
  ContentfulButtonFragment,
  ContentfulGroupSectionFragment,
  ContentfulHeaderFragment,
  ContentfulLinkFragment,
  ContentfulMenuItemsFragment,
  NavItem
} from './query'


const logo = 'logo'

const handleLinkClick = (linkUrl: string, linktext: string, trackEvent: (_data: Partial<TrackingData>) => void) => {
  setGtmCustomEvent({
    event: 'linkClick',
    eventAction: 'click',
    eventCategory: 'navigation',
    eventLabel: linktext,
  })
  // TODO add event type to ecomm-ts-types
  // @ts-ignore
  trackEvent({ event: 'linkClickHeader', })
}

const imgLogoStyle = { objectPosition: 'left center' /* //used for us logo on the left side */ }

const isLogo = pipe(toLower, t => t.includes(logo))

export const logoLinkUrl = (linkUrl: string) => {
  // If a partner cookie is set and it includes partner page URL, overwrite homepage URL to use partner page URL instead
  const defaultLogoLinkUrl = '/'
  const partnerPageUrl = getValueFromPartnerCookie('partnerUrl')
  const replaceLogoUrl: boolean = linkUrl === defaultLogoLinkUrl && isNotNil(partnerPageUrl)
  const logoLinkFinal = replaceLogoUrl ? partnerPageUrl : linkUrl
  return logoLinkFinal || defaultLogoLinkUrl
}

// TODO Split up the mobile and desktop logic
// TODO clean up the render functions
// TODO Clean up this typing
const renderLink = (link: ContentfulLinkFragment | ContentfulMenuItemsFragment, cartItemCount = 0, trackEvent: (_data: Partial<TrackingData>) => void) => {
  const linkText = propOr<string, string>('', 'linkText', link)
  const linkUrlCtfl = propOr<string, string>('', 'linkUrl', link)
  const id = prop('id', link)
  // TODO fix type
  // @ts-ignore
  const linkSubtext = prop('linkSubtext', link)

  // TODO fix type
  // @ts-ignore
  const linkIcon = prop('linkIcon', link)
  // TODO fix type
  // @ts-ignore
  const linkImage = prop('linkImage', link)
  const linkType = propOr<string, string>('', 'linkType', link)
  const { domain } = LOCALE_INFO
  const linkUrl = isLogo(linkType) ? logoLinkUrl(linkUrlCtfl) : linkUrlCtfl

  const cartIcon = linkUrl === '/cart'
    ? <CartCount
      count={cartItemCount}
      key={`cart-count-${cartItemCount}`} />
    : null

  // TODO fix type
  // @ts-ignore
  const imageNode = linkIcon && linkIcon.fluid
    ? <Img
      alt={linkText}
      fluid={linkIcon.fluid}
      loading="eager"
      style={{
        height: '24px',
        width: '24px'
      }} />
    : null

  const children = <>
    {!cartIcon && imageNode}
    {!linkIcon && !isLogo(linkType) && linkText}
    {cartIcon}
    {linkSubtext && <p><small>{linkSubtext}</small></p>}
  </>

  return linkUrl && linkUrl.startsWith('tel:')
    ? (
      <a
        href={linkUrl}
        key={id}
        onClick={() => setGtmCustomEvent({
          event: 'buttonClick',
          eventAction: 'phone',
          eventCategory: 'contact-us',
          eventLabel: linkUrl.replace('tel:', '')
        })}
        style={{
          color: '#a4a4a4',
          textDecoration: 'inherit'
        }}>
        {children}
      </a>
    )
    : (
      <Link
        aria-label={cartIcon ? `Cart with ${cartItemCount} items` : undefined}
        key={id}
        onClick={() => isNotNil(linkUrl) && handleLinkClick(linkUrl, linkText, trackEvent)}
        state={{ source: 'header' }}
        style={{ textDecoration: 'inherit' }}
        to={linkUrl || ''}>
        {linkImage && linkImage.fixed && <Img
          alt={linkText}
          fixed={linkImage.fixed}
          imgStyle={{
            objectFit: 'contain',
            ...and(equals(domain, 'us'), isLogo(linkType)) && imgLogoStyle
          }}
          loading="eager"
          style={{
            height: '100%',
            width: '100%'
          }}
        />}
        {children}
      </Link>
    )

}

type HeaderDropdownLink = ContentfulLinkFragment | ContentfulMenuItemsFragment | ContentfulGroupSectionFragment

const renderDropdownItem = (showImage: boolean, isMobile: boolean, trackEvent) => (item: ContentfulLinkFragment | ContentfulMenuItemsFragment | ContentfulGroupSectionFragment) => {
  const { domain } = LOCALE_INFO
  const contentsToGroup = prop('contentsToGroup', item)
  const groupingType = prop('groupingTypeDesktop', item)

  const renderLink = (link: HeaderDropdownLink, trackEvent) => {
    const linkText = defaultTo('')(prop('linkText', link))
    const linkUrl = defaultTo('')(prop('linkUrl', link))
    const id = prop('id', link)
    const linkSubtext = prop('linkSubtext', link)
    const linkImage = prop('linkImage', link)

    return <Link
      key={id}
      onClick={() => linkUrl && handleLinkClick(linkUrl, linkText, trackEvent)}
      state={{ source: 'header' }}
      style={{ textDecoration: 'inherit' }}
      to={linkUrl}>
      {showImage && <HeaderDropdownItem
        image={linkImage && linkImage.fluid && <Img
          fluid={linkImage.fluid}
          loading="eager"
          style={{
            display: 'block',
            height: '100%',
            objectFit: 'contain',
            width: '100%'
          }}
        />}
        label={linkText}
        labelPosition={equals(domain, 'us') && !isMobile ? 'right' : 'bottom'}
        subText={linkSubtext}
      />}
      {!showImage && linkText}
    </Link>
  }

  return Maybe.fromFalsy(contentsToGroup)
    .map((contents: readonly HeaderDropdownLink[]) => (
      <HeaderDropdownMultipleItems key={prop('id', item)}
        position={Maybe.fromNull(groupingType)
          .map(g => toLower(g) as 'column' | 'row')
          .orUndefined()}
      >
        {contents.map((link: HeaderDropdownLink) => renderLink(link, trackEvent))}
      </HeaderDropdownMultipleItems>
    ))
    .getOrElse(renderLink(item, trackEvent))
}

const linkToListItem =
  applySpec<{ readonly title: string; readonly href: string }>({
    href: prop('linkUrl'),
    label: prop('linkText'),
    title: prop('linkText')
  })

const renderAccountNav = (item: ContentfulAccountNavFragment) => {
  const login = prop('loginLink', item)
  const register = prop('registerLink', item)

  return <IconDropDown a11yLabel="account menu"
    key={prop('id', item)}
    listItem={[ linkToListItem(login), linkToListItem(register) ].filter(Boolean)}
    srcIcon={<Profile />}
  />
}

const renderDropdown = (dropDown: ContentfulMenuItemsFragment, showImage: boolean, isMobile: boolean, trackEvent) => {
  const id = prop('id', dropDown)
  const linkText = propOr<string, string>('', 'linkText', dropDown)
  const subNav = prop('subNav', dropDown)

  return (
    <HeaderDropdown id={id}
      key={id}
      label={linkText}>
      {subNav && subNav.map(renderDropdownItem(defaultTo(false)(showImage), isMobile, trackEvent))}
    </HeaderDropdown>
  )
}

const renderContentfulMenuItems = (item: ContentfulMenuItemsFragment, showImage: boolean, isMobile: boolean, trackEvent: (_data: Partial<TrackingData>) => void) => {
  const subNav = prop('subNav', item)
  return isNotNil(subNav)
    ? renderDropdown(item, showImage, isMobile, trackEvent)
    : renderLink(item, undefined, trackEvent)
}

const renderButton = ({
  id, text, url
}: ContentfulButtonFragment, trackEvent: (_data: Partial<TrackingData>) => void) => {
  const buttonUrl = url || ''
  const buttonText = text || ''

  /*
    Adding this condition because "#url" redirect, adds "/#url" and wont work if user clicks more than once
  */
  return buttonUrl.includes('#') ?
    (
      <a
        href={buttonUrl}
        style={{ textDecoration: 'none' }}>
        <SSButton
          id={id}
          target="_blank">
          {text}
        </SSButton>
      </a>
    ) : (
      <Link key={id}
        onClick={() => {
          handleLinkClick(buttonUrl, buttonText, trackEvent)
          trackShopNowEvent('header-shop-btn')(trackEvent)
        }}
        style={{
          textDecoration: 'none',
          whiteSpace: 'nowrap'
        }}
        to={buttonUrl}>
        <SSButton
          id={id}
          type='div'>
          {text}
        </SSButton>
      </Link>
    )
}

const flattenInternalType = (item: NavItem): NavItem => {
  const internalType = path([ 'internal', 'type' ], item)
  return {
    ...item,
    internalType
  } as NavItem // TODO clean up this typing
}

// TODO the isMobile or desktop logic to decide if to show text, imgaes, etc... all needs to be cleaned up.
// We need to clarify if the layout and content changes between desktop and mobile are content based, or if it's part of our design?
// I want to really do a deep dive into our navigation content types and design soon.
const renderNavItem =
  (showImage: boolean, cartItemCount, isMobile: boolean, trackEvent: (_data: Partial<TrackingData>) => void) =>
    (data: NavItem): ReactElement | null => {

      // TODO I want to try and come up with a better way to do this,
      // but TypeScript really doesn't have any type of good way of matching the data type to the function type
      // without using a typegaurd (which in this instance is an if statement)
      // I'm going to poke around for a clojure like Protocol library? Ideally some typesafe way of saying if data is this type, do this function, but functional.
      const item: NavItem = flattenInternalType(data)
      if (item.internalType === 'ContentfulLink') {
        return renderLink(item, cartItemCount, trackEvent)
      }
      if (item.internalType === 'ContentfulMenuItems') {
        return renderContentfulMenuItems(item, showImage, isMobile, trackEvent)
      }
      if (item.internalType === 'ContentfulButton') {
        return renderButton(item, trackEvent)
      }
      if (item.internalType === 'ContentfulAccountNav') {
        return renderAccountNav(item)
      }
      if (item.internalType === 'ContentfulFloatingBar') {
        return <FloatingQuizDialogue data={item}
          key={`header-quote-wizard-${item.id}`} />
      }
      if (item.internalType === 'ContentfulRichTextWithOptions') {
        return (<div key={`header-rich-text-${item.id}`}
          style={{
            flexGrow: 1,
            margin: prop('containerMargin', item),
            textTransform: 'none'
          }}>
          <RichTextWithOptionsComponent data={item} />
        </div>)
      }
      return null
    }

const renderNavigationDrawerBottomItem = (item: NavItem, cartItemCount: number, trackEvent: (_data: Partial<TrackingData>) => void) => {
  const linkText = prop('linkText', item)
  const linkUrl = prop('linkUrl', item)
  const id = prop('id', item)
  const linkIcon = prop('linkIcon', item)
  const cartIcon = linkUrl === '/cart'
    ? <CartCount
      count={cartItemCount}
      key={`cart-count-${cartItemCount}`} />
    : null

  return (
    <Link
      key={id}
      onClick={() => handleLinkClick(linkUrl, linkText, trackEvent)}
      state={{ source: 'header' }}
      style={{ textDecoration: 'inherit' }}
      to={linkUrl}>

      {linkIcon && linkIcon.fluid && !cartIcon && <Img
        fluid={linkIcon.fluid}
        loading="eager"
        style={{
          height: '24px',
          width: '24px'
        }} />}
      {cartIcon}
      {linkText}
    </Link>
  )
}

export type HeaderProps = {
  readonly data: ContentfulHeaderFragment
  /** Determines the specific styling of the Header when progress bar is present. */
  readonly hasProgressBar?: boolean
}

const Header: FC<HeaderProps> = ({ data, hasProgressBar }: HeaderProps) => {

  const isDesktop = useMediaQuery('DesktopAndUp')

  const isTablet = useMediaQuery('TabletAndUp')

  const [ mobileOrTabletRightSideContentFieldName, setMobileOrTabletRightSideContentFieldName ] =
    useState<'mobileHeaderContent' | 'tabletHeaderContent'>('mobileHeaderContent')

  useEffect(() => {
    isTablet
      ? setMobileOrTabletRightSideContentFieldName('tabletHeaderContent')
      : setMobileOrTabletRightSideContentFieldName('mobileHeaderContent')
  }, [ isTablet ])

  const cartItemCount = useSelector(selectCartCount)

  const { trackEvent } = useTracking()

  const rightContent = safeProp('rightContent', data)
    .chain(x => isDesktop && x ? Just(x) : None<readonly NavItem[]>())
    .map(map<NavItem, ReactElement | null>(renderNavItem(true, cartItemCount, false, trackEvent)))
    .map(links => <HeaderNavigation key="right-navigation"><>{links}</></HeaderNavigation>)

  const leftContent = safeProp('leftContent', data)
    .chain(x => isDesktop && x ? Just(x) : None<readonly NavItem[]>())
    .map(map<NavItem, ReactElement | null>(renderNavItem(true, cartItemCount, false, trackEvent)))
    .map(links => <HeaderNavigation key="left-navigation"><>{links}</></HeaderNavigation>)

  type DropDownItem = ContentfulLinkFragment | ContentfulMenuItemsFragment

  const drawerTopContent =
    safeProp('mobileDrawerTopContent', data)
      .chain(x => !isDesktop && x ? Just(x as readonly DropDownItem[]) : None<readonly DropDownItem[]>())
      .map(map(renderDropdownItem(true, true, trackEvent)))

  const drawerCenterContent =
    safeProp('mobileDrawerCenterContent', data)
      .chain(x => !isDesktop && x ? Just(x) : None<readonly NavItem[]>())
      .map(map<NavItem, ReactElement | null>(renderNavItem(false, cartItemCount, true, trackEvent)))

  const drawerBottomContent =
    safeProp('mobileDrawerBottomContent', data)
      .chain(x => !isDesktop && x ? Just(x) : None<readonly NavItem[]>())
      .map(map<NavItem, ReactElement>(navItem => renderNavigationDrawerBottomItem(navItem, cartItemCount, trackEvent)))

  const mobileLogo = safeProp(mobileOrTabletRightSideContentFieldName, data)
    .chain(_data => {
      const logo = _data.find(navItem => {
        // TODO: fix type
        // linkType does not exist on navItem
        // @ts-ignore
        const linkType = defaultTo('')(prop('linkType', navItem))
        return isLogo(linkType)
      })

      return Maybe.fromNull(logo).chain(_logo => Maybe.fromNull(renderNavItem(true, cartItemCount, true, trackEvent)(_logo)))
    })

  const mobileOrTabletHeaderContent =
    safeProp(mobileOrTabletRightSideContentFieldName, data)
      .chain(x => !isDesktop && x ? Just(x) : None<readonly NavItem[]>())
      .map(x => x.reduce((acc: readonly ReactElement[], item, i) => {
        const showHamburgerMenu = drawerTopContent.isJust() || drawerCenterContent.isJust() || drawerBottomContent.isJust()

        const contentGroup = prop('contentsToGroup', item)
        const renderNav = renderNavItem(true, cartItemCount, true, trackEvent)
        const navItems = !!contentGroup ? contentGroup.map(renderNav) : renderNav(item)
        const linkType = defaultTo('')(prop('linkType', item))

        const hamburgerMenu = showHamburgerMenu && i === 0
          ? <HeaderNavigationDrawer bottom={<>{drawerBottomContent.orNull()}</>}
            display={true}
            header={mobileLogo.orUndefined()}
            key={'mobile-navigation-drawer'}
            middle={<>{drawerCenterContent.orNull()}</>}
            onHamburgerClick={() => setGtmCustomEvent({
              event: 'buttonClick',
              eventAction: 'open',
              eventCategory: 'navigation',
              eventLabel: 'hamburger'
            })}
            top={<>{drawerTopContent.orNull()}</>} />
          : null

        return [
          ...acc,
          <HeaderNavigation key={`header-nagivation=${item.id}`}
            navigationType={isLogo(linkType) ? `${logo}-${i}` : linkType}>
            <>
              {hamburgerMenu}
              {navItems}
            </>
          </HeaderNavigation> ]
      }, []))

  return (
    <HeaderAtom
      hasProgressBar={hasProgressBar}
      key="header">
      <>
        {mobileOrTabletHeaderContent.orNull()}
        {leftContent.orNull()}
        {rightContent.orNull()}
      </>
    </HeaderAtom>
  )
}

export default Header
