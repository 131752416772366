/*
  NOTE: This component has a lot of shared and/or copied functionality with ItemContainerComponent.
  As such, when making changes in one, consider whether the same changes need to be made in the other one.
  TODO combine these two components into a single one to handle dual responsibility
*/
/* eslint-disable max-lines */
import { Document } from '@contentful/rich-text-types'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import isNotEmpty from '@simplisafe/ewok/ramda-adjunct/isNotEmpty'
import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import transformObject from '@simplisafe/ewok/transformObject'
import {
  safeFind, safePath, safeProp
} from '@simplisafe/monda'
import { IOAddBmsToCart } from '@simplisafe/ss-ecomm-data/cart'
import { AddToCartBody } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import { MiniCartLineItem, setMiniCartLineItem } from '@simplisafe/ss-ecomm-data/deprecated/minicart/actions'
import { Package } from '@simplisafe/ss-ecomm-data/packages'
import { Product } from '@simplisafe/ss-ecomm-data/products'
import {
  liftSelectProduct,
  selectCustomSystemDiscountedPrice,
  selectCustomSystemTotalPrice,
  selectLocale,
  selectMiniCart,
  selectPackage,
  selectProduct,
  selectProducts
} from '@simplisafe/ss-ecomm-data/redux/select'
import { ImmutableState } from '@simplisafe/ss-ecomm-data/redux/state'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import {
  OutOfStockMessage, Price, RichText, SSInput
} from '@simplisafe/ss-react-components/atoms'
import { SSButtonProps } from '@simplisafe/ss-react-components/atoms/SSButton'
import { inputTypeName } from '@simplisafe/ss-react-components/atoms/SSInput'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { Caution, Info } from '@simplisafe/ss-react-components/icons'
import {
  AffirmPromoMessage, BannerError, BannerLoading, IncludedItemProduct
} from '@simplisafe/ss-react-components/molecules'
import { AdditionalOptionItemType } from '@simplisafe/ss-react-components/molecules/AdditionalOptionItems'
import { CardBadgeProps } from '@simplisafe/ss-react-components/molecules/CardBadge'
import { IncludedItemProps, ItemProduct } from '@simplisafe/ss-react-components/molecules/IncludedItem'
import { ItemContainer } from '@simplisafe/ss-react-components/organisms'
import { ItemDetailProps } from '@simplisafe/ss-react-components/organisms/ItemDetail'
import { window } from 'browser-monads-ts'
import { navigate } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import {
  List, Maybe, None
} from 'monet'
import always from 'ramda/src/always'
import applySpec from 'ramda/src/applySpec'
import cond from 'ramda/src/cond'
import equals from 'ramda/src/equals'
import head from 'ramda/src/head'
import omit from 'ramda/src/omit'
import pathEq from 'ramda/src/pathEq'
// TODO replace with ts-functional-pipe
// eslint-disable-next-line no-restricted-imports
import pipe from 'ramda/src/pipe'
import pluck from 'ramda/src/pluck'
import propEq from 'ramda/src/propEq'
import subtract from 'ramda/src/subtract'
import React, {
  FC, ReactElement, useCallback, useMemo, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

import {
  ContentfulBmsSensors,
  ContentfulDynamicPackageHeroBanner,
  ContentfulPdpIncludedItemModal,
  ContentfulProductDetail,
  ContentfulProductImageCarousel,
  ContentfulProductImageCarouselDynamic,
  ContentfulRichTextWithOptions
} from '../../../graphql'
import { toButtonTypeValue } from '../../attributeMappings'
import {
  componentsNotInStock,   CoreComponentsData, coreComponentsRestockDate, getLatestDate, renderCoreComponentsNotInStockMsg, showProductsOutOfStock, systemCoreComponents
} from '../../commercetools/outOfStock'
import { formatDisplayPrice, renderPriceNumber  } from '../../commercetools/price'
import { ContentfulComponent, getMappedComponent } from '../../componentMappings'
import AddToCartError, { AddToCartErrorType } from '../../errorComponents/AddToCartError'
import usePriceVariation from '../../hooks/usePriceVariation'
import { usePriceContext } from '../../providers/PriceProvider'
import { useOptimizelyAffirm, useOptimizelyTrackSiteEvents } from '../../tracking/optimizely'
import type { AffirmClient } from '../../types/affirm'
import { trackAddToCartEvent, trackAddToCartPackageWithExtrasEvent } from '../../util/analytics/addToCart'
import getDescriptionJson from '../../util/getDescriptionJson'
import getJson from '../../util/getJson'
import { toButton } from '../../util/helper'
import BmsSensor from '../BmsSensorGroup/BmsSensor'
import { getTotalFormattedPrice } from '../CartSummaryComponent'
import {
  getAddToCartRedirectUrl,
  getPlanText,
  maybeAddMonitoringToProducts,
  toAdditionalOptionItem,
  withPlanKey
} from '../ItemContainerComponent/OldVersion'
import ModalComponent from '../ModalComponent'
import RichTextWithOptionsComponent from '../RichTextWithOptionsComponent'
import { extractHeroBannerComponentByType } from './utils'


export type DynamicPackageHeroBannerProps = {
  readonly affirmClient?: AffirmClient
  readonly data: Partial<ContentfulDynamicPackageHeroBanner>
}

type DynamicPackageIncludedItem = ItemProduct & {
  readonly sku: Maybe<string>
}

type ImageWithSku = {
  readonly image: ReactElement
  readonly sku: Maybe<string>
}

// TODO export and unit test
// TODO define a type for x and give it a clearer name
const toBadgeIcon = (x) => (
  <Img
    alt={prop('title', x)}
    fluid={prop('fluid', x)}
    key={prop('id', x)}
    style={{
      margin: '0 auto',
      overflow: 'inherit'
    }}
  />
)

// TODO define a type for the value passed to this function
const toCardBadge = applySpec<CardBadgeProps>({
  cardImage: pipe(path( [ 'images', '1' ]), toBadgeIcon),
  description: path( [ 'description', 'description' ] ),
  image: pipe(path( [ 'images', '0' ]), toBadgeIcon ),
  title: path( [ 'title', 'title' ] )
})

const toIncludedItemsProps = (includedItems: ReadonlyArray<DynamicPackageIncludedItem>) =>
  (setSelectedItem: (sku: Maybe<string>) => void) =>
    transformObject<ContentfulProductDetail, IncludedItemProps>({
      includedItems: always(includedItems.map(item => (
        <IncludedItemProduct
          {...item}
          key={item.sku.orJust(item.productName)}
          modalType='link'
          onHoverEnd={() => setSelectedItem(None())}
          onHoverStart={() => setSelectedItem(item.sku)}
        />
      ))),
      // TODO fix type
      // @ts-ignore
      includedItemsLink: data => getDescriptionJson(prop('customizedSystem', data)),
      includedItemsTitle: data => prop('includedItemsTitle', data) || '',
      numColumnsDesktop: () => 3,
      numColumnsMobile: () => 2,
      numColumnsTablet: () => 3,
    })

// exported for testing
export const getProSetupCheckbox = (
  productDetailData: ContentfulProductDetail,
  isProSetupChecked: boolean,
  setProSetupCheckbox: (checked: boolean) => void
) => {
  const maybeCheckbox = safeProp('proSetupCheckbox', productDetailData)
  const modal = safeProp('proSetupModal', productDetailData)
    .map(modalData => (
      <ModalComponent
        clickTarget={<Info forceButtonMode={true}/>}
        data={modalData}
        key={prop('id', modalData)}
      />
    ))
  const label = maybeCheckbox
    .chain(checkbox => safeProp('customLayoutField', checkbox))
    .map(getJson)
    .map(json => <React.Fragment
      key={maybeCheckbox.map(c => `${c.id}-label`).orNull()}>
      <RichText
        json={json}
        textColor='neutralMediumGray'
        textSize='xs'
      />
      { modal.orNull() }
    </React.Fragment>)
    .orNull()

  return maybeCheckbox.map(checkboxData => (
    <SSInput
      checked={isProSetupChecked}
      key={prop('id', checkboxData)}
      label={label}
      name={prop('propName', checkboxData) || ''}
      onChange={() => setProSetupCheckbox(!isProSetupChecked)}
      // TODO this should have type='checkbox' instead of 'Checkbox', but that apparently applies some extra classes in the react component that breaks the layout here
      type={prop('type', checkboxData) as inputTypeName}
      // TODO: fix type
      // value does not exist on checkBoxData
      // @ts-ignore
      value={prop('value', checkboxData)}
    />
  )).orNull()
}

const toItemDetail = (
  onClick: (url?: string) => void,
  productTitle: string,
  includedItems: ReadonlyArray<DynamicPackageIncludedItem>,
  price: string | undefined,
  onChange: (key: string) => void,
  showSpinner: boolean,
  setSelectedItem: (sku: Maybe<string>) => void,
  isProSetupChecked: boolean,
  setProSetupCheckbox: (b: boolean) => void,
  _package: Package | undefined,
  planPrice: number
) =>
  transformObject<ContentfulProductDetail, ItemDetailProps>({
    additionalItem: data => getProSetupCheckbox(data, isProSetupChecked, setProSetupCheckbox),
    additionalOptionItemsProps: toAdditionalOptionItem(onChange, _package, undefined, undefined, undefined, planPrice),
    buttonProps: data => ({
      ...toButton(prop('button', data) || {}),
      showSpinner
    }),
    description: getDescriptionJson,
    includedItemProps: toIncludedItemsProps(includedItems)(setSelectedItem),
    onClickButton: data => () => onClick(path( [ 'button', 'url' ], data)),
    price: () => isNotNil(price) ? <Price
      regularPrice={price}
    /> : <></>,
    productTitle: () => productTitle
  })

// TODO add type to sensor
const toBmsSensor = (sensor: ContentfulBmsSensors, pkg: Package) => <BmsSensor data={sensor} key={sensor.id}
  pkg={pkg} />

const toSensors = (data: ContentfulProductImageCarousel, pkg: Package) => {
  const sensorsData: Maybe<ReadonlyArray<ContentfulBmsSensors>> = safePath([ 'extraSensors', 'sensor' ], data)
  return sensorsData.map(sensorsData => sensorsData.map(sensorData => toBmsSensor(sensorData, pkg))).getOrElse([])
}

// TODO add type to miniCartLineItem in isBaseBundle, isBundleIncluded, isRedundantForCart
const isBaseBundle = (miniCartLineItem) => prop('isBaseBundle', miniCartLineItem) || false
const isBundleIncluded = (miniCartLineItem) => prop('isBundleIncluded', miniCartLineItem) || false
const isRedundantForCart = (miniCartLineItem) => isBaseBundle(miniCartLineItem) || isBundleIncluded(miniCartLineItem)

const restockDate = (miniCartLineItem: Product) => safeProp('restockDate', miniCartLineItem).getOrElse('')
const displayOutOfStockMessage = (miniCartLineItem: Product) => (showProductsOutOfStock(restockDate(miniCartLineItem)) === true)

const renderOutOfStockMessage = (restockDate: string, coreCopomnentsRestockDate: string) => {
  const restockDateDisplay: string = isNotEmpty(coreCopomnentsRestockDate) ? getLatestDate([ coreCopomnentsRestockDate, restockDate ]) : restockDate
  const includedInPackage: boolean = (restockDateDisplay === restockDate)

  return (
    <>
      <Caution/>
      <span>
        <OutOfStockMessage
          backInStockDate={restockDateDisplay}
          includedInPackage={includedInPackage}
          lowStockMessage={true}
        />
      </span>
    </>
  )
}

export const toDiscountText =
  (radioKey: string, promoDiscountText: Maybe<string>, promoWithMonitoringDiscountText: Maybe<string>) =>
    (onChange: (x: string) => void) =>
      (data: ContentfulProductDetail | undefined, planPrice: number) =>
        (_package: Package): Maybe<string> => {
          const additionalOptionItemsProps = toAdditionalOptionItem(onChange, _package, undefined, promoDiscountText.orUndefined(), promoWithMonitoringDiscountText.orUndefined(), planPrice)(data)
          const additionalData = Maybe.fromUndefined(additionalOptionItemsProps)
            .chain(safeProp('additionalOptionItems'))
            .chain(safeFind<AdditionalOptionItemType>(propEq('skuId', radioKey)))
            .getOrElse(additionalOptionItemsProps.additionalOptionItems[0]) // taking first index if None()

          const discountValue =
            radioKey === withPlanKey
              ? promoWithMonitoringDiscountText
              : promoDiscountText

          return discountValue.map(x => {
            // sensorListDiscountText does not exist on additionalData
            // @ts-ignore
            const service = prop('sensorListDiscountText', additionalData)
            return `+ ${x} ${service}:`
          })
        }

const toRichTextWithOptionsComponent = (data: ContentfulRichTextWithOptions) =>
  <RichTextWithOptionsComponent data={data} />

const toImage = (isMobile: boolean) => (fluid: FluidObject, alt: string, key: string) => {
  // TODO - copying this from ItemContainerComponent at the 11th hour to fix carousel in mobile
  // TODO - this should not live here and needs to be redone in both places
  const carouselImageDimensions = isMobile ? {
    height: '340px',
    minWidth: '380px'
  } : {
    height: '600px',
    width: '100%'
  }

  return <Img
    alt={alt}
    fluid={fluid}
    key={key}
    style={carouselImageDimensions}
  />
}

const getCarouselImages = (data, miniCartLineItems, isMobile: boolean)  => {
  const toResponsiveImage = toImage(isMobile)
  return (safeProp('possibleCarouselImages', data)
    .map(pdpCarouselImages =>
      pdpCarouselImages.map(pdpCarouselImage =>
        Maybe.fromNull(pdpCarouselImage)
          .flatMap(pdpCarouselImage =>
            safePath([ 'systemComponent', 'displayName' ], pdpCarouselImage)
              .flatMap((displayName: string) =>
                safeProp('id', pdpCarouselImage)
                  .flatMap((id: string) => {
                    const imgSku = path([ 'systemComponent', 'sku' ], pdpCarouselImage)

                    return miniCartLineItems.find(propEq('masterSku', imgSku))
                      .flatMap(() => safePath([ 'image', 'fluid' ], pdpCarouselImage))
                      .map(fluid => ({
                        image: toResponsiveImage(fluid, displayName, id),
                        sku: Maybe.fromNull(imgSku)
                      }))
                  })
              )
          )
      )
        .filter(val => val && val.isSome())
        .map(val => val && val.just())
    )
    .getOrElse([])
  )
}

const getIncludedItems = (data: Partial<ContentfulDynamicPackageHeroBanner>, miniCartLineItems, coreComponetsNotInStockList: readonly CoreComponentsData[], locale: string) => {
  const modalsData: readonly ContentfulPdpIncludedItemModal[] = safePath([ 'possibleIncludedItemModals', 'includedItemModals' ], data)
    .orJust([])
  const coreCopomnentsRestockDate: string = coreComponetsNotInStockList.length > 0 ? coreComponentsRestockDate(coreComponetsNotInStockList) : ''
  return safeProp('possibleSystemComponents', data)
    .map(systemComponents =>
      systemComponents.map(systemComponent =>
        Maybe.fromNull(systemComponent)
          .flatMap(systemComponent => {
            const systemComponentSku = prop('sku', systemComponent)

            // @ts-ignore
            const outOfStockDisplay = miniCartLineItems.find(propEq('masterSku', systemComponentSku))
              .filter((miniCartLineItem: Product) => displayOutOfStockMessage(miniCartLineItem))
              .map((miniCartLineItem: Product) => renderOutOfStockMessage(restockDate(miniCartLineItem), coreCopomnentsRestockDate))
              .getOrElse(<></>)

            const modalData = modalsData.find(pathEq([ 'systemComponent', 'sku' ], systemComponentSku)) || {}
            // TODO: fix type
            // modalContent does not exist on modalData
            // @ts-ignore
            const modalContent: ContentfulComponent = prop('modalContent', modalData) || {}
            const ModalComponent = getMappedComponent(modalContent)
            return miniCartLineItems
              .find(propEq('masterSku', systemComponentSku))
              // @ts-ignore
              .filter((miniCartLineItem: object) => prop('quantity', miniCartLineItem) > 0)
              .map(({ quantity, name }: { readonly quantity: number; readonly name: string}) => (
                {
                  modalContent: ModalComponent && <ModalComponent data={modalContent} />,
                  outOfStockMessage: outOfStockDisplay,
                  productName: `${quantity} ${name[locale]}`,
                  sku: Maybe.fromNull(systemComponent.sku)
                })
              )
          })
      )
        .filter((val ) => val.isSome())
        .map((val) => val.just())
    )
    .getOrElse([])
}
const getPackageItem = (miniCartLineItems) => miniCartLineItems.find(miniCartLineItem => prop('isBaseBundle', miniCartLineItem) || false)
  .map(miniCartLineItem => ({
    quantity: prop('quantity', miniCartLineItem),
    sku: prop('sku', miniCartLineItem)
  }))

const getCartItems = (miniCartLineItems: List<MiniCartLineItem>): AddToCartBody['products'] => miniCartLineItems
  .filterNot(isRedundantForCart)
  .map(miniCartLineItem => {
    return ({
      quantity: prop('quantity', miniCartLineItem),
      sku: prop('sku', miniCartLineItem)
    })
  })
  .toArray()

const DynamicPackageHeroBannerComponent: FC<DynamicPackageHeroBannerProps> =
  ({ affirmClient = window.affirm, data }: DynamicPackageHeroBannerProps) => {
    const locale = useSelector(selectLocale)
    const extractComponentByType = extractHeroBannerComponentByType(data)
    const [ addToCartError, setAddToCartError ] = useState<AddToCartErrorType>(null)
    const [ showSpinner, setShowSpinner ] = useState(true)
    const [ selectedIncludedItemSku, setSelectedIncludedItemSku ] = useState<Maybe<string>>(None())
    const dispatch = useDispatch()

    const { getDiscountedText, getDiscountedTextWithServicePlan } = usePriceContext()

    const packageSku = safeProp('packageSku', data).getOrElse('')
    const _package = useSelector(selectPackage(packageSku))
    const packageProduct = useSelector(selectProduct(packageSku))
    const select = useSelector((state: ImmutableState) => state)
    const coreComponetsProducts = useSelector(selectProducts(systemCoreComponents))
    const coreComponetsNotInStockList = useMemo(() => componentsNotInStock(coreComponetsProducts), [ coreComponetsProducts ])
    const miniCart = useSelector(selectMiniCart)
    const miniCartLineItems = List.from(miniCart.orJust([]))
    const itemCount = miniCartLineItems
      .filter(miniCartLineItem => prop('price', miniCartLineItem) > 0)
      .map(prop('quantity'))
      .foldLeft(0)((acc, val) => acc + val)
    // TODO use placeholder instead of partial string in CTFL
    const packageTitle = `${itemCount}${prop('titleFragment', data)}`
    // @ts-ignore
    const includedItems: ReadonlyArray<DynamicPackageIncludedItem> =  getIncludedItems(data, miniCartLineItems, coreComponetsNotInStockList, locale)
    const isMobile = !useMediaQuery('TabletAndUp')
    const imagesWithSku: ReadonlyArray<ImageWithSku> = getCarouselImages(data, miniCartLineItems, isMobile)
    const images: ReadonlyArray<ReactElement> = pluck('image', imagesWithSku)
    // Show the first image if no image is found for the given sku
    const selectedImageIndex = Math.max(0, imagesWithSku.findIndex(i => i.sku.equals(selectedIncludedItemSku)))
    const packageItem = getPackageItem(miniCartLineItems)
    const cartItems = getCartItems(miniCartLineItems)
    const [ radioKey, setRadioKey ] = useState(withPlanKey)
    const { Track, trackEvent } = useTracking()
    const totalPrice: Maybe<number> = useSelector(selectCustomSystemTotalPrice(packageSku, false))
    const rawTotalPriceNumber: number = renderPriceNumber(totalPrice)
    const promoDiscountText = getDiscountedText(packageSku)
    const promoWithMonitoringDiscountText = getDiscountedTextWithServicePlan(packageSku)

    const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()
    const optimizelyAffirm = useOptimizelyAffirm()

    const maybeDetailComponentData: Maybe<ContentfulProductDetail> = extractComponentByType('ContentfulProductDetail')

    const initialServicePlanSku = maybeDetailComponentData
      .chain(safeProp('priceOptions'))
      // betterer is not happy with .chain(safeHead), unclear why
      .chain(priceOptions => Maybe.fromNull(head(priceOptions)))
      .chain(safeProp('productSku'))

    const [ servicePlanSku, setServicePlanSku ] = useState(initialServicePlanSku)

    const proSetupSku = maybeDetailComponentData
      .chain(data => safeProp('proSetupId', data))

    const proSetupProduct = useSelector(liftSelectProduct(proSetupSku))

    const isProSetupChecked = proSetupProduct.cata(
      () => false,
      p => !!miniCartLineItems.filter(item => item.masterSku === p.masterSku).size()
    )
    const setProSetupCheckbox = (checked: boolean) => {
      proSetupProduct.forEach(product => {
        dispatch(setMiniCartLineItem({
          ...product,
          checked: true,
          quantity: checked ? 1 : 0
        }))
      })
    }

    const servicePlanProduct = usePriceVariation(servicePlanSku.getOrElse(''))
    const servicePlanProductPrice = servicePlanProduct.cata(
      () => 0,
      value => prop('price', value)
    )

    const price = getTotalFormattedPrice(totalPrice)
    const discountedPrice = useSelector(selectCustomSystemDiscountedPrice(packageSku, radioKey, false))
    const discountedPriceEqualsTotalPrice: boolean = equals(totalPrice, discountedPrice)

    const onAddToCartClick = useCallback((url?: string) => {
      setAddToCartError(null)
      const urlNavigate = url && getAddToCartRedirectUrl(locale)(url, radioKey)

      const urlRedirect = (url: string) => {
        navigate(url, { state: { packageSku: packageSku } })
      }

      const handleSuccess = () => {
        setShowSpinner(false)

        optimizelyTrackSiteEvents({ eventType: 'add_to_cart_clicked' })
        trackAddToCartPackageWithExtrasEvent(
          _package.toMaybe(),
          packageProduct.toMaybe(),
          miniCartLineItems.toArray(),
          true,
          trackEvent,
          select,
          servicePlanProduct.isRight(),
          undefined,
          discountedPrice.getOrElse(0)
        )
        trackAddToCartEvent(servicePlanProduct, trackEvent, 1)
        urlNavigate && urlRedirect(urlNavigate)
      }
      const handleFailure = () => {
        setShowSpinner(false)
        setAddToCartError('recoverable')
        optimizelyTrackSiteEvents({ eventType: 'website_error' })
      }

      const finalProducts = maybeAddMonitoringToProducts(locale)(cartItems, radioKey)


      packageItem.cata(
        () => {
          setAddToCartError('unrecoverable')
          setShowSpinner(false)
          logError(Error('Cannot add dynamic package to cart: missing base bundle'))
        },
        _packageItem => {
          dispatch(IOAddBmsToCart({
            package: _packageItem,
            products: finalProducts
          }, handleFailure, handleSuccess))
        }
      )
    },
    // with this many dependencies, *maybe* this callback is doing too much
    [ locale, radioKey, cartItems, packageItem, packageSku, optimizelyTrackSiteEvents, _package, packageProduct, miniCartLineItems, trackEvent, select, servicePlanProduct, discountedPrice, dispatch ])

    const onPlanChange = useCallback((radioKey: string, servicePlanSku?: string) => {
      setRadioKey(radioKey)
      setServicePlanSku(Maybe.fromNull(servicePlanSku))
    }, [])

    const getPriceProps = useCallback((packageValue: Package, componentData) => {
      const placeholderText = cond([
        [
          equals(withPlanKey), always(
            discountedPrice
              .map(subtract(rawTotalPriceNumber)))
        ],
        [
          equals('No Plan'), always(
            discountedPrice
          )
        ]
      ])(radioKey)
        .chain(formatDisplayPrice)
        .orUndefined()

      const formattedDiscountedPrice = getTotalFormattedPrice(discountedPrice)

      const discountAppliedPrice =
          discountedPrice
            .map(p => subtract(p, rawTotalPriceNumber))
            .chain(formatDisplayPrice)
            .orUndefined()

      const rawDiscountedPrice = discountedPrice.orUndefined()

      return ({
        additionalOptionItemsProps: toAdditionalOptionItem(onPlanChange, packageValue, undefined, promoDiscountText.orUndefined(), promoWithMonitoringDiscountText.orUndefined(), servicePlanProductPrice)(componentData),
        discountAppliedPrice,
        interactivePlan: placeholderText && getPlanText(radioKey)(componentData),
        placeholderText,
        price: (<Price
          discountedPrice={discountedPriceEqualsTotalPrice ? undefined : formattedDiscountedPrice}
          regularPrice={price} />),
        rawDiscountedPrice
      })
    }, [ radioKey, onPlanChange, discountedPrice, discountedPriceEqualsTotalPrice, price, rawTotalPriceNumber, servicePlanProductPrice, promoDiscountText, promoWithMonitoringDiscountText ])

    const itemContainer = extractComponentByType('ContentfulProductImageCarouselDynamic')
      .flatMap((carouselComponentData: ContentfulProductImageCarouselDynamic) =>
        maybeDetailComponentData
          .map((detailComponentData: ContentfulProductDetail) => {
            const cardBadge = toCardBadge(prop('guaranteeBadge', carouselComponentData))
            const checkoutButtonFooter: SSButtonProps = ({
              children: path([ 'extraSensors', 'button', 'text' ], carouselComponentData),
              href: path([ 'extraSensors', 'button', 'url' ], carouselComponentData),
              showSpinner,
              type: toButtonTypeValue(path([ 'extraSensors', 'button', 'type' ], carouselComponentData))
            })
            const checkOutClick = () => onAddToCartClick(path([ 'extraSensors', 'button', 'url' ], carouselComponentData))
            const pricePropData =
            _package.cata(
              () => null,
              v => getPriceProps(v, detailComponentData)
            )

            const maybePriceProps = Maybe.fromNull(pricePropData)
            const affirmPrice = maybePriceProps.chain(props => { return Maybe.fromUndefined(props.rawDiscountedPrice) }).getOrElse(rawTotalPriceNumber)
            const discountAppliedPrice =  maybePriceProps.chain(props => { return Maybe.fromUndefined(props.discountAppliedPrice) }).getOrElse('')

            const affirmPromoMessage = <AffirmPromoMessage affirmClient={affirmClient}
              onLearnMoreClick={optimizelyAffirm}
              pageType={'product'}
              price={ affirmPrice } />


            const itemDetail: ItemDetailProps = {
              affirmPromoMessage,
              ...toItemDetail(onAddToCartClick,
                packageTitle,
                includedItems,
                price,
                onPlanChange,
                showSpinner,
                setSelectedIncludedItemSku,
                isProSetupChecked,
                setProSetupCheckbox,
                _package.toMaybe().orUndefined(),
                servicePlanProductPrice
              )(detailComponentData),
              ...omit([ 'discountAppliedPrice' ], pricePropData),
              outOfStockMessage: coreComponetsNotInStockList.length > 0 ? renderCoreComponentsNotInStockMsg(coreComponetsNotInStockList) : undefined
            }

            const linkText: Document = getJson(prop('sensorLinkText', carouselComponentData))
            const priceCalculation: Document = getJson(path([ 'extraSensors', 'priceCalculation', ], carouselComponentData))

            const sensors = _package.map(pkg => toSensors(carouselComponentData, pkg))
            const discountText = _package.cata(
              () => '',
              (_package) => toDiscountText(radioKey, promoDiscountText, promoWithMonitoringDiscountText)(onPlanChange)(detailComponentData, servicePlanProductPrice)(_package).getOrElse('')
            )
            return (
              <Track key={prop('id', data)}>
                <ItemContainer
                  cardBadge={cardBadge}
                  checkOutButtonFooter={checkoutButtonFooter}
                  checkOutClick={checkOutClick}
                  discountAppliedPrice={discountAppliedPrice}
                  discountText={discountText}
                  errorMessage={addToCartError && <AddToCartError errorType={addToCartError} />}
                  images={images}
                  itemDetail={itemDetail}
                  linkText={linkText}
                  priceCalculation={priceCalculation}
                  selectedImageIndex={selectedImageIndex}
                  sensors={sensors.cata(() => [], val => val)}
                  systemTotalPrice={
                    safePath([ 'price', 'props', 'discountedPrice' ], pricePropData)
                      .orElse(safePath([ 'price', 'props', 'price' ], pricePropData))
                      .orUndefined()
                  }
                />
              </Track>
            )
          })
      )
    const packageRetrievalErrorMessage = safeProp('packageRetrievalErrorMessage', data).map(data => toRichTextWithOptionsComponent(data as ContentfulRichTextWithOptions))
      .orNull()
    const packageLoadingMessage = safeProp('packageLoadingMessage', data).map(data => toRichTextWithOptionsComponent(data as ContentfulRichTextWithOptions))
      .orNull()
    const packageErrorMessage = <BannerError height="responsive">{packageRetrievalErrorMessage}</BannerError>
    return miniCart.cata(
      () => <BannerLoading>{packageLoadingMessage}</BannerLoading>, // Loading state.
      () => packageErrorMessage,  // Error state.
      () => <BannerLoading>{packageLoadingMessage}</BannerLoading>, // No line items state. This flashes briefly on page load before loading = true is dispatched to the minicart
      _miniCart => itemContainer
        .catchMap(() => { // Log the error if itemContainer is None - this likely indicates that we're missing the expected component entry/entries in Contentful
          logError(Error('Could not display dynamic package hero banner. Check that expected components exist in CMS.'))
          return None()
        })
        .orJust(packageErrorMessage) // TODO render error if we don't have an item container component to show
    )
  }

export default DynamicPackageHeroBannerComponent
