import prop from '@simplisafe/ewok/ramda/prop'
import { Maybe } from 'monet'
import React, { ReactElement } from 'react'

import { ContentfulComponent, getMappedComponent } from '../componentMappings'

/**
 * Render the contents of a Maybe with an optional empty element.
 *
 * @example
 * renderMaybe(value => <p>{value}</p>)(Just('Hello world!')) // => <p>Hello world!</p>
 *
 * @example
 * renderMaybe(value => <p>{value}</p>)(None()) // => null
 *
 * @example
 * renderMaybe(value => <p>{value}</p>, <p>Empty!</p>)(None()) // => <p>Empty!</p>
 *
 * @example
 * const items = [ Just('one'), None(), Just('two') ]
 * const renderItem = renderMaybe(item => <p>{item}<p>)
 * items.map(renderItem) // => <p>one</p><p>two</p>
 */
export function renderMaybe<T>(render: (_: T) => ReactElement, empty?: ReactElement){
  return (content: Maybe<T>) => content.cata(() => empty || null, render)
}

/**
 * Maps Contentful data to a React component and renders it. Returns null if the data doesn't map to any component.
 */
export const renderComponentFromData = <T extends ContentfulComponent>(data?: T | null) => {
  const id = prop('id', data)
  const Component = data ? getMappedComponent(data) : null

  return Component ? (
    <Component
      data={data}
      key={id}
    />
  ) : null
}
