import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { selectCrimesByCategory, selectGeoLocationPlace } from '@simplisafe/ss-ecomm-data/redux/select'
import { IOSearchLocation } from '@simplisafe/ss-ecomm-data/ukPoliceData/policedata'
import { LocationBanner } from '@simplisafe/ss-react-components/organisms'
import { LocationBannerProps } from '@simplisafe/ss-react-components/organisms/LocationBanner'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import always from 'ramda/src/always'
import applySpec from 'ramda/src/applySpec'
import defaultTo from 'ramda/src/defaultTo'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import isEmpty from 'ramda/src/isEmpty'
import isNil from 'ramda/src/isNil'
import pathOr from 'ramda/src/pathOr'
// TODO replace with ts-functional-pipe
// eslint-disable-next-line no-restricted-imports
import pipe from 'ramda/src/pipe'
import toLower from 'ramda/src/toLower'
import trim from 'ramda/src/trim'
import unless from 'ramda/src/unless'
import React, {
  FC, useCallback, useEffect, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ContentfulCrimeLocationBannerWithAddressField } from '../../../graphql'
import getJson from '../../util/getJson'
import { getPreviousDates } from '../../util/helper'
import FluidImg from '../FluidImg'

export type LocationBannerComponentProps = {
  readonly data: Partial<ContentfulCrimeLocationBannerWithAddressField>
}

function getImage<T extends keyof ContentfulCrimeLocationBannerWithAddressField, U extends keyof T>(firstPath: T, secondPath: U, data: Partial<ContentfulCrimeLocationBannerWithAddressField>) {
  return ifElse(
    equals('image/svg+xml'),
    always(<img alt={path([ firstPath, secondPath, 'title' ], data)}
      src={path([ firstPath, secondPath, 'file', 'url' ], data)} />),
    always(path([ firstPath, secondPath, 'fluid' ], data) && <FluidImg alt={path([ firstPath, secondPath, 'title' ], data)}
      fluid={path([ firstPath, secondPath, 'fluid' ], data)} />)
  )(pathOr('', [ firstPath, secondPath, 'file', 'contentType' ], data))
}

const toLocationBannerData = (data: Partial<ContentfulCrimeLocationBannerWithAddressField>, useMyLocation) => {
  const toLocationLink = applySpec({
    // @ts-ignore linkIcon does not exist
    icon: always(getImage('locationLink', 'linkIcon', data)),
    onClick: () => useMyLocation,
    text: prop('linkText')
  })

  const toLocationBanner = applySpec<LocationBannerProps>({
    BackgroundComponent:pipe(prop('backdropImage'), unless(isNil, always(BackgroundImage))),
    backgroundComponentProps: { fluid: path( [ 'backdropImage', 'fluid' ]), },
    description: path([ 'description', 'json' ]),
    disclaimerDescription: path([ 'disclaimerTextDesc', 'json' ]),
    enterLocationText: prop('enterLocationText'),
    locationLink: pipe(prop('locationLink'), toLocationLink),
    placeholder: path([ 'searchBox', 'placeholderText' ]),
    title: prop('title'),
  })

  return toLocationBanner(data)
}

const ContentfulLocationBannerComponent: FC<LocationBannerComponentProps> =
  ({ data }: LocationBannerComponentProps) => {
    const dispatch = useDispatch()
    const myLocation = useSelector(selectGeoLocationPlace)
    const [ isSubmitAction, setSubmitAction ] = useState(false)
    const [ searchLocation, setSearchLocation ] = useState(myLocation)
    const crimesData = useSelector(selectCrimesByCategory(searchLocation))
    const isCrimesData = !isNil(crimesData) && !isEmpty(crimesData)

    const useMyLocation = () => {
      ifElse(isNil, always(''), (loc: Geolocation) => loc.getCurrentPosition(pos => {
        setSubmitAction(false)
        IOSearchLocation(dispatch)(`${pos.coords.longitude},${pos.coords.latitude}`, []).run()
      })
      )(navigator.geolocation)
    }

    const onSubmit = useCallback((place: string) => {
      const location = toLower(trim(defaultTo('', place)))
      setSearchLocation(location)
      // To get the list of dates YYYY-MM for getting crime data for last 12 months.
      // Here passing prevMonth as 2 for taking the last but the previous month to start the search.
      const dates = getPreviousDates(12, 2)
      location && IOSearchLocation(dispatch)(location, dates).run()
    }, [ dispatch ])

    const onClick = useCallback((event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.preventDefault()
      unless(pipe(isNil, isEmpty), onSubmit)(searchLocation)
    }, [ searchLocation, onSubmit ])

    const handleOnChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
      setSubmitAction(true)
      setSearchLocation(event.target.value)
    }, [])

    useEffect(() => {
      !isSubmitAction && setSearchLocation(myLocation)
    }, [ myLocation, isSubmitAction ])

    return (<LocationBanner {...toLocationBannerData(data, useMyLocation)}
      noResultSection={searchLocation && !isNil(crimesData) && isEmpty(crimesData) && getJson(prop('noResultSection', data))}
      onChange={handleOnChange}
      onSubmit={onClick}
      response={{
        location: searchLocation,
        theft: crimesData
      }}
      searchButton={
        {
          // @ts-ignore icon does not exist
          children: getImage('searchButton', 'icon', data),
          // @ts-ignore this needs to be a buttontype instead of a string
          type: path([ 'searchButton', 'type' ], data)
        }
      }
      searchResultSection={isCrimesData && getJson(prop('searchResultSection', data))}
    />)
  }

export const query = graphql`#graphql
fragment locationBanner on ContentfulCrimeLocationBannerWithAddressField{
  id
  internal {
    type
  }
  title
  backdropImage {
    fluid(maxWidth: 1100) {
      ...GatsbyContentfulFluid_withWebp_noBase64
    }
  }
    description: descriptionText {
      json
    }
    disclaimerTextDesc: disclaimerText {
      json
    }
    enterLocationText
    id
    searchBox {
        placeholderText
    }
    searchButton {
        icon {
        title
        file{
            url
            contentType
        }
        fluid(maxWidth: 30) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        type
    }
    locationLink {
        linkIcon {
        title
        file{
            url
            contentType
        }
        fluid(maxWidth: 30) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        linkText
    }
    searchResultSection {
        json
    }
    noResultSection {
        json
    }
  }
`
export default ContentfulLocationBannerComponent
