import { Locale } from 'date-fns'
import { enGB, enUS } from 'date-fns/locale'

import { languageType } from './common'

const localeMapping: { readonly [_lang in languageType]: Locale} = {
  'en-GB': enGB,
  'en-US': enUS,
}

export const getLocale = (lang: languageType) => localeMapping[lang]
