import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { ContactFormSubmitBody } from '@simplisafe/ss-ecomm-data/commercetools/contactUsForm'
import { IOSubmitContactForm } from '@simplisafe/ss-ecomm-data/deprecated/contactUsForm/actions'
import { selectContactSubmitResponse } from '@simplisafe/ss-ecomm-data/redux/select'
import { ContactUs } from '@simplisafe/ss-react-components/organisms'
import { ContactUsProps } from '@simplisafe/ss-react-components/organisms/ContactUs'
import { graphql } from 'gatsby'
import always from 'ramda/src/always'
import applySpec from 'ramda/src/applySpec'
import React, { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

import { ContentfulContactUs } from '../../../graphql'
import { locale } from '../../commercetools/utils'
import { trackSubmitLeadEvent } from '../../util/analytics'

const toContactUSProps = applySpec<ContactUsProps>({
  ctaNote: path([ 'emailUsSection', 'ctaNote' ]),
  emailUsSection: prop('emailUsSection'),
  headBanner: prop('headBanner'),
  otherOptionsSection: prop('otherOptionsSection')
})

type ContactUsComponentProps = {
    readonly data: Partial<ContentfulContactUs>
}

const getFormData = (contactUsData) => {
  return applySpec<ContactFormSubmitBody>({
    email: prop('emailAddress'),
    locale: always(locale),
    message: prop('message'),
    name: prop('name'),
    subject: prop('topic')
  })(contactUsData)
}

const ContactUsComponent: FC<ContactUsComponentProps> =
  ({ data }: ContactUsComponentProps) => {
    const contactusProps = toContactUSProps(data)
    const contactSuccessMsg = useSelector(selectContactSubmitResponse)
    const contactStatus = path([ 'value', 'status' ], contactSuccessMsg) as string
    const { trackEvent } = useTracking()
    const dispatch = useDispatch()

    const onValidForm = useCallback((contactData: Record<string, unknown>) => {
      // the record type above with `unknown` won't work here.
      // @ts-ignore
      const contactUsData = path([ 'forms', 0, 'Email Us' ], contactData)
      const formData = getFormData(contactUsData)
      IOSubmitContactForm(dispatch)({ body: formData }).run()
      trackSubmitLeadEvent(trackEvent)
    }, [ trackEvent, dispatch ])
    return (
      <ContactUs
        { ...contactusProps }
        onValidated={onValidForm}
        submissionMessage={contactStatus}
      />
    )
  }

//We have to integrate the filter selection to redux
export const query = graphql`
  fragment contactUsFragment on ContentfulContactUs {
    id
    internal {
      type
    }
    headBanner {
      contentsToGroup {
      ... on ContentfulSmallTextSection {
          id
          title
          uiTheme
          description {
          json
          }
          textColor {
          id
          color
          }
        }
      }
    }
    emailUsSection {
      formInput {
        ... on ContentfulCheckoutFormSection {
          id
          title
          inputField {
            title
            propName
            placeholderText
            maximumCharacter
            answerOptions
            characterType
            type
            fieldValidation {
              requirement
              errorMessage
            }
            radioOption {
              text
              value
              note {
                json
              }
            }
          }
        }
      }
      successMessageTitle
      successMessageDescription {
        successMessageDescription
      }
      formTheme
      button {
        text
        type
        url
      }
      ctaNote {
        json
      }
    }
    otherOptionsSection {
        headerText
        contentsToGroup {
        ... on ContentfulSmallTextSection {
          id
          description {
            json
          }
          title
        }
      }
    }
  }
`

export default ContactUsComponent
