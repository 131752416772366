import prop from '@simplisafe/ewok/ramda/prop'
import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import { safePath, safeProp } from '@simplisafe/monda'
import { Maybe } from 'monet'
import React, { FC } from 'react'

import {
  ContentfulBmsSensors, ContentfulProductDetail, ContentfulProductImageCarouselDynamic
} from '../../../graphql'
import { PriceProvider } from '../../providers/PriceProvider'
import DynamicPackageHeroBannerComponent, { DynamicPackageHeroBannerProps } from '.'
import { extractHeroBannerComponentByType } from './utils'

export type DynamicPackageHeroBannerWrapperProps = {
  readonly data: DynamicPackageHeroBannerProps['data']
}

const DynamicPackageHeroBannerWrapper: FC<DynamicPackageHeroBannerWrapperProps> = ({ data }: DynamicPackageHeroBannerWrapperProps) => {
  const productDetailData: Maybe<ContentfulProductDetail> = extractHeroBannerComponentByType(data)('ContentfulProductDetail')
  const carouselData: Maybe<ContentfulProductImageCarouselDynamic> = extractHeroBannerComponentByType(data)('ContentfulProductImageCarouselDynamic')

  const packageSku = safeProp('packageSku', data)
  const possibleSystemComponentSkus = safeProp('possibleSystemComponents', data)
    // TODO: fix type
  // @ts-ignore
    .map(components => components.filter(isNotNil).map(prop('sku')))
    .orJust([])
  const proSetupSku = productDetailData.chain(safeProp('proSetupId'))
  const servicePlanSkus = productDetailData
    .chain(safeProp('priceOptions'))
  // TODO: fix type
  // @ts-ignore
    .map(priceOptions => priceOptions.filter(isNotNil).map(prop('productSku')))
    .orJust([])
  const extraSensorSkus = carouselData
    .chain<ReadonlyArray<ContentfulBmsSensors>>(safePath([ 'extraSensors', 'sensor' ]))
    .map(sensors => sensors.map(sensor => sensor.productId))
    .orJust([])

  // Gather all package/product skus to be sent to the pricing service in one batch request
  const allProductIds = new Set<string>()
  packageSku.forEach(sku => allProductIds.add(sku))
  // TODO: fix type
  // @ts-ignore
  possibleSystemComponentSkus.forEach(sku => sku && allProductIds.add(sku))
  proSetupSku.forEach(sku => allProductIds.add(sku))
  // TODO: fix type
  // @ts-ignore
  servicePlanSkus.forEach(sku => sku && allProductIds.add(sku))
  extraSensorSkus.forEach(sku => sku && allProductIds.add(sku))

  return (
    <PriceProvider skus={Array.from(allProductIds)}>
      <DynamicPackageHeroBannerComponent data={data} />
    </PriceProvider>
  )
}

export default DynamicPackageHeroBannerWrapper
