import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import {
  leadGenCapture, LeadGenCaptureParams, LeadGenCaptureResponse
} from '@simplisafe/ss-ecomm-data/leads/capture'
import { selectActivePromoCode } from '@simplisafe/ss-ecomm-data/redux/select'
import { cookiesOption } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import { MenuItem, MenuProps } from '@simplisafe/ss-react-components/atoms/Menu'
import { ItemIconDropDown } from '@simplisafe/ss-react-components/molecules/IconDropDown'
import { Footer } from '@simplisafe/ss-react-components/organisms'
import {
  FooterLinkProps,
  FooterProps, Links, SocialLinks
} from '@simplisafe/ss-react-components/organisms/Footer'
import { graphql, navigate } from 'gatsby'
import Img from 'gatsby-image'
import { Maybe } from 'monet'
import always from 'ramda/src/always'
import append from 'ramda/src/append'
import applySpec from 'ramda/src/applySpec'
import defaultTo from 'ramda/src/defaultTo'
import either from 'ramda/src/either'
import equals from 'ramda/src/equals'
import findIndex from 'ramda/src/findIndex'
import ifElse from 'ramda/src/ifElse'
import isEmpty from 'ramda/src/isEmpty'
import isNil from 'ramda/src/isNil'
import map from 'ramda/src/map'
import pathOr from 'ramda/src/pathOr'
import propEq from 'ramda/src/propEq'
import propOr from 'ramda/src/propOr'
import when from 'ramda/src/when'
import React, {
  FC,
  useCallback,
  useState
} from 'react'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import { pipe } from 'ts-functional-pipe'

import { ContentfulFooterFragment } from '../../../graphql'
import { locale } from '../../commercetools/utils'
import useCookieChange from '../../hooks/useCookieChange'
import { handleBrazeTrackingEvent } from '../../tracking/braze'
import {
  COOKIE_LEAD_DATA,
  cookies,
  getLeadData
} from '../../tracking/cookies'
import { useOptimizelyTrackSiteEvents } from '../../tracking/optimizely'
import {
  GtmData, sendGtmCustomEvent, trackSubmitLeadEvent
} from '../../util/analytics'
import getJson from '../../util/getJson'
import ContentfulRichText from '../ContentfulRichText'
import FluidImg from '../FluidImg'

export type FooterComponentProps = {
  readonly id?: string
  readonly data: ContentfulFooterFragment
}

export const formatTelephoneLink =
  (source = '') =>
    'tel:'
      .concat(source
        .split('-')
        .join(''))

function toUrl<T extends { readonly linkType: string; readonly linkText: string; readonly linkUrl: string}>(source: T) {
  const typeIsTelephone = propEq('linkType', 'Telephone', source)

  const linkText: string = prop('linkText', source)

  return ifElse(
    always(typeIsTelephone),
    always(formatTelephoneLink(linkText)),
    prop('linkUrl')
  )(source)
}

const toMenuLink = applySpec<MenuProps>({
  name: prop('linkText'),
  url: toUrl
})

export const toFooterLinks = (contentfulFooter: ContentfulFooterFragment): readonly FooterLinkProps[] => {
  const toMenuItem = applySpec<FooterLinkProps>({
    menus: pipe(prop('links'), ifElse(isNil, always([]), map(toMenuLink))),
    title: prop('groupCategory'),
    titleUrl: prop('linkURL')
  })
  return map(toMenuItem, prop('footerGroup', contentfulFooter) || [])
}

export const toOffersData = (leadGenerationForm, handleEmailSubmit, isSuccess, defaultEmail: string) => {
  const inputLabel = prop('title', leadGenerationForm)
  const placeHolder = path([ 'formInput', '0', 'placeholderText' ], leadGenerationForm)
  const buttonText = path([ 'button', 'text' ], leadGenerationForm)
  const ctaContent = <ContentfulRichText rawRichText={getJson(prop('ctaNote', leadGenerationForm))} />
  const fieldValidation = pathOr({}, [ 'formInput', '0', 'fieldValidation' ], leadGenerationForm)
  const errorMsg = propEq('requirement', 'Invalid Input')(fieldValidation)
    // errorMessage does not exist on fieldValidation
  // @ts-ignore
    ? prop('errorMessage', fieldValidation)
    : ''
  const success = {
    message:defaultTo('')(prop('successMessageTitle', leadGenerationForm)),
    title:defaultTo('')(path([ 'successMessageDescription', 'successMessageDescription' ], leadGenerationForm))
  }

  const offersDataSpec = {
    buttonText,
    ctaContent,
    defaultEmail,
    errorMsg,
    inputLabel,
    onEmailSubmit: handleEmailSubmit,
    placeHolder,
    title: inputLabel,
  }

  return isSuccess ? {
    ...offersDataSpec,
    success
  } : offersDataSpec
}

const toLegalLinks = (contentfulFooter: ContentfulFooterFragment): readonly Links[] => {
  const toLegalLink = applySpec<Links>({
    linkText: prop('linkText'),
    linkUrl: prop('linkUrl')
  })
  return map(toLegalLink, prop('tertiaryLinks', contentfulFooter) || [])
}

const toLegalLinkMenu = (legalLinks: readonly Links[]): readonly MenuProps[] => {
  return map(toMenuLink, legalLinks)
}

const toSocialIcon = (data) => {
  const fluid = prop('fluid', data)
  const title = prop('title', data)
  return <FluidImg alt={title}
    fluid={fluid}
    style={{ width: '100%' }}
  />
}

export const toSocialLinks = (contentfulFooter: ContentfulFooterFragment): readonly SocialLinks[] => {
  const toSocialLink = applySpec<SocialLinks>({

    id: prop('id'),
    image: pipe(path([ 'icon', '0' ]), toSocialIcon),
    linkText: prop('linkText'),
    linkUrl: prop('linkUrl')
  })
  return map(toSocialLink, prop('socialMediaIcons', contentfulFooter) || [])
}

const toFormImg = (x) => {
  const alt = prop('title', x)

  return <Img
    alt={alt}
    fluid={path([ 'linkIcon', 'fluid' ], x)}
    style={{
      height: '12px',
      marginTop:'6px',
      width: '20px'
    }}
  /> }

const toListItem = applySpec<ItemIconDropDown>({
  href: prop('linkUrl'),
  srcIcon: (x) => {
    return toFormImg(x)
  },
  subtitle: prop('linkSubtext'),
  title: prop('linkText')
})

const toLeftBottomLinks = (data) => {
  const toLeftBottomLink = applySpec<Links>({
    linkText: prop('linkText'),
    linkUrl: prop('linkUrl')
  })
  return map(toLeftBottomLink, data)
}

const getCountryInformation = (data, locale: string) => {
  const isUS = equals('en-US', locale)
  const labelForUS = ifElse(equals(true), always('United States'), always('United Kingdom'))(isUS)
  const listItems = (!data) ? [] : map(toListItem)(data)
  const currentLocationIdx =
      pipe(
        findIndex(propEq('title', labelForUS)),
        when(equals(-1), always('0'))
      )(listItems)
  // TODO these 2 currentLocationIdx are not on listItems
  // @ts-ignore
  const image = path([ currentLocationIdx, 'srcIcon' ], listItems)
  // @ts-ignore
  const label: string = path([ currentLocationIdx, 'subtitle' ], listItems) || ''
  const positionDropDown: 'down' | 'up' = 'up'
  const eventShowDropDown: 'click' | 'hover' = 'click'
  return {
    a11yLabel: 'Current country is: ',
    eventShowDropdown: eventShowDropDown,
    label: label,
    listItem: listItems,
    positionDropdown: positionDropDown,
    srcIcon: image
  }
}

const toFooterData = (contentfulFooter: ContentfulFooterFragment, handleEmailSubmit: (email: string) => void, isSuccess, locale: string, defaultEmail: string): FooterProps => {
  const legalLinks = toLegalLinks(contentfulFooter)
  const copyRightText = contentfulFooter.copyrightText
  const leadForm = propOr(undefined, 'leadGenerationForm', contentfulFooter)
  const offers = leadForm ? toOffersData(contentfulFooter.leadGenerationForm, handleEmailSubmit, isSuccess, defaultEmail) : undefined
  const footerLinkList = ifElse(isNil, always([]), () => toFooterLinks(contentfulFooter))(prop('footerGroup', contentfulFooter))

  const legalLink: FooterLinkProps = {
    menus: toLegalLinkMenu(legalLinks),
    title: 'Legal' // TODO: this should not be hard coded
  }

  const socialLinks: readonly SocialLinks[] | undefined = ifElse(isNil, always(undefined), () => toSocialLinks(contentfulFooter))(prop('socialMediaIcons', contentfulFooter))
  const footerLinks: readonly FooterLinkProps[] = append(legalLink, footerLinkList)
  const countrySelector = pipe(
    ifElse(
      isNil,
      always(undefined),
      (data) => { return getCountryInformation(data, locale) }
    )
  )( path([ 'countrySelector', 'listOfCountries' ], contentfulFooter))
  const leftBottomLink = ifElse(either(isNil, isEmpty), always(undefined), toLeftBottomLinks)(prop('leftBottomLink', contentfulFooter))
  return {
    footerData: {
      copyRightText: copyRightText || '',
      countrySelector,
      footerLinks,
      leftBottomLink,
      legalLinks,
      offers,
      socialLinks,
    }
  }
}

const setLinkGtmCustomEvent = (menuItem: MenuItem) => {
  const gtmData: GtmData = {
    event: 'buttonClick',
    eventAction: 'click',
    eventCategory: 'footer',
    eventLabel: prop('name', menuItem)
  }
  sendGtmCustomEvent(gtmData)
}

const FooterComponent: FC<FooterComponentProps> =
  ({ data }: FooterComponentProps) => {
    const leadDataCookie = getLeadData()
    const promoCode = useSelector(selectActivePromoCode)
    const [ isSuccess, setIsSuccess ] = useState(false)
    const [ defaultEmail, setDefaultEmail ] = useState(propOr<string, string>('', 'email', leadDataCookie))

    const { Track, trackEvent } = useTracking({ appSection: 'newsletterSubmit' })

    const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()

    const handleSuccess = (value: Maybe<LeadGenCaptureResponse>) => {
      cookies.set(COOKIE_LEAD_DATA, value.orUndefined(), cookiesOption)
      setIsSuccess(true)
      handleBrazeTrackingEvent(value.orUndefined())
      trackEvent({ event: 'submit' })
      trackSubmitLeadEvent(trackEvent)
      optimizelyTrackSiteEvents({ eventType: 'lead_captured_fs' })
    }
    const handleError = () => {
      setIsSuccess(false)
      optimizelyTrackSiteEvents({ eventType: 'website_error' })
    }

    const handleEmailSubmit = (email: string) => {
      const leadGenParams: LeadGenCaptureParams = {
        email,
        promoCode: promoCode.getOrElse('NO_CODE'),
        source: 'footer',
        sourceType: 'footer'
      }

      trackEvent({ event: 'submit' })
      leadGenCapture(leadGenParams)(() => handleError())(handleSuccess)
    }

    useCookieChange(COOKIE_LEAD_DATA, data => setDefaultEmail(propOr('', 'email', JSON.parse(data))))

    const transformData = toFooterData(data, handleEmailSubmit, isSuccess, locale, defaultEmail)

    const onClickLink = useCallback((menuItem: MenuItem) => {
      navigate(menuItem.url || '', { state:{ source:'footer' } })
      setLinkGtmCustomEvent(menuItem)
    }, [])

    const type = defaultTo('Full')(data.type)
    return (
      <Track>
        <Footer
          footerData={transformData.footerData}
          key='Footer'
          onClickLink={onClickLink}
          type={type} />
      </Track>
    )
  }
export const footerQuery = graphql`#graphql
  fragment contentfulFooter on ContentfulFooter
    {
      id
      type
      copyrightText
      title
      countrySelector {
        listOfCountries {
          linkUrl
          linkText
          linkSubtext
          linkIcon {
            fluid(maxWidth: 20, maxHeight:20) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
      footerGroup {
        groupCategory
        links {
          linkUrl
          linkText
          linkType
        }
        linkURL
      }
      socialMediaIcons {
        id
        linkUrl
        linkTitle
        icon {
          fluid(maxWidth: 300) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
      }
      tertiaryLinks {
        linkUrl
        linkText
      }
      leadGenerationForm {
        successMessageTitle
        successMessageDescription {
          successMessageDescription
        }
        title
        button {
          text
        }
        formInput {
          ... on ContentfulForms {
            placeholderText
            fieldValidation {
              requirement
              errorMessage
            }
          }
        }
        ctaNote {
          json
        }
    }
    leftBottomLink {
      linkUrl
      linkText
    }
  }
`
export default FooterComponent
