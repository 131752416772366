import prop from '@simplisafe/ewok/ramda/prop'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { Text } from '@simplisafe/ss-react-components/atoms'
import { Maybe } from 'monet'
import React, { FC } from 'react'

import { ContentfulProductInformation } from '../../../graphql'
import usePriceVariation from '../../hooks/usePriceVariation'
import getDescriptionJson from '../../util/getDescriptionJson'
import { getTokenizedJson } from '../../util/monitoring-price-placeholders'
import ContentfulRichText from '../ContentfulRichText'

export type CartLineItemDescriptionProps = {
  readonly onClick: (_url: string) => void
  readonly productInformation: ContentfulProductInformation
  readonly sku: string
}

const CartLineItemDescription: FC<CartLineItemDescriptionProps> = ({
  onClick,
  productInformation,
  sku
}: CartLineItemDescriptionProps) => {
  return usePriceVariation(sku)
    .map(product => prop('price', product))
    .map(price =>
      Maybe.fromNull(productInformation)
        .chain(prodInfo => Maybe.fromNull(getDescriptionJson(prodInfo)))
        .chain(doc => Maybe.fromNull(getTokenizedJson(doc, price)))
        .orNull()
    )
    .cata(
      (e) => {
        logError(e)
        return null
      },
      (doc) =>
        <Text>
          <ContentfulRichText
            onLinkClick={(e, uri) => {
              e.preventDefault()
              onClick && onClick(uri)
            }}
            rawRichText={doc}
          />
        </Text>
    )
}

export default CartLineItemDescription
