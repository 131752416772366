import { BrazeQuoteWizardResponse } from '@simplisafe/ecomm-ts-types'
import path from '@simplisafe/ewok/ramda/path'
import isNil from 'ramda/src/isNil'

type PopupWizardResponseMapper = {
  readonly [key: string]: {
    readonly answers: { readonly [key: string]: string }
    readonly question: string
  }
}

// Map question and answer ids to the strings that need to be passed to Braze
const popupWizardResponseMapper: PopupWizardResponseMapper = {
  10: {
    answers: {
      24: 'Sure I\'d love some help',
      26: 'That\'s ok I can handle it myself'
    },
    question: 'QW Want one of our security specialists to help you choose a system?'
  },
  12: {
    answers: {
      28: 'House',
      30: 'Flat'
    },
    question: 'QW Find the perfect system. What are you protecting?'
  },
  16: {
    answers: {
      32: '1',
      34: '2-5',
      36: '6+'
    },
    question: 'QW How many locations are you looking to protect?'
  },
  18: {
    answers: {
      38: 'Office',
      40: 'Storefront',
      42: 'Restaurant'
    },
    question: 'QW What kind of business are you protecting?'
  },
  2: {
    answers: {
      2: 'House',
      4: 'Apartment',
      6: 'Business'
    },
    question: 'QW Find the perfect system. What are you protecting?'
  },
  4: {
    answers: {
      10: 'Average',
      12: 'Large',
      8: 'Small'
    },
    question: 'QW How big is your property?'
  },
  6: {
    answers: {
      14: '1',
      16: '2',
      18: '3+'
    },
    question: 'QW How many doors leading to the property?'
  },
  8: {
    answers: {
      20: 'I\'ll set it up myself (97% do this)',
      22: 'I\'d love a professional to do it.'
    },
    question: 'QW We\'ve got two options for set-up. Which one sounds right to you?'
  }
}


// TODO this is a super quick and dirty way of getting QW response data into GTM for Braze in the interest of time, and should be revisited and reworked.
// This should probably also be moved to ecomm-data.
// NOTE: Braze expects QW questions and answers to EXACTLY match what Drupal is currently sending, and IS CASE-SENSITIVE.
export const buildPopupWizardResponseData = (responses: Record<string, string>): ReadonlyArray<BrazeQuoteWizardResponse> => {
  return Object.keys(responses).map(questionId => ({
    answer: path([ questionId, 'answers', responses[questionId] ], popupWizardResponseMapper),
    question: path([ questionId, 'question' ], popupWizardResponseMapper)
  }))
    .filter(data => !isNil(data.answer) && !isNil(data.question))
}
