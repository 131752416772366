import { FormField, Text } from '@simplisafe/ss-react-components/atoms'
import React, { ReactElement } from 'react'

type ContactFormDescriptionProps = {
  readonly formDescription?: ReactElement
  readonly formTitle?: string
}

const Description: React.FC<ContactFormDescriptionProps> = (props: ContactFormDescriptionProps) => {
  const { formTitle, formDescription } = props
  return (
    <div data-component={'Description'}>
      <FormField hideLabel={true} name='formDescription'>
        {
          formTitle &&
          <Text fontWeight='light'>
            <h2>{formTitle}</h2>
          </Text>
        }
        {formDescription}
      </FormField>
    </div>
  )
}

export default Description
