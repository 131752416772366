import { BLOCKS, Document } from '@contentful/rich-text-types'

/**
 * Returns a blank document if one is not provided.
 *
 * Useful for handling documents that might be undefined.
 */
const documentFallback =
    (document?: Document): Document =>
      document
        ? document
        : {
          content: [],
          data: {},
          nodeType: BLOCKS.DOCUMENT
        }

export default documentFallback
