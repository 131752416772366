import path from '@simplisafe/ewok/ramda/path'
import { safeProp } from '@simplisafe/monda'
import { ComparisonTableRows } from '@simplisafe/ss-react-components/organisms'
import { Maybe } from 'monet'
import React from 'react'

import {
  ContentfulExpandableMonitoringPlan, ContentfulPlanComparisonTable, ContentfulPlanComparisonTableRow, ContentfulProductPlanContentfulRichTextWithOptionsUnion
} from '../../../graphql'
import ComparisonTableRowComponent from '../ComparisonTableRowComponent'
import RichTextWithOptionsComponent from '../RichTextWithOptionsComponent'
import PlanButton from './PlanButton'

const renderCompareTable = (data: Partial<ContentfulExpandableMonitoringPlan>) => {
  const planComparisonTable: Maybe<ContentfulPlanComparisonTable> = Maybe.fromNull(path([ 'planComparisonTable' ], data))
  const dataComponent = planComparisonTable.chain(value => safeProp('title', value)).getOrElse('compare-table')
  const headers = planComparisonTable.chain(value => safeProp('tableHeaders', value)).getOrElse([ '' ])
  const footers = planComparisonTable.chain(value => safeProp('tableFooters', value)).getOrElse([ '' ])
  const footerPlan = planComparisonTable.chain(value => safeProp('planTableFooters', value)).getOrElse([])
  const rowsList = planComparisonTable.chain(value => safeProp('tableRows', value)).getOrElse([ null ])

  return <ComparisonTableRows
    dataComponent={dataComponent}
    footerPlan={footerPlan
      .filter((val): val is ContentfulProductPlanContentfulRichTextWithOptionsUnion => !!val)
      .map((plan) => {
        const titlePlan = safeProp('title', plan).getOrElse('')
        const getType = path([ 'internal', 'type' ], plan)
        return ( getType === 'ContentfulRichTextWithOptions' ?
          <RichTextWithOptionsComponent data={plan}/> :
          <PlanButton data={plan}
            titlePlan={titlePlan} />
        )
      })
    }
    footers={footers.filter((str): str is string => !!str)}
    headers={headers.filter((str): str is string => !!str)}
    key={'id-compare-table'}
    rowsList={rowsList && rowsList
      .filter((val): val is ContentfulPlanComparisonTableRow => !!val)
      .map((row, i) => {
        return <ComparisonTableRowComponent
          data={row}
          key={`row-${i}`}
        />
      })
    }
  />
}

export default renderCompareTable
