import { useCookies } from 'react-cookie'
import { CookieSetOptions } from 'universal-cookie'

const COOKIE_DRUPAL_UID = 'DRUPAL_UID'
const COOKIE_VID = 'vid'
const COOKIE_ATAT_TOKEN = 'atat_v2'
const COOKIE_LEAD_DATA = 'leadData'
const COOKIE_PURCHASED_CART_ID = 'purchasedCartId'
const COOKIE_FCP_ORDER_ID = 'FCP_ORDER_ID'
const COOKIE_PREACTIVATION = 'ssOauthAccessToken'
const COOKIE_CHAT_APP_ID = 'chat_app_id'
const COOKIE_CHAT_OPENED = 'chat_opened'

export type CookieNames = typeof COOKIE_DRUPAL_UID | typeof COOKIE_VID | typeof COOKIE_ATAT_TOKEN | typeof COOKIE_LEAD_DATA | typeof COOKIE_PURCHASED_CART_ID | typeof COOKIE_FCP_ORDER_ID | typeof COOKIE_PREACTIVATION | typeof COOKIE_CHAT_APP_ID | typeof COOKIE_CHAT_OPENED

export type Cookies = Record<CookieNames, string | undefined>

const useTrackingCookies = (): readonly [Cookies, <T>(name: CookieNames, value: T, options?: CookieSetOptions) => void,  (name: CookieNames, options?: CookieSetOptions) => void] => {
  const [ cookies, setCookie, removeCookie ] =
      useCookies<CookieNames, Cookies>([
        COOKIE_DRUPAL_UID,
        COOKIE_VID,
        COOKIE_ATAT_TOKEN,
        COOKIE_LEAD_DATA,
        COOKIE_PURCHASED_CART_ID,
        COOKIE_FCP_ORDER_ID,
        COOKIE_PREACTIVATION,
        COOKIE_CHAT_APP_ID,
        COOKIE_CHAT_OPENED
      ])
  return [ cookies, setCookie, removeCookie ]
}

export default useTrackingCookies
