import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import Img, { FluidObject, GatsbyImageFluidProps } from 'gatsby-image'
import all from 'ramda/src/all'
import React from 'react'

const isFluid = (fluid: FluidObject | Partial<FluidObject> | undefined): fluid is FluidObject => {
  return fluid && all(isNotNil, [ fluid.src, fluid.srcSet, fluid.sizes, fluid.aspectRatio ]) || false
}

type FluidImgProps = {
    readonly fluid?: Partial<FluidObject>
} & Omit<GatsbyImageFluidProps, 'fluid'>

/**
 * This component should be used instead of directly using Gatsby's Img component
 * for fluid images from Contentful.
 *
 * Contentful data can always be undefined, and this can make working with objects like FluidObject difficult.
 * This component makes it easy by checking if fluid is defined and that it has all the required keys before
 * trying to render Img.
 */
const FluidImg = ({ fluid, ...rest }: FluidImgProps) => {

  return isFluid(fluid)
    ? <Img fluid={fluid}
      {...rest}/>
    : null
}

export default FluidImg

