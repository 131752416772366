import { Cancel } from '@simplisafe/ss-react-components/icons'
import * as Promo from '@simplisafe/ss-react-components/promotions'
import React, { FC } from 'react'

import type { LeadCaptureFormPropsOnSubmit } from '../LeadCaptureForm'
import type { ActivePromoBannerType } from '.'
import MobileMaximizedLayout from './MobileMaximizedLayout'
import MobileMinimizedLayout from './MobileMinimizedLayout'

export type MobileBottomBannerProps = {
  readonly isDismissed: boolean
  readonly isFormSubmitted: boolean
  readonly isMinimized: boolean
  readonly onDismiss: () => void
  readonly onEmailSubmit: LeadCaptureFormPropsOnSubmit
  readonly onMinimize: () => void
  readonly onRedeemClick: () => void
  readonly type: ActivePromoBannerType
}

const MobileBottomBanner: FC<MobileBottomBannerProps> = ({
  isDismissed,
  isFormSubmitted,
  isMinimized,
  onDismiss,
  onEmailSubmit,
  onMinimize,
  onRedeemClick,
  type,
}: MobileBottomBannerProps) => {

  const closeBtn = (
    <span
      style={{
        position: 'absolute',
        right: '12px',
        top: '12px',
      }}
    >
      <Cancel
        className='closebutton'
        onClick={() => isMinimized ? onDismiss() : onMinimize()}
        titleA11y='Close'
      />
    </span>
  )

  const shouldShow = type !== 'cart' && !isDismissed
  return shouldShow ? (
    <Promo.PromoBanner data-component='MobileBottomBanner' position='bottom'>
      {closeBtn}
      {isMinimized ? <MobileMinimizedLayout isFormSubmitted={isFormSubmitted} onRedeemClick={onRedeemClick} /> : <MobileMaximizedLayout
        isFormSubmitted={isFormSubmitted}
        onEmailSubmit={onEmailSubmit}
        type={type}
      />}
    </Promo.PromoBanner>
  ) : null
}

export default MobileBottomBanner
