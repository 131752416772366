import { TrackingData } from '@simplisafe/ecomm-ts-types'
import localizeCent from '@simplisafe/ewok/currency-utils/localizeCent'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import { Product } from '@simplisafe/ss-ecomm-data/commercetools/products'
import { RichText } from '@simplisafe/ss-react-components/atoms'
import { ProductPlan } from '@simplisafe/ss-react-components/organisms'
import { Either, Maybe } from 'monet'
import React from 'react'

import { ContentfulProductPlan } from '../../../graphql'
import { renderPriceWithTemplate } from '../../commercetools/price'
import { locale } from '../../commercetools/utils'
import { getMappedComponent } from '../../componentMappings'
import getDescriptionJson from '../../util/getDescriptionJson'
import getJson from '../../util/getJson'
import type { MonitoringPlanTypeTokens } from '.'
import {
  getAddPlanButton,
  getMonitoringConfirmModal
} from '.'



const renderProductPlan = (
  item: Partial<ContentfulProductPlan>,
  product: Either<Error, Product>,
  index: MonitoringPlanTypeTokens,
  trackEvent: (_: TrackingData) => void,
  offerValue: Maybe<string>,
  monthsOfFreeService: string
) => {

  const keyFeatures = safeProp('keyFeatures', item).orJust([])
  const productOffer = getJson(prop('productOfferText', item))
  const relatedInformation = prop('relatedInformation', item)
  const typeButton = path([ 'button', 'internal', 'type' ], item)
  const button = prop('button', item)
  const titlePlan = prop('title', item) || ''
  const priceRateValue = safeProp('priceRate', item).orUndefined()

  const THEME_PRODUCT_PLAN_COMPONENT = titlePlan.includes('three free months of Interactive Monitoring') === true ? 'fullWidthBlue' : 'fullwidth'

  return <ProductPlan
    button={typeButton !== 'ContentfulModal' ?
      button && getAddPlanButton(button)
      : button && getMonitoringConfirmModal(button, trackEvent, titlePlan, monthsOfFreeService)}
    key={`productplan ${index}`}
    keyFeatures={keyFeatures.map(data => {
      const Component = data && getMappedComponent(data)
      return data ? {
        'clickTarget': Component ? <Component data={data} /> : null,
        'id': prop('id', data)
      } : {
        'clickTarget': null,
        'id': '',
      }
    })}
    planDescription={<RichText json={getDescriptionJson(item)}/>}
    price={renderPriceWithTemplate({
      customFormatPrice: (price) => localizeCent(locale, price),
      product,
      // showCustomFormatPrice: priceFormat === 'per-day',
      showDiscountedPrice: false,
      template: priceRateValue
    })}
    productOffer={productOffer}
    productOfferValue={offerValue.orUndefined()}
    relatedInformation={relatedInformation || ''}
    theme={THEME_PRODUCT_PLAN_COMPONENT}
    title={prop('title', item) || ''}
  />
}

export default renderProductPlan
