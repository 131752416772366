import {
  get, remove, set
} from 'local-storage'
import { Maybe } from 'monet'
import { StringParam, useQueryParam } from 'use-query-params'

import { params } from '../util/queryParams'

const utmCodeMap: Record<string, string> = {
  'protect': '8XOKBCYX',
  'safety': '8XOKBCYX'
}

const useUtmCode = (enable = true, path: Maybe<string> = Maybe.none()) => {
  /** The promo code in the URL */
  const [ queryPromoCode, setUtmPromoCode ] = useQueryParam(params.promoCode, StringParam)
  const utmPromoCode = path.cata(
    () => queryPromoCode,
    (path) => utmCodeMap[path] || queryPromoCode
  )

  // if enable is true and utmCode is defined write it to locale storage
  enable && utmPromoCode && set('utm_code', utmPromoCode)

  /** clear local storage if disable is true */
  !enable && remove('utm_code')

  /** clear utm_code from url if disable is true */
  !enable && setUtmPromoCode(undefined)

  /** To persist the utm_code across sessions and pages we write it to local storage */
  const localStorageUtmCode: string | undefined = get('utm_code')

  /** the code to apply to the cart */
  return utmPromoCode || localStorageUtmCode
}

export default useUtmCode
