/* eslint-disable sonarjs/cognitive-complexity */
import { Document } from '@contentful/rich-text-types'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import { IOSaveMySystem } from '@simplisafe/ss-ecomm-data/cart'
import { MiniCartLineItem } from '@simplisafe/ss-ecomm-data/deprecated/minicart/actions'
import {
  leadGenCapture, LeadGenCaptureParams, LeadGenCaptureResponse
} from '@simplisafe/ss-ecomm-data/leads/capture'
import { selectActivePromoCode, selectMiniCartLineItems } from '@simplisafe/ss-ecomm-data/redux/select'
import { cookiesOption } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import {
  LoadingSpinner,
  Modal, RichText, SSButton, SSInput, Text,
} from '@simplisafe/ss-react-components/atoms'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Maybe } from 'monet'
import contains from 'ramda/src/contains'
import propOr from 'ramda/src/propOr'
import React, {
  ChangeEvent,
  FC,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

import { SaveMySystemModalFragment } from '../../../graphql'
import useCookieChange from '../../hooks/useCookieChange'
import { handleBrazeTrackingEvent } from '../../tracking/braze'
import {
  COOKIE_LEAD_DATA,
  cookies,
  getLeadData
} from '../../tracking/cookies'
import { useOptimizelyTrackSiteEvents } from '../../tracking/optimizely'
import { trackSubmitLeadEvent } from '../../util/analytics'
import { nullToUndefined, toButton } from '../../util/helper'

export type SaveMySystemModalProps = {
  readonly data: SaveMySystemModalFragment
}

const replaceEmailAddressPlaceholder = (json: Document, emailAddress: string): Document => ({
  ...json,
  content: json.content.map(parentNode => ({
    ...parentNode,
    content: parentNode.content.map(childNode => childNode.nodeType === 'text' ? {
      ...childNode,
      value: childNode.value.replace(/{{email_address}}/g, emailAddress)
    } : childNode)
  }))
})

const mapLineItemToRequestBody = (item: MiniCartLineItem) => ({
  quantity: item.quantity,
  sku: item.sku
})

const SaveMySystemModalComponent: FC<SaveMySystemModalProps> = ({ data }: SaveMySystemModalProps) => {
  const leadDataCookie = getLeadData()
  const [ isOpen, setIsOpen ] = useState(false)
  const [ emailAddress, setEmailAddress ] = useState(propOr('', 'email', leadDataCookie))
  const [ isEmailSent, setIsEmailSent ] = useState(false)
  const [ inputError, setInputError ] = useState('')
  const [ isLoading, setIsLoading ] = useState(false)
  const {
    openModalButton, emailTextbox, headerText, image, description, saveMySystemButton, disclaimerNote, successMessage
  } = data

  const promoCode = useSelector(selectActivePromoCode)
  useCookieChange(COOKIE_LEAD_DATA, data => setEmailAddress(propOr('', 'email', JSON.parse(data))))
  const lineItems = useSelector(selectMiniCartLineItems)
  const dispatch = useDispatch()
  const { trackEvent } = useTracking()
  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()

  const dependentProduct = prop('dependentProduct', data) || []
  const inputHandleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailAddress(e.target.value)
  }
  const handleSubmit = () => {
    // These validation keys are defined in Contentful
    const defaultValidations = {
      'Invalid Input': 'Please enter a valid email address.',
      'Required Field': 'Please enter a valid email address.'
    }
    const validations = (emailTextbox && emailTextbox.fieldValidation || []).reduce((obj, validation) => {
      return validation && validation.requirement && validation.errorMessage ? {
        ...obj,
        [validation.requirement]: validation.errorMessage
      } : obj
    }, defaultValidations)

    const leadBody: LeadGenCaptureParams = {
      email: emailAddress,
      leadSource: 'exit_intent',
      promoCode: promoCode.getOrElse('NO_CODE'),
      source: 'bms quote',
      sourceType: 'save_system'
    }

    const handleLeadError = () => {
      setIsLoading(false)
      setInputError(validations['Invalid Input'])
      optimizelyTrackSiteEvents({ eventType: 'website_error' })
    }

    const handleLeadSuccess = (e: Maybe<LeadGenCaptureResponse>) => {
      setIsLoading(false)
      cookies.set(COOKIE_LEAD_DATA, e.orUndefined(), cookiesOption)
      handleBrazeTrackingEvent(e.orUndefined())
      const externalId = e.chain(safeProp('externalId')).orJust('')
      const leadId = e.chain(safeProp('leadId')).orJust('')
      const saveMySystemBody = {
        email: emailAddress,
        externalId: externalId,
        leadId: leadId,
        products: lineItems.map(mapLineItemToRequestBody)
      }

      // Don't wait for success/failure of IOSaveMySystem call, and immediately assume email is sent to reduce UI loading time
      dispatch(IOSaveMySystem(saveMySystemBody, () => null, () => null))
      setIsEmailSent(true)
      optimizelyTrackSiteEvents({ eventType: 'saved_system' })
      optimizelyTrackSiteEvents({ eventType: 'lead_captured_fs' })
      trackSubmitLeadEvent(trackEvent)
    }

    const sendLeadsRequest = () => {
      setIsLoading(true)
      leadGenCapture(leadBody)(handleLeadError)(handleLeadSuccess)
    }

    emailAddress === '' ? setInputError(validations['Required Field']) : sendLeadsRequest()
  }

  const isOnlyDependentProduct = lineItems.find(item => item && !contains({ 'sku':item.masterSku }, dependentProduct))
  const floatingButton =  isOnlyDependentProduct && openModalButton && <div
    key={'floating-button-container'}
    style={{
      backgroundColor: '#fbfbf9',
      boxShadow: '-2px 2px 6px 0 rgba(0,0,0,.18)',
      margin: '15px 20px',
      position: 'fixed',
      right: '10px',
      top: '170px',
      zIndex: 5,
    }}>
    <SSButton {...toButton(openModalButton)}
      className={'m0'} // sets the margin to 0
      key={'floating-button'}
      onClick={() => setIsOpen(true)} />
  </div>

  const submissionForm = <div>
    {emailTextbox && <div style={{ margin: '16px 0' }}>
      <SSInput error={inputError !== ''}
        errorMsg={inputError}
        id={emailTextbox.id}
        label={emailTextbox.title}
        onChange={inputHandleChange}
        placeholder={nullToUndefined(emailTextbox.placeholderText)}
        type="email"
        value={emailAddress}/>
    </div>}
    {saveMySystemButton && <SSButton {...toButton(saveMySystemButton)}
      onClick={handleSubmit} />}
    <Text textSize="sm">
      {disclaimerNote && <RichText json={disclaimerNote.json} />}
    </Text>
  </div>

  const successView = successMessage ? <>
    <div style={{ margin: '16px 0' }}>
      <RichText json={replaceEmailAddressPlaceholder(successMessage.json, emailAddress)} />
    </div>
  </> : null

  const loading = <div
    className="m2_t"
    style={{
      display: 'flex',
      justifyContent: 'center'
    }}>
    <LoadingSpinner />
  </div>

  const modalImgFluid = image && path([ 'fluid' ], image)
  const modal = <Modal appElementId='___gatsby'
    isOpen={isOpen}
    onRequestClose={() => setIsOpen(false)}
    style={{ content: { width: '640px' } }}>
    {modalImgFluid && <Img fluid={modalImgFluid} />}
    <div style={{ padding: '16px 64px 50px 64px' }}>
      <Text>
        <h3>{headerText}</h3>
        {description && <RichText json={description.json} />}
      </Text>
      { !isEmailSent ? isLoading ? loading : submissionForm : successView }
    </div>
  </Modal>

  return (
    <section key={'save-my-system-modal-container'}>
      {floatingButton}
      {modal}
    </section>
  )
}

export default SaveMySystemModalComponent

export const SaveMySystemModalQuery = graphql`#graphql
  fragment saveMySystemModal on ContentfulModalSaveYourSystem {
    id
    internal {
      type
    }
    image {
      fluid(maxWidth: 768) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    headerText
    description {
      json
    }
    dependentProduct{
      sku
    }
    emailTextbox {
      ... on ContentfulForms {
        id
        title
        placeholderText
        fieldValidation {
          requirement
          errorMessage
        }
      }
    }
    saveMySystemButton {
      ... on ContentfulButton {
        ...contentfulButtonFragment
      }
    }
    successMessage {
      json
    }
    disclaimerNote {
      json
    }
    openModalButton {
      ... on ContentfulButton {
        ...contentfulButtonFragment
        buttonColor
        textColor
      }
    }
  }
`
