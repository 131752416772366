import React, { useCallback } from 'react'

import { cookies } from '../tracking/cookies'

// value is string because cookies.onChangeListener doesn't auto-parse value like cookies.get does
// https://github.com/reactivestack/cookies/issues/302
const useCookieChange = (cookieName: string, onChangeCookie: (value: string) => void) => {
  const onChange = useCallback(({ name, value }) => {
    (name === cookieName) ? onChangeCookie(value) : undefined
  }, [ cookieName, onChangeCookie ])

  React.useEffect(() => {
    cookies.addChangeListener(onChange)
    return () => cookies.removeChangeListener(onChange)
  }, [ onChange ])

  return { onChange }
}

export default useCookieChange
