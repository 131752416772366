import { FormField, Text } from '@simplisafe/ss-react-components/atoms'
import React, { ReactElement } from 'react'

type ContactFormSuccessMessageProps = {
  readonly successMessageDescription?: ReactElement
  readonly successMessageTitle?: string
}

const SuccessMessage: React.FC<ContactFormSuccessMessageProps> = (props: ContactFormSuccessMessageProps) => {
  const successMessageTitle = props.successMessageTitle
  return (
    <FormField hideLabel={true} name='contactFormSuccessMessage'>
      {
        successMessageTitle &&
        <Text fontWeight='light'>
          <h3>{successMessageTitle}</h3>
        </Text>
      }
      {props.successMessageDescription}
    </FormField>
  )
}

export default SuccessMessage
