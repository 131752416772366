import { liveChatScriptUrl } from '@simplisafe/ss-ecomm-data/thirdparty/livechat'
import { window } from 'browser-monads-ts'
import React from 'react'

import useLiveChatAvailability from '../../hooks/useLiveChatAvailability'
import useLiveChatTracking from '../../hooks/useLiveChatTracking'
import useScript from '../../hooks/useScript'
import { getChatAppId } from '../../tracking/cookies'

type LiveChatProps = {
  readonly appId?: string
}

// The underlying embed code. Loads the Sprinklr LiveChat script to the document
// after defining its settings on the window object.
const LiveChatWidget = ({ appId }: LiveChatProps) => {
  const liveChatAppIdCookie: string = getChatAppId()
  const liveChatAppId = appId || '610bd23d07ea1e23ca931dd8_app_979960'

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  window['sprChatSettings'] = window['sprChatSettings'] || {}
  window['sprChatSettings']['appId'] = liveChatAppIdCookie || liveChatAppId

  const status = useScript(liveChatScriptUrl)

  useLiveChatTracking(status)
  return null
}

// Wrapping code that determines if we should enable the livechat widget via
// optimizely, and if agents are available.
const LiveChat = ({ appId }: LiveChatProps)  => {
  const isLiveChatEnabled: boolean = useLiveChatAvailability(appId)

  return isLiveChatEnabled ? <LiveChatWidget appId={appId} /> : null
}

export default LiveChat
