/* eslint-disable */
// @ts-nocheck
/**
 * 
 * 
 * 
 * This file needs to be replaced and rewritten from scratch. 
 * The types and code quality are hard to work with.
 * 
 * Do not make changes to this file.
 * 
 * Type checking and linting have been disabled.
 * 
 * 
 * 
 * 
 * 
 */

import type { Document } from '@contentful/rich-text-types'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import isNotEmpty from '@simplisafe/ewok/ramda-adjunct/isNotEmpty'
import {
  safeFind, safePath, safeProp
} from '@simplisafe/monda'
import {
  clearCartError, IOAddToCart,
  IORemoveFromCart, IOUpdateDiscountCodeToCart, IOUpdateQuantity
} from '@simplisafe/ss-ecomm-data/cart'
import {
  getCartDetails,
  ImmutableCart,
  LineItem
} from '@simplisafe/ss-ecomm-data/commercetools/cart'
import {
  selectCart, selectHiddenProductSkus, selectLocale, selectProduct, selectProducts
} from '@simplisafe/ss-ecomm-data/redux/select'
import { ImmutableState } from '@simplisafe/ss-ecomm-data/redux/state'
import { Text } from '@simplisafe/ss-react-components/atoms'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import {
  AffirmPromoMessage, BannerError, BannerLoading, CartMonitoringPlan, CustomerReviewCard, IconWithText, SmallBannerItem, SmallTextSection
} from '@simplisafe/ss-react-components/molecules'
import type { CartLineItemProps } from '@simplisafe/ss-react-components/molecules/CartLineItem'
import type { CartMonitoringPlanProps } from '@simplisafe/ss-react-components/molecules/CartMonitoringPlan'
import { CustomerReviewCardProps } from '@simplisafe/ss-react-components/molecules/CustomerReviewCard'
import { SmallTextSectionProps } from '@simplisafe/ss-react-components/molecules/SmallTextSection'
import { CartDetails } from '@simplisafe/ss-react-components/organisms'
import type { CartDetailsProps } from '@simplisafe/ss-react-components/organisms/CartDetails'
import { window } from 'browser-monads-ts'
import {
  graphql, Link, navigate
} from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import { get, set } from 'local-storage'
import { Maybe, None } from 'monet'
import always from 'ramda/src/always'
import applySpec from 'ramda/src/applySpec'
import defaultTo from 'ramda/src/defaultTo'
import equals from 'ramda/src/equals'
import F from 'ramda/src/F'
import ifElse from 'ramda/src/ifElse'
import isEmpty from 'ramda/src/isEmpty'
import length from 'ramda/src/length'
import map from 'ramda/src/map'
// TODO replace with ts-functional-pipe
// eslint-disable-next-line no-restricted-imports
import pipe from 'ramda/src/pipe'
import T from 'ramda/src/T'
import uniq from 'ramda/src/uniq'
import React, {
  FC, ReactElement, ReactNode, useCallback, useEffect, useMemo,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import { debounce, throttle } from 'throttle-debounce'
import Cookies from 'universal-cookie'

import {
  ContentfulAsset, ContentfulCartDetails, ContentfulProductCardAccessories,
  ContentfulProductInformation, ContentfulRichTextWithOptions, ContentfulSmallTextSection
} from '../../../graphql'
import {
  componentsNotInStock,
  CoreComponentsData,
  renderCartLineOutOfStockMessage,
  renderCoreComponentsCartMessage,
  renderCoreComponentsNotInStockMsg,
  systemCoreComponents
} from '../../commercetools/outOfStock'
import { formatDisplayPrice } from '../../commercetools/price'
import { ContentfulComponent } from '../../componentMappings'
import useUtmCode from '../../hooks/useUtmCode'
import { outdoorCamCrm } from '../../hooks/useUtmContent'
import { useOptimizelyAffirm, useOptimizelyTrackSiteEvents } from '../../tracking/optimizely'
import type { AffirmClient } from '../../types/affirm'
import { trackAddToCartEvent } from '../../util/analytics/addToCart'
import { trackLoadCartEvent } from '../../util/analytics/loadCart'
import { trackRemoveCartEvent } from '../../util/analytics/removeFromCart'
import getDescriptionJson from '../../util/getDescriptionJson'
import {
  leadingSlashIt,
  nullToUndefined, strToCamelCase, toButton
} from '../../util/helper'
import { params } from '../../util/queryParams'
import { renderComponentFromData } from '../../util/render'
import { USER_DISCOUNT_CODE } from '../GlobalPromotionalComponent'
import RichText from '../RichText'
import RichTextWithOptionsComponent from '../RichTextWithOptionsComponent'
import CartLineItemDescription from './CartLineItemDescription'
import {
  getCartDiscountCode, getCartDiscountValue, getLocalizedCartSubtotal,
  toDiscountApplied, toItemList
} from './transformLineItem'

const cookies = new Cookies()
const SHIELD_SKU = 'CMOB1'

type CartDetailsComponentProps = {
  readonly affirmClient?: AffirmClient
  readonly data: Partial<ContentfulCartDetails>
}

type FileTypeProp = {
  readonly file: {
    readonly fileName: string
    readonly url: string
  }
  readonly title: string
}

type bannerDataProps = {
  readonly backgroundColor: {
    readonly color: string
  }
  readonly childContentfulIconWithTextTextRichTextNode: {
    readonly json: Document
  }
  readonly icon: FileTypeProp
  readonly iconPosition: string
  readonly textAlignment: string
  readonly textPosition: string
  readonly title: string
}

const renderCartMiniBanner = (data: readonly bannerDataProps[]) => {
  // TODO
  // @ts-ignore data is typed as an array, but it's being used here as an object
  const bannerDetails = (path([ 'miniBanner', 'sectionContent' ], data) as readonly bannerDataProps[])

  return Maybe.fromNull(bannerDetails).map(banners => banners.map((bannerdata, i) => (
    <SmallBannerItem
      icon={renderImgIcon(bannerdata)}
      key={`bannerId-${i}`}
      text={<RichText json={path([ 'childContentfulIconWithTextTextRichTextNode', 'json' ], bannerdata) as Document} />}
    />
  )))
    .orUndefined()
}

const toBackgroundColor =
  (data: Partial<ContentfulSmallTextSection>) =>
    safeProp('backgroundColor', data)
      .map(strToCamelCase)
      .getOrElse('none')

const toSmallTextSectionData = applySpec<SmallTextSectionProps>({
  backgroundColor: toBackgroundColor,
  description: getDescriptionJson,
  listStyle: pipe(prop('listStyle'), nullToUndefined),
  sidePadding: pipe(prop('sidePadding'), nullToUndefined),
  verticalPadding: pipe(prop('verticalPadding'), nullToUndefined),
})

const renderGuaranteeCard = (data: CartDetailsProps) => {
  const guaranteeCardSection = defaultTo({})(prop('guaranteeCard', data))

  // TODO: fix type
  // @ts-ignore
  return Maybe.fromNull(prop('description', guaranteeCardSection)).map(() => <SmallTextSection
    key={'cartGuaranteeCard'}
    {...toSmallTextSectionData(guaranteeCardSection)}
  />)
    .orUndefined()
}

const toCustomerImg = (data: CustomerReviewCardProps) => {
  return <Img alt={path([ 'customerNameImage', 'icon', 'title' ], data)}
    fluid={path([ 'customerNameImage', 'icon', 'fluid' ], data)}
    imgStyle={{ objectFit: 'contain' }}
    key={path([ 'customerNameImage', 'icon', 'id' ], data)}
  />
}

const renderCustomerNameAndImg = (data: CustomerReviewCardProps) => <IconWithText icon={toCustomerImg(data)}
  iconSize='large'
  iconVerticalAlignment="top"
  text={<RichText json={path([ 'customerNameImage', 'text', 'json' ], data) as Document} />}
/> as ReactElement


const renderCustomerReview = (data: CustomerReviewCardProps) => <RichText json={path([ 'comment', 'json' ], data) as Document} />

const renderCustomerReviewCard = (data: ContentfulCartDetails) => {
  // TODO: fix type
  // @ts-ignore
  return Maybe.fromNull(prop('comment', data)).map(() => <CustomerReviewCard
    key={'cartCustomerReviewCard'}
    {...applySpec<CustomerReviewCardProps>({
      content: renderCustomerReview,
      title: renderCustomerNameAndImg
    })(data)} />)
    .orUndefined()
}

const renderContent = (data: CartMonitoringPlanProps) => <RichText json={path([ 'descriptionImage', 'text', 'json' ], data) as Document} /> as ReactElement

const renderIcon = (data: CartMonitoringPlanProps) => <Img alt={path([ 'descriptionImage', 'title' ], data)}
  fluid={path([ 'descriptionImage', 'icon', 'fluid' ], data) as FluidObject}
  style={{ width: '100px' }} />

// TODO this needs a type defined for the object this expects to take in
const toCartMonitoringPlanDetails = applySpec<CartMonitoringPlanProps>({
  buttonProps: path([ 'button' ]),
  buttonText: path([ 'button', 'text' ]),
  icon: renderIcon,
  text: renderContent,
  title: path([ 'descriptionImage', 'title' ])
})

// TODO this should be exported and unit tested
const renderCartMonitoringPlan = (data: Partial<ContentfulCartDetails>, onAddToCart: () => void, showCartMonitoringPlan: boolean) => {
  const cartMonitoringPlanDetails = path([ 'freeMonitoring' ], data) as readonly bannerDataProps[]

  const cartMonitoringPlanDetailsProps = toCartMonitoringPlanDetails(cartMonitoringPlanDetails)
  return showCartMonitoringPlan && prop('buttonText', cartMonitoringPlanDetailsProps) && <CartMonitoringPlan {...cartMonitoringPlanDetailsProps}
    onAddToCart={onAddToCart} />
}

const renderCreditCardLogoImg = (data: Partial<ContentfulCartDetails>) => {
  const creditCardLogo = prop('creditCardLogo', data)
  return map(toFormImg)(creditCardLogo as readonly ContentfulAsset[])
}

const renderAffirmMessage = (data: Partial<ContentfulCartDetails>) => {
  return path([ 'affirmMessage', 'json' ], data) as Document
}


const toFormImg = (data: Partial<ContentfulCartDetails>) =>
// TODO: fix type
// @ts-ignore
  <Img alt={prop('title', data) as string}
    // TODO: fix type
    // @ts-ignore
    fluid={prop('fluid', data) as FluidObject}
    imgStyle={{ objectFit: 'contain' }}
    key={prop('id', data)}
    style={{
      height: '25px',
      width: '45px'
    }} />


const renderCouponData = (data: Partial<ContentfulCartDetails>, isMobile: boolean) =>
  ifElse(equals(true),
    always(path([ 'mobileCouponSection', 'json' ], data)),
    always(''))(isMobile)

const isShieldWarningOnLocalStorage = () => equals(get(params.content), outdoorCamCrm)

// TODO this should be exported and unit tested
// TODO switch this from applySpec to transformObject
const toContentfulCartDetails = (data: Partial<ContentfulCartDetails>, onAddToCart: () => void, showCartMonitoringPlan: boolean, isMobile: boolean, shieldIsInCart: boolean, productWarningMessage: Maybe<ContentfulProductInformation>) => {
  // If there's a Shield in cart and not system, show Shield specific No System message if it is set in Contentful
  const shieldWithoutSystemMessage = safePath([ 'shieldWithoutSystemMessage', 'json' ], data).getOrElse('')

  const showShieldWithoutSystemWarning: boolean = (shieldIsInCart && isNotEmpty(shieldWithoutSystemMessage) && !isShieldWarningOnLocalStorage())

  const withoutSystemPath: string = showShieldWithoutSystemWarning ? 'shieldWithoutSystemMessage' : 'withoutSystemMessage'

  const warningMessage = productWarningMessage
    .chain(safePath([ 'description', 'json' ]))
    .orJust(path([ withoutSystemPath, 'json' ], data))

  return applySpec<CartDetailsProps>({
    affirmMessage: renderAffirmMessage,
    applyCouponCode: (data) => renderCouponData(data, isMobile),
    belowCartContentsSection: (data: Partial<ContentfulCartDetails>) => renderComponentFromData(prop('belowCartContent', data)),
    cartMiniBanner: renderCartMiniBanner,
    cartMonitoringPlan: (data) => renderCartMonitoringPlan(data, onAddToCart, showCartMonitoringPlan),
    checkOutButtonFooter: pipe(prop('checkoutButtonFooter'), toButton),
    checkOutButtonHeader: pipe(prop('checkoutButtonHeader'), toButton),
    creditCardLogo: renderCreditCardLogoImg,
    customerReviewCard: (data) => renderCustomerReviewCard(prop('customerReview', data)),
    emptyCartMessage: path([ 'emptyCartMessage', 'json' ]),
    guaranteeCard: renderGuaranteeCard,
    guaranteeNote: path([ 'guaranteeNote', 'json' ]),
    headerText: path([ 'miniBanner', 'headerText' ]),
    linkHeader: prop('linkHeader'),
    mobileLink: data =>
      safeProp('linkHeader', data)
        .chain(linkData =>
          safeProp('linkText', linkData)
            .chain(linkText =>
              safeProp('linkUrl', linkData)
                .map(linkUrl => <Link key={`mobile-link-${linkText}`}
                  to={leadingSlashIt(linkUrl)}>{linkText}</Link>)))
        .orUndefined(),
    partnerCaptureForm: data => {
      // TODO: fix betterer errors
      // eslint-disable-next-line
      const components: ContentfulComponent[] = safeProp('partnerCaptureModal', data).getOrElse([])
      return components.map((component: ContentfulComponent) => renderComponentFromData(component))
    },
    subTotalContent: path([ 'subtotal', 'json' ]),
    titleHeader: prop('titleHeader'),
    withoutSystemMessage: always(warningMessage)
  })(data)
}

// checks if only refurbished system is in cart,
// if any other package is in cart, it returns false
export const onlyRefurbishedSystemIsInCart = (items: readonly CartLineItemProps[]): boolean => {
  // return an array of all systems in cart
  const allSystems = items.filter(item => safeProp('subItems', item).getOrElse([]).length > 0)
  // returns an array of all refurbished systems in cart
  const refurbishedSystems = allSystems.filter(item => safeProp('sku', item).getOrElse('')
    .includes('ss3-refurbished'))
  return (refurbishedSystems.length > 0 && refurbishedSystems.length === allSystems.length)
}

const toCoreComponentsOosProps = (coreComponentsNotInStockList: readonly CoreComponentsData[], systemInCart: boolean, items: readonly CartLineItemProps[]) => {
  const showCoreComponentsMessage = (coreComponentsNotInStockList.length > 0 && systemInCart && !onlyRefurbishedSystemIsInCart(items))
  return showCoreComponentsMessage ? {
    coreComponentNotInStock: showCoreComponentsMessage,
    coreComponentShipEstimate: renderCoreComponentsNotInStockMsg(coreComponentsNotInStockList, false),
    coreComponentsShipDelayText: renderCoreComponentsCartMessage(coreComponentsNotInStockList)
  } : { coreComponentNotInStock: false }
}

const renderImgIcon = (bannerData: bannerDataProps) => {
  return <Img alt={path([ 'icon', 'title' ], bannerData) as string}
    fluid={path([ 'icon', 'fluid' ], bannerData) as FluidObject} // TODO fluid could be undefined making this prop invalid
    style={{ width: '70px' }} />
}

export const isPackage = (lineItem: LineItem): boolean =>
  prop('productType', lineItem) === 'package_parent'

export const isCartQualifiedFreeShipping = (cart: ImmutableCart): boolean =>
  safeProp('lineItems', cart)
    .map(lineItems => lineItems.some(isPackage))
    .orJust(false)

export const isCartQualifiedFreeShippingUS = (cart: ImmutableCart): boolean =>
  isCartQualifiedFreeShipping(cart)

export const isCartQualifiedFreeShippingUK = (cart: ImmutableCart): boolean =>
  isCartQualifiedFreeShipping(cart) || prop('totalPrice', cart) > 50

export const getFreeShippingItem = (shippingText: string, cart: ImmutableCart, siteLocale: string): CartLineItemProps => {
  const hasFreeShippingText = ifElse(
    equals('en-GB'),
    () => isCartQualifiedFreeShippingUK(cart),
    () => isCartQualifiedFreeShippingUS(cart),
  )

  const freeShippingItem: CartLineItemProps = {
    isHighlightedLineItem: true,
    itemDescriptionContent: siteLocale === 'en-US' ? <p>You may select a different shipping method at checkout.</p> : undefined,
    itemName: shippingText,
    price: formatDisplayPrice(0).orUndefined()
  }

  return hasFreeShippingText(siteLocale) ? freeShippingItem : { itemName: '' }
}

export const renderCart = (prop: CartDetailsProps) => <CartDetails {...prop} />

export const shouldShowMonitoringPlanWidget = (monitoringSku: string) => (cart: ImmutableCart): boolean => {
  const hasLineItems = !!length(cart.lineItems)
  const cartHasSystem = !!cart.isThereAnySecurity
  const cartHasMonitoringPlan = safeProp('lineItems', cart)
    .map(lineItems => lineItems.some(item => item.sku === monitoringSku))
    .orJust(false)

  return hasLineItems && cartHasSystem && !cartHasMonitoringPlan
}

// Gets a number of shield products in cart
const shieldQuantityInCart = (lineItems: readonly LineItem[]) => {
  // TODO: product quantity limit should be added in product data
  return lineItems.filter(lineItem => safeProp('sku', lineItem).getOrElse('')
    .toUpperCase() === SHIELD_SKU)
    .reduce((prev, cur) => prev + cur.quantity, 0)
}

/**
 * Find the maximum number of a particular product allowed in a cart by SKU.
 * @param sku The product SKU
 * @param productMaxQuantity Product configuration data
 */
const getMaxQuantityBySku = (sku: string, productMaxQuantity?: readonly (ContentfulProductCardAccessories | null)[]): number => {
  const product = productMaxQuantity
    .filter(product => product)
    .find(product => prop('productId', product) === sku)

  return Maybe.fromUndefined(product)
    .chain(safeProp('maximumQuantity'))
    .orJust(0)
}

const getShieldMaxQuantity = (productMaxQuantity: readonly (ContentfulProductCardAccessories | null)[]): number => getMaxQuantityBySku(SHIELD_SKU, productMaxQuantity)

/** Filter out Products from lineItmes that do not have cameras
* outdoor camera: CMOB1, indoor camera: SSCM2, video doorbell: SSDB3
*/
// TODO: set tags for sensors with cameras in Commercetools, remove hardcoded filters
const sensorWithCamera = [ SHIELD_SKU, 'SSCM2', 'SSDB3' ]
const cameraSensorQuantityInCart = (lineItems: readonly LineItem[]) => {
  // TODO: product quantity limit should be added in product data
  return lineItems.map(quantity =>
    quantity)
    .filter(product => sensorWithCamera.includes(prop('sku', product)))
    .reduce((prev, cur) => prev + cur.quantity, 0)
}

const toRichTextWithOptionsComponent = (data: ContentfulRichTextWithOptions) =>
  <RichTextWithOptionsComponent data={data} />

const productIsInCart = (items: readonly CartLineItemProps[], productSku: string) => {
  return isNotEmpty(items.filter(item => safeProp('sku', item).getOrElse('')
    .toUpperCase() === productSku))
}

const isInteractiveMonitoringInCart = (items: readonly CartLineItemProps[], siteLocale: string) => productIsInCart(items, siteLocale === 'en-US' ? 'SSEDSM2__4867366' : 'SSEDSM2_GB__5229044')

// Sensors with cameras warning message
// TODO: this should not be hard-coded
// temporary solution for the Shield launch
const renderSensorWarningMessage = (productMaxQuantity: readonly (ContentfulProductCardAccessories | null)[], cameraSensorQuantity: number, shieldQuantity: number, items: readonly CartLineItemProps[], siteLocale: string) => {
  const interactiveMonitoringInCart: boolean = isInteractiveMonitoringInCart(items, siteLocale)
  const selfMonitoringInCart: boolean = productIsInCart(items, 'SSSLFCAM__7711192')

  // TODO: eventually quantity limit information will be coming from Commercetools and will not need to be hard-coded
  const maxCameraSensors = 10
  const maxShieldQuantity: number = getShieldMaxQuantity(productMaxQuantity)
  const cameraSensorLimit: number = (interactiveMonitoringInCart) ? maxCameraSensors : 5
  const showShieldWarning = shieldQuantity && shieldQuantity > maxShieldQuantity
  const showCameraSensorWarning: boolean = (interactiveMonitoringInCart || selfMonitoringInCart) && cameraSensorQuantity > cameraSensorLimit

  return (showShieldWarning || showCameraSensorWarning) ? [
    showShieldWarning && <Text key={'outdoor-camera-warning'}>{`Max number of outdoor security cameras allowed per user is ${maxShieldQuantity}.`}</Text>,
    (showCameraSensorWarning) ? (
      (selfMonitoringInCart) ?
        <Text key={'self-monitoring-warning'}>
          Heads up! You can only get security recordings on {cameraSensorLimit} cameras. Upgrade to 24/7 professional monitoring to get recordings on up to {maxCameraSensors} cameras.
          {<Link to={'/choose-monitoring'}>CHANGE PLAN</Link>}
        </Text>
        : <Text key={'interactive-monitoring-warning'}>Heads up! You can only get security recordings on {cameraSensorLimit} cameras.</Text>
    ) : undefined
  ] : undefined
}

const navigateLink = (url: string, parentId: string, sku: string) => {
  Maybe.fromFalsy(url === '/change-monitoring').forEach(() => set('parentId', parentId))
  navigate(url, { state: { packageSku: sku } })
}

const CartDetailsComponent: FC<CartDetailsComponentProps> =
  ({ affirmClient = window.affirm, data }: CartDetailsComponentProps) => {
    const siteLocale = useSelector(selectLocale)
    const isMobile = !useMediaQuery('TabletAndUp')
    // TODO this can just be an array instead of a Maybe
    const [ lineItem, setLineItems ] = useState(None<readonly CartLineItemProps[]>())
    const [ totalPrice, setTotalPrice ] = useState(None<string>())
    const [ shieldQuantity, setShieldQuantity ] = useState(0)
    const [ cameraSensorQuantity, setCameraSensorQuantity ] = useState(0)
    const [ showApplyCoupon, setShowApplyCoupon ] = useState(false)
    const [ showApplyCouponError, setShowApplyCouponError ] = useState<boolean | undefined>(undefined)
    const dispatch = useDispatch()
    const cart = useSelector(selectCart)
    const hiddenProductSkus = useSelector(selectHiddenProductSkus)
    const [ isMounted, setMounted ] = useState(false)
    const productMaxQuantity = useMemo(() => prop('productMaxQuantity', data) || [], [ data ])
    const shieldMaxQuantity = getShieldMaxQuantity(productMaxQuantity)
    const subItemsSkusRepeated = lineItem.orJust([]).reduce((acc: readonly string[], item) => acc.concat((item?.subItems || []).map(subItem => subItem?.subItemSku || '')), [])
    const subItemsSkus = uniq<string>(subItemsSkusRepeated)
    const itemsSkusRepeated = lineItem.orJust([]).map(item => item.sku || '')
    const itemsSkus = uniq<string>(itemsSkusRepeated)
    const subItemsProducts = useSelector(selectProducts(subItemsSkus))
    const itemsProducts = useSelector(selectProducts(itemsSkus))

    const freeMonitoringSku = safePath([ 'freeMonitoring', 'productId' ], data)

    const { Track, trackEvent } = useTracking()
    const shippingText = defaultTo('')(prop('shippingText', data))
    const productReference = defaultTo([])(prop('productReference', data))

    const freeMonitoringDetails = useSelector((state: ImmutableState) =>
      freeMonitoringSku.chain(sku => selectProduct(sku)(state).toMaybe())
    ).toEither(Error('Unable to obtain free monitoring product details'))

    const coreComponentsProducts = useSelector(selectProducts(systemCoreComponents))

    const coreComponentsNotInStockList = useMemo(() => componentsNotInStock(coreComponentsProducts), [ coreComponentsProducts ])

    const liftFreeMonitoringSku = useMemo(() => freeMonitoringSku.map(shouldShowMonitoringPlanWidget), [ freeMonitoringSku ])

    const productMessageWithoutSystemData = safeProp('productMessageWithoutSystem', data) as Maybe<readonly ContentfulProductInformation[]>

    const [ productWarningMessage, setProductWarningMessage ] = useState(None<ContentfulProductInformation>())

    const optimizelyAffirm = useOptimizelyAffirm()
    const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()

    /**
     * If there is an error, or the monitoring plan should not render, this is false.
     *
     * If the `.apTo` is new to you, you can read more about applicative functors here: https://mostly-adequate.gitbook.io/mostly-adequate-guide/ch10
     **/
    const [ showCartMonitoringPlan, setShowCartMonitoringPlan ] = useState(liftFreeMonitoringSku.apTo(cart.toMaybe()).cata(F, (shouldShowMonitoringPlanWidget) => shouldShowMonitoringPlanWidget))

    const onUpdateQuantity = debounce(500, (lineItemId: string, quantity: number) => {
      dispatch(IOUpdateQuantity(lineItemId, quantity))
    })

    const onRemoveProduct = (lineItems: readonly LineItem[]) => throttle(500, (lineItem: LineItem) => {
      const lineItemId = prop('lineItemId', lineItem)
      const handleSuccess = () => {
        trackRemoveCartEvent(lineItem, trackEvent, lineItems)
      }
      dispatch(IORemoveFromCart(lineItemId, undefined, handleSuccess))
    })

    /** handle add to cart in cart monitaoring right pane section */
    const onAddToCart = useCallback(() => {
      const handleSuccess = () => {
        optimizelyTrackSiteEvents({ eventType: 'add_to_cart_clicked' })
        trackAddToCartEvent(freeMonitoringDetails, trackEvent, 1)
      }

      freeMonitoringSku.forEach(sku => dispatch(IOAddToCart({
        products: [ {
          quantity: 1,
          sku
        } ]
      }, undefined, handleSuccess)))
    }, [ dispatch, freeMonitoringDetails, freeMonitoringSku, trackEvent, optimizelyTrackSiteEvents ])

    useEffect(() => {
      // TODO this function needs to be abstracted, exported, and have unit tests
      cart.forEach(_cart => {
        const cartItems = toItemList(getCartDetails(hiddenProductSkus)(_cart.lineItems))(onUpdateQuantity, onRemoveProduct(_cart.lineItems))
        const hasLineItems = !!length(_cart.lineItems)

        // set total qty of sensors with cameras
        setCameraSensorQuantity(cameraSensorQuantityInCart(_cart.lineItems))

        // Check if shield is in cart and shield quantity state
        hasLineItems && setShieldQuantity(shieldQuantityInCart(_cart.lineItems))
        hasLineItems && !_cart.isThereAnySecurity && setProductWarningMessage(
          productMessageWithoutSystemData
            .chain(data => safeFind(p => _cart.lineItems.some(l => equals(l.sku, p.productId)), data))
        )


        setLineItems(cartItems)
        setTotalPrice(getLocalizedCartSubtotal(_cart))

        setShowCartMonitoringPlan(liftFreeMonitoringSku.apTo(cart.toMaybe()).cata(F, (shouldShowMonitoringPlanWidget) => shouldShowMonitoringPlanWidget))

        // Send Custom Event for eec.details when first page load.
        ifElse((_isMounted) => {
          return !_isMounted && !isEmpty(_cart.lineItems)
        }, () => {
          trackLoadCartEvent(_cart.lineItems, trackEvent)
          setMounted(true)
        }, always(undefined))(isMounted)
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ cart, isMobile, dispatch ])

    const shieldIsInCart: boolean = (shieldQuantity > 0)

    const props = useMemo(() => toContentfulCartDetails(data, onAddToCart, showCartMonitoringPlan, isMobile, shieldIsInCart, productWarningMessage), [ data, onAddToCart, showCartMonitoringPlan, isMobile, shieldIsInCart, productWarningMessage ])

    /** if the user manually enters a promo code we want to remove the utm_code */
    const [ enableUtmCode, setEnableUtmCode ] = useState(true)
    useUtmCode(enableUtmCode)

    const getCartDetailsProps = useCallback((cart: ImmutableCart, cartIsUpdating: boolean): CartDetailsProps => {
      const standaloneProduct = prop('standaloneProduct', data) || []
      const discountApplied = Maybe.fromNull(getCartDiscountValue(cart))
        .map(toDiscountApplied(safeProp('discountAppliedText', data).getOrElse(''), getCartDiscountCode(cart)))
      // This is messier than ideal, but this disables the quantity changers if the cart is currently updating
      const items: readonly CartLineItemProps[] = lineItem.map((_lineItems) => {
        return _lineItems.map<CartLineItemProps>((lineItemProps, index) => {
          // TODO: fix type
          // @ts-ignore
          const parentId = defaultTo('')(prop('packageParentId', lineItemProps))
          const previousItemSku = Maybe.fromUndefined(_lineItems[index - 1])
            .chain(safeProp('sku'))
            .getOrElse('')

          const product = Maybe.fromNull(
            productReference.find(product => (product && prop('productId', product)) === prop('sku', lineItemProps))
          )

          const getQuantityProp = (quantity) =>
          {
            const maxQuantity = productMaxQuantity.find(item => item && item.productId === prop('sku', lineItemProps))
            return {
              ...quantity,
              disabled: cartIsUpdating,
              max: Maybe.fromNull(maxQuantity).chain(safeProp('maximumQuantity'))
                .getOrElse(100)
            }
          }

          const description = product.chain(productInformation =>
            safeProp('productId', productInformation)
              .map(sku =>
                <CartLineItemDescription
                  key={sku}
                  onClick={(url: string) => navigateLink(url, parentId, previousItemSku)}
                  productInformation={productInformation}
                  sku={sku}
                />
              )
          ).orNull()

          const subItemsLineItemSkus = (lineItemProps.subItems || []).map(subItem => subItem.subItemSku)
          const itemsNotInStock = componentsNotInStock(itemsProducts.map(products => products.filter(product => safeProp('sku', product).getOrElse('') === prop('sku', lineItemProps))))
          const subItemsProductsNotInStock = componentsNotInStock(subItemsProducts.map(products => products.filter(product => subItemsLineItemSkus.includes(safeProp('sku', product).getOrElse('')))))
          const subItemsOOSMessage = subItemsProductsNotInStock.length > 0 && renderCartLineOutOfStockMessage(subItemsProductsNotInStock)
          const itemsOOSMessage = itemsNotInStock.length > 0 && renderCartLineOutOfStockMessage(itemsNotInStock)

          return ({
            ...lineItemProps,
            itemDescriptionContent: description,
            outOfStockMessage: subItemsOOSMessage || itemsOOSMessage,
            // quantity changer props
            quantity: Maybe.fromNull(lineItemProps.quantity)
              .map(getQuantityProp)
              .orUndefined()
          })
        })
      }).orJust([])

      const sensorWarningMessage: ReactNode | undefined = renderSensorWarningMessage(productMaxQuantity, cameraSensorQuantity, shieldQuantity, items, siteLocale)
      const disableCartSubmit: boolean = (shieldIsInCart && shieldQuantity > shieldMaxQuantity)
      const coreComponentsOosProps = toCoreComponentsOosProps(coreComponentsNotInStockList, cart.isThereAnySecurity, items)
      const freeShippingItem = getFreeShippingItem(shippingText, cart, siteLocale)
      const isHaveStandAloneProduct = items.find(item => {
        return standaloneProduct.find(product => product && product.sku === item.sku)
      })
      const isShowMessage = isHaveStandAloneProduct ?  !isHaveStandAloneProduct : !cart.isThereAnySecurity
      isShowMessage ? cookies.set('standAloneProduct', true) : cookies.set('standAloneProduct', false)
      const isShowProductMessage = productWarningMessage.cata(always(isShowMessage), T)
      const rawTotalPrice = cart.get('totalPrice')
      const affirmPromoMessage = <AffirmPromoMessage affirmClient={affirmClient}
        onLearnMoreClick={optimizelyAffirm}
        pageType={'cart'}
        price={rawTotalPrice}
        textAlignment={'right'}  />

      return {
        ...props,
        ...coreComponentsOosProps,
        affirmPromoMessage,
        disabledCartSubmit: disableCartSubmit,
        // Adding the shipping & discount info when the cart is not empty.
        itemList: items.length >  0 ? items.concat(freeShippingItem, discountApplied.toArray()) : items,
        quantityLimitMessage: sensorWarningMessage,
        showWarning: isShowProductMessage,
        subTotal: totalPrice.getOrElse('')
      }
    }, [ itemsProducts, subItemsProducts, affirmClient, data, lineItem, cameraSensorQuantity, shieldQuantity, siteLocale, shieldIsInCart, coreComponentsNotInStockList, shippingText, props, totalPrice, productReference, productWarningMessage, optimizelyAffirm, productMaxQuantity, shieldMaxQuantity ])

    const cartRetrievalErrorMessage = safeProp('cartRetrievalErrorMessage', data).map(data => toRichTextWithOptionsComponent(data as ContentfulRichTextWithOptions))
      .orNull()
    const cartLoadingMessage = safeProp('cartLoadingMessage', data).map(data => toRichTextWithOptionsComponent(data as ContentfulRichTextWithOptions))
      .orNull()

    const applyCouponCodeClick = useCallback((code: string) => {
      setEnableUtmCode(false)
      const showMessage = (isError: boolean) => () => {
        setShowApplyCouponError(isError)
        isError ? dispatch(clearCartError()) : set(USER_DISCOUNT_CODE, code)
      }

      // Resetting apply coupon form and its message display.
      setShowApplyCoupon(true)
      setShowApplyCouponError(undefined)

      isEmpty(code) ? showMessage(true) :
        dispatch(IOUpdateDiscountCodeToCart([ code ], showMessage(true), showMessage(false)))
    }, [ dispatch ])

    return (
      <Track>
        <>
          {cart.cata(
            () => {
              // Normally we would always use cart.cata(), but in this case we want to avoid showing the
              // loading view if the cart has a value and is just being updated, so the user doesn't see
              // a flicker when changing quantities
              return cart.map(
                _cart => renderCart({
                  ...getCartDetailsProps(_cart, true),
                  applyCouponCodeClick,
                  showApplyCoupon,
                  showApplyCouponError
                })
              ).orJust(
                // Loading state.
                <BannerLoading>{cartLoadingMessage}</BannerLoading>
              )
            },
            // Error state.
            () => <BannerError height="responsive">{cartRetrievalErrorMessage}</BannerError>,
            // Cart empty state. CartDetails renders an empty cart message under the hood if there is no cart.
            () => renderCart({
              ...props,
              applyCouponCodeClick,
              showApplyCoupon,
              showApplyCouponError
            }),
            _cart => renderCart({
              ...getCartDetailsProps(_cart, false),
              applyCouponCodeClick,
              showApplyCoupon,
              showApplyCouponError
            })
          )}
        </>
      </Track>
    )

  }

export const query = graphql`
  fragment cartDetailsInformation on ContentfulCartDetails{
    id
    internal {
    type
    }
    titleHeader
    shippingText
    discountAppliedText
    linkHeader {
      linkText
      linkUrl
    }
    cartRetrievalErrorMessage {
      ... on ContentfulRichTextWithOptions {
        ...richTextWithOptions
      }
    }
    cartLoadingMessage {
      ... on ContentfulRichTextWithOptions {
        ...richTextWithOptions
      }
    }
    checkoutButtonHeader {
      text
      type
      url
    }
    checkoutButtonFooter {
      text
      type
      url
    }
    guaranteeNote {
      json
    }
    creditCardLogo {
      id
      title
      fluid(maxWidth: 300) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    affirmMessage {
      json
    }
    subtotal {
      json
    }
    emptyCartMessage {
      json
    }
    withoutSystemMessage {
      json
    }
    shieldWithoutSystemMessage {
      json
    }
    productReference {
      productId
      description {
        json
      }
    }
    productMaxQuantity{
      maximumQuantity
      productId
    }
    standaloneProduct {
      sku
    }
    miniBanner: rightSection1 {
      ... on ContentfulDefaultBanner {
        headerText
        sectionContent {
          backgroundColor {
            color
          }
          childContentfulIconWithTextTextRichTextNode {
            json
          }
          icon {
            file {
              fileName
              url
            }
            title
            fluid(maxWidth: 300) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          iconPosition
          textPosition
          textAlignment
          title
        }
      }
    }
    guaranteeCard: rightSection1 {
      ... on ContentfulSmallTextSection {
        title
        listStyle
        backgroundColor
        description {
          json
        }
        sidePadding
        verticalPadding
      }
    }
    freeMonitoring: rightSection2 {
      ... on ContentfulCartFreeMonitoringPlanWidget {
        cartDetailsTitle
        cartDetailsPromoText
        cartDetailsDescription {
          json
        }
        button {
          text
          type
          url
        }
        descriptionImage {
          icon {
            title
            file {
              url
              fileName
            }
            fluid(maxWidth: 300) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          iconPosition
          title
          text {
            json
          }
        }
        title
        productId
      }
    }
    customerReview: rightSection2 {
      ... on ContentfulCustomerReviewCard {
        title
        comment {
          json
        }
        customerNameImage {
          title
          icon {
            title
            fluid(maxWidth: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          iconPosition
          iconSize
          iconVerticalAlignment
          textPosition
          textAlignment
          text {
            json
          }
        }
      }
    }
    mobileCouponSection {
      json
    }
    mobileValidCoupon {
      json
    }
    belowCartContent {
      ... on ContentfulConditionalContent {
          ...conditionalContent
        }
    }
    partnerCaptureModal {
      ... on ContentfulConditionalContent {
        ...conditionalContent
      }
    }
    productMessageWithoutSystem {
      productId
      description {
        json
      }
    }
  }
`

export default CartDetailsComponent
