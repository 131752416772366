import { graphql } from 'gatsby'
import  { createContext } from 'react'

import { SiteWideMessagesFragment } from '../../graphql'

/**
 * Used to make content from the Site-Wide Messages singleton content type available to descendant components.
 */
export const SiteWideMessagesContext = createContext<SiteWideMessagesFragment>({})

export const query = graphql`#graphql
  fragment siteWideMessages on ContentfulSiteWideMessages {
    addToCartError
    addToCartErrorUnrecoverable
  }
`
