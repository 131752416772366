import prop from '@simplisafe/ewok/ramda/prop'
import { safePath, safeProp } from '@simplisafe/monda'
import { SSButton } from '@simplisafe/ss-react-components/atoms'
import { MinWidthType } from '@simplisafe/ss-react-components/atoms/SSButton'
import { ChevronDownSm } from '@simplisafe/ss-react-components/icons'
import { graphql, Link } from 'gatsby'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import propOr from 'ramda/src/propOr'
import React, {
  CSSProperties, FC, MouseEventHandler
} from 'react'

import { ContentfulAsset, ContentfulButton } from '../../../graphql'
import { toButton } from '../../util/helper'
import FluidImg from '../FluidImg'

export type ContentfulButtonProps = {
  //todo data should be using a fragment here instead of a partial
  readonly data: Partial<ContentfulButton>
  readonly onClick?: MouseEventHandler<HTMLElement>
}

const imgStyle: CSSProperties = {
  height: '15px',
  marginLeft: '15px',
  width: '15px'
}

const toGatsbyImg = (data: ContentfulAsset) => {
  const fluid = prop('fluid', data)
  const title = prop('title', data)
  const key = prop('id', data)
  return <FluidImg alt={title}
    fluid={fluid}
    imgStyle={{
      marginLeft: '0',
      objectFit: 'contain'
    }}
    key={key}
    style={imgStyle}
  />
}

const isLocalUrl = (url?: string): boolean => !!url && url.startsWith('/')

const ButtonComponent: FC<ContentfulButtonProps> =
  ({ data, onClick }: ContentfulButtonProps) => {
    const buttonSize = propOr<MinWidthType, MinWidthType>('auto', 'buttonSize', data)
    const buttonProp = toButton(data)
    const icon = safeProp('icon', data)
      .chain(iconData =>
        safePath([ 'file', 'contentType' ], iconData)
          .map(ifElse(
            equals('image/svg+xml'),
            //todo should check the icon type
            () => <span style={imgStyle}><ChevronDownSm /></span>,
            () => toGatsbyImg(iconData)
          )))
      .orNull()

    const url = prop('url', data)
    const isLocal = isLocalUrl(url)

    const ssButton = (
      <SSButton minWidth={buttonSize}
        onClick={onClick}
        {...buttonProp}
        {...isLocal && { type: 'div' }}
      >
        {buttonProp.children}
        {icon}
      </SSButton>
    )

    return isLocal && url ? <Link to={url}>{ssButton}</Link> : ssButton
  }

export default ButtonComponent

export const query = graphql`#graphql
    fragment contentfulButtonFragment on ContentfulButton {
        id
        internal {
          type
        }
        addToCartButton
        buttonSize
        buttonText: text
        type
        url
        icon {
          id
          title
          file {
            url
            contentType
          }
          fluid(maxWidth: 20) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
    }
`
