import {
  FormField,
  FormTextInput,
  Text
} from '@simplisafe/ss-react-components/atoms'
import React from 'react'

  type NameProps = {
    readonly nameFieldLabel: string
    readonly nameFieldPlaceholder: string
  }

const Name: React.FC<NameProps> = ({ nameFieldLabel, nameFieldPlaceholder }: NameProps) => {
  return (
    <div data-component={'Name'} style={{ marginBottom: '32px' }}>
      <FormField hideLabel={true} label={nameFieldLabel}
        name='name'>
        <Text fontWeight='medium'>{nameFieldLabel}</Text>
        <FormTextInput fullWidth={true} name='name'
          placeholder={nameFieldPlaceholder} />
      </FormField>
    </div>
  )
}

export default Name
