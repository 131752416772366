import type { Document } from '@contentful/rich-text-types'
import { TrackingData } from '@simplisafe/ecomm-ts-types'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safePath, safeProp } from '@simplisafe/monda'
import {
  selectCart,
  selectLocale
} from '@simplisafe/ss-ecomm-data/redux/select'
import { ZuoraClient } from '@simplisafe/ss-ecomm-data/thirdparty/zuora'
import {
  Column, Row,
  SSRadio, Text
} from '@simplisafe/ss-react-components/atoms'
import { PlaceOrder } from '@simplisafe/ss-react-components/molecules'
import { window } from 'browser-monads-ts'
import { navigate } from 'gatsby'
import { Maybe, } from 'monet'
import concat from 'ramda/src/concat'
import propOr from 'ramda/src/propOr'
import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import Cookies from 'universal-cookie'

import { PaymentFormFragment } from '../../../graphql'
import { visitorIdAtAt } from '../../tracking/atat'
import type { AffirmClient } from '../../types/affirm'
import getDescriptionJson from '../../util/getDescriptionJson'
import getJson from '../../util/getJson'
import { leadingSlashIt } from '../../util/helper'
import FixedImg from '../FixedImg'
import RichText from '../RichText'
import RichTextWithOptionsComponent from '../RichTextWithOptionsComponent'
import AffirmCheckoutContent from './form-sections/AffirmCheckoutContent'
import InlineChasePaymentForm from './form-sections/InlineChasePaymentForm'
import InlineZuoraPaymentForm from './form-sections/InlineZuoraPaymentForm'
import PaymentFormBanner from './PaymentFormBanner'
import usePayment from './usePayment'
import useTrackPurchaseComplete from './useTrackPurchaseComplete'
import { getBillingAddress } from './utils/zuora'

const cookies = new Cookies()

type ContentfulPaymentFormProps = {
  readonly affirmClient?: AffirmClient
  readonly data: PaymentFormFragment
  readonly zuoraClient?: ZuoraClient
}

/** Value for the Affirm checkout radio button. This value is sent to tracking endpoints. */
const PAYMENT_OPTION_AFFIRM = 'affirm'
/** Value for the credit card checkout radio button. This value is sent to tracking endpoints. */
const PAYMENT_OPTION_CARD = 'card'
/** Maps the value for selected payment option from a string to an accepted TrackingData value */
const paymentOptionMapper: Record<string, TrackingData['selectedPaymentOption']> = {
  [PAYMENT_OPTION_AFFIRM]: PAYMENT_OPTION_AFFIRM,
  [PAYMENT_OPTION_CARD]: PAYMENT_OPTION_CARD
}

const ContentfulPaymentFormComponent = ({
  // allows passing in a different value for affirmClient in unit tests
  affirmClient = window.affirm,
  data,
  zuoraClient = window.Z
}: ContentfulPaymentFormProps) => {
  const paymentFormTitle = propOr('', 'title', data)
  const paymentErrorMessage = <RichText json={getDescriptionJson(prop('paymentErrorMessage', data))} />
  const cart = useSelector(selectCart)
  const locale = useSelector(selectLocale)
  const { Track, trackEvent } = useTracking({ appSection: 'paymentForm' })

  const {
    chaseErrorCodes,
    dataCollectorSrc,
    handleSubmitAffirmOrder,
    iframeSrc,
    paymentState,
    handleSubmitZuoraOrder,
    handleZuoraFormRender,
    zuoraPaymentMethod,
    creditPaymentExperience,
    safeTechSdkUrl
  } = usePayment(cart, cookies, trackEvent)

  const [ selectedPaymentOption, selectPaymentOption ] = useState<TrackingData['selectedPaymentOption']>(PAYMENT_OPTION_CARD)

  // Tracks all purchase events after order payment is complete
  useTrackPurchaseComplete(paymentState, selectedPaymentOption, trackEvent)

  const onPaymentOptionChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const paymentOption = paymentOptionMapper[e.target.value]

    selectPaymentOption(paymentOption)
    trackEvent({
      event: 'paymentOptionToggle',
      selectedPaymentOption: paymentOption
    })
  }, [ trackEvent ])

  const buttonText = prop('navigationLinkText', data) || ''

  const backButtonUrl = path([ 'backButtonTargetPage', 'pageUrl' ], data)
  const onBackButtonClick = useCallback(() => {
    backButtonUrl && navigate(leadingSlashIt(backButtonUrl))
  }, [ backButtonUrl ])


  /** ----- Payment form banner messaging ----- */

  const paymentFormRetrievalErrorMessage = safeProp('paymentFormRetrievalErrorMessage', data)
    .map(data => <RichTextWithOptionsComponent data={data}
      key={data.id} />)
    .orNull()

  const paymentCompleteMessage = safePath([ 'orderCompleteInterstitial', 'json' ], data)
    .map((json: Document) => <RichText json={json}
      key='payment-complete-message' />)
    .orNull()

  const paymentProcessingMessage = safePath([ 'paymentProcessingMessage', 'json' ], data)
    .map((json: Document) => <RichText json={json}
      key='payment-processing-message' />)
    .orNull()


  /** ----- Credit card checkout content ----- */

  const creditCardRadioButtonLabel = <Text fontWeight='medium'
    letterSpacing='wide'>
    {safeProp('creditCardCheckoutOptionTitle', data).orJust('')}
  </Text>

  const chaseCheckoutContent = (): JSX.Element | boolean => {
    const sessionVid = visitorIdAtAt() || ''
    const fullKaptchaSrc = concat(dataCollectorSrc, sessionVid.replace(/[^A-Za-z0-9]+/gi, '').substr(0, 32))

    return paymentState !== 'loading' && <InlineChasePaymentForm
      cardVerificationNote={getJson(prop('cardVerificationNote', data))}
      chaseErrorCodes={chaseErrorCodes}
      cvvModalContent={safeProp('cvvModal', data).orUndefined()}
      fullKaptchaSrc={fullKaptchaSrc}
      iframeSrc={iframeSrc}
      paymentErrorMessage={paymentErrorMessage}
      paymentState={paymentState}
    />
  }

  const zuoraCheckoutContent = (): JSX.Element => {
    return <InlineZuoraPaymentForm
      billingAddress={getBillingAddress(cart)}
      cardVerificationNote={getJson(prop('cardVerificationNote', data))}
      locale={locale}
      onOrderSubmit={handleSubmitZuoraOrder}
      onPaymentFormRender={handleZuoraFormRender}
      paymentMethod={Maybe.fromNull(zuoraPaymentMethod)}
      paymentState={paymentState}
      safeTechCollectorSdkUrl={Maybe.fromNull(safeTechSdkUrl).orJust('')}
      zuoraClient={Maybe.fromNull(zuoraClient)}
    />
  }

  const creditCardCheckoutContent = creditPaymentExperience === 'zuora' ? zuoraCheckoutContent() : chaseCheckoutContent()

  /** ----- Affirm checkout content ----- */

  const isAffirmEnabled = !!(affirmClient && prop('affirmEnabled', data))

  const isCartAffirmEligible = cart.map(_cart =>
    _cart.totalPrice >= safeProp('affirmMinimumCartTotal', data).orJust(0)
  ).orJust(false)

  const affirmUnavailableMessage = safePath([ 'affirmUnavailableMessage', 'json' ], data)
    .map((json: Document) =>
      <Row key='affirm-unavailable'
        padding='small'>
        <Column><RichText json={json} /></Column>
      </Row>
    )

  const affirmCheckoutContent = <AffirmCheckoutContent
    affirmClient={affirmClient}
    checkoutButton={safeProp('affirmCheckoutButton', data)}
    instructions={safeProp('affirmCheckoutInstructions', data)}
    note={safeProp('affirmCheckoutNote', data)}
    onOrderSubmit={handleSubmitAffirmOrder}
  />

  const affirmCheckoutOptionIcon = safeProp('affirmCheckoutOptionIcon', data)

  const affirmRadioButtonLabel = <Text fontWeight='medium'
    letterSpacing='wide'>
    {safeProp('affirmCheckoutOptionTitle', data).orJust('')} {
      affirmCheckoutOptionIcon.chain(icon => Maybe.fromNull(prop('fixed', icon)))
        .map(fixed =>
          <FixedImg
            alt={affirmCheckoutOptionIcon.chain(safeProp('description')).orJust('')}
            fixed={fixed}
            key='affirm-checkout-option-icon'
            loading='eager'
          />
        )
    }
  </Text>

  const orderNote = <RichText json={getJson(prop('orderNote', data))} />

  /** ----- Render the payment section ----- */

  const shouldShowCheckoutOptions = paymentState !== 'processing' && paymentState !== 'complete'

  return (
    <Track>
      <div className='payment-iframe-container'>
        <Text><h5>{paymentFormTitle}</h5></Text>

        <PaymentFormBanner
          paymentCompleteMessage={paymentCompleteMessage}
          paymentFormRetrievalErrorMessage={paymentFormRetrievalErrorMessage}
          paymentProcessingMessage={paymentProcessingMessage}
          paymentState={paymentState}
        />

        {/** This inline style isn't ideal, but this is to hide the checkout options if a payment is processing or
          * complete. If someone cancels checking out with Affirm, we want to render the checkout options again
          * without causing the Chase iframe to unmount/remount, so this shows/hides the options with CSS instead
          * not rendering them at all. */}
        <div style={{ display: shouldShowCheckoutOptions ? 'block' : 'none' }}>
          { isAffirmEnabled ? (
            <SSRadio
              dataComponent='payment-options'
              id='payment-options'
              name='payment-options'
              onChange={onPaymentOptionChange}
              options={[
                {
                  content: affirmCheckoutContent,
                  disabled: !isCartAffirmEligible,
                  disabledContent: affirmUnavailableMessage,
                  text: affirmRadioButtonLabel,
                  value: PAYMENT_OPTION_AFFIRM
                }, {
                  content: creditCardCheckoutContent,
                  defaultChecked: true,
                  text: creditCardRadioButtonLabel,
                  value: PAYMENT_OPTION_CARD
                }
              ]}
            ></SSRadio>
          ) : creditCardCheckoutContent }
        </div>

        <PlaceOrder
          buttonText={buttonText}
          content={orderNote}
          onClick={onBackButtonClick}
        />
      </div>
    </Track>
  )
}

export default ContentfulPaymentFormComponent
