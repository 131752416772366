import prop from '@simplisafe/ewok/ramda/prop'
import { graphql } from 'gatsby'
import React from 'react'

import { ContentfulReferAFriendPlaceholder } from '../../../graphql'

type ReferAFriendPlaceholderComponentProps = {
    readonly data: Partial<ContentfulReferAFriendPlaceholder>
}

const MENTION_ME_TEXT = 'Mention Me'

const ReferAFriendPlaceholderComponent = React.memo(({ data }: ReferAFriendPlaceholderComponentProps ) => {
  const typeReferFriend = prop('type', data) || ''

  const talkAbleElement = (
    <div id='talkable-offer'>
    </div>
  )

  const mentionMe = (
    <div id='mmWrapper'>
    </div>
  )

  return typeReferFriend === MENTION_ME_TEXT ? mentionMe : talkAbleElement
})

export const query = graphql`#graphql
fragment mentionMeFragment on ContentfulReferAFriendPlaceholder{
    id
    title
    internal {
        type
    }
    type
}
`
export default ReferAFriendPlaceholderComponent
