import { safeProp } from '@simplisafe/monda'
import { Modal } from '@simplisafe/ss-react-components/atoms'
import { ModalTheme } from '@simplisafe/ss-react-components/atoms/Modal'
import { useScrollPosition } from '@simplisafe/ss-react-components/hooks'
import { graphql } from 'gatsby'
import { Maybe } from 'monet'
import map from 'ramda/src/map'
import React, {
  FC, useEffect, useState
} from 'react'

import { ModalExitIntentFragment } from '../../../graphql'
import {
  mapPageComponentToTemplate, PageComponent, PageProps
} from '../Page'

type ModalExitIntentProps = {
  readonly data: ModalExitIntentFragment
  readonly pageContext: PageProps['pageContext']
  readonly location: PageProps['location']
}

const modalThemeMapping: { [key: string]: ModalTheme } = {
  'blackFridayUK1': 'blackFridayUK1',
  'blackFridayUK2': 'blackFridayUK2',
  'blackFridayUS': 'blackFridayUS',
  'cyberMondayUK': 'cyberMondayUK',
  'cyberMondayUS': 'cyberMondayUS',
  'default': 'default',
  'minimal': 'minimal'
}

const ModalExitIntentComponent: FC<ModalExitIntentProps> = ({
  data, pageContext, location
}: ModalExitIntentProps) => {
  const [ hasOpened, setHasOpened ] = useState(false)
  const [ isOpen, setIsOpen ] = useState(false)
  const shouldOpen = useScrollPosition()

  useEffect(() => { !hasOpened && !isOpen && shouldOpen && setIsOpen(true) }, [ shouldOpen, hasOpened, isOpen ])

  const dontOpenThisSession = () => {
    setIsOpen(false)
    setHasOpened(true)
  }

  const contentComponents = safeProp('content', data).chain(Maybe.fromNull)
  const theme: string = safeProp('theme', data).orJust('default')

  return (
    <Modal
      appElementId="___gatsby"
      isOpen={isOpen}
      onRequestClose={dontOpenThisSession}
      theme={modalThemeMapping[theme]}
    >
      {contentComponents
        .cata(
          () => null,
          map((comp: PageComponent | null) => comp ? (
            <React.Fragment key={comp.id}>
              { mapPageComponentToTemplate(comp, pageContext, location) }
            </React.Fragment>
          ) : null
          )
        )
      }
    </Modal>
  )
}

export default ModalExitIntentComponent

export const AlarmSensorsQuery = graphql`#graphql
  fragment modalExitIntent on ContentfulModalExitIntent {
    id
    internal {
      type
    }
    content {
      ... on ContentfulGroupSection {
        ...contentfulGroupSectionFragment
      }
    }
    theme
  }
`
