import { safeProp } from '@simplisafe/monda'
import isNil from 'ramda/src/isNil'

export const handleBrazeTrackingEvent = (data) => {
  const appBoyExists = (typeof window.appboy !== 'undefined')
  appBoyExists ? updateBraze(data) : null
}

export const updateBraze = (data) => {

  const leadID = safeProp('leadId', data).getOrElse('')
  const email = safeProp('email', data).getOrElse('')
  const externalID = safeProp('externalId', data).getOrElse('')
  const leadSource = safeProp('leadSource', data).getOrElse('')

  window.appboy.openSession()

  window.appboy.getUser().getUserId(
    function(brazeUserId) {
      const userId = externalID || `L${leadID}`
      isNil(brazeUserId) ? setUserId(leadSource, userId) : null
    }
  )

  window.appboy.getUser().setCustomUserAttribute('Last Lead ID', leadID)
  window.appboy.getUser().setCustomUserAttribute('External ID', externalID)
  window.appboy.getUser().setCustomUserAttribute('Last Lead Source', leadSource.replace(/[^a-zA-Z0-9]/g, ' '))
  window.appboy.getUser().addAlias('simplisafe_lead_id', leadID)
  window.appboy.getUser().setEmail(email)
}

const setUserId = (leadSource, userId) => {
  const properties = { 'lead_source': leadSource }
  window.appboy.logCustomEvent('lead_created', properties)
  window.appboy.changeUser(userId)
}
