import prop from '@simplisafe/ewok/ramda/prop'
import { safePath } from '@simplisafe/monda'
import { ContentSelector } from '@simplisafe/ss-react-components/organisms'
import { ContentSelectorProps } from '@simplisafe/ss-react-components/organisms/ContentSelector'
import { graphql } from 'gatsby'
import { Maybe } from 'monet'
import propOr from 'ramda/src/propOr'
import React, { ReactNode } from 'react'

import { ContentSelectorFragment } from '../../../graphql'
import { ContentfulComponent } from '../../componentMappings'
import { renderComponentFromData } from '../../util/render'

export type ContentSelectorComponentProps = {
  readonly data: ContentSelectorFragment
}

const ContentSelectorComponent: React.FC<ContentSelectorComponentProps> = ({ data }: ContentSelectorComponentProps) => {
  const renderSVG = (name: keyof typeof data, width: string): ReactNode =>
    Maybe.fromNull(prop(name, data))
      .chain(safePath([ 'file', 'url' ]))
      .map((fileUrl: string) => <img key={fileUrl} src={fileUrl}
        width={width} />)

  const firstContent = propOr<readonly ContentfulComponent[], readonly ContentfulComponent[]>([], 'firstContent', data)
  const secondContent = propOr<readonly ContentfulComponent[], readonly ContentfulComponent[]>([], 'secondContent', data)

  const contentProps: ContentSelectorProps = {
    buttonGroupTitle: propOr<string, string>('', 'buttonGroupTitle', data),
    description: renderComponentFromData(prop('introduction', data)),
    firstButtonIcon: renderSVG('firstButtonIcon', '102'),
    firstButtonText: propOr<string, string>('', 'firstButtonText', data),
    firstContent: firstContent.map((data) => renderComponentFromData(data)),
    secondButtonIcon: renderSVG('secondButtonIcon', '102'),
    secondButtonText: propOr<string, string>('', 'secondButtonText', data),
    secondContent: secondContent.map((data) => renderComponentFromData(data)),
  }

  return <ContentSelector {...contentProps} />
}
export default ContentSelectorComponent

export const ContentSelectorQuery = graphql`
  #graphql
  fragment contentSelector on ContentfulContentSelector {
    id
    entryTitle
    introduction {
      ... on ContentfulSmallTextSection {
        contentful_id
        ...smallTextSectionFragment
      }
    }
    buttonGroupTitle
    firstButtonText
    firstButtonIcon {
      description
      file {
        url
        contentType
      }
      fluid(maxWidth: 200) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
      id
    }
    secondButtonText
    secondButtonIcon {
      description
      file {
        url
        contentType
      }
      fluid(maxWidth: 200) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
      id
    }
    firstContent {
      ... on ContentfulSmallTextSection {
        ...smallTextSectionFragment
      }
      ... on ContentfulTwoColumn {
        ...contentfulTwoColumnFragment
      }
      ... on ContentfulResponsiveContainer {
        ...responsiveContainer
      }
      ... on ContentfulGroupSection {
        ...contentfulGroupSectionFragment
      }
    }
    secondContent {
      ... on ContentfulSmallTextSection {
        ...smallTextSectionFragment
      }
      ... on ContentfulTwoColumn {
        ...contentfulTwoColumnFragment
      }
      ... on ContentfulResponsiveContainer {
        ...responsiveContainer
      }
    }
    internal {
      type
    }
  }
`
