import { BannerError, BannerLoading } from '@simplisafe/ss-react-components/molecules'
import React, { FC, ReactNode } from 'react'

import { PaymentState } from './usePayment'

export type PaymentFormBannerProps = {
  readonly paymentCompleteMessage: ReactNode
  readonly paymentFormRetrievalErrorMessage: ReactNode
  readonly paymentProcessingMessage: ReactNode
  readonly paymentState: PaymentState
}

/**
 * Loading any one of the Loader/Error/Payment Form section based on the state.
 * Adding empty element inside BannerLoading for mandatory children.
 */
const PaymentFormBanner: FC<PaymentFormBannerProps> = ({
  paymentCompleteMessage,
  paymentFormRetrievalErrorMessage,
  paymentProcessingMessage,
  paymentState
}: PaymentFormBannerProps) => (

  paymentState === 'loading' ? (
    <BannerLoading dataComponent='BannerLoading_form'><div></div></BannerLoading>
  ) : paymentState === 'processing' ? (
    <BannerLoading dataComponent='BannerLoading_paymentProcessing'>{paymentProcessingMessage}</BannerLoading>
  ) : paymentState === 'complete' ? (
    <BannerLoading dataComponent='BannerLoading_paymentComplete'>{paymentCompleteMessage}</BannerLoading>
  ) : paymentState === 'error' ? (
    <BannerError dataComponent='BannerLoading_paymentError'
      height='responsive'>{paymentFormRetrievalErrorMessage}</BannerError>
  ) : null
)

export default PaymentFormBanner
