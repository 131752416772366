import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import { Text } from '@simplisafe/ss-react-components/atoms'
import { SmallTextSection } from '@simplisafe/ss-react-components/molecules'
import { window } from 'browser-monads-ts'
import propOr from 'ramda/src/propOr'
import React, { MouseEvent } from 'react'

import { LiveChatTriggerFragment } from '../../../graphql'
import useLiveChatAvailability from '../../hooks/useLiveChatAvailability'
import getJson from '../../util/getJson'
import LiveChat from '.'

export type LiveChatTriggerProps = {
  readonly data: LiveChatTriggerFragment
}

const LiveChatTrigger = ({ data }:  LiveChatTriggerProps) => {
  const disclaimer = propOr<string, string>('', 'disclaimerText', data)
  const isLiveChatEnabled: boolean = useLiveChatAvailability()
  const clickTagetData = isLiveChatEnabled ? 'clickTarget' : 'clickTargetDisabled'

  const clickTarget =
  safeProp(clickTagetData, data)
    .map(getJson)
    .map(json => <SmallTextSection description={json}
      key={prop('id', data)}/>)
    .orNull()

  const onClick = (e: MouseEvent) => {
    e.preventDefault()
    // so the build doesn't complain about missing type for window.sprChat
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    isLiveChatEnabled && window.sprChat && window['sprChat'].call(window['sprChat'], 'open')
  }

  return (
    <>
      <LiveChat />
      <span onClick={(e) => onClick(e)}>
        {clickTarget}
      </span>
      {!isLiveChatEnabled &&
        <Text display='inline-flex'
          fontWeight='medium'
          textColor='neutralDarkGray'
        >
          {disclaimer}
        </Text>
      }
    </>
  )
}

export default LiveChatTrigger
