import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { Heading } from '@simplisafe/ss-react-components/atoms'
import type { HeadingProps } from '@simplisafe/ss-react-components/atoms/Heading'
import { graphql } from 'gatsby'
import applySpec from 'ramda/src/applySpec'
import React, { FC } from 'react'

import { ContentfulHeading } from '../../../graphql'

export type HeadingComponentProps = {
  readonly data: Partial<ContentfulHeading>
}

const toHeadingProps = applySpec<HeadingProps>({
  color: path([ 'color', 'color' ]),
  element: prop('headingType'),
})

const HeadingComponent: FC<HeadingComponentProps> =
    ({ data }: HeadingComponentProps) => {
      return (
        <Heading {...toHeadingProps(data)}>
          {path([ 'text', 'text' ], data)}
        </Heading>
      )
    }

export default HeadingComponent

export const query = graphql`#graphql
  fragment heading on ContentfulHeading {
    color {
      color
    }
    headingType
    id
    internal {
      type
    }
    text {
      text
    }
    title
    useTailwind
  }`
