import '@simplisafe/ss-react-components/styles.css'

import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { submitIdeasFormSubmission } from '@simplisafe/ss-ecomm-data/leads/submission'
import {
  Column, Row, SSInput
} from '@simplisafe/ss-react-components/atoms'
import type { RichTextProps } from '@simplisafe/ss-react-components/atoms/RichText'
import { PatentTable } from '@simplisafe/ss-react-components/molecules'
import { PatentTableCell, PatentTableRow } from '@simplisafe/ss-react-components/molecules/PatentTable'
import { Form } from '@simplisafe/ss-react-components/organisms'
import { FormProps } from '@simplisafe/ss-react-components/organisms/Form'
import { graphql } from 'gatsby'
import always from 'ramda/src/always'
import applySpec from 'ramda/src/applySpec'
import defaultTo from 'ramda/src/defaultTo'
import head from 'ramda/src/head'
import pathOr from 'ramda/src/pathOr'
// TODO replace with ts-functional-pipe
// eslint-disable-next-line no-restricted-imports
import pipe from 'ramda/src/pipe'
import values from 'ramda/src/values'
import React, { FC, useState } from 'react'

import {
  ContentfulLegal,
  ContentfulTableCell,
  ContentfulTableRow
} from '../../../graphql'
import useScrollToHash from '../../hooks/useScrollToHash'
import getDescriptionJson from '../../util/getDescriptionJson'
import { renderComponentFromData } from '../../util/render'
import { PageProps } from '../Page'
import RichText from '../RichText'

type ContentfulLegalContainerProps = {
  readonly id: string
  readonly data: Partial<ContentfulLegal>
  readonly pageContext?: PageProps['pageContext']
}

type LegalFormProps = {
  readonly firstName: string
  readonly lastName: string
  readonly message: string
  readonly email: string
  readonly attachment: string
}

const toLegalContainerData = (data: Partial<ContentfulLegal>): RichTextProps => {
  const toData = applySpec<RichTextProps>({ json: getDescriptionJson })
  return toData(data)
}

const toFormContent = applySpec<PatentTableCell>({
  colspan: (x: ContentfulTableCell) => prop('colspan', x),
  content: (x: ContentfulTableCell) => renderComponentFromData(prop('content', x))
})

const toGetPatentTableProps = (tableData: readonly ContentfulTableRow[]): readonly PatentTableRow[] => {
  return tableData && tableData.map(data => {
    const { cell } = data
    return { cell: cell?.map(toFormContent) ?? [] }
  })
}

export const toGetFormData = (v = {}) => applySpec<FormProps>({
  button: prop('button'),
  formInput: prop('formInput'),
  formTheme: prop('formTheme'),
  successMessageDescription: prop('successMessageDescription'),
  successMessageTitle: prop('successMessageTitle'),
  title: prop('title')
})(v)

const getLegalFormdata = applySpec<LegalFormProps>({
  attachment: path([ 'fileAttachment', '0' ]),
  email: prop('emailAddress'),
  firstName: prop('firstName'),
  lastName: prop('lastName'),
  locale: always('en-US'),
  message: prop('lastName')
})

const onValidLegalForm = (setFormSuccess: (_: boolean) => void) => (formData: Record<string, unknown>) => {
  const form = pathOr<Record<string, LegalFormProps>, Record<string, LegalFormProps>>({}, [ '0', 'form' ], formData)
  const legalForm = pipe<Record<string, LegalFormProps>, readonly LegalFormProps[], LegalFormProps>(values, head)(form)
  const formRequest = getLegalFormdata(legalForm)
  const formDataObj = new FormData()
  Object.keys(formRequest).forEach(key => formDataObj.append(key, defaultTo('')(formRequest[key])))
  const handleFailure = always(setFormSuccess(false))
  const handleSuccess = () => setFormSuccess(true)
  submitIdeasFormSubmission(formDataObj)(handleFailure)(handleSuccess)
}

const LegalContainer: FC<ContentfulLegalContainerProps> =
  ({ data }: ContentfulLegalContainerProps) => {
    const props = toLegalContainerData(data)
    const patentTableProps = toGetPatentTableProps(pathOr<readonly ContentfulTableRow[], readonly ContentfulTableRow[]>([], [ 'table', 'row' ], data))
    const submissionCheckbox = prop('submissionCheckbox', data)
    const submissionForm = prop('submissionForm', data)
    const [ isFormSuccess, setFormSuccess ] = useState(false)
    const [ isShowForm, setShowForm ] = useState(false)
    const formProps = toGetFormData(submissionForm)
    // TODO fix type
    // @ts-ignore
    const successMessageTitle = prop('successMessageTitle', data)

    useScrollToHash()

    return (
      <>
        <Row>
          <Column spans={[ 12, 12 ]}>
            <RichText {...props} />
          </Column>
        </Row>
        {patentTableProps && <PatentTable row={patentTableProps} />}
        {submissionCheckbox && <SSInput
          checked={isShowForm}
          defaultChecked={!!prop('defaultChecked', submissionCheckbox)}
          id={prop('id', submissionCheckbox)}
          label={<p>{prop('placeholderText', submissionCheckbox)}</p>}
          name={prop('propName', submissionCheckbox) || ''}
          onChange={() => {
            setShowForm(!isShowForm)
          }}
          // TODO fix type
          // @ts-ignore
          type={prop('type', submissionCheckbox)}
        />}
        {submissionForm && isShowForm &&
          <Form {...formProps}
            onValidated={onValidLegalForm(setFormSuccess)}
            submissionMessage={isFormSuccess ? successMessageTitle : undefined}
          />}
      </>
    )
  }

export default LegalContainer

export const query = graphql`#graphql
  fragment contentfulLegalFragment on ContentfulLegal {
    id
    internal {
      type
    }
    description {
      json
    }
    table {
      row {
        cell {
          colspan
          content {
            ... on ContentfulImage {
              ...contentfulImage
            }
            ... on ContentfulRichTextWithOptions {
              ...richTextWithOptions
            }
          }
        }
      }
    }
    submissionCheckbox{
      ... on ContentfulForms {
        answerOptions
        maximumCharacter
        propName
        title
        placeholderText
        placeholderTextMobile
        id
        fieldValidation {
          requirement
          errorMessage
        }
        type
        checked
        defaultChecked
        deviceToDisplay
      }
    }
    submissionForm {
      formInput {
        ... on ContentfulCheckoutFormSection {
          id
          title
          inputField {
            title
            propName
            placeholderText
            maximumCharacter
            answerOptions
            characterType
            type
            fieldValidation {
              requirement
              errorMessage
            }
            customLayoutField {
              json
            }
            radioOption {
              text
              value
              note {
                json
              }
            }
          }
        }
      }
      successMessageTitle
      successMessageDescription {
        successMessageDescription
      }
      formTheme
      button {
        text
        type
        url
      }
    }
  }
`
