import path from '@simplisafe/ewok/ramda/path'
import { safeProp } from '@simplisafe/monda'
import { BuildMySystemHeroItem } from '@simplisafe/ss-react-components/molecules'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import React, { FC } from 'react'
import { useTracking } from 'react-tracking'

import { ContentfulBmsHeroItem } from '../../../graphql'
import { getMappedComponent } from '../../componentMappings'
import getDescriptionJson from '../../util/getDescriptionJson'
import RichText from '../RichText'
import { SimpleBannerComponentProps } from '../SimpleBannerComponent/types'

type ContentfulBmsHeroItemProps = {
  readonly data: Partial<ContentfulBmsHeroItem>
}

const BmsHeroItem: FC<ContentfulBmsHeroItemProps> =
  ({ data }: ContentfulBmsHeroItemProps) => {
    const detailsModalContent = safeProp('detailsModalContent', data).orUndefined()
    const { trackEvent } = useTracking()
    const trackEventBasePlanTooltip = (trackEvent, title) => {
      trackEvent({
        action: 'tooltip',
        category: 'bms',
        event: 'clickTooltip',
        label: title,
        site: 'fcp'
      })
    }
    const DetailsModalComponent = detailsModalContent ? getMappedComponent<SimpleBannerComponentProps>(detailsModalContent) : undefined

    return (
      <BuildMySystemHeroItem
        description={<RichText json={getDescriptionJson(data)} />}
        detailsModalContent={DetailsModalComponent && <DetailsModalComponent data={detailsModalContent} id='' />}
        image={
          <Img
            alt={path([ 'icon', 'title' ], data)}
            fluid={path([ 'icon', 'fluid' ], data)}
            imgStyle={{ objectFit: 'contain' }}
            style={{
              height: '100%',
              width: '100%',
            }}
          />
        }
        onClick={() => trackEventBasePlanTooltip(trackEvent, safeProp('displayTitle', data).getOrElse(''))}
        title={safeProp('displayTitle', data).getOrElse('')}
      />
    )
  }

export default BmsHeroItem

export const query = graphql`#graphql
  fragment contentfulBmsHeroItemFragment on ContentfulBmsHeroItem {
    id
    internal {
      type
    }
    displayTitle
    description {
      json
    }
    icon {
      fluid(maxWidth: 400) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
      title
    }
    detailsModalContent {
      ... on ContentfulBanner {
        ...contentfulBanner
      }
    }
  }
`
