import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import isNotEmpty from '@simplisafe/ewok/ramda-adjunct/isNotEmpty'
import { safeProp } from '@simplisafe/monda'
import {
  CheckoutFormValues,
  clearCartError,
  ShippingOption
} from '@simplisafe/ss-ecomm-data/cart'
import {
  IOSetShippingMethod, IOUpdateCart, IOUpdateDiscountCodeToCart
} from '@simplisafe/ss-ecomm-data/cart/actions'
import {
  selectCartLoading, selectCheckoutFormSubmitActions, selectShippingOptions
} from '@simplisafe/ss-ecomm-data/cart/select'
import { UKAddressSchema, USAddressSchema } from '@simplisafe/ss-ecomm-data/checkout/address'
import { ukPostalCodeSchema, usPostalCodeSchema } from '@simplisafe/ss-ecomm-data/checkout/fields'
import {
  buildCustomFieldUpdateAction,
  buildShippingAddressUpdateAction,
  commercetoolsGetShippingMethods,
  ImmutableCart,
  ShippingMethod,
  ShippingMethodPagedQueryResponse
} from '@simplisafe/ss-ecomm-data/commercetools/cart'
import {
  selectCart,
  selectLocale
} from '@simplisafe/ss-ecomm-data/redux/select'
import {
  cookiesOption, fetchUserCheckoutDataWithPopup, leadGenCapture, LeadGenCaptureParams, LeadGenCaptureResponse, leadGenUnsub
} from '@simplisafe/ss-ecomm-data/simplisafe'
import {
  fetchPaymentExperience,
  PaymentExperience
} from '@simplisafe/ss-ecomm-data/simplisafe/paymentsClient'
import { UserCheckoutData } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { fork } from 'fluture'
import {
  Form, Formik, useFormikContext
} from 'formik'
import { navigate } from 'gatsby'
import { get as getLocalStorage, set as setLocalStorage } from 'local-storage'
import { Maybe } from 'monet'
import always from 'ramda/src/always'
import applySpec from 'ramda/src/applySpec'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import propOr from 'ramda/src/propOr'
import when from 'ramda/src/when'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import Cookies from 'universal-cookie'

import { CheckoutFormFragment } from '../../../graphql'
import { toMaybeOrNone } from '../../commercetools/price'
import { handleBrazeTrackingEvent } from '../../tracking/braze'
import { COOKIE_LEAD_DATA } from '../../tracking/cookies'
import { OptimizelyEvent, useOptimizelyTrackSiteEvents } from '../../tracking/optimizely'
import { trackEpsilonAbacusOptIn, trackSubmitLeadEvent } from '../../util/analytics'
import getJson from '../../util/getJson'
import { setDeviceId } from '../../util/helper'
import {
  remove as removeSessionStorage,
  set as setSessionStorage
} from '../../util/session-storage'
import { getCartDiscountCode } from '../CartDetailsComponent/transformLineItem'
import RichText from '../ContentfulRichText'
import Coupons from './form-sections/Coupons'
import CustomerService from './form-sections/CustomerService'
import Email from './form-sections/Email'
import ShippingAddress from './form-sections/ShippingAddress'
import ShippingOptions from './form-sections/ShippingOptions'
import Submission from './form-sections/Submission'

type CheckoutFormProps = {
  readonly data: CheckoutFormFragment
  readonly onSubmit: (values: CheckoutFormValues) => void
}

const CheckoutForm = ({ data, onSubmit }: CheckoutFormProps) => {
  const dispatch = useDispatch()
  const cart = useSelector(selectCart)
  const locale = useSelector(selectLocale)
  const cartIsLoading = useSelector(selectCartLoading)

  const isMobile = !useMediaQuery('TabletAndUp')
  const { trackEvent } = useTracking()
  setDeviceId()
  const cookies = new Cookies()
  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()

  const localeSchema = ifElse(
    equals('en-US'),
    always(USAddressSchema),
    always(UKAddressSchema)
  )(locale)

  const localePostalCodeSchema = ifElse(
    equals('en-US'),
    always(usPostalCodeSchema),
    always(ukPostalCodeSchema)
  )(locale)

  const countryAbbreviation = ifElse(
    equals('en-US'),
    always('US'),
    always('GB')
  )(locale)

  const initialCoupon = cart.map(_cart => getCartDiscountCode(_cart)).toMaybe()
    .getOrElse('')

  const initialFormValues: CheckoutFormValues = {
    additionalFoundInfoThrough: '',
    additionalStreetInfo: '',
    city: '',
    country: countryAbbreviation,
    couponCode: initialCoupon,
    email: '',
    epsilonAbacusOptIn: false,
    firstName: '',
    foundInfoThrough: '',
    lastName: '',
    offerAndTip: true,
    phone: '',
    postalCode: '',
    sameAddress: true,
    shippingOption: '',
    state: '',
    streetName: ''
  }

  const [ currentCoupon, setCurrentCouponValue ] = useState(initialCoupon)
  const [ couponSubmissionMessage, setCouponSubmissionMessage ] = useState('')
  const [ couponSubmissionSuccess, setCouponSubmissionSuccess ] = useState(false)
  const [ couponSubmissionFail, setCouponSubmissionFail ] = useState(false)
  const [ validatedFormValues, setValidatedFormValues ] = useState(initialFormValues)
  const [ showErrorMessage, setShowErrorMessage ] = useState(false)
  const [ disableSubmit, setDisableSubmit ] = useState(false)
  const [ currentShippingOptions, setCurrentShippingOptions ] = useState<readonly ShippingMethod[]>([])
  const [ loginSuccess, setLoginSuccess ] = useState(false)
  const [ userAddress, setUserAddress ] = useState({})
  const [ creditPaymentExperience, setCreditPaymentExperience ] = useState<PaymentExperience>()


  const captureLead = (promoCode: string, locale: string, optimizelyTrackSiteEvents: (_data: OptimizelyEvent) => void,
    email: string, offerAndTip: boolean, loginSuccess: boolean) => {
    const trackLeadSuccess = () => {
      optimizelyTrackSiteEvents({ eventType: 'lead_captured_fs' })
      trackSubmitLeadEvent(trackEvent)
    }
    const leadHandleSuccess = (value: Maybe<LeadGenCaptureResponse>) => {
      // Always cookie lead data and pass data to Braze
      cookies.set(COOKIE_LEAD_DATA, value.orUndefined(), cookiesOption)
      handleBrazeTrackingEvent(value.orUndefined())

      // we track lead when offerAndTip is true AND user is not logged in
      const shouldTrackLeadEvent = offerAndTip && !loginSuccess
      shouldTrackLeadEvent && trackLeadSuccess()

      // we unsubscribe when offerAndTip is false AND user is not logged in
      const shouldUnsubscribe = !offerAndTip && !loginSuccess
      shouldUnsubscribe && leadGenUnsub({ email })(() => null)(() => null)
    }

    const leadHandleError = () => {
      optimizelyTrackSiteEvents({ eventType: 'website_error' })
    }

    const leadGenParams: LeadGenCaptureParams = {
      email,
      leadSource: 'exit_intent',
      locale,
      promoCode,
      source: 'checkout',
      sourceType: 'cart',
    }
    leadGenCapture(leadGenParams)(leadHandleError)(leadHandleSuccess)
  }

  const handleSubmitSuccess = () => {
    setDisableSubmit(false)
    setShowErrorMessage(false)
    navigate(propOr<string, string>('', 'redirectToPaymentPage', data))
  }
  const handleSubmitError = () => {
    setDisableSubmit(false)
  }

  const handleSubmit = (values: CheckoutFormValues) => {
    const submissionActions = maybeActions.getOrElse([])
    Maybe.fromNull(values)
      .chain(safeProp('epsilonAbacusOptIn'))
      .forEach(trackEpsilonAbacusOptIn(trackEvent))
    captureLead(currentCoupon, locale, optimizelyTrackSiteEvents, prop('email', values), prop('offerAndTip', values), loginSuccess)
    dispatch(IOUpdateCart(submissionActions, handleSubmitError, handleSubmitSuccess))
    onSubmit(values)
  }

  const validatePostalCode = (code: string) => {
    return localePostalCodeSchema.isValidSync(code)
  }

  const formatAddress = applySpec<Record<string, string>>({
    additionalStreetInfo: path([ 'addresses', 0, 'street2' ]),
    city: path([ 'addresses', 0, 'city' ]),
    email: prop('emailAddress'),
    firstName: path([ 'addresses', 0, 'firstName' ]),
    lastName: path([ 'addresses', 0, 'lastName' ]),
    phone: path([ 'addresses', 0, 'phone' ]),
    postalCode: path([ 'addresses', 0, 'zip' ]),
    state: path([ 'addresses', 0, 'state' ]),
    streetName: path([ 'addresses', 0, 'street1' ]),
  })

  const fetchCreditPaymentExperience = (userId?: string) => {
    fetchPaymentExperience({ userId })(() => {
      setCreditPaymentExperience('chase')
    })(response => {
      response.forEach(response => {
        setCreditPaymentExperience(response.experience)
      })
    })
  }

  const fetchUserCheckoutDataSuccess = (maybeUserCheckoutData: Maybe<UserCheckoutData>) => {
    const userCheckoutData = maybeUserCheckoutData.orNull()
    const formattedAddress = formatAddress(userCheckoutData)
    //im not sure what this is used for but it was being set in the old form
    setLocalStorage('postalCode', propOr('', 'postalCode', formattedAddress))

    setUserAddress(formattedAddress)
    setLoginSuccess(true)

    maybeUserCheckoutData
      .chain(safeProp('id'))
      .forEach((userId) => {
        fetchCreditPaymentExperience(userId)
        setSessionStorage('checkoutUserId', userId)
      })
  }

  const fetchUserCheckoutDataFailure = () => {
    logError(Error('CheckoutForm: UserCheckoutData is empty'))
  }

  const getReturningCustomerAddress = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    fetchUserCheckoutDataWithPopup(getLocalStorage('deviceId'))(fetchUserCheckoutDataFailure)(fetchUserCheckoutDataSuccess)
  }

  const emailDescription =
  safeProp('emailDescription', data)
    .map(getJson)
    .map(json => <RichText key={prop('id', data)}
      onLinkClick={getReturningCustomerAddress}
      rawRichText={json}/>)
    .orNull()

  const validatedDescription =
  safeProp('validatedDescription', data)
    .map(getJson)
    .map(json => <RichText key={prop('id', data)}
      rawRichText={json}/>)
    .orNull()

  const getShippingMethodId =
    (cart: ImmutableCart) =>
      safeProp('shippingInfo', cart)
        .chain(toMaybeOrNone)
        .chain(safeProp('shippingMethod'))
        .chain(safeProp('id'))


  const maybeShippingOptions: Maybe<readonly ShippingOption[]> = useSelector(selectShippingOptions(currentShippingOptions))
  const formattedShippingOptions = maybeShippingOptions.getOrElse([])
  const maybeActions = useSelector(selectCheckoutFormSubmitActions(validatedFormValues))

  const handleShippingOptionsSuccess = (cartId: string) => () => {
    commercetoolsGetShippingMethods(cartId)
      .pipe(fork((e: Error) => {
        logError(e)
      })((shipResp: Maybe<ShippingMethodPagedQueryResponse>) => {
        // eslint-disable-next-line
        const shippingData = shipResp.getOrElse([])
        const shippingResults = propOr<readonly ShippingMethod[], readonly ShippingMethod[]>([], 'results', shippingData)
        setCurrentShippingOptions(shippingResults)
      }))
  }

  const handleShippingOptionsError = () => {
    logError(Error('something went wrong when requesting shipping options'))
  }

  const showCouponSuccessMessage = () => {
    setCouponSubmissionMessage(propOr<string, string>('', 'couponSuccessMessage', data))
    setCouponSubmissionFail(false)
    setCouponSubmissionSuccess(true)
  }

  const hideCouponSuccessMessage = () => {
    setCouponSubmissionMessage('')
    setCouponSubmissionSuccess(false)
  }

  const handleCouponSubmissionSuccess = () => {
    showCouponSuccessMessage()
    //set a timeout so that the submission message fades away after a code is added
    setTimeout(() => hideCouponSuccessMessage(), 5100)
  }

  const handleCouponSubmissionError = () => {
    setCouponSubmissionSuccess(false)
    setCouponSubmissionFail(true)
    // use this dispatch event to prevent cart from erroring on the screen
    dispatch(clearCartError())
    setCouponSubmissionMessage(propOr<string, string>('', 'couponErrorMessage', data))
  }

  const submitNewCouponOnClick = () => {
    /* eslint-disable max-lines */
    when(equals(true), () => {
      dispatch(IOUpdateDiscountCodeToCart([ currentCoupon ], handleCouponSubmissionError, handleCouponSubmissionSuccess))
    })(currentCoupon !== '')
  }

  const submitNewCouponOnEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    when(equals(true), () => {
      e.preventDefault()
      dispatch(IOUpdateDiscountCodeToCart([ e.currentTarget.value ], handleCouponSubmissionError, handleCouponSubmissionSuccess))
    })(e.keyCode === 13 && e.currentTarget.name === 'couponCode' && e.currentTarget.value !== '')
  }

  const shouldLoadShippingOptions = (stateValue: string) => {
    //only load shipping options if the state field is not empty on the us site. For uk, always load shipping option
    return countryAbbreviation === 'GB' ? true : isNotEmpty(stateValue)
  }

  // use for loading shipping options from commercetools after postal code filled in
  const LoadShippingOptions = () => {
    const {
      values, touched, setFieldTouched, setFieldValue, isSubmitting
    } = useFormikContext<CheckoutFormValues>()
    useEffect(() => {
      const touchedPostalCode = propOr<boolean, boolean>(false, 'postalCode', touched)
      const postalCodeValue = propOr<string, string>('', 'postalCode', values)
      const stateValue = propOr<string, string>('', 'state', values)
      const paymentExperienceUpdateAction = buildCustomFieldUpdateAction({
        name: 'creditPaymentExperience',
        value: creditPaymentExperience
      })
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const shippingAddressUpdateAction = buildShippingAddressUpdateAction({
        country: countryAbbreviation,
        postalCode: postalCodeValue,
        state: stateValue
      })
      const updateActions = [
        paymentExperienceUpdateAction,
        shippingAddressUpdateAction
      ]
      const validPostalCode: boolean = validatePostalCode(postalCodeValue)
      when(equals(true), () => {
        cart.forEach((_cart) => {
          setFieldTouched('postalCode', false)
          dispatch(IOUpdateCart(updateActions, handleShippingOptionsError, handleShippingOptionsSuccess(_cart.id)))
        })
      })(validPostalCode && touchedPostalCode && isSubmitting === false && shouldLoadShippingOptions(stateValue))
    }, [ isSubmitting, setFieldTouched, setFieldValue, values, touched ])
    return null
  }

  const SetShippingOption = () => {
    const { values, setFieldValue } = useFormikContext<CheckoutFormValues>()
    // eslint-disable-next-line
    const [ firstShippingResult ] = formattedShippingOptions
    const shippingOptionValue = propOr<string, string>('', 'shippingOption', values)
    useEffect(() => {
      setDisableSubmit(cartIsLoading)
      const firstShippingResultId = propOr<string, string>('', 'id', firstShippingResult)
      ifElse(equals(''),
        () => setFieldValue('shippingOption', firstShippingResultId),
        () => setFieldValue('shippingOption', shippingOptionValue)
      )(shippingOptionValue)

    }, [ firstShippingResult, setFieldValue, shippingOptionValue ])
    return null
  }

  // updates cart with the currently selected shipping option
  const UpdateCartShippingOption = () => {
    const { values, isSubmitting } = useFormikContext<CheckoutFormValues>()
    // eslint-disable-next-line
    const shippingMethodIdFromCart: string = cart.chain(getShippingMethodId)
      .getOrElse('')
    const shippingOptionValue = propOr<string, string>('', 'shippingOption', values)
    useEffect(() => {
      when(equals(true), () => {
        setDisableSubmit(cartIsLoading)
        dispatch(IOSetShippingMethod(shippingOptionValue))
      })((shippingMethodIdFromCart !== shippingOptionValue) && isNotEmpty(shippingOptionValue) && isSubmitting === false)
    }, [ shippingMethodIdFromCart, shippingOptionValue, isSubmitting ])
    return null
  }

  const UpdateCoupon = () => {
    const { values } = useFormikContext<CheckoutFormValues>()
    const couponValue = propOr('', 'couponCode', values)
    useEffect(() => {
      setDisableSubmit(cartIsLoading)
      setCurrentCouponValue(couponValue)

    }, [ couponValue ])
    return null
  }

  const UpdatePostalCode = () => {
    const { values, setFieldValue } = useFormikContext<CheckoutFormValues>()
    useEffect(() => {
      when(equals(true), () => {
        setFieldValue('postalCode', propOr('', 'postalCode', values).toUpperCase())
      })(locale === 'en-GB')
    }, [ values, setFieldValue ])
    return null
  }

  const ValidateFormValues = () => {
    const { isSubmitting, values } = useFormikContext<CheckoutFormValues>()
    useEffect(() => {
      const isValid = localeSchema.isValidSync(values)
      when(equals(true), () => {
        ifElse(equals(true),
          () => {
            setDisableSubmit(true)
            setValidatedFormValues(values)
          },
          () => {
            setShowErrorMessage(true)
          }
        )(isValid)
      })(isSubmitting)
    }, [ isSubmitting, values ])
    return null
  }


  const SetReturningCustomerAddress = () => {
    const {
      setFieldValue, setFieldTouched, isSubmitting
    } = useFormikContext<CheckoutFormValues>()
    useEffect(() => {
      when(equals(true), () => {
        setFieldValue('postalCode', propOr('', 'postalCode', userAddress))
        setFieldTouched('postalCode', true)
        setFieldValue('additionalStreetInfo', propOr('', 'additionalStreetInfo', userAddress))
        setFieldValue('email', propOr('', 'email', userAddress))
        setFieldValue('firstName', propOr('', 'firstName', userAddress))
        setFieldValue('lastName', propOr('', 'lastName', userAddress))
        setFieldValue('streetName', propOr('', 'streetName', userAddress))
        setFieldValue('phone', propOr('', 'phone', userAddress))
        setFieldValue('city', propOr('', 'city', userAddress))
        setFieldValue('state', propOr('', 'state', userAddress))
        // set offerAndTip field to false here because we don't want to register returning customers as a lead
        setFieldValue('offerAndTip', false)
        //empty userAddress so that setFieldValues stop executing
        setUserAddress({})
      })(loginSuccess && isNotEmpty(userAddress) && isSubmitting === false)
    }, [ setFieldValue, isSubmitting, setFieldTouched ])
    return null
  }

  // On initial render, unset the checkoutUserId session variable in case the user was previously logged in.
  useEffect(() => {
    Maybe.fromFalsy(!loginSuccess)
      .forEach(() => {
        removeSessionStorage('checkoutUserId')
      })
  }, [ loginSuccess ])

  // On initial render, fetch the credit payment experience for guest/anonymous users.
  useEffect(() => {
    Maybe.fromFalsy(!creditPaymentExperience)
      .forEach(() => {
        fetchCreditPaymentExperience()
      })
  }, [ creditPaymentExperience ])

  return (
    <Formik enableReinitialize={true} initialValues={initialFormValues}
      onSubmit={handleSubmit}
      validationSchema={localeSchema}>
      <Form data-component="Form">
        <LoadShippingOptions/>
        <SetShippingOption/>
        <UpdatePostalCode/>
        <UpdateCartShippingOption/>
        <UpdateCoupon/>
        <ValidateFormValues/>
        <SetReturningCustomerAddress/>
        <Email
          emailCheckboxLabel={propOr<string, string>('', 'emailCheckboxLabel', data)}
          emailDescription={emailDescription}
          emailFieldLabel={propOr<string, string>('', 'emailFieldLabel', data)}
          emailFieldPlaceholder={propOr<string, string>('', 'emailFieldPlaceholder', data)}
          emailSectionTitle={propOr<string, string>('', 'emailSectionTitle', data)}
          isMobile={isMobile}
          isValidCustomer={false}
          locale={locale}
          loginSuccess={loginSuccess}
          validatedDescription={validatedDescription}
        />
        <ShippingAddress
          cityFieldLabel={propOr<string, string>('', 'cityFieldLabel', data)}
          cityFieldPlaceholder={propOr<string, string>('', 'cityFieldPlaceholder', data)}
          countryFieldLabel={propOr<string, string>('', 'countryFieldLabel', data)}
          emailFieldPlaceholder={propOr<string, string>('', 'emailFieldPlaceholder', data)}
          firstNameFieldLabel={propOr<string, string>('', 'firstNameFieldLabel', data)}
          firstNameFieldPlaceholder={propOr<string, string>('', 'firstNameFieldPlaceholder', data)}
          isMobile={isMobile}
          lastNameFieldLabel={propOr<string, string>('', 'lastNameFieldLabel', data)}
          lastNameFieldPlaceholder={propOr<string, string>('', 'lastNameFieldPlaceholder', data)}
          locale={locale}
          phoneNumberFieldLabel={propOr<string, string>('', 'phoneNumberFieldLabel', data)}
          phoneNumberFieldPlaceholder={propOr<string, string>('', 'phoneNumberFieldPlaceholder', data)}
          postalCodeFieldLabel={propOr<string, string>('', 'postalCodeFieldLabel', data)}
          postalCodeFieldPlaceholder={propOr<string, string>('', 'postalCodeFieldPlaceholder', data)}
          sameAddressFieldLabel={propOr<string, string>('', 'sameAddressFieldLabel', data)}
          selectCountry={propOr<readonly string[], readonly string[]>([ '' ], 'selectCountry', data)}
          shippingSectionTitle={propOr<string, string>('', 'shippingSectionTitle', data)}
          stateFieldLabel={propOr<string, string>('', 'stateFieldLabel', data)}
          streetAddress2FieldLabel={propOr<string, string>('', 'streetAddress2FieldLabel', data)}
          streetAddress2FieldPlaceholder={propOr<string, string>('', 'streetAddress2FieldPlaceholder', data)}
          streetAddressFieldLabel={propOr<string, string>('', 'streetAddressFieldLabel', data)}
          streetAddressFieldPlaceholder={propOr<string, string>('', 'streetAddressFieldPlaceholder', data)}

        />
        <Coupons
          couponButtonLabel={propOr<string, string>( '', 'couponButtonLabel', data)}
          couponFieldLabel={propOr<string, string>('', 'couponFieldLabel', data)}
          couponSubmissionFail={couponSubmissionFail}
          couponSubmissionMessage={couponSubmissionMessage}
          couponSubmissionSuccess={couponSubmissionSuccess}
          couponsSectionTitle={propOr<string, string>('', 'couponsSectionTitle', data)}
          isMobile={isMobile}
          locale={locale}
          submitNewCouponOnClick={submitNewCouponOnClick}
          submitNewCouponOnEnter={submitNewCouponOnEnter}
        />
        <ShippingOptions
          isMobile={isMobile}
          shippingOptions={formattedShippingOptions}
          shippingOptionsSectionText={propOr<string, string>('', 'shippingOptionsSectionText', data)}
          shippingOptionsSectionTitle={propOr<string, string>('', 'shippingOptionsSectionTitle', data)}
        />
        <CustomerService
          additionDetailsFieldLabel={propOr<string, string>('', 'additionDetailsFieldLabel', data)}
          additionalDetailsFieldPlaceholder={propOr<string, string>('', 'additionalDetailsFieldPlaceholder', data)}
          epsilonAbacusOptInLabel={propOr<string, string>('', 'epsilonAbacusOptInLabel', data)}
          foundInfoThrough={propOr<readonly string[], readonly string[]>([ '' ], 'foundInfoThrough', data)}
          foundInfoThroughPlaceholder={propOr<string, string>('', 'foundInfoThroughPlaceholder', data)}
          isMobile={isMobile}
          locale={locale}
          sourceFieldLabel={propOr<string, string>('', 'sourceFieldLabel', data)}
          sourceSectionText={propOr<string, string>('', 'sourceSectionText', data)}
          sourceSectionTitle={propOr<string, string>('', 'sourceSectionTitle', data)}
        />
        <Submission
          cancelButtonLabel={propOr<string, string>('', 'cancelButtonLabel', data)}
          disableSubmit={disableSubmit}
          isMobile={isMobile}
          showErrorMessage={showErrorMessage}
          submissionErrorMessage={propOr<string, string>('', 'submissionErrorMessage', data)}
          submitButtonLabel={propOr<string, string>('', 'submitButtonLabel', data)}
        />
      </Form>
    </Formik>
  )
}


export default CheckoutForm
