import path from '@simplisafe/ewok/ramda/path'
import { safeHead, safeProp } from '@simplisafe/monda'
import { Maybe } from 'monet'

import type { DynamicPackageHeroBannerProps } from '.'

export const extractHeroBannerComponentByType = (data: DynamicPackageHeroBannerProps['data']) => (componentType: string) =>
  safeHead(
    safeProp('components', data)
      .map(components => components.filter(component => component && path([ 'internal', 'type' ], component) === componentType))
      .map(components =>
        components.map(component =>
          Maybe.fromNull(component)
        )
          .filter(val => val.isSome())
          .map(val => val.just())
      )
      .getOrElse([])
  )
