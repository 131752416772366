
import { DiscountCodeInfo } from '@commercetools/platform-sdk'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import transformObject from '@simplisafe/ewok/transformObject'
import { safeFind, safeProp } from '@simplisafe/monda'
import { ImmutableCart, LineItem } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import { formatCTPriceToNumber, localizedDisplayPrice } from '@simplisafe/ss-ecomm-data/commercetools/price'
import { QuantityChangerProps } from '@simplisafe/ss-react-components/atoms/QuantityChanger'
import { CartLineItemProps } from '@simplisafe/ss-react-components/molecules/CartLineItem'
import { Just } from 'monet'
import always from 'ramda/src/always'
import applySpec from 'ramda/src/applySpec'
import concat from 'ramda/src/concat'
import equals from 'ramda/src/equals'
import identity from 'ramda/src/identity'
import ifElse from 'ramda/src/ifElse'
import isEmpty from 'ramda/src/isEmpty'
import isNil from 'ramda/src/isNil'
import map from 'ramda/src/map'
import omit from 'ramda/src/omit'
// TODO replace with ts-functional-pipe
// eslint-disable-next-line no-restricted-imports
import pipe from 'ramda/src/pipe'
import propEq from 'ramda/src/propEq'
import subtract from 'ramda/src/subtract'
import unless from 'ramda/src/unless'

import { formatDisplayPrice, toMaybeOrNone } from '../../commercetools/price'
import { locale } from '../../commercetools/utils'
import { OptimizelyPurchaseEvent } from '../../tracking/optimizely'

export const getNameWithUSFallback = (item: { readonly name: Record<string, string> }): string => {
  const usName: string = path([ 'name', 'en-US' ], item)
  const localeName: string = path([ 'name', locale ], item)

  return localeName || usName
}


const toTextQuantity = (quantity: number) => concat(quantity.toString(), 'x')

const toQuantity = (onQuantityChange) => applySpec<QuantityChangerProps>({
  defaultValue: prop('quantity'),
  onChange: (x) => (quantity, type) => onQuantityChange(prop('lineItemId', x), quantity, x, type)
})

// TODO move this out of here and into contentful or commercetools
/** This is more hardcoded than it should be, but it works for now. */
/**
 * Adds the word "Included" before skus that end with "-INC".
 */
export function prefixIncSkuNameWithIncludedText<T extends { readonly sku: string; readonly name: Record<string, string> }>(item: T) {
  return item && item.sku && item.sku.includes('-INC')
    ? {
      ...omit([ 'name' ], item),
      name: {
        ...Object.keys(item.name)
          .reduce((acc, i) => ({
            ...acc,
            [i]: `Included ${item.name[i]}`
          }), {})
      }
    }
    : item
}

export const toSubItem = transformObject<LineItem & { readonly name: Record<string, string> }, { readonly subItemName: string; readonly subItemQuantity: number; readonly subItemSku: string }>({
  subItemName: pipe(prefixIncSkuNameWithIncludedText, getNameWithUSFallback), // something weird is going on and this function can be called with an undefined object.
  subItemQuantity: prop('quantity'),
  subItemSku: prop('sku')
})

const toItem = (onQuantityChange, onRemoveProduct, linkText?: string) => (x): CartLineItemProps => {
  const lineItemProp = {
    isFreeItem: prop('isGift'),
    // TODO: fix the type for child
    // the hild prop does not exist on unknown
    // @ts-ignore
    itemLink: pipe<LineItem, LineItem['child'], string>(prop('child'), unless(isEmpty, always(linkText))),
    itemName: getNameWithUSFallback,
    onClickRemove: always(unless(isNil, always(() => onRemoveProduct(x)))(onRemoveProduct)),
    packageParentId : path([ 'custom', 'fields', 'package_parent_id' ]),
    price: pipe(prop('price'), (p: number) => formatDisplayPrice(p).orJust('')),
    sku: prop('sku'),
    // @ts-ignore
    subItems: pipe(prop('child'), unless(isNil, map(toSubItem)))
  }
  //Quantity text will be shown when there is no quantity callback action related to quantity update
  return ifElse(
    equals(true),
    always(
      applySpec<CartLineItemProps>({
        ...lineItemProp,
        quantityText: pipe(prop('quantity'), toTextQuantity)
      })(x)
    ),
    always(
      applySpec<CartLineItemProps>({
        ...lineItemProp,
        quantity: toQuantity(onQuantityChange)
      })(x)
    )
  )(isNil(onQuantityChange) || prop('isGift', x) || propEq('productType', 'service')(x))
}

export const toItemList = (lineItems) => (onQuantityChange?, onRemoveProduct?, linkText?: string) =>
  Just(lineItems).map(x => map(toItem(onQuantityChange, onRemoveProduct, linkText), x))

/**
 * Returns display string for cart subtotal, which represents the cart total less discounts,
 * less shipping, and optionally less taxes. en-GB is VAT-included pricing, so that remains.
 *
 * TODO move this logic to ecomm-data or maybe CT if aware of locale-based tax inclusion
 *
 * @param locale
 * @param cart
 */
export const getCartSubtotal =
  (locale: string) =>
    (cart: ImmutableCart) => {
      const shouldIncludeTax = locale === 'en-GB'
      return Just(cart.get('totalPrice'))
        .map(totalPrice => subtract(totalPrice, getShippingInfoPrice(cart).orJust(0)))
        .map(totalPrice =>
          shouldIncludeTax
            ? totalPrice
            : subtract(totalPrice, cart.get('taxedPrice').orJust(0))
        )
        .chain(localizedDisplayPrice(locale))
    }

/**
 * @see transformLineItem.getCartSubtotal with local locale included
 */
export const getLocalizedCartSubtotal = getCartSubtotal(locale)

export const toTrackCartItem = transformObject<LineItem, OptimizelyPurchaseEvent>({
  price: (x) => prop('totalPrice', x),
  productType: (x) => prop('productType', x),
  qty: (x) => prop('quantity', x),
  sku: (x) => prop('sku', x),
})

export const toTrackCartItemsList = (lineItems: ReadonlyArray<LineItem>) => lineItems.map(x => toTrackCartItem(x))

export const toDiscountApplied = (discountAppliedText: string, discountCode?: string) => applySpec<CartLineItemProps>({
  isHighlightedLineItem: always(true),
  itemName: () => `${discountCode} ${discountAppliedText}`,
  price: identity,
})

export const getShippingInfoPrice =
  (c: ImmutableCart) =>
    safeProp('shippingInfo', c)
      .chain(toMaybeOrNone)
      .chain(safeProp('price'))
      .map(formatCTPriceToNumber)

export const getCartDiscountValue = (cart: ImmutableCart): string | null => {
  const discountAmount = cart.totalCartDiscount * -1
  return discountAmount < 0 ? formatDisplayPrice(discountAmount).orJust('') : null
}

export const getCartDiscountCode =
  (c: ImmutableCart) =>
    safeProp('discountCodes', c)
      .chain(toMaybeOrNone)
      .chain((safeFind<DiscountCodeInfo>(propEq('state', 'MatchesCart'))))
      .chain(safeProp('discountCode'))
      .chain(safeProp('obj'))
      .chain(safeProp('code'))
      .getOrElse('')
