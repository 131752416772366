import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import { safePath, safeProp } from '@simplisafe/monda'
import { graphql, useStaticQuery } from 'gatsby'
import { Maybe } from 'monet'
import always from 'ramda/src/always'
import assocPath from 'ramda/src/assocPath'
import cond from 'ramda/src/cond'
// TODO replace with ts-functional-pipe
// eslint-disable-next-line no-restricted-imports
import pipe from 'ramda/src/pipe'
import T from 'ramda/src/T'
import React from 'react'
import { Helmet } from 'react-helmet'
import { helmetJsonLdProp } from 'react-schemaorg'

import {
  ContentfulSeo, ContentfulSeoSchema, ContentfulSeoSchemaJsonNode
} from '../../graphql'
import useRequestPrice from '../hooks/useRequestPrice'
import { jsonObjectRemoveUnderscore } from './helper'

/**
 * Property that will be used for SEO
 * metaDescription: string value of metaDescription
 * cannonicalLink: string value of cannonical link url
 * metaTitle: string value of metaTitle
 * lang: string value for html attribute language
 * metaKeyword: string value of metaKeyword
 */
type Props = {
  readonly metaDescription?: string
  readonly lang?: string
  readonly canonicalLink?: string
  readonly meta?: readonly []
  readonly metaTitle: string
  readonly metaKeyword?: string
  readonly seoDetails?: Partial<ContentfulSeo>
  readonly pageSchema?: Partial<ContentfulSeoSchema>
  readonly pageUrl: string
  readonly productId: Maybe<string>
};

type SchemaReformatted = Partial<ContentfulSeoSchemaJsonNode>

const SCHEMA_KEY_MAP = {
  context: '@context',
  type: '@type',
}

const SCHEMA_KEY_MAP_OLD = {
  _context: '@context',
  _type: '@type',
}

const SEO = ({
  metaDescription, lang, meta, canonicalLink, metaTitle, metaKeyword, pageSchema, pageUrl, seoDetails, productId
}: Props) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const metaDataDescription: string = metaDescription || site.siteMetadata.description
  const {
    isNoindex, isNofollow, schema
  } = seoDetails || {}

  const metaRobot = cond([
    [ () => (isNoindex === false && isNofollow === false), always('') ],
    [ () => (isNoindex !== false && isNofollow === false), always('noindex') ],
    [ () => (isNoindex === false && isNofollow !== false), always('nofollow') ],
    [ T, always('noindex, nofollow') ],
  ])()

  const {
    getPrice, getDiscountedPrice, getDiscountedPriceWithServicePlan
  } = useRequestPrice(productId.orJust(''))

  const productImage: string | undefined = safePath([ 'productImage', 'file', 'url' ], seoDetails).orUndefined()

  // Update some of object keys to match Schema.org format
  const reformatJsonPageSchema = (pageSchema: Partial<ContentfulSeoSchema>) => {
    Object.keys(pageSchema).forEach(key => {
      const schemaValue = pageSchema[key]
      const jsonData = safeProp('jsonData', schemaValue).orUndefined()
      pageSchema[key] = jsonData ? jsonObjectRemoveUnderscore(jsonData) : schemaValue
    }
    )
    const jsonStr = JSON.stringify(pageSchema)
    const reformattedJson =  Object.keys(SCHEMA_KEY_MAP).reduce((previousValue, currentKey) => {
      return previousValue.replace(new RegExp(currentKey, 'g'), SCHEMA_KEY_MAP[currentKey])
    }, jsonStr)
    return JSON.parse(reformattedJson)
  }

  const productSku: string | undefined = productId.cata(
    () => undefined,
    x => x
  )

  const isPlaProduct = productId.map(_productId => _productId.includes('sscs3-pla-')).orJust(false)

  // Product specific Schema.org fields
  const productSchemaFields = () => {
    return {
      description: metaDataDescription,
      image: productImage ? `https:${productImage}` : undefined,
      name: metaTitle,
      sku: productSku
    }
  }

  // return object with all values for Product Schema (Schema.org)
  const allProductSchema = (pageSchema: Partial<ContentfulSeoSchema>) => {
    const pageSchemaDefault = reformatJsonPageSchema(pageSchema)
    pageSchemaDefault.offers['url'] = `${site.siteMetadata.siteUrl}/${pageUrl}`
    const productSchema = productSchemaFields()

    const productDiscountedPrice = isPlaProduct ? getDiscountedPrice : getDiscountedPriceWithServicePlan
    const productFinalPrice = productDiscountedPrice.catchMap(() => getPrice)
    pageSchemaDefault.offers['price'] = productFinalPrice.orUndefined()

    return [
      helmetJsonLdProp<SchemaReformatted>({
        ...pageSchemaDefault,
        ...productSchema
      })
    ]
  }

  /**
   * Function to reformat the schema object from contentful.
   * Currently the response from Contentful is slightly different with the actual json that we put on the json field
   * Somehow the object key started with '@' are replaced into '_', ('_context' & '_type' are supposed to be '@context' and '@type' )
   * So the purpose of this function is to revert them back to the original keys.
   * @param schema : Schema object from Contentful
   * @returns reformattedSchema
   */
  const reformatJsonSchema = (schema: ContentfulSeoSchemaJsonNode) => {
    const jsonStr = JSON.stringify(schema)
    const reformattedJson =  Object.keys(SCHEMA_KEY_MAP_OLD).reduce((previousValue, currentKey) => {
      return previousValue.replace(new RegExp(currentKey, 'g'), SCHEMA_KEY_MAP_OLD[currentKey])
    }, jsonStr)
    return JSON.parse(reformattedJson)
  }

  const assignSalePriceToSchema = (schema: ContentfulSeoSchemaJsonNode) => {
    const discountedPrice = getDiscountedPrice.orUndefined()
    return discountedPrice ? assocPath([ 'offers', 'sale_price' ], discountedPrice.toString(), schema) : schema
  }

  const schemaOrg = schema
    ? [
      /**
       * According to the docs, the type can be obtained from schema-dts module,
       * but since it will causing betterer issue, we use generated type from Contentful instead
       */
      helmetJsonLdProp<SchemaReformatted>(
        pipe(
          reformatJsonSchema,
          assignSalePriceToSchema
        )(schema)
      )
    ]
    : []

  const seoSchemaOrg = (isNotNil(pageSchema) && isNotNil(productSku)) ? allProductSchema(pageSchema) : schemaOrg

  return (
    <Helmet
      htmlAttributes={{ lang }}
      link={
        canonicalLink
          ? [
            {
              href: canonicalLink,
              rel: 'canonical',
            },
          ]
          : []
      }
      meta={[
        {
          content: metaDataDescription,
          name: 'description',
        },
        {
          content: metaTitle,
          property: 'og:title',
        },
        {
          content: metaDataDescription,
          property: 'og:description',
        },
        {
          content: 'website',
          property: 'og:type',
        },
        {
          content: 'summary',
          name: 'twitter:card',
        },
        {
          content: site.siteMetadata.author,
          name: 'twitter:creator',
        },
        {
          content: metaTitle,
          name: 'twitter:title',
        },
        {
          content: metaDataDescription,
          name: 'twitter:description',
        },
        {
          content: metaKeyword,
          name: 'keywords',
        },
        {
          ...metaRobot && {
            content: metaRobot,
            name: 'robots',
          }
        }
      ].concat(meta || [])}
      script={[
        ...seoSchemaOrg
      ]}
      title={metaTitle}
    />
  )
}

export default SEO
