import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import { safeProp } from '@simplisafe/monda'
import { chainProp } from '@simplisafe/monda/chain'
import { selectCustomerGroupKey } from '@simplisafe/ss-ecomm-data/cart/select'
import { Package } from '@simplisafe/ss-ecomm-data/packages'
import { Prices, requestPrices } from '@simplisafe/ss-ecomm-data/prices/service'
import { Product } from '@simplisafe/ss-ecomm-data/products'
import { selectItemsFromSkus } from '@simplisafe/ss-ecomm-data/redux/select'
import { Price } from '@simplisafe/ss-react-components/atoms'
import { Maybe, None } from 'monet'
import React, {
  createContext,
  ReactNode, useContext,
  useEffect, useState
} from 'react'
import { useSelector } from 'react-redux'

import {
  formatDisplayPrice, formatPercentage, safeIsNotNan
} from '../../commercetools/price'
import useOptimizelyParams from '../../hooks/useOptimizelyParams'
import { userAttributes } from '../../tracking/optimizely'
import { findFirstJust } from '../../util/helper'
import { PriceFormatter } from './formatter'
import { PriceContextProps } from './types'

const PriceContext = createContext<PriceContextProps>({
  getDiscountedPrice: (_sku: string) => None(),
  getDiscountedPriceWithServicePlan: (_sku: string) => None(),
  getDiscountedText: (_sku: string) => None(),
  getDiscountedTextWithServicePlan: (_sku: string) => None(),
  getFormattedPrice: (_skuID) => (_formatter, _showDiscountedPrice) => null,
  getPrice: (_sku: string) => None()
})

export const usePriceContext = () => useContext(PriceContext)

const formatPrice = (price: Maybe<number>, formatter: PriceFormatter) =>
  price
    .chain(safeIsNotNan)
    .chain(formatDisplayPrice)
    .map(formatter)
    .orUndefined()

/**
 * Renders formatted price details for the given sku, optionally including strikethrough price (default: true) and service plan discount (default: false).
 */
export const getFormattedPriceHelper = (prices: Prices, fallbacks: Record<string, Product | Package>, isLoading: boolean) =>
  (sku: string) => (formatter: PriceFormatter, showDiscountedPrice = true, isServiceDiscount = true) => {
    const price = getRawPrice(prices, fallbacks, isLoading)(sku)
    const discountedPrice = getRawDiscountedPrice(prices, fallbacks, isLoading)(sku)
    const discountedPriceWithServicePlan = getRawDiscountedPriceWithServicePlan(prices, fallbacks, isLoading)(sku)
    const finalDiscountedPrice = isServiceDiscount && discountedPriceWithServicePlan.isSome() ? discountedPriceWithServicePlan : discountedPrice
    const hasDiscount = finalDiscountedPrice.map(discountedPrice => price.cata(() => false, _price => discountedPrice !== _price)).orJust(false)

    return <Price
      discountedPrice={showDiscountedPrice && hasDiscount ? formatPrice(finalDiscountedPrice, formatter) : undefined}
      regularPrice={formatPrice(price, formatter)}
    />
  }

type PriceProviderProps = {
  readonly children: ReactNode | readonly ReactNode[]
  readonly skus: readonly string[]
}

const excludedSkus = [ 'SSPSH-ON' ]

const isPLAsku = (sku: string) => sku.includes('sscs3-pla-')

const divideByFractionDigits = (sku: string, prices: Prices) => (price: number) => {
  const fractionDigits = safeProp(sku, prices)
    .chain(safeProp('fractionDigits'))
    .orJust(0)
  return price / Math.pow(10, fractionDigits)
}

const getPriceData = (sku: string, prices: Prices) =>
  safeProp(sku, prices)
    .filter(priceData => isNotNil(priceData.price))

export const getRawPrice = (prices: Prices, fallbacks: Record<string, Product | Package>, isLoading: boolean) => (sku: string) => getPriceData(sku, prices)
  .cata<Maybe<number>>(
    () => isPLAsku(sku) && isLoading ? None() : safeProp(sku, fallbacks).chain(safeProp('price')),
    _priceData => safeProp('price', _priceData).map(divideByFractionDigits(sku, prices))
  )

export const getRawDiscountedPrice = (prices: Prices, fallbacks: Record<string, Product | Package>, isLoading: boolean) => (sku: string) => getPriceData(sku, prices)
  .cata<Maybe<number>>(
    () => isLoading ? None() : safeProp(sku, fallbacks).chain(chainProp('discountedPrice')),
    _priceData => safeProp('discountedPrice', _priceData).map(divideByFractionDigits(sku, prices))
  )

export const getRawDiscountedPriceWithServicePlan = (prices: Prices, fallbacks: Record<string, Product | Package>, isLoading: boolean) => (sku: string) => getPriceData(sku, prices)
  .cata<Maybe<number>>(
    () => isLoading ? None() : safeProp(sku, fallbacks).chain(chainProp('discountedPriceWithServicePlan')),
    _priceData => safeProp('discountedPriceWithServicePlan', _priceData).map(divideByFractionDigits(sku, prices))
  )

export const getDiscountedTextHelper = (prices: Prices, fallbacks: Record<string, Product | Package>, isLoading: boolean) => (sku: string) => getPriceData(sku, prices)
  .cata<Maybe<string>>(
    () => isLoading ? None() : safeProp(sku, fallbacks).chain(product => product['@@type'] === 'package'
      ? findFirstJust([
        product.relativeDiscount.map(formatPercentage),
        product.absoluteDiscount.chain(formatDisplayPrice),
      ])
      : None()),
    _priceData => {
      const absoluteDiscount = safeProp('absoluteDiscount', _priceData)
        .map(price => price / 100)
        .chain(formatDisplayPrice)

      const relativeDiscount = safeProp('relativeDiscount', _priceData)
        .map(price => price / 100)
        .map(formatPercentage)

      return findFirstJust([ absoluteDiscount, relativeDiscount ])
    }
  )

export const getDiscountedTextWithServicePlanHelper = (prices: Prices, fallbacks: Record<string, Product | Package>, isLoading: boolean) => (sku: string) => getPriceData(sku, prices)
  .cata<Maybe<string>>(
    () => isLoading ? None() : safeProp(sku, fallbacks).chain(product => product['@@type'] === 'package'
      ? findFirstJust([
        product.relativeDiscountWithServicePlan.map(formatPercentage),
        product.absoluteDiscountWithServicePlan.chain(formatDisplayPrice)
      ])
      : None()),
    _priceData => {
      const absoluteDiscount = safeProp('absoluteDiscountWithServicePlan', _priceData)
        .map(price => price / 100)
        .chain(formatDisplayPrice)

      const relativeDiscount = safeProp('relativeDiscountWithServicePlan', _priceData)
        .map(price => price / 100)
        .map(formatPercentage)

      return findFirstJust([ absoluteDiscount, relativeDiscount ])
    }
  )

export const PriceProvider = ({ children, skus }: PriceProviderProps) => {
  const [ prices, setPrices ] = useState<Prices>({})
  const [ isLoading, setLoading ] = useState(true)
  const customerGroup = useSelector(selectCustomerGroupKey)
  const optimizelyParams = useOptimizelyParams()

  const fallbacks = useSelector(selectItemsFromSkus(skus))

  useEffect(() => {
    const includedSkus = skus.filter(sku => !excludedSkus.includes(sku))
    const attributes = userAttributes()
    includedSkus.length > 0 && requestPrices(includedSkus, attributes, customerGroup.orUndefined(), optimizelyParams)(() => {
      setLoading(false)
    })(response => {
      response.forEach(newPrices => setPrices(newPrices))
      setLoading(false)
    })
  // TODO: With exhaustive deps, the pricing service gets hit ~10 times per page load. Something in Page/index.tsx is creating unnecessary rerenders
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ ])

  return (
    <PriceContext.Provider value={{
      getDiscountedPrice: getRawDiscountedPrice(prices, fallbacks, isLoading),
      getDiscountedPriceWithServicePlan: getRawDiscountedPriceWithServicePlan(prices, fallbacks, isLoading),
      getDiscountedText: getDiscountedTextHelper(prices, fallbacks, isLoading),
      getDiscountedTextWithServicePlan: getDiscountedTextWithServicePlanHelper(prices, fallbacks, isLoading),
      getFormattedPrice: getFormattedPriceHelper(prices, fallbacks, isLoading),
      getPrice: getRawPrice(prices, fallbacks, isLoading)
    }}>
      {children}
    </PriceContext.Provider>
  )
}
