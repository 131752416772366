import prop from '@simplisafe/ewok/ramda/prop'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { graphql } from 'gatsby'
import React, { FC } from 'react'

import { ContentfulDeviceVariations } from '../../../graphql'
import { getMappedComponent } from '../../componentMappings'

type DeviceVariationsComponentProps = {
  readonly data: ContentfulDeviceVariations
}

const DeviceVariationsComponent: FC<DeviceVariationsComponentProps> =
  ({ data }: DeviceVariationsComponentProps) => {

    const isMobile = !useMediaQuery('TabletAndUp')
    const isDesktop = useMediaQuery('DesktopAndUp')
    const mobileComponent = prop('mobile', data) || undefined
    const tabletComponent = prop('tablet', data) || undefined
    const desktopComponent = prop('desktop', data) || undefined

    const dataComponent = isMobile ? mobileComponent : isDesktop ? desktopComponent : tabletComponent
    const Component = dataComponent && getMappedComponent(dataComponent)
    return ( Component ?
      <Component data={dataComponent}/> : <></>
    )
  }

export const query = graphql`
  fragment deviceVariationsFragment on ContentfulDeviceVariations {
    id
    internal {
      type
    }
    mobile {
        ... on ContentfulGroupSection {
            ...nonCyclicalGroupSectionFragment
        }
    }
    desktop{
        ... on ContentfulTwoColumn {
          ...contentfulTwoColumnFragment
        }
        ... on ContentfulGroupSection {
            ...nonCyclicalGroupSectionFragment
        }
    }
    tablet{
        ... on ContentfulTwoColumn {
          ...contentfulTwoColumnFragment
        }
        ... on ContentfulGroupSection {
            ...nonCyclicalGroupSectionFragment
        }
    }
  }
`

export default DeviceVariationsComponent
