import { LineItem } from '@simplisafe/ss-ecomm-data/commercetools/cart'

import { getCommerceDataFromLineItem, TrackEvent } from '.'

const GTM_REMOVE_CART_EVENT = 'eec.remove'
const GTM_REMOVE_CART_CATEGORY = 'eec'
const GTM_REMOVE_CART_ACTION = 'removeFromCart'

export const trackRemoveCartEvent = (lineItem: LineItem, trackEvent: TrackEvent, lineItems: readonly LineItem[]) => {
  trackEvent({
    ecommerce: getCommerceDataFromLineItem('remove')(lineItems)(lineItem),
    event: 'removeFromCart'
  })

  trackEvent({
    ecommerce: getCommerceDataFromLineItem('remove')(lineItems)(lineItem),
    event: GTM_REMOVE_CART_EVENT,
    eventAction: GTM_REMOVE_CART_ACTION,
    eventCategory: GTM_REMOVE_CART_CATEGORY
  })
}
