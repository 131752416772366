import { Document } from '@contentful/rich-text-types'
import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import { selectCart } from '@simplisafe/ss-ecomm-data/redux/select'
import {
  Column, Row, Text
} from '@simplisafe/ss-react-components/atoms'
import { AffirmPromoMessage } from '@simplisafe/ss-react-components/molecules'
import { window } from 'browser-monads-ts'
import { Maybe } from 'monet'
import React, { FC, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

import { ContentfulAsset, Scalars } from '../../../../graphql'
import { useOptimizelyAffirm, useOptimizelyTrackSiteEvents } from '../../../tracking/optimizely'
import { AffirmClient } from '../../../types/affirm'
import getJson from '../../../util/getJson'
import FixedImg from '../../FixedImg'
import RichText from '../../RichText'

export type CheckoutButton = Maybe<Pick<ContentfulAsset, 'title' | 'description' | 'fixed'>>

export type AffirmCheckoutContentProps = {
  readonly affirmClient?: AffirmClient
  readonly checkoutButton: CheckoutButton
  readonly instructions: Maybe<{readonly json?: Scalars['JSON'] | null}>
  readonly note: Maybe<{readonly json?: Scalars['JSON'] | null}>
  readonly onOrderSubmit: () => void
}

const AFFIRM_CHECKOUT_ID = 'affirm-checkout'

const AffirmCheckoutContent: FC<AffirmCheckoutContentProps> = ({
  // allows passing in a different value for affirmClient in unit tests
  affirmClient = window.affirm,
  checkoutButton,
  instructions,
  note,
  onOrderSubmit
}: AffirmCheckoutContentProps) => {
  const cart = useSelector(selectCart)
  const { Track, trackEvent } = useTracking()
  const optimizelyAffirm = useOptimizelyAffirm()
  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()

  const onCheckoutButtonClick = useCallback(() => {
    trackEvent({ event: 'affirmCheckoutClick' })
    optimizelyTrackSiteEvents({ eventType: 'select_affirm_in_checkout' })
    onOrderSubmit()
  }, [ onOrderSubmit, optimizelyTrackSiteEvents, trackEvent ])

  return (
    affirmClient ? (
      <Track>
        <Row
          dataComponent='AffirmCheckoutContent'
          id={AFFIRM_CHECKOUT_ID}
          padding='small'>
          <Column>
            {
              cart.map(_cart => <AffirmPromoMessage affirmClient={affirmClient}
                elementType='span'
                key='affirm-promo-msg'
                onLearnMoreClick={optimizelyAffirm}
                pageType='cart'
                price={_cart.totalPrice}
                textSize='md' />).orNull()
            }
            {instructions.map(getJson)
              .map((json: Document) => <RichText json={json}
                key='affirm-instructions' />
              )
              .orNull() }
            { checkoutButton
              .chain(img => Maybe.fromNull(prop('fixed', img)))
              .map(fixed =>
                <Text key='affirm-checkout-link'
                  textSize='sm'>
                  <a href={`#${AFFIRM_CHECKOUT_ID}`}
                    onClick={onCheckoutButtonClick}
                  >
                    <FixedImg
                      alt={checkoutButton.chain(safeProp('description')).orJust('')}
                      fixed={fixed}
                      loading='eager'
                    />
                  </a>
                  {note.map(getJson)
                    .map((json: Document) => <RichText json={json}
                      key='affirm-note'
                      textSize='sm' />)
                    .orNull()}
                </Text>
              )}
          </Column>
        </Row>
      </Track>
    ) : null
  )
}

export default AffirmCheckoutContent
