import { EmailFormSchema } from '@simplisafe/ss-ecomm-data/leads/emailFormSchema'
import {
  Column, FormField, FormTextInput,
  Row,
  SimpleButton,
  Text
} from '@simplisafe/ss-react-components/atoms'
import { Spans } from '@simplisafe/ss-react-components/atoms/Column'
import { useMediaQuery, useUniqueId } from '@simplisafe/ss-react-components/hooks'
import { Form, Formik } from 'formik'
import { Link } from 'gatsby'
import propOr from 'ramda/src/propOr'
import React, { ReactNode, useState } from 'react'

import useCookieChange from '../../hooks/useCookieChange'
import { COOKIE_LEAD_DATA, getLeadData } from '../../tracking/cookies'
import { SimpliSafeCSSProperties } from '../../types/SimpliSafeCSSProperties'
import { leadingSlashIt } from '../../util/helper'

type FormStyles = 'stack' | 'full' | 'auto'

const columns: Record<string, readonly Spans[]> = {
  auto: [ [ 12, 8 ], [ 12, 4 ] ],
  full: [ [ 8 ], [ 4 ] ],
  stack: [ [ 12 ], [ 12 ] ]
}

const getColumns = (style: FormStyles) => columns[style]

export type LeadCaptureFormPropsOnSubmit = (_email: string, _onFailure: (_errorText: string) => void) => void

export type LeadCaptureFormProps = {
  /** Button styles to apply to the form submit button and ctaButton after form submission. */
  readonly buttonStyles?: SimpliSafeCSSProperties
  /** Variant for submit and CTA buttons */
  readonly buttonVariant?: 'solid' | 'outlined'
  /** The link for the CTA button after form submission, e.g. "/home-security-shop" */
  readonly ctaButtonHref: string
  /** Text to show on CTA button after form submission, e.g. "shop now" */
  readonly ctaButtonText: string
  /** Whether to hide the email field label. Default: true */
  readonly hideLabel?: boolean
  /** If true, shows the success message instead of the form. Default: false */
  readonly isSubmitted?: boolean
  /** The email field label */
  readonly label: string
  /** Optional legal text to show below the email field */
  readonly legal?: ReactNode
  /** Function to call on form submission. The onFailure callback takes error text to be shown below the email field. */
  readonly onSubmit: LeadCaptureFormPropsOnSubmit
  /** Placeholder text for the email field */
  readonly placeholder?: string
  /** Whether to show the CTA button after form submission. Default: true */
  readonly showCta?: boolean
  /** Whether to show the submit button next to the email input (full), below it (stack), or decide automatically based on screen size (auto). Default: auto */
  readonly style?: FormStyles
  /** Text to be shown on the email submit button */
  readonly submitButtonText: string
  /** Message to show after successful email submission */
  readonly successMessage: string
}

const LeadCaptureForm = ({
  buttonStyles,
  buttonVariant = 'solid',
  hideLabel = true,
  isSubmitted = false,
  label,
  legal,
  onSubmit,
  placeholder,
  showCta = true,
  style = 'auto',
  submitButtonText,
  ctaButtonHref,
  ctaButtonText,
  successMessage
}: LeadCaptureFormProps) => {
  const columns = getColumns(style)
  const isTabletUp = useMediaQuery('TabletAndUp')
  const fieldId = useUniqueId('LeadCaptureForm')
  const [ defaultEmail, setDefaultEmail ] = useState(propOr<string, string>('', 'email', getLeadData()))
  useCookieChange(COOKIE_LEAD_DATA, data => {
    setDefaultEmail(propOr('', 'email', JSON.parse(data)))
  })

  const submitButton = <SimpleButton padding={false}
    size='full'
    style={buttonStyles}
    type='submit'
    variant={buttonVariant}
  >{submitButtonText}</SimpleButton>

  return !isSubmitted
    ? (<Formik
      enableReinitialize={true}
      initialValues={{ email: defaultEmail }}
      onSubmit={(values, formikBag) => {
        onSubmit(
          values.email,
          errorText => formikBag.setErrors({ 'email': errorText })
        )
      }}
      validationSchema={EmailFormSchema}
    >
      <Form data-component="LeadCaptureForm" data-style={style}>
        <FormField hideLabel={hideLabel}
          id={fieldId}
          label={label}
          labelPosition='top'
          name='email'>
          <Row gap='small' inheritTextColor={true}>
            <Column alignSelf='end' spans={columns[0]}>
              <Text inheritTextColor={true} textSize='sm'
                useTailwind={true}>
                <FormTextInput fullWidth={true}
                  id={fieldId}
                  name='email'
                  placeholder={placeholder}
                  type='email'
                />
              </Text>
            </Column>
            {isTabletUp && (
              <Column
                alignSelf='end'
                spans={columns[1]}>
                <Text dataComponent='DesktopSubmitButton' useTailwind={true}>{submitButton}</Text>
              </Column>
            )}
          </Row>
        </FormField>
        <Row gap='none' inheritTextColor={true}>
          <Column spans={[ 12 ]}>
            {!isTabletUp && (
              <Text className='m1_t' dataComponent='MobileSubmitButton'
                useTailwind={true}>{submitButton}</Text>
            )}
            {legal && (
              <Text className='m1_t'
                inheritTextColor={true}
                textAlignment={ isTabletUp ? 'left' : 'center' }
                textSize='xs'
                useTailwind={true}
              >
                {legal}
              </Text>
            )}
          </Column>
        </Row>
      </Form>
    </Formik>
    )
    : (
      <Row inheritTextColor={true}>
        <Column>
          {showCta && (
            <Text useTailwind={true}>
              <Link to={leadingSlashIt(ctaButtonHref)}>
                <SimpleButton
                  size='full'
                  style={buttonStyles}
                  variant={buttonVariant}
                >
                  {ctaButtonText}
                </SimpleButton>
              </Link>
            </Text>
          )}
          <Text className='m1_t'
            inheritTextColor={true}
            textAlignment="center"
            textSize='sm'
            useTailwind={true}>
            {successMessage}
          </Text>
        </Column>
      </Row>
    )
}

export default LeadCaptureForm
