import { safePath, safeProp } from '@simplisafe/monda'
import { HeaderPartner } from '@simplisafe/ss-react-components/atoms'
import { graphql } from 'gatsby'
import type { FluidObject } from 'gatsby-image'
import Img from 'gatsby-image'
import { Maybe } from 'monet'
import type { FC, ReactNode } from 'react'
import React from 'react'

import type { HeaderPartnerFragment } from '../../../graphql'

export type HeaderPartnerComponentProps = {
  readonly data: HeaderPartnerFragment
}

// This type refers to a ContentfulAsset. If its an image file type, the
// response sets fluid key to ContentfulFluid props. However, this value is null
// if its an SVG. Therefore, we have to use the file url on a regular img tag.
type ImageData = HeaderPartnerFragment['logoPartner'] | null

const getAltTextFromImageData = (imgData: ImageData): string => Maybe.fromNull(imgData)
  .chain(safeProp('description'))
  .getOrElse('')

// Outputs SVG as a regular img tag.
const toImg = (imgData: ImageData): ReactNode =>
  Maybe.fromNull(imgData)
    .chain(safePath([ 'file', 'url' ]))
    .cata(
      () => null,
      (fileUrl: string) => <img
        alt={getAltTextFromImageData(imgData)}
        key={fileUrl}
        src={fileUrl}
        width='200' />
    )

const toGatsbyImage = (imgData: ImageData, position: string): ReactNode =>
  Maybe.fromNull(imgData)
    .chain(safeProp('fluid'))
    .cata(
      () => null,
      fluid => {
        return (<Img
          alt={getAltTextFromImageData(imgData)}
          fluid={fluid as FluidObject}
          imgStyle={{
            objectFit: 'contain',
            objectPosition: position,
          }}
          style={{
            height: '100%',
            width: '100%'
          }}
        />) }
    )

// Renders either a Gatsby <Img /> component, or a regular <img /> tag if src is svg.
const renderImage = (imgData: ImageData, position: string): ReactNode => {
  const contentType = Maybe.fromNull(imgData)
    .chain(safePath([ 'file', 'contentType' ]))
    .getOrElse('')

  return contentType === 'image/svg+xml' ? toImg(imgData) : toGatsbyImage(imgData, position)
}

const HeaderPartnerComponent: FC<HeaderPartnerComponentProps> = ({ data }: HeaderPartnerComponentProps) => {

  const {
    id,
    logoPartner,
    logoSimpliSafe,
    backgroundColor,
    mergerText,
    offerText
  } = data

  return (
    <HeaderPartner
      backgroundColor={backgroundColor || ''}
      key={id}
      logoPartner={renderImage(logoPartner, 'left')}
      logoSimpliSafe={renderImage(logoSimpliSafe, 'right')}
      mergerText={mergerText}
      offerText={offerText}
    />
  )
}

export default HeaderPartnerComponent

export const HeaderPartnerQuery = graphql`#graphql
  fragment headerPartner on ContentfulHeaderPartner {
    id
    internal {
      type
    }
    logoPartner {
      description
      file{
        url
        contentType
      }
      fluid(maxWidth: 200) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
      id
    }
    logoSimpliSafe {
      description
      file{
        url
        contentType
      }
      fluid(maxWidth: 200) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
      id
    }
    backgroundColor,
    mergerText,
    offerText
  }
`
