import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import { selectTopBannerVisible } from '@simplisafe/ss-ecomm-data/redux/select'
import { SSButtonProps } from '@simplisafe/ss-react-components/atoms/SSButton'
import { CardItemSmallBanner } from '@simplisafe/ss-react-components/molecules'
import { CardItemSmallBannerProps } from '@simplisafe/ss-react-components/molecules/CardItemSmallBanner'
import { graphql } from 'gatsby'
import always from 'ramda/src/always'
import applySpec from 'ramda/src/applySpec'
import defaultTo from 'ramda/src/defaultTo'
import equals from 'ramda/src/equals'
import join from 'ramda/src/join'
// TODO replace with ts-functional-pipe
// eslint-disable-next-line no-restricted-imports
import pipe from 'ramda/src/pipe'
import split from 'ramda/src/split'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { ContentfulCardItemSmallBannerFragment } from '../../../graphql'
import { usePriceContext } from '../../providers/PriceProvider'
import { priceDefault } from '../../providers/PriceProvider/formatter'
import getJson from '../../util/getJson'
import { toFirstCharLower } from '../../util/helper'
import FluidImg from '../FluidImg'

type CardItemSmallBannerComponentProps = {
  readonly id?: string
  readonly data: Partial<ContentfulCardItemSmallBannerFragment>
}

const toButtonProps = applySpec<SSButtonProps>({
  color: pipe(prop('type'), defaultTo('primaryOutline'), toFirstCharLower, split(' '), join('')),
  href: path([ 'button', 'url' ]),
  type: always('link')
})

const toImageProps = (data: Partial<ContentfulCardItemSmallBannerFragment>) => {
  return <FluidImg alt={path([ 'image', 0, 'file', 'url' ], data)} fluid={path([ 'image', 0, 'fluid' ], data)}/>
}

const toCardItemSmallBannerData = (data: Partial<ContentfulCardItemSmallBannerFragment>) => {
  const toData = applySpec<CardItemSmallBannerProps>({
    additionalInfo: path([ 'additionalInfo', 'json' ]),
    bannerImage: toImageProps,
    buttonProps: toButtonProps,
    buttonText: path([ 'button', 'text' ]),
    productDescription: path([ 'productDescription', 'json' ]),
    productHighlights: path([ 'productHighlights', 'json' ]),
    title: path([ 'title' ])
  })
  return toData(data)
}


const CardItemSmallBannerComponent: FC<CardItemSmallBannerComponentProps> =
  ({ data }: CardItemSmallBannerComponentProps) => {
    const props = toCardItemSmallBannerData(data)

    const skuID =  safeProp('productId', data)
    const isPromoTopBanner = useSelector(selectTopBannerVisible)
    // For US, when the top banner promo is displayed then priority should be given to discount without plan.
    const isNoPlanDiscount = isPromoTopBanner && equals('en-US', process.env.LOCALE)

    const {
      getDiscountedText, getDiscountedTextWithServicePlan, getFormattedPrice
    } = usePriceContext()
    const price = getFormattedPrice(skuID.orJust(''))(priceDefault, true, !isNoPlanDiscount)

    const discountedText = getDiscountedText(skuID.orJust(''))
    const discountedTextWithServicePlan = getDiscountedTextWithServicePlan(skuID.orJust(''))

    const placeholderText = isNoPlanDiscount ? discountedText : discountedTextWithServicePlan

    const tagBackgroundColor: string | undefined = path([ 'promotionalTagging', 'tagBackgroundColor' ], data) || undefined
    const taggingText = getJson(path([ 'promotionalTagging', 'taggingText' ], data))
    const tagTextColor: string | undefined = path([ 'promotionalTagging', 'textColor' ], data) || undefined

    return (
      <CardItemSmallBanner
        {...props}
        offerTag={
          isPromoTopBanner ? {
            placeholderText: placeholderText.orJust(''),
            tagBackgroundColor,
            tagTextColor,
            taggingText
          } : undefined
        }
        price={price}
      />
    )
  }

export default CardItemSmallBannerComponent

export const query = graphql`#graphql
    fragment promotionalTagging on ContentfulPromotionalTagging {
      id
      tagBackgroundColor
      textColor
      borderColor
      taggingText {
        json
      }
      tagBackgroundColor
    }
    fragment contentfulCardItemSmallBanner on ContentfulProductCard {
      id
      internal {
        type
      }
      textPosition
      spaceId
      additionalInfo {
        json
      }
      productDescription {
        json
      }
      productHighlights {
        json
      }
      title
      button {
        text
        url
        type
      }
      image {
        file {
          url
          fileName
        }
        fluid(maxWidth: 300) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        title
      }
      productId
      promotionalTagging {
        ...promotionalTagging
      }
    }
`
