import * as Promo from '@simplisafe/ss-react-components/promotions'
import React, { FC } from 'react'

import type { LeadCaptureFormPropsOnSubmit } from '../LeadCaptureForm'
import type { ActivePromoBannerType } from '.'
import CartLayout from './CartLayout'
import DesktopLayout from './DesktopLayout'

export type DesktopBannerProps = {
  readonly isFormSubmitted: boolean
  readonly onEmailSubmit: LeadCaptureFormPropsOnSubmit
  readonly type: ActivePromoBannerType
}

// Shows at the top of the page for tablet/desktop.
const DesktopBanner: FC<DesktopBannerProps> = ({
  isFormSubmitted,
  onEmailSubmit,
  type,
}: DesktopBannerProps) => {

  return (
    <Promo.PromoBanner position='top'
      role='banner'>
      {type === 'cart' ? <CartLayout /> : <DesktopLayout
        isFormSubmitted={isFormSubmitted}
        onEmailSubmit={onEmailSubmit}
        type={type}
      />}
    </Promo.PromoBanner>
  )
}

export default DesktopBanner
