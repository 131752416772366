import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import { ContactFormSchema } from '@simplisafe/ss-ecomm-data/leads/contactUsFormSchema'
import type { ContactUsFormSubmitBody } from '@simplisafe/ss-ecomm-data/leads/submission'
import { contactUsFormSubmit } from '@simplisafe/ss-ecomm-data/leads/submission'
import { selectLocale } from '@simplisafe/ss-ecomm-data/redux/select'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { SmallTextSection } from '@simplisafe/ss-react-components/molecules'
import { Form, Formik } from 'formik'
import propOr from 'ramda/src/propOr'
import React from 'react'
import { useSelector } from 'react-redux'

import { ContactUsFormFragment } from '../../../graphql'
import getJson from '../../util/getJson'
import ContentfulRichText from '../ContentfulRichText'
import Description from './form-sections/Description'
import Email from './form-sections/Email'
import Message from './form-sections/Message'
import Name from './form-sections/Name'
import PrivacyPolicy from './form-sections/PrivacyPolicy'
import Subject from './form-sections/Subject'
import Submission from './form-sections/Submission'
import SuccessMessage from './form-sections/SuccessMessage'



export type FormValues = {
  readonly email: string
  readonly locale: string
  readonly message: string
  readonly name: string
  readonly subject: string
}

type ContactUsFormProps = {
  readonly data: ContactUsFormFragment
}

const ContactUsForm = ({ data }: ContactUsFormProps) => {
  const contactFormDescription =
    safeProp('contactFormDescription', data)
      .map(getJson)
      .map(json => <ContentfulRichText key={prop('id', data)}
        rawRichText={json}/>)
      .orNull()

  const contactFormPrivacyPolicy =
    safeProp('privacyPolicy', data)
      .map(getJson)
      .map(json => <SmallTextSection description={json}
        key={prop('id', data)}/>)
      .orNull()

  const contactFormsuccessMessageDescription =
    safeProp('successMessageDescription', data)
      .map(getJson)
      .map(json => <SmallTextSection description={json}
        key={prop('id', data)}
        verticalPadding={'small'}/>)
      .orNull()

  const locale = useSelector(selectLocale)

  const initialFormValues: FormValues = {
    email: '',
    locale: locale,
    message: '',
    name: '',
    subject: ''
  }

  return (
    <Formik initialValues={initialFormValues}
      onSubmit={async (formData: ContactUsFormSubmitBody, { setSubmitting, setStatus }) => {
        setSubmitting(false)
        const handleSuccess = () => {
          setStatus({ message: 'success' })
        }
        const handleFailure = (error: Error) => logError(error)
        contactUsFormSubmit(formData)(handleFailure)(handleSuccess)
      }}
      validationSchema={ContactFormSchema}>
      {({ isSubmitting, status }) => (
        <Form>
          {!status && (
            <>
              <Description
                contactFormDescription={contactFormDescription}
                formTitle={propOr<string, string>('', 'formTitle', data)}
              />
              <Name
                nameFieldLabel={propOr<string, string>('', 'nameFieldLabel', data)}
                nameFieldPlaceholder={propOr<string, string>('', 'nameFieldPlaceholder', data)}
              />
              <Email
                emailFieldLabel={propOr<string, string>('', 'emailFieldLabel', data)}
                emailFieldPlaceholder={propOr<string, string>('', 'emailFieldPlaceholder', data)}
              />
              <Subject
                subjectDisclaimer={propOr<string, string>('', 'subjectDisclaimer', data)}
                subjectFieldLabel={propOr<string, string>('', 'subjectFieldLabel', data)}
                subjectOptions={prop('subjectOptions', data)}
              />
              <Message
                messageFieldLabel={propOr<string, string>('', 'messageFieldLabel', data)}
                messageFieldPlaceholder={propOr<string, string>('', 'messageFieldPlaceholder', data)}
              />
              <Submission
                submitButtonDisabled={isSubmitting}
                submitButtonLabel={propOr<string, string>('', 'submitButtonLabel', data)}
              />
              <PrivacyPolicy
                contactFormPrivacyPolicy={contactFormPrivacyPolicy}
              />
            </>
          )}
          {status && status.message && (
            <SuccessMessage
              successMessageDescription={contactFormsuccessMessageDescription}
              successMessageTitle={propOr('', 'successMessageTitle', data)}
            />
          )}
        </Form>
      )}
    </Formik>
  )
}

export default ContactUsForm
