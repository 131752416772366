import { safePath } from '@simplisafe/monda'
import { Divider } from '@simplisafe/ss-react-components/atoms'
import { graphql } from 'gatsby'
import React, { FC } from 'react'

import { DividerFragment } from '../../../graphql'

export type DividerComponentProps = {
  readonly data: DividerFragment
}

const DividerComponent: FC<DividerComponentProps> = ({ data }: DividerComponentProps) => {
  const {
    borderWidth,
    id,
    width,
  } = data

  return <Divider
    borderWidth={borderWidth ? borderWidth : undefined}
    color={safePath([ 'color', 'color' ], data)
      .getOrElse(undefined)}
    key={id}
    width={width ? width : undefined}
  />
}

export default DividerComponent

export const DividerQuery = graphql`#graphql
  fragment divider on ContentfulDivider {
    borderWidth
    color {
      color
    }
    id
    internal {
      type
    }
    width
  }
`
