import { Address } from '@commercetools/platform-sdk'
import { ImmutableCart } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import { RemoteData } from '@simplisafe/ss-ecomm-data/RemoteData'
import { Maybe } from 'monet'
import propOr from 'ramda/src/propOr'

export const getBillingAddress = (cart: RemoteData<ImmutableCart>): Maybe<Address> => {
  return cart.toMaybe()
    .chain((_cart: ImmutableCart): Maybe<Address> => {
      const defaultAddress = () => _cart.get('shippingAddress')
        .map((address: Address): Address => ({
          country: propOr('', 'country', address),
          email: propOr('', 'email', address)
        }))

      return _cart.get('billingAddress').orElse(defaultAddress())
    })
}
