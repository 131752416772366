import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { ReviewGrid } from '@simplisafe/ss-react-components/organisms'
import { contentProps, ReviewGridProps } from '@simplisafe/ss-react-components/organisms/ReviewGrid'
import { graphql } from 'gatsby'
import always from 'ramda/src/always'
import applySpec from 'ramda/src/applySpec'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import isNil from 'ramda/src/isNil'
import map from 'ramda/src/map'
import pathOr from 'ramda/src/pathOr'
// TODO replace with ts-functional-pipe
// eslint-disable-next-line no-restricted-imports
import pipe from 'ramda/src/pipe'
import React, { FC } from 'react'

import { ContentfulReviewGrid as ContentfulReviewGrid } from '../../../graphql'
import FluidImg from '../FluidImg'

export type ReviewGridComponentProps = {
  readonly data: Partial<ContentfulReviewGrid>
}

const getGatsbyImage = (x: Partial<contentProps>) => {
  const imageTitle: string = pathOr('', [ 'icon', 'title' ], x)
  const iconPosition: string = pathOr('', [ 'iconPosition' ], x)
  const reviewCardStyle: Record<string, unknown> = {
    height: '100%',
    width: '100%'
  }
  const imgStyle: Record<string, unknown> = {
    height: 'auto',
    margin: '0 auto',
    right: '0px',
    width: 'auto'
  }
  const outserStyle = ifElse(equals('Background'), always(reviewCardStyle), always({}))(iconPosition)
  const innerStyle = ifElse(equals('Background'), always({}), always(imgStyle))(iconPosition)

  return (
    <FluidImg
      alt={imageTitle}
      // TODO icon doesn't exist on contentProps
      // @ts-ignore
      fluid={path([ 'icon', 'fluid' ], x)}
      imgStyle={innerStyle}
      style={outserStyle}
    />
  )
}

const toData = applySpec <contentProps>({
  icon: getGatsbyImage,
  iconPosition: prop('iconPosition'),
  iconSize: prop('iconSize'),
  id: path([ 'id' ]),
  text: path([ 'text' ]),
  textAlignment: path([ 'textAlignment', 'json' ]),
  textPosition:path([ 'textPosition' ]),
})

export const toReviewGrid = applySpec<ReviewGridProps>({
  leftContent: pipe(prop('leftContent'), ifElse(isNil, always([]), map(toData))),
  rightContent:pipe(prop('rightContent'), ifElse(isNil, always([]), map(toData))),
  rowOrderParity: prop('rowOrderParity'),
  title:prop('title')
})

const ContentfulReviewGridComponent: FC<ReviewGridComponentProps> =
  ({ data }: ReviewGridComponentProps) => {
    return (<ReviewGrid {...toReviewGrid(data)} />)
  }

export const query = graphql`#graphql
fragment reviewGrid on ContentfulReviewGrid {
  id
  internal {
    type
  }
  title
  leftContent {
    ... on ContentfulIconWithText {
      id
      title
      text {
        id
        json
      }
      iconPosition
      iconSize
      icon {
        title
        file {
          url
          fileName
        }
        fluid {
        ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      textPosition
    }
  }
  rightContent {
    ... on ContentfulIconWithText {
      id
      title
      text {
        id
        json
      }
      iconPosition
      iconSize
      icon {
        id
        title
        file {
          url
          fileName
        }
        fluid {
        ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      textPosition
    }
  }
  rowOrderParity
}
`
export default ContentfulReviewGridComponent
