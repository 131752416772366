/* eslint-disable max-lines */

import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import round from '@simplisafe/ewok/ramda-adjunct/round'
import { safeProp } from '@simplisafe/monda'
import { Column, Row } from '@simplisafe/ss-react-components/atoms'
import { ColumnProps, Spans } from '@simplisafe/ss-react-components/atoms/Column'
import { HeightTypes, RowProps } from '@simplisafe/ss-react-components/atoms/Row'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { CarouselContainer } from '@simplisafe/ss-react-components/molecules'
import { graphql } from 'gatsby'
import { Maybe } from 'monet'
import length from 'ramda/src/length'
import pathOr from 'ramda/src/pathOr'
import React, { FC } from 'react'
import { DeepPartial } from 'redux'

import { ContentfulGroupSection, ContentfulProductCardAccessories } from '../../../graphql'
import { toGapValue, toPaddingValue } from '../../attributeMappings'
import {
  ComponentMappedProps,
  ContentfulComponent,
  getMappedComponent
} from '../../componentMappings'
import { PriceProvider } from '../../providers/PriceProvider'
import { nullToUndefined } from '../../util/helper'
import CardItemSmallBannerComponent from '../CardItemSmallBannerComponent'
import PartnerCaptureForm from '../PartnerCaptureForm'

// This prop is aliased on GroupSection because it conflicts with other components that have a backgroundColor field
// of type String. It doesn't seem like the plugin that generates our GraphQL types takes this into account.
type AliasedContentfulGroupSection = ContentfulGroupSection & {
  readonly groupSectionBackgroundColor: ContentfulGroupSection['backgroundColor']
}

export type GroupSectionProps = {
  readonly data: DeepPartial<AliasedContentfulGroupSection>
}

type displayType = string | null | undefined
type displayCountType = number | null | undefined
const groupSectionComponentMapping = {
  ContentfulPartnerCaptureForm: PartnerCaptureForm,
  ContentfulProductCard: CardItemSmallBannerComponent
}

export const getMappedGroupSectionComponent = (contentfulComponent: ContentfulComponent): React.FC<ComponentMappedProps> | null => (
  groupSectionComponentMapping[pathOr<string, string>('', [ 'internal', 'type' ], contentfulComponent)] ?? null
)

export const renderComponentFromGroupSectionData = (_data?: ContentfulComponent | null) => {
  const data = _data || {}
  const id = prop('id', data)
  const Component = Maybe.fromNull(getMappedGroupSectionComponent(data))
    .orElse(Maybe.fromNull(getMappedComponent(data)))
    .orNull()

  return Component ? (
    <Component
      data={data}
      key={id}
    />
  ) : null
}

/**
 * For mapping Contentful string value to number that can be used for dividing
 * grid tracks (12) by a finite number of columns.
 */
export const groupingTypeMapper: {[key: string]: number} = {
  '2 Columns': 6,
  '3 Columns': 4,
  '4 Columns': 3,
  'Carousel': 12,
  'Row': 12,
}

/** Calculate the span of each column based on the display settings for mobile, tablet, and desktop. */
export const calculateColumns =
    (
      columns: number,
      mobile: displayType,
      tablet: displayType,
      desktop: displayType,
    ) =>
      [ mobile, tablet, desktop ].map((groupingType: displayType) =>
        Maybe.fromNull(groupingType)
          .chain(groupingType => safeProp(groupingType, groupingTypeMapper))
          .cata(
            // If undefined or an unsupported groupingType, default number of columns to number of items equally.
            () => round(12 / columns),
            value => value
          )
      )

export const deriveContents = (
  _rawContentsToGroup: DeepPartial<ContentfulGroupSection['contentsToGroup']>,
  displayCountTablet: displayCountType,
  displayCountMobile: displayCountType,
  isDesktop: boolean,
  isMobile: boolean,
) => {
  const rawContentsToGroup = _rawContentsToGroup || []
  const maxLength = length(rawContentsToGroup)
  const tabletCount = displayCountTablet || maxLength
  const mobileCount = displayCountMobile || maxLength
  return isDesktop ? rawContentsToGroup : isMobile ? rawContentsToGroup.slice(0, mobileCount) : rawContentsToGroup.slice(0, tabletCount)
}

const alignItemsValueMapper: {[key: string]: RowProps['alignItems']} = {
  Center: 'center',
  Stretch: 'stretch'
}
const toAlignItemsValue = value => prop(value, alignItemsValueMapper)

const justifyContentValueMapper: {[key: string]: ColumnProps['justifySelf']} = {
  Center: 'center',
  Stretch: 'stretch'
}
const toJustifyContentValue = value => prop(value, justifyContentValueMapper)

const GroupSection: FC<GroupSectionProps> =
    ({ data }: GroupSectionProps) => {
      const {
        alignItems,
        columnPadding,
        contentsToGroup: rawContentsToGroup,
        equalHeightChildren,
        equalHeightRows,
        gapSize,
        groupingTypeMobile,
        groupingTypeTablet,
        groupingTypeDesktop,
        displayCountTablet,
        displayCountMobile,
        height,
        id,
        justifyContent,
        rowPadding,
        separatorLine
      } = data

      const backgroundColor = path([ 'groupSectionBackgroundColor', 'color' ], data)

      const isMobile = !useMediaQuery('TabletAndUp')
      const isDesktop = useMediaQuery('DesktopAndUp')
      const isMobileCarousel = isMobile && groupingTypeMobile === 'Carousel'

      const contentsToGroup = deriveContents(
        rawContentsToGroup,
        displayCountTablet,
        displayCountMobile,
        isDesktop,
        isMobile,
      )

      const numberOfColumns =
        length(contentsToGroup)

      const spans =
        calculateColumns(
          numberOfColumns,
          groupingTypeMobile,
          groupingTypeTablet,
          groupingTypeDesktop,
        ) as unknown

      const skus = (safeProp('contentsToGroup', data)
        .map(contents => contents.filter((content): content is ContentfulProductCardAccessories => !!content && 'productId' in content ))
      // TODO: fix type
      // @ts-ignore
        .map(contents => contents.map(prop('productId'))
        )
        .orJust([]))
        .filter(isNotNil)

      return (
      // TODO: fix type
      // @ts-ignore
        <PriceProvider skus={skus}>
          <Row
            alignItems={toAlignItemsValue(alignItems)}
            backgroundColor={nullToUndefined(backgroundColor)}
            divider={separatorLine || false}
            equalHeightRows={equalHeightRows ? true : false}
            gap={toGapValue(gapSize)}
            height={nullToUndefined(height) as HeightTypes}
            key={`${id}-row`}
            padding={toPaddingValue(rowPadding)}
            textColor='none'
          >
            { isMobileCarousel && contentsToGroup &&
              <Column
                equalHeightChildren={!!equalHeightChildren}
                justifySelf={toJustifyContentValue(justifyContent)}
                padding={toPaddingValue(columnPadding)}
                spans={[ 12 ]}>
                <CarouselContainer
                  autoplay={false}
                  paginationPosition='bottom-right-to-center'
                  slides={contentsToGroup
                    .map(renderComponentFromGroupSectionData)
                    .filter(component => !!component)
                  }
                />
              </Column>
            }
            { !isMobileCarousel && contentsToGroup && contentsToGroup
              .map(
                (contentData, idx) => {
                  return (
                    <Column
                      equalHeightChildren={!!equalHeightChildren}
                      justifySelf={toJustifyContentValue(justifyContent)}
                      key={`${id}-column-${idx}`}
                      padding={toPaddingValue(columnPadding)}
                      spans={spans as Spans}>
                      { renderComponentFromGroupSectionData(contentData) }
                    </Column>
                  )
                }
              )
            }
          </Row>
        </PriceProvider>
      )
    }

export default GroupSection

export const contentfulGroupSection = graphql`#graphql
    fragment contentfulGroupSectionFragment on ContentfulGroupSection {
      ...__groupSectionFields
      contentsToGroup {
        ... on ContentfulGroupSection {
          ...__groupSectionFields
          contentsToGroup {
            ... on ContentfulGroupSection {
              ...__groupSectionFields
            }
          }
        }
      }
    }

    # This alternative fragment omits certain content in order to avoid self-referential/cyclical queries in certain
    # situations (e.g. a GroupSection that holds a ProductPlan that holds a Modal that holds another GroupSection).
    fragment nonCyclicalGroupSectionFragment on ContentfulGroupSection {
      ...__groupSectionFieldsBasic
      contentsToGroup {
        ... on ContentfulGroupSection {
          ...__groupSectionFieldsBasic
          contentsToGroup {
            ... on ContentfulGroupSection {
              ...__groupSectionFieldsBasic
            }
          }
        }
      }
    }

    fragment __groupSectionFieldsBasic on ContentfulGroupSection {
      ...groupSectionInteranls
      # aliased because backgroundColor conflicts with other components' fields of type String
      groupSectionBackgroundColor: backgroundColor {
        color
      }
      contentsToGroup {
        ... on ContentfulAccordion {
          ...contentfulAccordionItemFragment
        }
        ... on ContentfulSmallTextSection {
          ...smallTextSectionFragment
        }
        ... on ContentfulIconWithText {
          ...iconWithText
        }
        ... on ContentfulButton {
          ...contentfulButtonFragment
        }
        ... on ContentfulProductCardAccessories {
          ...productCardAccessories
        }
        ... on ContentfulProductCard {
          ...contentfulCardItemSmallBanner
        }
        ... on ContentfulCrimeLocationGrid {
          ...contentfulLocationGrid
        }
        ... on ContentfulImage {
          ...contentfulImage
        }
        ... on ContentfulHeading {
          ...heading
        }
        ... on ContentfulCardShop {
          ...cardShop
        }
        ... on ContentfulTestimonialCard {
          ...testimonialCard
        }
        ... on ContentfulBmsHeroItem {
          ...contentfulBmsHeroItemFragment
        }
        ... on ContentfulMeetTheSystem {
          ...meetTheSystemFragment
        }
        ... on ContentfulRichTextWithOptions {
          ...richTextWithOptions
        }
        ... on ContentfulFindYourPerfectSystem {
          ...quoteWizard
        }
        ... on ContentfulContactUsForm {
          ...contactUsForm
        }
        ... on ContentfulLiveChatTrigger {
          ...liveChatTrigger
        }
        ... on ContentfulImageWithFloatingBadge {
          ...imageWithFloatingBadge
        }
        ... on ContentfulGetaQuoteForm {
          ...getAQuoteForm
        }
        ... on ContentfulImageWithFocalPoint {
          ...imageWithFocalPoint
        }
      }
    }

    # This contains fragments for grouped content that can otherwise be cyclical
    # (e.g. a GroupSection that holds a ProductPlan that holds a Modal that can hold another GroupSection)
    fragment __groupSectionFields on ContentfulGroupSection {
      ...__groupSectionFieldsBasic
      contentsToGroup {
        ... on ContentfulProductPlan {
          ...contentfulProductPlan
        }
        ... on ContentfulTwoColumn {
          ...contentfulTwoColumnFragment
        }
        ... on ContentfulModal {
          ...modalFragment
        }
        ... on ContentfulAdditionalInfoBanner {
          ...OverlayBanner
        }
        ... on ContentfulBanner {
          ...contentfulBanner
        }
        ... on ContentfulVariationContainer {
          ...variationContainer
        }
        ... on ContentfulCardKit {
          ...cardKit
        }
      }
    }

    fragment groupSectionInteranls on ContentfulGroupSection {
      id
      alignItems
      columnPadding
      equalHeightChildren
      equalHeightRows
      internal {
        type
      }
      gapSize
      justifyContent
      rowPadding
      groupingTypeMobile
      groupingTypeTablet
      groupingTypeDesktop
      displayCountTablet
      displayCountMobile
      height
    }
`
