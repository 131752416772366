import { ImageWithFloatingBadge } from '@simplisafe/ss-react-components/molecules'
import { graphql } from 'gatsby'
import propOr from 'ramda/src/propOr'
import React from 'react'

import type { ImageWithFloatingBadgeFragment } from '../../../graphql'
import { ContentfulComponent } from '../../componentMappings'
import { renderComponentFromData } from '../../util/render'

export type ImageWithFloatingBadgeComponentProps = {
  readonly data: ImageWithFloatingBadgeFragment
}

const ImageWithFloatingBadgeComponent: React.FC<ImageWithFloatingBadgeComponentProps> = ({ data }: ImageWithFloatingBadgeComponentProps) => {
  const text: string = propOr<string, string>('', 'badgeText', data)
  const rotation: number = propOr<number, number>(0, 'badgeRotation', data)
  const backgroundImage: ContentfulComponent = propOr<ContentfulComponent, ContentfulComponent>({}, 'backgroundImage', data)

  return (
    <ImageWithFloatingBadge badgeRotation={rotation} badgeText={text}
      image={backgroundImage !== {} && renderComponentFromData(backgroundImage)} />
  )
}

export default ImageWithFloatingBadgeComponent

export const ImageWithFloatingBadgeQuery = graphql`
  #graphql
  fragment imageWithFloatingBadge on ContentfulImageWithFloatingBadge {
    badgeText
    badgeRotation
    backgroundImage {
      ... on ContentfulImage {
        ...contentfulImage
      }
      ... on ContentfulImageWithFocalPoint {
        ...imageWithFocalPoint
      }
    }
    internal {
      type
    }
    entryTitle
  }
`
