import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { Info } from '@simplisafe/ss-react-components/icons'
import { IconWithText } from '@simplisafe/ss-react-components/molecules'
import { graphql } from 'gatsby'
import pathOr from 'ramda/src/pathOr'
import propOr from 'ramda/src/propOr'
import React, { FC } from 'react'

import { IconWithTextFragment } from '../../../graphql'
import {
  toIconPositionValue, toIconSizeValue, toIconVerticalAlignmentValue, toTextAlignmentValue
} from '../../attributeMappings'
import FluidImg from '../FluidImg'
import ModalComponent from '../ModalComponent'
import RichText from '../RichText'

type IconWithTextContainerProps = {
  readonly data: IconWithTextFragment
}

const IconWithTextComponent: FC<IconWithTextContainerProps> =
  ({ data }: IconWithTextContainerProps) => {

    const iconPosition = propOr('Left', 'iconPosition', data)
    const iconSize = propOr('medium', 'iconSize', data)
    const verticalAlignment = propOr('middle', 'iconVerticalAlignment', data)
    const textAlignment = propOr('Left', 'textAlignment', data)
    const modalContent = prop('modalContent', data)
    return (
      <IconWithText
        icon={
          <FluidImg
            alt={path([ 'icon', 'title' ], data)}
            fluid={path([ 'icon', 'fluid' ], data)}
          />
        }
        iconPosition={toIconPositionValue(iconPosition)}
        iconSize={toIconSizeValue(iconSize)}
        iconVerticalAlignment={toIconVerticalAlignmentValue(verticalAlignment)}
        text={<div style={{ display:'flex' }}><RichText json={pathOr<string, string>('', [ 'text', 'json' ], data)} />{modalContent && <ModalComponent clickTarget={<Info forceButtonMode={true}
          titleA11y="show more info"/>}
        data={modalContent}/>}</div>}
        textAlignment={toTextAlignmentValue(textAlignment)}
      />
    )
  }

export default IconWithTextComponent

export const IconWithTextQuery = graphql`#graphql
  fragment iconWithText on ContentfulIconWithText {
    ...iconWithTextInteranls
    icon {
      fixed(width: 188, height: 189) {
        ...GatsbyContentfulFixed_withWebp_noBase64
      }
      fluid(maxWidth: 400) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
      title
    }
    modalContent {
      ... on ContentfulModal {
        internal {
          type
        }
        # TODO : using specific queries since facing errors while using the fragment, need to fix as soon as possible
        # error showing : Cannot spread fragment "modalFragment" within itself via 
        # contentfulTwoColumnFragment, __twoColumnBannerBasic, popupButtonFragment, addExtraSensorsFragment, contentfulIconWithTextFragment.
        modalContent {
          ... on ContentfulRichTextWithOptions {
            ...richTextWithOptions
          }
          ... on ContentfulTwoColumn {
            ...contentfulTwoColumnInteranls
            backgroundColor
            padding
            leftContent {
              ... on ContentfulSmallTextSection {
                ...smallTextSectionFragment
              }
            }
            rightContent {
              ... on ContentfulGroupSection {
                ...groupSectionInteranls
                headerText
                contentsToGroup {
                  ... on ContentfulIconWithText {
                    ...iconWithTextInteranls
                    icon {
                      fluid(maxWidth: 300) {
                          ...GatsbyContentfulFluid_withWebp_noBase64
                      }
                        title
                      }
                      text {
                        json
                    }
                    iconObjectFit
                    textPosition
                    title
                  }
                }
              }
            }
          }
        }
        modalSize
      }
    }
  }

  fragment iconWithTextInteranls on ContentfulIconWithText {
    id
    iconPosition
    iconSize
    iconVerticalAlignment
    internal {
      type
    }
    textAlignment
    text {
      json
    }
  }
`
