export const deviceIdStorageKey = 'deviceId'
export const TITLE_SHIPPING_ADDRESS = 'SHIPPING ADDRESS'
export const TITLE_SHIPPING_OPTIONS = 'SHIPPING OPTIONS'
export const TITLE_COUPONS = 'COUPONS'
export const TITLE_EMAIL = 'EMAIL'
export const TITLE_CUSTOMER_SERVICE_INFO = 'CUSTOMER SERVICE INFO'
export const TITLE_HOW_HEAR_INFO = 'How\'d you hear about us?'
export const KEY_AUTHENTICATED = 'auth0.is.authenticated'
export const TYPE_ERROR = 'manual'
export const OFFER_PROP_NAME = 'offerAndTip'
export const EPSILON_ABACUS_PROP_NAME = 'epsilonAbacusOptIn'

/* TODO Partner with us Request body Params need to be revisited fir dynamic data */
export const BUSINESS_TYPE = 'Partners'
export const PHONE = '8005551234'
export const SOURCE = 'business_security_desktop'
