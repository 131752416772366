import { FluidObject } from 'gatsby-image'
import always from 'ramda/src/always'
import cond from 'ramda/src/cond'
import isNil from 'ramda/src/isNil'
import replace from 'ramda/src/replace'

/**
 * Use the x and y from an image with focal point to update the contentful src url to focus on that point.
 * https://www.contentful.com/developers/docs/references/images-api/#/reference/resizing-&-cropping/specify-focus-area
 */

type Focus = 'center' | 'top' | 'right' | 'left' | 'bottom' | 'top_right' | 'top_left' | 'bottom_right' | 'bottom_left'

// replace the f= parameter in a contentful image request to set the desired focal point
export const replaceF =
  (newFocus: Focus) =>
    (srcUrl: string) =>
      replace(/f=[^& \n]*/g, `f=${newFocus}`, srcUrl)

const getThirds = (width: number) => {
  const third = width / 3
  return [ third, third * 2 ]
}

export const setFocus = (x: number | undefined, y: number | undefined) => {
  return isNil(x) ? (y || 0) / 2 : x
}

export const getXFocus = (x: number, width: number) => {
  const [ left, center ] = getThirds(width)
  const isEmpty = isNil(x)
  const isLeft = x <= left
  const isCenter = x > left && x <= center
  const isRight = x > center
  return cond([
    [ always(isEmpty), always('center') ],
    [ always(isLeft), always('left') ],
    [ always(isCenter), always('center') ],
    [ always(isRight), always('right') ]
  ])()
}

export const getYFocus = (x: number, height: number) => {
  const [ top, center ] = getThirds(height)
  const isEmpty = isNil(x)
  const isTop = x <= top
  const isCenter = x > top && x <= center
  const isBottom = x > center
  return cond([
    [ always(isEmpty), always('center') ],
    [ always(isTop), always('top') ],
    [ always(isCenter), always('center') ],
    [ always(isBottom), always('bottom') ]
  ])()
}

const getFocus = (x: string, y: string) => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return (y + '_' + x)
    .replace('center_center', 'center')
    .replace('center_', '')
    .replace('_center', '') as Focus
}

export const transformFluid = (fluid: FluidObject, x: number, y: number): FluidObject => {
  const xFocus = getXFocus(x, 900)
  const yFocus = getYFocus(y, 900)
  const focus = getFocus(xFocus, yFocus)

  return {
    ...fluid,
    src: replaceF(focus)(fluid.src),
    srcSet: replaceF(focus)(fluid.srcSet),
    srcSetWebp: replaceF(focus)(fluid.srcSetWebp ?? ''),
    srcWebp: replaceF(focus)(fluid.srcWebp ?? ''),
  }
}
