/* eslint-disable camelcase */
import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import {
  IOAddToCart, IOCreateOrUpgradeCart, IOVerifyEligibility, IOVerifySS2Eligibility
} from '@simplisafe/ss-ecomm-data/cart/actions'
import { Locale } from '@simplisafe/ss-ecomm-data/commercetools/locale'
import { ACTION } from '@simplisafe/ss-ecomm-data/redux/actions'
import { selectLocale } from '@simplisafe/ss-ecomm-data/redux/select'
import { ImmutableState } from '@simplisafe/ss-ecomm-data/redux/state'
import { fetchUserCheckoutDataWithRedirect, handleRedirectCallback } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { graphql, navigate } from 'gatsby'
import { get, set } from 'local-storage'
import { Maybe } from 'monet'
import cond from 'ramda/src/cond'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import T from 'ramda/src/T'
import React, {
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { StringParam, useQueryParams } from 'use-query-params'

import { AuthenticationFragment } from '../../../graphql'
import { setDeviceId } from '../../util/helper'
import { mapPageComponentToTemplate, PageProps } from '../Page'

export type AuthenticationProps = {
  readonly data: AuthenticationFragment
  readonly location: PageProps['location']
  readonly pageContext: PageProps['pageContext']
  readonly children: ReactNode
}

const localeMapping = {
  'en-GB': 'GB',
  'en-US': 'US',
}

export const DEVICE_ID = 'deviceId'
export const USER_ID = 'userId'

const ss2UpgradeKey = 'SS2 Upgrade'
const interactiveMonitoringKey = 'Interactive Monitoring Discount'

const ss2UpgradeFlow = (
  href: string,
  locale: Locale,
  dispatch: ThunkDispatch<ImmutableState, void, ACTION>,
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>,
  setFailedAuthentication: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const localeSuffix = localeMapping[locale]
  const failAuthentication = () => setFailedAuthentication(true)
  handleRedirectCallback(get(DEVICE_ID), href)(failAuthentication)(userAuthData => {
    const { id } = userAuthData
    IOVerifySS2Eligibility(id, failAuthentication, () => {
      dispatch(IOCreateOrUpgradeCart(id, `ss2Customer-${localeSuffix}`))
      setIsAuthenticated(true)
    })
  })
}

const interactiveUpgradeFlow = (
  href: string,
  locale: Locale,
  dispatch: ThunkDispatch<ImmutableState, void, ACTION>,
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>,
  setFailedAuthentication: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const customerGroup = `interactiveCustomers-${localeMapping[locale]}`
  const failAuthentication = () => setFailedAuthentication(true)

  const verifyEligibility = (id: string) => {
    IOVerifyEligibility(id, customerGroup, failAuthentication, () => {
      dispatch(IOAddToCart({ products: [] }, () => null, () => {
        dispatch(IOCreateOrUpgradeCart(id, customerGroup))
      }))
      setIsAuthenticated(true)
    })
  }
  handleRedirectCallback(get(DEVICE_ID), href)(() => {
    Maybe.fromFalsy(get<string>(USER_ID)).cata(
      failAuthentication,
      verifyEligibility
    )
  })(userAuthData => {
    const { id } = userAuthData
    set(USER_ID, id)
    verifyEligibility(id)
  })
}

export const getHrefWithoutSearch = (location: PageProps['location']): string => Maybe.fromUndefined(location)
  .map(({ search, href }) => (
    Maybe.fromUndefined(href)
      .map(_href => search ? _href.substring(0, _href.indexOf(search)) : _href)
      .orJust('')
  ))
  .orJust('')

const failedAuth = (failureComponents: ReactNode | readonly ReactNode[]) => ifElse(
  equals(ss2UpgradeKey),
  () => {
    navigate('/alarm-sensors')
    return <div />
  },
  () => <>{failureComponents}</>,
)

const fromAppAuth = (utmSource?: string | null, utmMedium?: string | null, planStr?: string | null, locale?: Locale): boolean => {
  const plans: readonly string[] = Maybe.fromFalsy(planStr)
    .map(plan => plan.split(','))
    .orJust([])

  const isUsLocale = locale ? locale === 'en-US' : true
  return !!(utmSource && utmMedium && isUsLocale && plans.includes('SSEDSM2'))
}

const AuthenticationComponent: FC<AuthenticationProps> = ({
  data, children, location, pageContext
}: AuthenticationProps) => {
  const [ isAuthenticated, setIsAuthenticated ] = useState(false)
  const [ failedAuthentication, setFailedAuthentication ] = useState(false)
  const type = prop('type', data) || ''
  const href = getHrefWithoutSearch(location)

  const selectorLocale = useSelector(selectLocale)
  const locale = pageContext.language || selectorLocale

  const [ query ] = useQueryParams({
    code: StringParam,
    plan: StringParam,
    state: StringParam,
    utm_medium: StringParam,
    utm_source: StringParam
  })

  const {
    code, state, utm_source: utmSource, utm_medium: utmMedium, plan
  } = query

  const fromCallback = code && state
  const isFromAppAuth = fromAppAuth(utmSource, utmMedium, plan, locale)
  const isSS2Upgrade = type === ss2UpgradeKey
  const isInteractiveMonitoring = type === interactiveMonitoringKey

  const dispatch = useDispatch()

  const authenticate = useCallback(() => fetchUserCheckoutDataWithRedirect(get(DEVICE_ID), href)(logError)(() => null), [ href ])

  const callback = cond([
    [ equals(ss2UpgradeKey), () => ss2UpgradeFlow(href, locale, dispatch, setIsAuthenticated, setFailedAuthentication) ],
    [ equals(interactiveMonitoringKey), () => interactiveUpgradeFlow(href, locale, dispatch, setIsAuthenticated, setFailedAuthentication) ],
    [ T, () => <>{children}</> ]
  ])

  const failureState = safeProp('failureState', data)
    .map(fragments => fragments.map(fragment => fragment && mapPageComponentToTemplate(fragment, pageContext, location)))
    .orJust([])

  useEffect(() => {
    setDeviceId()
    !fromCallback && isSS2Upgrade && authenticate()
    !fromCallback && isInteractiveMonitoring && isFromAppAuth && authenticate()

    fromCallback && callback(type)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const defaultBehavior = (): ReactElement => isAuthenticated ? <>{children}</> : failedAuthentication ? failedAuth(failureState)(type) : <div />

  return cond([
    [ equals(ss2UpgradeKey), defaultBehavior ],
    [ equals(interactiveMonitoringKey), () => isAuthenticated ? <>{children}</> : failedAuth(failureState)(type) ],
    [ T, () => <>{children}</> ]
  ])(type)
}

export default AuthenticationComponent

export const AuthenticationQuery = graphql`#graphql
  fragment authentication on ContentfulAuthentication {
    internal {
      type
    }
    failureState {
      ... on ContentfulAlarmSensors {
        ...alarmSensors
      }
    }
    type
  }
`
