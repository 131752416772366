import { FormField, Text } from '@simplisafe/ss-react-components/atoms'
import React, { ReactElement } from 'react'

type ContactFormPrivacyPolicyProps = {
  readonly contactFormPrivacyPolicy?: ReactElement
}

const PrivacyPolicy: React.FC<ContactFormPrivacyPolicyProps> = (props: ContactFormPrivacyPolicyProps) => {
  return (
    <FormField hideLabel={true} name='contactFormPrivacyPolicy'>
      <Text textSize='xs'>
        {props.contactFormPrivacyPolicy}
      </Text>
    </FormField>
  )
}

export default PrivacyPolicy
