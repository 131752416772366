import type { Block, Inline } from '@contentful/rich-text-types'
import path from '@simplisafe/ewok/ramda/path'
import {
  safeFind, safePath, safeProp
} from '@simplisafe/monda'
import { VideoPlayer } from '@simplisafe/ss-react-components/atoms'
import Img from 'gatsby-image'
import cond from 'ramda/src/cond'
import equals from 'ramda/src/equals'
// TODO replace with ts-functional-pipe
// eslint-disable-next-line no-restricted-imports
import pipe from 'ramda/src/pipe'
import props from 'ramda/src/props'
import React, { ReactNode } from 'react'

import { returnNextBusinessDay } from '../../util/businessDays'
import ModalComponent from '../ModalComponent'
import RichText from '../RichText'

export function getContentType<T>(obj: T): string {
  // @ts-ignore obj needs to be typed correctly
  const contentType = path([ 'data', 'target', 'sys', 'contentType' ], obj)
  // @ts-ignore obj needs to be typed correctly
  return path([ 'sys', 'id' ], contentType) || ''
}

export function getFieldType<T>(obj: T): string {
  // @ts-ignore obj needs to be typed correctly
  return path([ 'data', 'target', 'fields', 'type' ], obj) || ''
}

type AssetHyperlinkFileData = {
  readonly contentType: string
  readonly details?: {
    readonly image?: {
      readonly height?: number
      readonly width?: number
    }
  }
  readonly url: string
}

/**
 * Handles rendering RichText links that link to Contentful assets. The link will
 * render inline and open a modal containing the asset when clicked.
 */
export const renderAssetHyperlink = (node: Block | Inline, children: ReactNode): ReactNode => {
  const asset: Partial<AssetHyperlinkFileData> = safePath([ 'data', 'target', 'fields', 'file' ], node).orJust({})
  const [ contentType, url ] = props([ 'contentType', 'url' ], asset)
  const { width, height } = safePath([ 'details', 'image' ], asset).orJust({})
  const title = safePath([ 'data', 'target', 'fields', 'title' ], node).orJust('')
  const imgStyles = {
    maxHeight: '65vh',
    maxWidth: '90vw',
    objectFit: 'contain',
    position: 'relative'
  }

  const modalContent = url && contentType && cond([
    [ () => contentType.includes('mp4'),
      () => <VideoPlayer
        autoplay={true}
        // enable controls when autoplay enabled to comply w/ WCAG Success Criterion 2.2.2 Pause, Stop, Hide
        controls
        srcMP4={url}
      />
    ],
    [ () => contentType.includes('image'),
      () => width && height && <Img alt={title}
        fixed={{
          height,
          src: url,
          // We don't get srcSet for free when an image is embedded in RichText.
          // We could manually create one using the img url in the future, but for now, this will just fall back to src.
          srcSet: '',
          width
        }}
        imgStyle={imgStyles}
        style={imgStyles}
        title={title}
      />
    ]
  ])()

  return modalContent ? (
    <ModalComponent
      clickTarget={<button style={{
        background: 'none',
        border: 0,
        cursor: 'pointer',
        fontSize: 'inherit',
        fontWeight: 'inherit',
        padding: 0,
        textDecoration: 'underline',
        userSelect: 'auto'
      }}>{children}</button>}
      closeButtonOutside={true}
      modalContent={modalContent}
    />
  ) : null
}

export const renderModalVideoHyperlink = (title: string, uri: string, children: ReactNode): ReactNode => (
  <ModalComponent
    clickTarget={<button style={{
      background: 'none',
      border: 0,
      cursor: 'pointer',
      fontSize: 'inherit',
      fontWeight: 'inherit',
      padding: 0,
      textDecoration: 'underline',
      userSelect: 'auto'
    }}>{children}</button>}
    closeButtonOutside={true}
    modalContent={
      <iframe
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        frameBorder="0"
        src={uri}
        style={{
          height: '480px',
          maxHeight: '100%',
          maxWidth: '100%',
          width: '720px'
        }}
        title={title}
      >
      </iframe>
    }
  />
)

export const renderEmbeddedEntryBlock = (node: Block | Inline) => {
  // @ts-ignore target doesn't exist on data
  const title = path([ 'data', 'target', 'fields', 'clickTarget', 'fields', 'text' ], node)
  // @ts-ignore target doesn't exist on data
  const modalContentData = path([ 'data', 'target', 'fields', 'modalContent', 'fields', 'richText' ], node)
  // @ts-ignore target doesn't exist on data
  const modalSize: 'small' | 'medium' | 'large' | undefined = path([ 'data', 'target', 'fields', 'modalSize' ], node)

  return modalContentData ? (
    <ModalComponent
      clickTarget={<span className="link"
        style={{
          color: '#008cc1',
          cursor: 'pointer',
        }}>{title}</span>}
      modalContent={<RichText json={modalContentData} />}
      size={modalSize}
      style={{ content: { padding: '60px' } }}
    />
  ) : null
}

// Renders next business date to display shipping estimate for a system
const renderShipEstimateDate = () => <span>{returnNextBusinessDay()}</span>

// Map which content needs to render based on placeholder type
const placeholderTypeRenderMaps: readonly { readonly placeholder: string; readonly fn: (_node: Block | Inline) => JSX.Element }[] = [
  {
    fn: renderShipEstimateDate,
    placeholder: 'Shipping Estimate Date'
  }
]

const renderPlaceholderContent = (node: Block | Inline) => {
  const fieldType = getFieldType(node)
  return safeFind((entry) => entry.placeholder === fieldType, placeholderTypeRenderMaps)
    .chain(safeProp('fn'))
    .map(fn => fn.call(null, node))
    .orNull()
}

/**
 * Renders embedded entry in RichText
 */
export const renderEmbeddedEntry = (node: Block | Inline) => {
  const contentType = getContentType(node)
  // As of now, renderEmbeddedEntryBlock() is set to render modal only
  return contentType === 'placeholder' ? renderPlaceholderContent(node) : renderEmbeddedEntryBlock(node)
}

export const renderSectionId = (node: Block | Inline, referenceId: string) => {
  const sectionId = pipe(getContentType, equals('sectionId'))(node)
  return sectionId ?
    <p id={referenceId}></p> : null
}
