import { Document } from '@contentful/rich-text-types'
import prop from '@simplisafe/ewok/ramda/prop'

/**
 * Checks if a Contentful RichText Document has content or not.
 * Useful to skip rendering of empty blocks.
 *
 * @example
 * return doesDocumentHaveContent(json)
 *  ? <RichText json={json} />
 *  : null
 */
function doesDocumentHaveContent(document: Document){
  const content = prop('content', document)
  return content && content.length > 0
}

export default doesDocumentHaveContent
