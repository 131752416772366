import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import { PartnerFormSchema } from '@simplisafe/ss-ecomm-data/partners/partnersFormSchema'
import type { PartnersSubmitBody } from '@simplisafe/ss-ecomm-data/partners/submission'
import { partnersFormSubmit } from '@simplisafe/ss-ecomm-data/partners/submission'
import { CreatePartnerOrderResponse } from '@simplisafe/ss-ecomm-data/simplisafe/partnersClient'
import {
  Column,
  FormField,
  RichText,
  Row,
  Square,
  Text,
} from '@simplisafe/ss-react-components/atoms'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import {
  Field, Form, Formik
} from 'formik'
import { Link } from 'gatsby'
import { Maybe } from 'monet'
import pathOr from 'ramda/src/pathOr'
import propOr from 'ramda/src/propOr'
import { useEffect, useRef } from 'react'
import * as React from 'react'
import Cookies from 'universal-cookie'
import { StringParam, useQueryParam } from 'use-query-params'

import { PartnerFormFragment } from '../../../graphql'
import { visitorIdAtAt } from '../../tracking/atat'
import { getWebAppUrl } from '../../util/common'
import getJson from '../../util/getJson'
import ShippingAddress from './form-sections/ShippingInformationForm'
import Submit from './form-sections/submitButton'

export type FormValues = {
  readonly address1: string
  readonly address2: string
  readonly city: string
  readonly customerKey: string
  readonly email: string
  readonly firstName: string
  readonly lastName: string
  readonly optIn: boolean
  readonly partnerName: string
  readonly policyId: string
  readonly phone: string
  readonly zoneCode: string
  readonly postal: string
  readonly vidToken: string
};

type PartnerFormProps = {
  readonly data: PartnerFormFragment
  readonly onSubmit: (values: PartnersSubmitBody) => void
};

type CookiesOptions = {
  readonly domain: '.simplisafe.com'
  readonly expires?: Date
}

const cookies = new Cookies()
const cookiesOptions: CookiesOptions = { domain: '.simplisafe.com' } // doing this as session cookie
const COOKIE_MANDO_TOKEN = 'ssOauthAccessToken'
const COOKIE_MANDO_EXPIRES_TOKEN = 'ssOauthAccessExpires'

const PartnerForm = ({ data, onSubmit }: PartnerFormProps) => {
  //get the utm code and policy_id from the url
  const [ keyParam ] = useQueryParam('utm_key', StringParam)
  const [ policyIdParam ] = useQueryParam('utm_id', StringParam) // wont be in for verizon but will be there for other partners
  const isMobile = !useMediaQuery('TabletAndUp')
  const setLoadingTimer = useRef<number>(0)
  const redirectTimer = useRef<number>(0)

  //TODO: Get the partnerName as part of the form, add a dropdown with partners to select in the form Content

  //initial values
  const initialFormValues: FormValues = {
    address1: '',
    address2: '',
    city: '',
    customerKey: Maybe.fromNull(keyParam).getOrElse(''),
    email: '',
    firstName: '',
    lastName: '',
    optIn: true,
    partnerName: Maybe.fromNull(data.partnerName).getOrElse(''),
    phone: '',
    policyId: Maybe.fromNull(policyIdParam).getOrElse(''),
    postal: '',
    vidToken: visitorIdAtAt() || 'NO_VISITOR_ID',
    zoneCode: '',
  }

  useEffect(() => {
    return () => {
      clearTimeout(redirectTimer.current)
      clearTimeout(setLoadingTimer.current)
    }
  }, [ redirectTimer, setLoadingTimer ])

  const forwardPreactivationFlowToWebApp = (response: CreatePartnerOrderResponse) => {
    const webAppUrl = getWebAppUrl()
    cookies.set(COOKIE_MANDO_TOKEN, response.token, cookiesOptions)
    cookies.set(COOKIE_MANDO_EXPIRES_TOKEN, response.expires, cookiesOptions)
    redirectTimer.current = window.setTimeout(() => { window.location.href = `${webAppUrl}/#/collect-monitoring?funnel&orderid=${response.orderId}&sid=${response.sid}}` }, 1500)
  }

  const partnerFormsuccessMessageDescription = safeProp(
    'successMessageDescription',
    data
  )
    .map(getJson)
    .map(json => (
      <RichText
        json={json}
        key={prop('id', data)}
        maxWidth={true}
        padding="medium"
        textAlignment="left"
        textSize="md"
      />
    ))
    .orNull()

  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={
        async (
          formData: PartnersSubmitBody,
          {
            setSubmitting, setStatus, setFieldError
          }
        ) => {
          const handleSuccess = (data: Maybe<CreatePartnerOrderResponse>) => {
            data.cata(() => null, response => response.token && forwardPreactivationFlowToWebApp(response))
            setStatus({ message: 'success' })
          }

          const handleFailure = (error: Error) => {
            const alreadyRedeemed = error.message.includes('Already Redeemed')
            const message = alreadyRedeemed
              ? `It looks like this offer may have already been redeemed. Please contact ${Maybe.fromNull(
                pathOr('', [ 'partnerName' ], data)
              ).getOrElse('')} for further assistance.`
              : 'Uh oh! Looks like something is wrong! try again'
            setFieldError('customerKey', message)
          }
          setLoadingTimer.current = window.setTimeout(() => setSubmitting(true), 200)
          partnersFormSubmit(formData)(handleFailure)(handleSuccess)
          onSubmit && onSubmit(formData)
        }
      }
      validationSchema={PartnerFormSchema}
    >
      {({
        isSubmitting, status, errors
      }) => (
        <Form>
          <Square padding={3}>
            {!status && (
              <>
                <Text className={'p2'}>
                  <h5>
                    {propOr<string, string>(
                      '',
                      'formTitle',
                      data
                    ).toUpperCase()}
                  </h5>
                </Text>
                <ShippingAddress
                  cityFieldLabel={propOr<string, string>(
                    '',
                    'cityFieldLabel',
                    data
                  )}
                  cityFieldPlaceholder={propOr<string, string>(
                    '',
                    'cityFieldPlaceholder',
                    data
                  )}
                  emailFieldLabel={propOr<string, string>(
                    '',
                    'emailFieldLabel',
                    data
                  )}
                  emailFieldPlaceholder={propOr<string, string>(
                    '',
                    'emailFieldPlaceholder',
                    data
                  )}
                  firstNameFieldLabel={propOr<string, string>(
                    '',
                    'firstNameFieldLabel',
                    data
                  )}
                  firstNameFieldPlaceholder={propOr<string, string>(
                    '',
                    'firstNameFieldPlaceholder',
                    data
                  )}
                  isMobile={isMobile}
                  lastNameFieldLabel={propOr<string, string>(
                    '',
                    'lastNameFieldLabel',
                    data
                  )}
                  lastNameFieldPlaceholder={propOr<string, string>(
                    '',
                    'lastNameFieldPlaceholder',
                    data
                  )}
                  locale={'en-US'}
                  phoneNumberFieldLabel={propOr<string, string>(
                    '',
                    'phoneFieldLabel',
                    data
                  )}
                  phoneNumberFieldPlaceholder={propOr<string, string>(
                    '',
                    'phoneFieldPlaceholder',
                    data
                  )}
                  postalCodeFieldLabel={propOr<string, string>(
                    '',
                    'zipCodeLabel',
                    data
                  )}
                  postalCodeFieldPlaceholder={propOr<string, string>(
                    '',
                    'zipCodePlaceHolder',
                    data
                  )}
                  shippingSectionTitle={propOr<string, string>(
                    '',
                    'formTitle',
                    data
                  )}
                  stateFieldLabel={propOr<string, string>(
                    '',
                    'stateFieldLabel',
                    data
                  )}
                  streetAddress2FieldLabel={propOr<string, string>(
                    '',
                    'street2FieldLabel',
                    data
                  )}
                  streetAddress2FieldPlaceholder={propOr<string, string>(
                    '',
                    'street2FieldPlaceholder',
                    data
                  )}
                  streetAddressFieldLabel={propOr<string, string>(
                    '',
                    'street1FieldLabel',
                    data
                  )}
                  streetAddressFieldPlaceholder={propOr<string, string>(
                    '',
                    'street1FieldPlaceholder',
                    data
                  )}
                ></ShippingAddress>
                <Row
                  alignItems="center"
                  equalHeightRows={true}
                  gap="none"
                  padding={2}
                >
                  <Column
                    alignSelf="center"
                    justifySelf="center"
                    padding={2}
                    spans={[ 12, 12, 12 ]}
                  >
                    <Text
                      className={'p2'}
                      fontWeight="light"
                      maxWidth={true}
                      textAlignment="left"
                      textSize="xs"
                    >
                      <label>
                        <Field
                          name="optIn"
                          style={{
                            height: '1rem',
                            width: '1rem',
                          }}
                          type="checkbox"
                        />
                        {propOr<string, string>('', 'optInText', data)}
                      </label>
                      {errors.optIn && (
                        <Text
                          fontWeight="light"
                          textAlignment="left"
                          textColor="darkOrange"
                          textSize="xs"
                        >
                          {errors.optIn}
                        </Text>
                      )}
                    </Text>
                  </Column>
                </Row>

                <Column
                  justifySelf="center"
                  padding="small"
                  spans={[ 12, 12, 12 ]}
                >
                  <Submit
                    showSpinner={isSubmitting}
                    submitButtonDisabled={
                      isSubmitting ||
                      Maybe.fromNull(errors.customerKey).getOrElse('').length >
                        0
                    }
                    submitButtonLabel={propOr<string, string>(
                      '',
                      'submitButtonLabel',
                      data
                    )}
                  ></Submit>
                  {errors.customerKey && (
                    <Text
                      fontWeight="body"
                      textAlignment="center"
                      textColor="darkOrange"
                      textSize="sm"
                    >
                      {errors.customerKey}
                    </Text>
                  )}
                </Column>

                <Row gap="none" padding="small">
                  <Column justifySelf="center" spans={[ 12, 12, 12 ]}>
                    <Text fontWeight="light" textSize="xs"
                      useTailwind={true}>
                      <span>
                        By submitting this order, you agree to SimpliSafe&apos;s{' '}
                        <Link to="/terms-sale">Terms of Sale</Link> ,{' '}
                        <Link to="/terms-of-service">Terms of Service</Link> and{' '}
                        <Link to="/privacy-policy">Privacy Policy</Link>.
                      </span>
                    </Text>
                  </Column>
                </Row>
              </>
            )}
            {status && status.message && (
              <FormField hideLabel={true} name="partnerFormSuccessMessage">
                {propOr('', 'successMessageTitle', data) && (
                  <Row equalHeightRows={true} gap="none"
                    padding={false}>
                    <Column justifySelf="center" spans={[ 12, 12, 12 ]}>
                      <Text
                        fontWeight="medium"
                        textSize="lg"
                        useTailwind={true}
                      >
                        {propOr('', 'successMessageTitle', data)}
                      </Text>
                    </Column>
                  </Row>
                )}
                {partnerFormsuccessMessageDescription}
              </FormField>
            )}
          </Square>
        </Form>
      )}
    </Formik>
  )
}

export default PartnerForm
