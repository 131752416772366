import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import hasPath from '@simplisafe/ewok/ramda-adjunct/hasPath'
import { safeProp } from '@simplisafe/monda'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img, { FluidObject } from 'gatsby-image'
import props from 'ramda/src/props'
import React, { FC, ReactNode } from 'react'

import {
  ContentfulAsset,
  ContentfulImageFragment
} from '../../../graphql'

export type ImageProps = {
  /** If children are passed when rendering an image, it will be rendered as a background image */
  readonly children?: ReactNode
  /** If rendering a background image for a Row, it needs to accept a class name for styling */
  readonly className?: string
  readonly data: ContentfulImageFragment
}

// TODO Look into gatsby video converters
// the videos we have now are already optimized, but we need to make sure we're handling these correctly
// we also want to generate multiple formats
const renderVideo = (asset: Partial<ContentfulAsset>) => {
  const id = prop('id', asset)
  const url = path([ 'file', 'url' ], asset)
  const type = path([ 'file', 'contentType' ], asset)
  return (
    <video
      autoPlay
      // enable controls when autoplay enabled to comply w/ WCAG Success Criterion 2.2.2 Pause, Stop, Hide
      controls
      key={id}
      loop
      muted
      playsInline
      preload='auto'>
      <source
        src={url}
        type={type}/>
    </video>
  )
}

type RenderImageProps = {
  readonly children?: ReactNode
  readonly className?: string
  readonly description?: string
  readonly id?: string
  readonly title?: string
}

const renderImage = ({
  children, className, description, id, title
}: RenderImageProps) =>
  (fluid: FluidObject) => {
    return children ? (
      <BackgroundImage alt={description}
        className={className}
        fluid={fluid}
        key={id}
        title={title}
      >
        {children}
      </BackgroundImage>
    ) : (
      <Img alt={description}
        fluid={fluid}
        key={id}
        title={title}/>
    )
  }

/**
 *
 * A component to render an image, or a video without controls.
 *
 */
const Media: FC<ImageProps> = ({
  children, className, data
}: ImageProps) => {
  const image = safeProp('imageItem', data)
  const [ id, title, description ] = image.map(props([ 'id', 'title', 'description' ])).orJust([])
    .map(val => val ?? undefined)

  const fluid = image.chain(safeProp('fluid'))
  const assetWithFileUrl = image.filter(hasPath([ 'file', 'url' ]))

  return (
    <>
      {// if an asset has a fluid value that means it's an image
        fluid
          .map(renderImage({
            children,
            className,
            description,
            id,
            title
          }))
          // if it doesn't have a fluid value that means it's a video
          // catchMap will be run if the above .map is a None. If it's not an image, we try and render the video content
          .catchMap(() => assetWithFileUrl.map(renderVideo))
          // Now we either render the image, video, or default to null
          .orNull()
      }
    </>
  )
}

export const query = graphql`#graphql
  fragment contentfulImage on ContentfulImage {
    id
    internal {
      type
    }
    imageItem: image {
      id
      contentful_id
      title
      description
      file {
        url
        contentType
      }
      fluid(maxWidth: 1440) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
  }
`

export default Media
