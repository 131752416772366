import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { RichText } from '@simplisafe/ss-react-components/atoms'
import { ComparisonTableRows } from '@simplisafe/ss-react-components/organisms'
import { graphql } from 'gatsby'
import defaultTo from 'ramda/src/defaultTo'
import React, { FC } from 'react'

import { ContentfulPlanComparisonTable, ContentfulPlanComparisonTableRow } from '../../../graphql'
import doesDocumentHaveContent from '../../util/doesDocumentHaveContent'
import getDescriptionJson from '../../util/getDescriptionJson'
import ComparisonTableRowComponent from '../ComparisonTableRowComponent'

type PlanComparisonTableRowsProps = {
  readonly data: Partial<ContentfulPlanComparisonTable>
}

const PlanComparisonTableRows: FC<PlanComparisonTableRowsProps> = ({ data }: PlanComparisonTableRowsProps) => {

  const componentId = defaultTo('')( path([ 'sectionId', 'referenceId' ], data ))

  const description = getDescriptionJson(data)

  const footers = prop('tableFooters', data) || []

  const headers = prop('tableHeaders', data) || []

  const rows = prop('tableRows', data) || []

  return (
    <ComparisonTableRows
      componentId={componentId}
      description={doesDocumentHaveContent(description) && <RichText json={description} />}
      footers={footers as readonly string[]}
      headers={headers as readonly string[]}
      rowsList={rows.map((row, i) => {
        return (
          <ComparisonTableRowComponent
            data={row as ContentfulPlanComparisonTableRow}
            key={`row-${i}`}
          />
        )
      })}
    />
  )
}

export default PlanComparisonTableRows

export const PlanComparisonTableRowsQuery = graphql`#graphql
  fragment planComparisonTableRowsFragment on ContentfulPlanComparisonTable {
    description {
      json
    }
    id
    internal {
      type
    }
    sectionId {
      referenceId
    }
    tableFooters
    tableHeaders
    tableRows {
      ... on ContentfulPlanComparisonTableRow {
        ...comparisonTableRowFragment
      }
    }
  }
`
