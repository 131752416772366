import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import transformObject from '@simplisafe/ewok/transformObject'
import {
  safeFind, safePath, safeProp
} from '@simplisafe/monda'
import { getDynamicPackageAttributeHash } from '@simplisafe/ss-ecomm-data/dynamicPackages'
import {
  Column, RichText, Row, SSButton
} from '@simplisafe/ss-react-components/atoms'
import { EditPackageSensorItem, EditPackageSensorItemContainer } from '@simplisafe/ss-react-components/molecules'
import { EditPackageSensorItemProps } from '@simplisafe/ss-react-components/molecules/EditPackageSensorItemContainer/EditPackageSensorItem'
import { fork } from 'fluture'
import { graphql, navigate } from 'gatsby'
import { Maybe } from 'monet'
import always from 'ramda/src/always'
import clone from 'ramda/src/clone'
import head from 'ramda/src/head'
import values from 'ramda/src/values'
import React, {
  FC, useCallback, useEffect, useState
} from 'react'

import {
  ContentfulBmsSensors, ContentfulPdpPackageSensorEdit, ContentfulTwoColumn
} from '../../../graphql'
import getDescriptionJson from '../../util/getDescriptionJson'

type Child = {
  readonly sku: string
  readonly quantity: number
}

type EditPackageSensorProps = {
  readonly data: Partial<ContentfulPdpPackageSensorEdit>
  readonly childList: readonly Child[]
}

const toEditPackageSensorItem =
(updateQuantity: (quantity: number, sku: string) => void, defaultQuantity: number) =>
  transformObject<ContentfulBmsSensors, EditPackageSensorItemProps>({
    cartUpdatedMessage: c => path([ 'cartUpdatedText', 'text', 'text' ], c),
    defaultQuantityValue: always(defaultQuantity),
    description: c => <RichText json={getDescriptionJson(c)} />,
    onQuantityChange: c => q => updateQuantity(q, safePath([ 'systemComponent', 'componentName' ], c).orJust('')),
    title: c => safeProp('sensorsName', c).orJust('')
  })

const getChildDefaultQuantity =
    (childData: readonly Child[], c) =>
      safeFind(child => child.sku === prop('productId', c), childData)
        .chain(safeProp('quantity'))
        .orJust(0)

const renderTwoColumnComponent =
(updateQuantity: (quantity: number, sku: string) => void, childData: readonly Child[]) =>
  (data: Partial<ContentfulTwoColumn>) => {
    const renderEditPackage = (key: keyof ContentfulTwoColumn) =>
      safeProp(key, data)
        .map(content =>
          content.map(c => {
            const defaultQuantity = getChildDefaultQuantity(childData, c)
            return <Column key={'column'}
              spans={[ 12, 5, 6 ]} >
              <EditPackageSensorItem {...toEditPackageSensorItem(updateQuantity, defaultQuantity)(c)}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                key={prop('sensorsName', c)}/>
            </Column>
          }))
        .orNull()

    return <Row gap={'small'}
      key={`row-${prop('id', data)}`}>
      {renderEditPackage('leftContent')}
      {renderEditPackage('rightContent')}
    </Row>
  }

const mapToColumn =
(contentData: Maybe<readonly ContentfulTwoColumn[]>, updateQuantity: (quantity: number, sku: string) => void, childData: readonly Child[]) =>
  contentData
    .map(head)
    .map(renderTwoColumnComponent(updateQuantity, childData))
    .orNull()

const getButton = (data, onclick, isShowSpinner) =>
  safeProp('button', data)
    .map(content => {
      const type = prop('type', content)
      const textButton = prop('text', content)
      return <div key={'warpperButton'}
        style={{
          textAlign:'center',
          width:'100%'
        }}>
        <SSButton
          color={'primaryOutline'}
          onClick={onclick}
          showSpinner={isShowSpinner}
          target="_blank"
          type={type}>
          {textButton}
        </SSButton>
      </div>
    })

const getButtonLink = (data, onclick) =>
  safeProp('link', data)
    .map(content => {
      const type = 'link'
      const textButton = prop('linkText', content)
      return <div key={'warpperButtonLink'}
        style={{
          textAlign:'center',
          width:'100%'
        }}>
        <SSButton
          color={'link'}
          onClickCtaButton={onclick}
          target="_blank"
          type={type}>
          {textButton}
        </SSButton>
      </div>
    })

const isEmptyItems =
      (items: Record<string, number>) =>
        !values(items)
          .some(i => i > 0)

const EditPackageSensorComponent: FC<EditPackageSensorProps> =
  ({ data, childList }: EditPackageSensorProps) => {
    const additionalSensor = safeProp('additionalSensor', data) as Maybe<readonly ContentfulTwoColumn[]>
    const sensor = safeProp('sensor', data) as Maybe<readonly ContentfulTwoColumn[]>
    const title = safeProp('title', data)
    const [ isShowAnotherSensor, setShowAnotherSensor ] = useState(false)
    const [ isShowSaveButton, setShowSaveButton ] = useState(false)
    const [ isShowSpinner, setIsShowSpinner ] = useState(false)
    const initialList = additionalSensor
      .map(head)
      .chain(
        additionalSensors =>
          sensor
            .map(head)
            .map(
              sensors => {
                const leftContent = [
                  ...prop('leftContent', sensors),
                  ...prop('leftContent', additionalSensors)
                ]
                const rightContent = [
                  ...prop('rightContent', sensors),
                  ...prop('rightContent', additionalSensors)
                ]
                const mergedSensors = [
                  ...leftContent,
                  ...rightContent
                ]
                return mergedSensors
                  .reduce((acc: Record<string, number>, item) => {
                    const componentName = safePath([ 'systemComponent', 'componentName' ], item)

                    return componentName
                      .cata(always(acc), val => ({
                        ...acc,
                        [val]: getChildDefaultQuantity(childList, item)
                      }))
                  }, {})
              })
      )

    const [ itemList, setItemList ] = useState<Maybe<Record<string, number>>>(initialList)
    const updateQuantity = useCallback((quantity: number, productName: string) => {
      setItemList(item => item.map(i => ({
        ...i,
        [productName]: quantity
      })))
      setShowSaveButton(true)
    }, [])
    const onClickButtonSave = useCallback(
      () => {
        itemList.forEach(items => {
          setIsShowSpinner(true)
          getDynamicPackageAttributeHash(items)
            .pipe(fork(() => {
              setIsShowSpinner(false)
            })((hash) => {
              hash.forEach(val => {
                const newVal = clone(val) as {readonly attributeHash: string}
                navigate(`/product/system/${prop('attributeHash', newVal)}`)
                setIsShowSpinner(false)
              })
            }))
        })

      }, [ itemList ])

    const onClickButtonAnotherSensor = () => {
      setShowAnotherSensor(true)
    }

    useEffect(() => {
      itemList.forEach((items) => {
        isEmptyItems(items) && setShowSaveButton(false)
      })

    }, [ itemList ])

    return (
      <>
        <EditPackageSensorItemContainer key={'container'}
          title={title.orJust('')}>
          {mapToColumn(sensor, updateQuantity, childList)}
          {isShowAnotherSensor && mapToColumn(additionalSensor, updateQuantity, childList)}
          {!!isShowSaveButton && getButton(data, onClickButtonSave, isShowSpinner).orNull()}
          {!isShowAnotherSensor && getButtonLink(data, onClickButtonAnotherSensor).orNull()}
        </EditPackageSensorItemContainer>
      </>
    )
  }


export default EditPackageSensorComponent

export const query = graphql`#graphql
    fragment contentfulPackageSensorEditFragment on ContentfulPdpPackageSensorEdit {
        id
        internal {
          type
        }
        title
        button {
          text
          type
          url
          id
        }
        link {
          linkText
        }
        additionalSensor {
          internal {
            type
          }
          leftContent {
            ... on ContentfulBmsSensors {
              ...bmsSensors
            }
          }
          rightContent {
            ... on ContentfulBmsSensors {
              ...bmsSensors
            }
          }
          columnPadding
          gapSize
          id
          margin
          mobilleColumnRatio
          padding
          title
        }
        sensor {
          internal {
            type
          }
          leftContent {
            ... on ContentfulBmsSensors {
              ...bmsSensors
            }
          }
          rightContent {
            ... on ContentfulBmsSensors {
              ...bmsSensors
            }
          }
          columnPadding
          gapSize
          id
          margin
          mobilleColumnRatio
          padding
          title
        }
    }
`
